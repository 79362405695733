<div *ngIf="mostrarPrimerPDF && dataLoaded">
  <div id="htmlData" #htmlData>
    <div class="cabecera">
      <img [src]="base64Image" #imagenCabecera alt="Membrete" *ngIf="base64Image">
    </div>
    <div class="cuerpo">
      <div class="datos-propuesta">
        <p><b>Fecha de iniciacion: </b>{{fechaPropuesta}}</p>
        <p><b>Nº Expediente: </b>{{ExpdtAsoc}} - <b>Nº Propuesta: </b>{{idPropuesta}}</p>
      </div>
      <div class="titulo">
        <p><b>PROPUESTA DE GASTO</b></p>
      </div>
      <div class="parrafo-titulo">
        <p>D./Dña. <b>{{proponente}}</b> como <b>{{cargo}}</b>, emite la siguiente <b>PROPUESTA DE GASTO</b>, para que se autorice si se estima oportuno: </p>
      </div>
      <div class="objeto-contrato">
        <label for="parrafo-objeto"><p><b>1. Objeto del contrato:</b></p></label>
        <div id="parrafo-objeto"><p><b>{{objeto}}</b> con cargo al crédito que se indica, del vigente presupuesto.</p></div>
      </div>
      <div class="datos-aplicacion">
        <label for="parrafo-datos"><p><b>2. Aplicación presupuestaria e importe máximo:</b></p></label>
        <div id="parrafo-datos">
          <table class="tabla-datos">
            <tr>
              <th>Partida</th>
              <th>Aplicacion presupuestaria</th>
              <th>Importe</th>
            </tr>
            <tr>
              <th>{{ejercicio}}.{{cod_prog}}.{{cod_eco}}</th>
              <th>{{desc_aplicacion}}</th>
              <th>{{Importe}}</th>
            </tr>
          </table>
        </div>
      </div>
      <div class="necesidad">
        <label for="parrafo-necesidad"><p><b>3. Necesidad:</b></p></label>
        <div id="parrafo-necesidad"><p>{{necesidad}}</p></div>
      </div>
      <div class="justificacion">
        <label for="parrafo-justificacion"><p><b>4. Justificación de insuficiencia de medios:</b></p></label>
        <div id="parrafo-justificacion"><p>No se dispone de medios materiales ni personales para llevar a cabo esta actuación.</p></div>
      </div>
      <div class="plazo-condiciones">
        <label for="parrafo-plazo"><p><b>5. Plazo de entrega/duración y condiciones del contrato:</b></p></label>
        <div id="parrafo-plazo"><p>{{plazo}}</p></div>
      </div>
      <div class="organo-contratacion">
        <label for="parrafos-organo"><p><b>6. Órgano de contratación:</b></p></label>
        <div id="parrafos-organo">
          <p>El Alcalde (Disp. Adicional 2ª LCSP)</p>
          <p>Por parte de intervención se ha comprobado la disponibilidad, adecuación y suficiencia de cada uno de los créditos que se reflejan en este presupuesto</p>
          <p>El órgano de contratación declara bajo su responsabilidad, que no se está alterando el objeto del contrato con el fin de evitar la aplicación de los umbrales previstos en la Ley. (Artículo 118.1 LCSP)</p>
          <p>El Alcalde-Presidente, como ordenador de pagos del Excmo Ayuntamiento y una vez comprobada la disponibilidad, adecuación y suficiencia de cada uno de los créditos procedentes en la vigente propuesta, se <b>AUTORIZA EL GASTO EXPUESTO</b> en las aplicaciones indicadas y por los importes relacionados, firmando este documento el día que se refleja en el margen de forma electrónica.</p>
        </div>
      </div>
      <div class="firma"><b>DOCUMENTO FIRMADO ELECTRONICAMENTE</b></div>
    </div>
  </div>
</div>

  <!-- Segundo HTML -->
  <div *ngIf="!mostrarPrimerPDF" id="nuevoHtmlData">
    <!-- Otro contenido si es necesario -->
  </div>
  