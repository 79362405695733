import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; //hacer tablas
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../_services/auth.service';
import { Router } from '@angular/router';
import { Block } from '@angular/compiler';


@Component({
  selector: 'app-generar-pdf-propuesta',
  templateUrl: './generar-pdf-propuesta.component.html',
  styleUrls: ['./generar-pdf-propuesta.component.css']
})
export class GenerarPdfPropuestaComponent {
  @Input() data: any;
  @ViewChild('htmlData') htmlData!: ElementRef;
  @ViewChild('imagenCabecera') imagenCabecera!: ElementRef;

  mostrarPrimerPDF = true;
  proponente = "";
  fechaPropuesta = "";
  ExpdtAsoc = "";
  ExpdtGest = "";
  idPropuesta = "";
  cargo = "";
  objeto = "";
  ejercicio = "";
  cod_prog = "";
  cod_eco = "";
  Importe = "";
  necesidad = "";
  plazo = "";
  desc_aplicacion = "";
  idContabilidad = "";
  responsable = "";
  cargoResponsable = "";
  dataLoaded: boolean = false;
  base64Image: string = '';
  blobPDF: any;
  contratoObra: boolean = false;
  @Output() dataReady = new EventEmitter<any>(); //Para devolver el PDF al componente NuevaPropuesta

  constructor(private dialog: MatDialog, private authService: AuthService, private router: Router) { }

  async ngOnInit() {
    try {
      const imgBase64 = await this.preloadImage('/assets/Membrete-2017.png');
      this.base64Image = imgBase64;
      this.setDataInput(this.data);
      this.dataLoaded = true;

      // Generate the PDF based on the presence of expediente asociado
      if (this.ExpdtAsoc == null || this.ExpdtAsoc === "") {
        this.blobPDF = await this.generatePDFConEjercicio();
      } else {
        this.blobPDF = await this.generatePDFCertificadoConsignacion();
      }

      if (this.blobPDF) {
        this.dataReady.emit(this.blobPDF);
      } else {
        console.error('Blob is not initialized');
      }

    } catch (error) {
      console.error('Error loading image or generating PDF:', error);
      this.dataLoaded = true; // Proceed even if there is an error
      //this.showErrorModal(error);
      localStorage.clear();
    }
  }


  mostrarPDF1() {
    this.mostrarPrimerPDF = true;
  }



  private preloadImage(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        } else {
          reject('Failed to get canvas context');
        }
      };
      img.onerror = (error) => reject(error);
    });
  }

  async generatePDFConEjercicio(): Promise<Blob | undefined> {
    if (!this.dataLoaded) {
      console.error('Data or image not loaded yet.');
      return undefined;
    }

    const pdf = new jsPDF('p', 'pt', 'a4');

    // Path to the image in the assets directory
    const imageUrl = this.base64Image;

    try {
      const imageData = await this.loadImage(imageUrl);

      const date = new Date(this.fechaPropuesta);

      // Manually format the date to DD/MM/YYYY
      const formattedDate = ("0" + date.getDate()).slice(-2) + "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) + "/" +
        date.getFullYear();

      // Add image to PDF (50x50 px image at position x=20, y=20)
      pdf.addImage(imageData, 'PNG', 40, 20, 500, 60);

      // Define indentation value
      const leftIndent = 85; // Adjusted for 3 cm indentation
      const rightMargin = 595.28 - 85; // Adjusted to leave a 3 cm margin on both sides


      // Add text to PDF with indentation
      pdf.setFontSize(10); // Adjust font size for better fitting
      // Fecha de iniciacion in bold
      pdf.setFont("helvetica", "bold");
      pdf.text("Fecha de iniciacion: ", leftIndent, 150);

      // Formatted date in normal
      pdf.setFont("helvetica", "normal");
      pdf.text(formattedDate, leftIndent + pdf.getTextWidth("Fecha de iniciacion: ") + 10, 150);


      // Nº Expediente and Nº Propuesta in bold
      pdf.setFont("helvetica", "bold");
      pdf.text("Nº Expediente: ", leftIndent, 170);

      // Nº Expediente value in normal
      pdf.setFont("helvetica", "normal");
      pdf.text(this.ExpdtGest, leftIndent + pdf.getTextWidth("Nº Expediente: ") + 10, 170);

      // Nº Propuesta in bold
      pdf.setFont("helvetica", "bold");
      pdf.text(" - Nº Propuesta: ", leftIndent + pdf.getTextWidth("Nº Expediente: " + this.ExpdtGest) + 10, 170);

      // Nº Propuesta value in normal
      pdf.setFont("helvetica", "normal");
      pdf.text(this.idPropuesta + "", leftIndent + pdf.getTextWidth("Nº Expediente: " + this.ExpdtGest + " - Nº Propuesta: ") + 20, 170);

      pdf.setFont("helvetica", "bold");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const textWidth = pdf.getTextWidth("PROPUESTA DE GASTO");
      const textX = (pageWidth - textWidth) / 2;
      pdf.text("PROPUESTA DE GASTO", textX, 190);

      // Bold proponente and responsable, with other parts in normal
      pdf.setFont("helvetica", "normal");

      var dona = "D./Dña. " + this.proponente + " como " +
        this.cargo + " y cuyo responsable " + this.responsable + ", con cargo " + this.cargoResponsable + ", emite la siguiente PROPUESTA DE GASTO, para que se autorice si se estima oportuno:";

      const splitText = pdf.splitTextToSize(dona, rightMargin - leftIndent);
      pdf.text(splitText, leftIndent, 215);
      // Objeto del contrato
      pdf.setFont("helvetica", "bold");
      pdf.text("1. Objeto del contrato:", leftIndent, 260);

      pdf.setFont("helvetica", "normal");

      var obje = this.objeto + " con cargo al crédito que se indica, del vigente presupuesto.";
      const splitTextObj = pdf.splitTextToSize(obje, rightMargin - leftIndent);
      pdf.text(splitTextObj, leftIndent, 275);

      // Aplicación presupuestaria e importe máximo
      pdf.setFont("helvetica", "bold");
      pdf.text("2. Aplicación presupuestaria e importe máximo:", leftIndent, 305);

      // Table headers bold
      const tableData = [
        ['Partida', 'Aplicacion presupuestaria', 'Importe'],
        [this.ejercicio + "." + this.cod_prog + "." + this.cod_eco, this.desc_aplicacion, this.Importe + " €"]
      ];

      const contentWidth = pageWidth - leftIndent - leftIndent; // width for content within the margins

      // Adjusted cell widths based on content
      const cellWidths = [
        contentWidth * 0.3,  // Partida column (30% of content width)
        contentWidth * 0.5,  // Aplicacion presupuestaria column (50% of content width)
        contentWidth * 0.2   // Importe column (20% of content width)
      ];
      const startX = leftIndent;
      const startY = 315;
      const cellHeight = 20;
      const numRows = tableData.length;

      pdf.setFont("helvetica", "bold");
      for (let col = 0; col < tableData[0].length; col++) {
        pdf.rect(startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0), startY, cellWidths[col], cellHeight);
        pdf.text(tableData[0][col], startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0) + 5, startY + 15);
      }

      // Table rows normal
      pdf.setFont("helvetica", "normal");
      for (let row = 1; row < tableData.length; row++) {
        for (let col = 0; col < tableData[row].length; col++) {
          const cellX = startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0);
          const cellY = startY + cellHeight * row;

          pdf.rect(cellX, cellY, cellWidths[col], cellHeight + 20);
          const cellText = tableData[row][col];
          const textLines = pdf.splitTextToSize(cellText, cellWidths[col] - 10);
          pdf.text(textLines, cellX + 5, cellY + 15);
        }
      }


      pdf.setFontSize(10); // Adjust font size for better fitting

      // Other bold sections
      pdf.setFont("helvetica", "bold");
      pdf.text("3. Necesidad:", leftIndent, 395);
      pdf.setFont("helvetica", "normal");
      pdf.text(this.necesidad + "", leftIndent, 415);


      if (this.contratoObra) {
        const splitTextObra = pdf.splitTextToSize("Se adjunta memoria del técnico al expediente: Memoria Justificativa", rightMargin - leftIndent);
        console.log(splitTextObra);
        pdf.setFont("helvetica", "bold");
        pdf.text("4. Justificación de insuficiencia de medios:", leftIndent, 440);
        pdf.setFont("helvetica", "normal");

        pdf.text(splitTextObra, leftIndent, 455);
      } else {
        pdf.setFont("helvetica", "bold");
        pdf.text("4. Justificación de insuficiencia de medios:", leftIndent, 440);
        pdf.setFont("helvetica", "normal");

        const splitTextO = pdf.splitTextToSize("No se dispone de medios materiales ni personales para llevar a cabo esta actuación.", rightMargin - leftIndent);
        pdf.text(splitTextO, leftIndent, 455);
      }
      // Plazo de entrega/duración y condiciones del contrato
      pdf.setFont("helvetica", "bold");
      pdf.text("5. Plazo de entrega/duración y condiciones del contrato:", leftIndent, 495);
      pdf.setFont("helvetica", "normal");
      pdf.text(this.plazo + "", leftIndent, 515);

      // Órgano de contratación
      pdf.setFont("helvetica", "bold");
      pdf.text("6. Órgano de contratación:", leftIndent, 545);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(11); // Adjust font size for better fitting
      pdf.text("El Alcalde (Disp. Adicional 2ª LCSP)", leftIndent, 560);


      const splitTextA = pdf.splitTextToSize("Por parte de intervención se ha comprobado la disponibilidad, adecuación y suficiencia de cada uno de los créditos que se reflejan en este presupuesto", rightMargin - leftIndent);
      const splitTextB = pdf.splitTextToSize("El órgano de contratación declara bajo su responsabilidad, que no se está alterando el objeto del contrato con el fin de evitar la aplicación de los umbrales previstos en la Ley. (Artículo 118.1 LCSP)", rightMargin - leftIndent);
      const splitTextC = pdf.splitTextToSize("El Alcalde-Presidente, como ordenador de pagos del Excmo Ayuntamiento y una vez comprobada la disponibilidad, adecuación y suficiencia de cada uno de los créditos procedentes en la vigente propuesta, se AUTORIZA EL GASTO EXPUESTO en las aplicaciones indicadas y por los importes relacionados, firmando este documento el día que se refleja en el margen de forma electrónica.", rightMargin - leftIndent);

      pdf.text(splitTextA, leftIndent, 580);
      pdf.text(splitTextB, leftIndent, 615);
      pdf.text(splitTextC, leftIndent, 660);

      pdf.setFont("times", "bold");

      const pageWidthO = pdf.internal.pageSize.getWidth();
      const textWidthO = pdf.getTextWidth("DOCUMENTO FIRMADO ELECTRONICAMENTE");
      const rightIndent = pageWidthO - textWidthO - 80; // 20 is an optional right margin
      pdf.text("DOCUMENTO FIRMADO ELECTRONICAMENTE", rightIndent, 760);

      // Convert the PDF to a Blob and return it
      const blob = pdf.output('blob');
      console.log("Blob created successfully:", blob);
      return blob;

    } catch (error) {
      console.error('Error loading image:', error);
      return undefined;
    }
  }


  async generatePDFCertificadoConsignacion(): Promise<Blob | undefined> {

    if (!this.dataLoaded) {
      console.error('Data or image not loaded yet.');
      return undefined;
    }

    const pdf = new jsPDF('p', 'pt', 'a4');
    const imageUrl = this.base64Image; // Adjust the path as needed

    try {
      // Wait for the image to load before proceeding
      const imageData = await this.loadImage(imageUrl);

      // Add image to PDF (50x50 px image at position x=20, y=20)
      pdf.addImage(imageData, 'PNG', 40, 20, 500, 60);

      // Define indentation value
      const leftIndent = 85; // Adjusted for 3 cm indentation
      const rightMargin = 595.28 - 85; // Adjusted to leave a 3 cm margin on both sides

      pdf.setFontSize(10);
      // 1. "Certificado de Consignación" in bold
      pdf.setFont("helvetica", "bold");
      pdf.text("Certificado de consignación", leftIndent, 130);

      // Procedimiento: Contratación
      pdf.setFont("helvetica", "bold");
      pdf.text("Procedimiento: ", leftIndent, 150);
      pdf.setFont("helvetica", "normal");
      pdf.text("Contratación", leftIndent + pdf.getTextWidth("Procedimiento: ") + 7, 150);

      // Tipo de Informe: Borrador [] Provisional [] Definitivo [X]
      pdf.setFont("helvetica", "bold");
      pdf.text("Tipo de Informe: ", leftIndent, 170);
      pdf.setFont("helvetica", "normal");
      pdf.text("Borrador [] Provisional [] Definitivo [X]", leftIndent + pdf.getTextWidth("Tipo de Informe: ") + 7, 170);

      // Nº Expediente with variable in normal font
      pdf.setFont("helvetica", "bold");
      pdf.text("Nº Expediente: ", leftIndent, 190);
      pdf.setFont("helvetica", "normal");
      pdf.text(this.ExpdtAsoc, leftIndent + pdf.getTextWidth("Nº Expediente: ") + 2, 190);

      // Nº Propuesta with variable in normal font
      pdf.setFont("helvetica", "bold");
      pdf.text("Nº Propuesta: ", leftIndent, 210);
      pdf.setFont("helvetica", "normal");
      pdf.text(this.idPropuesta + "", leftIndent + pdf.getTextWidth("Nº Propuesta: ") + 5, 210);

      var dona = "D./Dña. " + this.proponente + ", con responsable " + this.responsable + " (" + this.cargoResponsable + "), Interventor/a del Excmo Ayuntamiento de Mengíbar ";
      const splitText = pdf.splitTextToSize(dona, rightMargin - leftIndent);
      pdf.text(splitText, leftIndent, 250);
      pdf.text("CERTIFICO", leftIndent, 280);

      var textL = "Que, examinada la documentación obrante en la Intervención a mi cargo, referida al PRESUPUESTO MUNICIPAL PARA EL AÑO 2024," +
        " resulta que existe consignación presupuestaria suficiente o por vinculación para la celebración del " + this.objeto + " " + this.necesidad + " " +
        "por un importe máximo de " + this.Importe + " euros en las siguientes aplicaciones presupuestarias:";

      const splitTextL = pdf.splitTextToSize(textL, rightMargin - leftIndent);
      pdf.text(splitTextL, leftIndent, 320);

      // Define table columns and data
      const part = this.ejercicio + "." + this.cod_prog + "." + this.cod_eco;

      const tableData = [
        ['Partida', 'Aplicacion presupuestaria', 'Importe'],
        [part, this.desc_aplicacion, this.Importe + " €"]
      ];
      const pageWidth = pdf.internal.pageSize.getWidth();
      // Adjusted cell widths based on content
      const contentWidth = pageWidth - leftIndent - leftIndent; // width for content within the margins

      // Adjusted cell widths based on content
      const cellWidths = [
        contentWidth * 0.3,  // Partida column (30% of content width)
        contentWidth * 0.5,  // Aplicacion presupuestaria column (50% of content width)
        contentWidth * 0.2   // Importe column (20% of content width)
      ];
      const startX = leftIndent;
      const startY = 370;
      const cellHeight = 20;
      const numRows = tableData.length;

      // Draw table headers
      pdf.setFontSize(10); // Adjust font size for better fitting

      pdf.setFont("helvetica", "bold");
      for (let col = 0; col < tableData[0].length; col++) {
        pdf.rect(startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0), startY, cellWidths[col], cellHeight);
        pdf.text(tableData[0][col], startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0) + 5, startY + 15);
      }

      // Table rows normal
      pdf.setFont("helvetica", "normal");
      // Draw table rows
      for (let row = 1; row < tableData.length; row++) {
        for (let col = 0; col < tableData[row].length; col++) {
          const cellX = startX + cellWidths.slice(0, col).reduce((a, b) => a + b, 0);
          const cellY = startY + cellHeight * row;

          pdf.rect(cellX, cellY, cellWidths[col], cellHeight + 20);
          const cellText = tableData[row][col];
          const textLines = pdf.splitTextToSize(cellText, cellWidths[col] - 10);
          pdf.text(textLines, cellX + 5, cellY + 15);
        }
      }

      pdf.setFontSize(10); // Adjust font size for better fitting

      var calculoJose = await this.valorPropuesta();
      var diezPorCiento = ((calculoJose * 10)/100).toFixed(2); //2 decimales

      var textLo = "Los recursos ordinarios del presupuesto 2024 ascienden a la cantidad de " + calculoJose +
        "€, siendo el 10% la cantidad de " + diezPorCiento + "€, superior a la de la adquisición que se pretende realizar";

      const splitTextLo = pdf.splitTextToSize(textLo, rightMargin - leftIndent);
      pdf.text(splitTextLo, leftIndent, 480);

      var textLoP = "Y para que conste en el expediente de su razón, extiendo el presente, de orden y con el" +
        "visto bueno del señor Alcalde, en Mengibar con la fecha y firma electrónica que aparece en el margen.";

      const splitTextLop = pdf.splitTextToSize(textLoP, rightMargin - leftIndent);
      pdf.text(splitTextLop, leftIndent, 520);

      // Return the Blob
      return pdf.output('blob');

    } catch (error) {
      console.error('Error generating PDF:', error);
      return undefined;
    }
  }

  // Method to load image as a base64 string
  private loadImage(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Handle cross-origin image if needed
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        } else {
          reject('Failed to get canvas context');
        }
      };
      img.onerror = (error) => reject(error);
    });
  }


  setDataInput(data: any) {
    this.proponente = data[0].proponente;
    this.responsable = data[0].responsable;
    this.fechaPropuesta = data[0].fechaPropuesta;
    this.ExpdtAsoc = data[0].expdtAsoc;
    this.ExpdtGest = data[0].expdtGestiona;
    this.idPropuesta = data[0].idPropuesta;
    this.cargo = data[0].cargo;
    this.cargoResponsable = data[0].cargoResponsable;
    this.objeto = data[0].objeto;
    this.ejercicio = data[0].ejercicio;
    this.cod_prog = data[0].codProg;
    this.cod_eco = data[0].codEco;
    this.Importe = data[0].importe;
    this.necesidad = data[0].necesidad;
    this.idContabilidad = data[0].idContabilidad;
    this.plazo = data[0].plazo;
    this.desc_aplicacion = data[1];
    this.contratoObra = data[2];
  }

  handleImageError(error: any) {
    console.error('Error loading image:', error);
    // Handle image loading error (optional)
    this.openModal(error);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  valorPropuesta(): Promise<number> {
    var numb = 0;
    const token = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${token}` };
    const headers = new HttpHeaders(strHead);
    //Llamada a funcion de jose en SQL
    return this.authService.generarValorPropuesta(this.idContabilidad, this.ejercicio, headers).toPromise()
      .then(datoUPT => {
        if (datoUPT != undefined) {
          var strDato = datoUPT.toString();
          return parseFloat(strDato.replace(',', '.'));
        }
        return -1;

      })
      .catch(err => {
        this.showErrorModal(err);
        localStorage.clear();
        this.router.navigate(['/login']);
        return -1;  // return -1 in case of an error
      });

  }

}