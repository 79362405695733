import { Component, HostListener, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';

@Component({
  selector: 'app-modal-busca-aplpresupuestaria',
  templateUrl: './modal-busca-aplpresupuestaria.component.html',
  styleUrl: './modal-busca-aplpresupuestaria.component.css'
})
export class ModalBuscaAplpresupuestariaComponent {
  terminoBusqueda: string = "";
  typeRow: any[] = [];
  selectedYear: number = 0; // Año predeterminado
  contabilidad: number = 0; // Año predeterminado
  columnDefsUsuario: ColDef[] = [
    {
      field: 'id_conta',
      headerName: 'ID Contabilidad',
      hide: true
    },
    {
      field: 'apl_eje',
      headerName: 'Ejercicio'
      //hide: true
    },
    {
      field: 'apl_fun',
      headerName: 'Codigo Programacion',
      hide: true
    },
    {
      field: 'apl_eco',
      headerName: 'Codigo Economico',
      hide: true
    },
    {
      field: 'des_aplic',
      headerName: 'Descripcion Aplicacion',
      flex: 1,
      minWidth: 100,
      cellRenderer: (params: any) => {
        return `<a href="javascript:void(0)" class="name-link">${params.value}</a>`;
      }
    }
  ];
  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false
  };
  private agGrid: any;

  @Output() rowSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<ModalBuscaAplpresupuestariaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('jwtToken');
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    this.http.get<any>(Constantes.GETVISTA_ESTADOEJECUCION, { headers: headers }).subscribe({
      next: data => {
        this.typeRow = data; // Asignar los datos a typeRow
        const storedYear = localStorage.getItem('yearContable');
        if (storedYear) {
          this.selectedYear = parseInt(storedYear, 10); // Convertir a número
        }
        const contabilidad = localStorage.getItem('contabilidad');
        if (contabilidad) {
          this.contabilidad = parseInt(contabilidad, 10); // Convertir a número
        }

        this.applyFiltroContabilidad();
      },
      error: error => {
        console.log(error);
      }
    });
  }

  onGridReady(params: any): void {
    this.agGrid = params.api;
    this.agGrid.sizeColumnsToFit();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (this.agGrid) {
      this.agGrid.sizeColumnsToFit();
    }
  }

  applySearchFilter(): void {
    if (this.agGrid) {
      this.agGrid.setQuickFilter(this.terminoBusqueda);
    }
  }

  onCellClicked(event: any): void {
    if (event.colDef.field === 'des_aplic') {
      this.rowSelected.emit(event.data);
      this.closeDialog();
    }
  }

  applyFiltroContabilidad(): void {
    
      //  aplicar la restricción por año y contabilidad
      this.typeRow = this.typeRow.filter(row => row.apl_eje === this.selectedYear && row.id_conta === this.contabilidad);
  }
}
