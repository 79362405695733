import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ColDef, FilterChangedEvent, GridOptions, GridReadyEvent, SideBarDef, } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular'
import { ButtonsActionsPropuestaComponent } from '../buttons-actions-propuesta/buttons-actions-propuesta.component';
import { Constantes } from '../../../_services/constantes.service';
import { CustomFilterPanelPropuestaComponent } from '../../AgGridCustom/custom-filter-panel-propuesta/custom-filter-panel-propuesta.component';
import { CustomLeyendaPropuestaComponent } from '../../AgGridCustom/custom-leyenda-propuesta/custom-leyenda-propuesta.component';import { MatDialog } from '@angular/material/dialog';
import { ModalMuestrapropuestaComponent } from '../modal-muestrapropuesta/modal-muestrapropuesta.component';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { RoutingService } from '../../../_services/routing.service';


@Component({
  selector: 'app-propuesta-gasto',
  templateUrl: './propuesta-gasto.component.html',
  styleUrls: ['./propuesta-gasto.component.css'] // Corregir 'styleUrl' a 'styleUrls'
})
export class PropuestaGastoComponent implements OnInit {

  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  isHidden = true;
  tokenJWT: string = "";
  terminoBusqueda: string = "";
  selectedRowData: any;
  typeRow: any[] = [];
  totalBolsa: number = 0;
  tienePermisosVista = false;

  columnDefsPropuesta: ColDef[] = [];

  sideBar: SideBarDef = {}

  gridOptions: GridOptions = {
    //groupDefaultExpanded: 1,
    //rowGroupPanelShow: 'always',
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false,
    context: {
      componentParent: this,
    },
    defaultColDef: {
      sortable: true,
      filter: true,
    },
  };
  currentValue: number = 0;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private redirige: RoutingService, private dialog: MatDialog) { }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');

    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('aplicacionPresupuestariaSeleccionada') != null || localStorage.getItem('aplicacionPresupuestariaSeleccionada') == '') {
            this.selectedRowData = JSON.parse(localStorage.getItem('aplicacionPresupuestariaSeleccionada') || '{}');
            const headers =new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });

            this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
              next: data => {
                var idUsuario=data.idUsuario;
  
                var cadenaGet = Constantes.GET_PERMISO_W_USUARIO + `${idUsuario}`;
                this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
                  next: dataN=>{
                  
                    var encontrado= false;

                    var cadenaGetP = Constantes.GET_PERMISO_BY_NOMBREPERMISO + Constantes.VER_ACCIONES_PROPUESTA;
                      this.http.get<any>(cadenaGetP, { headers: headers }).subscribe({
                        next: dataPerm=>{
                          

                          this.http.get<any>(Constantes.GETPROPUESTA + `${this.selectedRowData.apl_eje}/${this.selectedRowData.apl_fun}/${this.selectedRowData.apl_eco}`, { headers: headers }).subscribe({
                            next: data => {

                              for (let i = 0; i < dataN.length && !encontrado; i++) {
                                if(dataN[i].idPermiso==dataPerm.idPermiso){ //Hemos encontrado el permiso
                                  this.tienePermisosVista=true;
                                }
                                
                              }

                              this.columnDefsPropuesta = [
                                {
                                  field: 'estadoPropuesta',
                                  headerName: 'Estado',
                                  filter: 'agTextColumnFilter',
                                  width: 70,
                                  filterParams: {      
                                    defaultOption: 'equals',
                                    maxNumConditions: 4,
                                  },
                                  cellRenderer: (params: any) => {
                                    const div = document.createElement('div');  // Creamos un div para el círculo
                                    div.className = 'estado-circle';  // Aplicamos una clase CSS para el estilo del círculo
                                    // Definimos el color del círculo basado en el valor de estadoPropuesta
                                    switch (params.value) {
                                      case 0:
                                        div.style.backgroundColor = '#F7F7F7'; // Sin Firmar
                                        break;
                                      case 1:
                                        div.style.backgroundColor = '#F6E962'; // Firmada
                                        break;
                                      case 2:
                                        div.style.backgroundColor = '#84D2A5'; // Contabilizada
                                        break;
                                      case 3:
                                        div.style.backgroundColor = '#D55455'; // Anulado
                                        break;
                                      case 4:
                                        div.style.backgroundColor = '#BED3F2'; // Sin Finalizar
                                        break;
                                      default:
                                        div.innerText = params.value;  // Si no coincide con ningún caso, mostramos el valor
                                    }
                                
                                    // Ajustes de estilo para el círculo
                                    div.style.width = '20px';
                                    div.style.height = '20px';
                                    div.style.borderRadius = '50%';  // Para que sea un círculo
                                    div.style.border = '1pt solid #000';  // Borde del círculo
                                    div.style.margin = 'auto';  // Centrar el círculo en la celda
                                
                                    return div;  // Devolvemos el div como el contenido de la celda
                                  },
                                  autoHeight: true,  // Asegura que la altura de la fila se ajuste automáticamente
                                  cellStyle: {
                                    'display': 'flex',  // Utilizamos flexbox para centrar el contenido
                                    'justify-content': 'center',  // Centrar horizontalmente
                                    'align-items': 'center',  // Centrar verticalmente
                                  },
                                },
                                {
                                  field: 'idPropuesta',
                                  headerName: 'Codigo',
                                  filter: 'agTextColumnFilter',
                                  floatingFilter: true,
                                  cellStyle: {
                                    textAlign: 'center'
                                  },
                                  filterParams: {
                                    defaultOption: 'startsWith',
                                  },
                                  onCellClicked: (params: any) => {
                                    // Call your method, e.g., this.onCodigoClicked(params.data)
                                    this.onCodigoClicked(params.data); // Trigger action with row data
                                  }
                                },
                                { 
                                  field: 'objeto', 
                                  headerName: 'Propuesta', 
                                  width: 400,
                                  floatingFilter: true,
                                  filter: 'agTextColumnFilter',
                                  filterParams: {
                                    defaultOption: 'contains',
                                  },
                                  cellRenderer: (params: any) => {
                                    // Add a clickable span or button
                                    return `<span class="clickable-code">${params.value}</span>`;
                                  },
                                  onCellClicked: (params: any) => {
                                    // Call your method, e.g., this.onCodigoClicked(params.data)
                                    this.onPropuestaObjetoClicked(params.data); // Trigger action with row data
                                    
                                  }
                                },
                                { field: 'proponente',
                                  headerName: 'Proponente',
                                  enableRowGroup: false,
                                  rowGroup: false,
                                  hide: false,
                                  filter: 'agTextColumnFilter',
                                  floatingFilter: true,
                                  filterParams: {
                                    defaultOption: 'contains',
                                  }
                                },
                                { field: 'responsable',
                                  headerName: 'Responsable',
                                  enableRowGroup: false,
                                  rowGroup: false,
                                  hide: false,
                                  filter: 'agTextColumnFilter',
                                  floatingFilter: true,
                                  filterParams: {
                                    defaultOption: 'contains',
                                  }
                                },
                                { field: 'importe',
                                  headerName: 'Importe',
                                  valueFormatter: (params) => this.formatNumber(params.value),
                                  filter: 'agTextColumnFilter',
                                  floatingFilter: true,
                                  cellStyle:{
                                    textAlign: 'right'
                                  },
                                  filterParams: {
                                    defaultOption: 'startsWith',
                                  }
                                },
                                { field: 'Acciones',
                                  cellRenderer: ButtonsActionsPropuestaComponent,
                                  width: 350,
                                  hide: !this.tienePermisosVista
                                }
                              ];
                              this.typeRow = data;
                              this.typeRow.sort((a, b) => b.idPropuesta - a.idPropuesta);
                              this.totalBolsa = this.selectedRowData.creditos_definitivos;
                              this.currentValue = this.selectedRowData.credito_gastado;
              
                              this.sideBar = {
                                toolPanels: [
                                  {
                                    id: 'customToolPanel',
                                    labelDefault: 'Filtros',
                                    labelKey: 'customFilters',
                                    iconKey: 'menu',
                                    toolPanel: CustomFilterPanelPropuestaComponent,  // Panel personalizado que vamos a crear
                                  },
                                  {
                                    id: 'leyenda',
                                    labelDefault: 'Leyenda',
                                    labelKey: 'customFilters',
                                    iconKey: 'eye',
                                    toolPanel: CustomLeyendaPropuestaComponent,  // Panel personalizado que vamos a crear
                                  },
                                ],
                                defaultToolPanel: 'customToolPanelProp',
                              }
                            },
                            error: err => {
                              console.log(err);
                              this.showErrorModal(err);
                            }
                          });
                        },
                        error: err=>{
                          // Redirigir al usuario a la página de inicio de sesión
                          this.showErrorModal(err);
                          this.router.navigate(['/gestionGasto/estadoEjecucion']);
                        }
                      });
                    
  
                    
                  },
                  error: err=>{ 
                    // Redirigir al usuario a la página de inicio de sesión
                    this.showErrorModal(err);
                    this.router.navigate(['/gestionGasto/estadoEjecucion']);
                  }
                });
                  
              },
              error: err => {
                  // Redirigir al usuario a la página de inicio de sesión
                  this.showErrorModal(err);
                  this.router.navigate(['/gestionGasto/estadoEjecucion']);
              }
            });
 
          } else {
            console.error('Aplicacion Presupuestaria Invalida');
            this.openModal('Aplicacion Presupuestaria Invalida');
            localStorage.clear();
            this.router.navigate(['/gestionGasto']);
          }
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  applySearchFilter() {
    if (this.agGrid.api) {
      this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
    }
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}

  setLocalStorageAplicacionPresupuestaria(des_aplic: string, apl_eje: string, apl_fun: string, apl_eco: string, creditos_definitivos: string, credito_gastado: string, credito_disponible: string, disponible_bolsa: string, pagos_realizados: string) {
    const aplicacionPresupuestaria = { des_aplic, apl_eje, apl_fun, apl_eco, creditos_definitivos, credito_gastado, credito_disponible, disponible_bolsa, pagos_realizados };
    localStorage.setItem('aplicacionPresupuestaria', JSON.stringify(aplicacionPresupuestaria));
  }
  nuevaPropuesta() {
    this.setLocalStorageAplicacionPresupuestaria(this.selectedRowData.des_aplic, this.selectedRowData.apl_eje, this.selectedRowData.apl_fun, this.selectedRowData.apl_eco, this.selectedRowData.creditos_definitivos, this.selectedRowData.credito_gastado, this.selectedRowData.credito_disponible, this.selectedRowData.disponible_bolsa, this.selectedRowData.pagos_realizados);
    this.router.navigate(['gestionGasto/propuesta/nueva']);
  }
  verGrafico() {
    if(this.isHidden){
      this.isHidden = false;
    } else if(!this.isHidden){
      this.isHidden = true
    }
  }

  onPropuestaObjetoClicked(rowData: any) {
    localStorage.setItem("idPropuestaVisualizacion", rowData.idPropuesta);
    const dialogRef = this.dialog.open(ModalMuestrapropuestaComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onCodigoClicked(rowData: any) {
    localStorage.setItem('justiPro', JSON.stringify(rowData));
    var enlace = this.redirige.switchRoutes("JustificantePro");
    this.router.navigate(['/',...enlace]);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
} 