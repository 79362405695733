<label class="label-desplegable">Año contable: </label>
<div class="container">
  <div class="desplegable-year">    
    <!-- Dropdown para seleccionar el año -->
    <select class="desplegable" [(ngModel)]="selectedYear">
      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select>
  </div>
  <button class="botonReload" (click)="onChangeYear()">
    <img src="../../../../assets/iconos/iconoRecarga.png" height="40pt">
  </button>
  <button class="botonPrevisiones" (click)="modalPrevisiones()">
    <img src="../../../../assets/iconos/billete.png" height="40pt">
  </button>
</div>
