<div id="escritorio">
    <img src="../../../assets/usuario.png" class="fotoUsuario"alt="usuario">
    <h1 id="perfilTitulo">PERFIL</h1>
    <h1 id="tituloNombre">Nombre</h1>
    <input type="text" id="form-control-nombre" value="{{nombreCompleto}}" readonly name="nombreapellido">
    <h1 id="tituloEmail">Email</h1>
    <input type="text" id="form-control-email" value="{{userEmail}}" readonly name="email">
    <h1 id="tituloRol">Rol Designado</h1>
    @if(this.isAdmin){
        <input type="text" id="form-control-rol" value="Admin" disabled name="rol">
    } @else {
        <input type="text" id="form-control-rol" value="Usuario Base" disabled name="rol">
    }

    <app-taskbar></app-taskbar>
</div>