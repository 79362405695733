<div id="escritorio" *ngIf="formData">
   
    <div class="container">
      <div class="subcontainer">
        <h2 class="h2T" >Editar Modulo</h2>
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label  class="labelT" for="nombre">Nombre de Modulo:</label>
                <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="formData.nombre" required>
              </div>
          <div class="form-group">
            <label class="labelT" for="idTemplate">URL de imagen del modulo:</label>
            <input type="text" class="form-control" id="idTemplate" name="idTemplate" [(ngModel)]="formData.urlImg" >
          </div>
          <br>
          <div class="form-group" class="selectorGrupos">
            <h3 class="h2T">Grupos asociados</h3>
            <select multiple (change)="onSelectionChange($event)" >
              <option *ngFor="let grupo of listaGrupos" [value]="grupo.idGrupo" [selected]="checkedGroups[grupo.idGrupo]">
                {{ grupo.nombreGrupo }}
              </option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button type="submit" routerLink="/dashboard" class="btn btn-warning">Cancelar </button>
        </form>
      </div>
        
      </div>
      
    <app-taskbar></app-taskbar>
</div>