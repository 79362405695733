

<div class="dialogo">
  <div class="cierra" mat-dialog-actions>
  <button mat-button type="button" class="btn-close" (click)="closeDialog()"></button>
</div>
<h1 class="err" mat-dialog-title><b>Error</b></h1>
<div mat-dialog-content>
  <p>{{modalText}}</p>
</div>
</div>