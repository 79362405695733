import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';
import { RoutingService } from '../../../_services/routing.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-justificante-pro',
  templateUrl: './justificante-pro.component.html',
  styleUrl: './justificante-pro.component.css'
})
export class JustificanteProComponent {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  typeRow: any[] = [];
  selectedRowData: any;
  aplicacion: boolean = false;
  datosPropuesta: any;
  datosCargoPropuesta: any;
  datosCargoPropuestaSuma: any;
  sumJustificantes = 0;
  disponibles = 0;
  importePropuesta = 0;
  nombrePropuesta = "";
  aplSelecionada: any;
  idProp = "";
  columnDefsUsuario: ColDef[] = [
    {
      field: 'fec_opera',
      headerName: 'Fecha',
      width: 150,
      valueFormatter: (params) => {
        // Check if the date is valid
        if (params.value) {
          const date = new Date(params.value);
          // Format the date as DD/MM/YYYY
          const day = ("0" + date.getDate()).slice(-2);
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return ''; // Return empty string if the date is invalid or null
      }
    },
    { field: 'nif_tercero',
      headerName: 'NIF/CIF',
      cellStyle: {
        textAlign: 'center'
      },
    },
    { field: 'tercero', headerName: 'Tercero',width: 150 },
    {
      field: 'concepto',
      headerName: 'Descripción',
      width: 200,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.substring(0, 100);
        }
        return ''; // Return empty string if the value is null or undefined
      },
    },
    { field: 'importe',
      headerName: 'Importe',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle:{
        textAlign: 'right'
      }
    },
    {
      field: 'concepto',
      headerName: 'Descripción Completa',
      width: 700
    },
    
  ];



  constructor(private http: HttpClient, private router: Router, private authService: AuthService,  private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('justiPro') != null) {
            this.aplicacion = true;
            this.selectedRowData = JSON.parse(localStorage.getItem('justiPro') || '{}');
            console.log(this.selectedRowData)
            this.aplSelecionada = JSON.parse(localStorage.getItem('aplicacionPresupuestariaSeleccionada') ||'{}');
            if (this.selectedRowData != null) {
              const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
              if(this.selectedRowData.idPropuesta){
                this.idProp = this.selectedRowData.idPropuesta;
              } else {
                this.idProp = this.selectedRowData.propuesta;
              }
              this.authService.getDatosPropuesta(this.idProp, headers).subscribe({
                next: propuesta => {
                  if(propuesta.importe){
                    this.importePropuesta = propuesta.importe;
                  } else {
                    this.importePropuesta = this.selectedRowData.autorizado_final;
                  }
                  if(propuesta.objeto){
                    this.nombrePropuesta = propuesta.objeto;
                  } else {
                    this.nombrePropuesta = this.selectedRowData.concepto;
                  }
                  this.importePropuesta = propuesta.importe;
                  this.authService.getDatosCargosPropuesta(this.idProp, headers).subscribe({
                    next: (cargoPropuesta: any[]) => {
                      this.authService.getDatosCargosPropuestaSuma(this.idProp, headers).subscribe({
                        next: cargoPropuestaSuma => {
                          //console.log(localStorage.getItem('aplicacionPresupuestariaSeleccionada'))
                          this.typeRow = cargoPropuesta;
                          this.sumJustificantes = cargoPropuestaSuma.total_propuesta;
                          this.disponibles = this.importePropuesta - this.sumJustificantes;
                        },
                        error: err => {
                          console.log(err);
                          this.openModal("No se dispone aun de Justificantes para esta Propuesta");
                          this.router.navigate(['/gestionGasto/propuestas']);
                        }
                      });
                    },
                    error: err => {
                      console.log(err);
                      this.openModal("No se dispone aun de Justificantes para esta Propuesta");
                          this.router.navigate(['/gestionGasto/propuestas']);
                    }
                  });
                },
                error: err => {
                  console.log(err);
                  this.showErrorModal(err);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
              });


            } else {
              console.error('selectedRowData viene vacío');
              this.openModal('No hay rowData seleccionado');
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          }
        }
      }
    }
  }
  applySearchFilter() {
    this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}
}
