<div class="custom-filter-panel">
    <h4 class="titulo-label"><b>Filtros estados propuestas</b></h4>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp" [(ngModel)]="filtro1Checked" (change)="applyFiltro1()">
        <label for="filtroProp">Propuesta sin firmar</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp2" [(ngModel)]="filtro3Checked" (change)="applyFiltro3()">
        <label for="filtroProp2">Propuestas firmadas</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp3" [(ngModel)]="filtro4Checked" (change)="applyFiltro4()">
        <label for="filtroProp3">Propuestas contabilizadas</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp4" [(ngModel)]="filtro5Checked" (change)="applyFiltro5()">
        <label for="filtroProp4">Propuestas anuladas</label>
    </div>
</div>