<div class="contenedorDelete">
  <h1 class="textDelete" mat-dialog-title><b>ELIMINAR</b></h1>
  <div mat-dialog-content>
    <p>¿Seguro que quiere eliminar esta fila?</p>
    <p style="text-align: center;"><b>{{modalText}}</b></p>
    <div class="botonescentro">
      <button type="button" (click)="clickYes()"  class="btn btn-danger">Si</button>
      <button type="button" (click)="closeDialog()" class="btn btn-light">No</button>
    </div>
    
  </div>
</div>
  
  