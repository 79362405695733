<app-botonera></app-botonera>
<div class="escritorio">
    <div class="common-componentes">
      <app-year-contable></app-year-contable>
    </div>
    <div id="grid-container" class="tab-content grid-container">
      <div class="search-container">
        <!-- Etiqueta -->
        <label for="searchInput" class="search-label">Buscar:</label>
        <!-- Input de búsqueda -->
        <input type="text" id="searchInput" [(ngModel)]="terminoBusqueda" (keyup)="applySearchFilter()" class="form-control mb-2">
      </div>
     
      <!-- Ag-Grid con configuración -->
      <ag-grid-angular 
        class="ag-theme-quartz" 
        [rowData]="typeRow" 
        [columnDefs]="columnDefsUsuario" 
        style="height: 50vh;">
      </ag-grid-angular>
      <!-- Fila de total -->
     
    <app-taskbar></app-taskbar>
  </div>
  