  <!-- Navbar -->
<nav class="navbar navbar-expand-md rounded" style="background-color: #992325; margin-bottom: 1rem;">
  <div class="container-fluid">
    <!-- Toggle button visible on small screens -->
    <button class="btn btn-light d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
      ☰
    </button>

    <!-- Navbar links visible on larger screens -->
    <div class="collapse navbar-collapse d-none d-md-flex justify-content-start">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white fw-bold" routerLink="/gestionGasto/estadoEjecucion">Estado de ejecución</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white fw-bold" href="/gestionGasto/estadoPropuesta">Estado propuestas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white fw-bold" href="/gestionGasto/propuesta/nuevaTemporal">Nueva Propuesta (Temporal)</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white fw-bold" routerLink="/gestionGasto/propuestas">Propuestas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white fw-bold" href="/gestionGasto/cerrado">Cerrado</a>
        </li>    
      </ul>
    </div>
  </div>
</nav>

<!-- Sidebar -->
<div class="offcanvas offcanvas-start rounded" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel" style="border-radius: 0.5rem;">
  <div class="offcanvas-header" style="background-color: #992325; border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem;">
    <h5 class="offcanvas-title text-white fw-bold" id="offcanvasMenuLabel">Menu</h5>
    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="list-unstyled">
      <li class="nav-item">
        <a class="nav-link fw-bold" routerLink="/gestionGasto/estadoEjecucion">Estado de ejecución</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-bold" href="/gestionGasto/estadoPropuesta">Estado propuestas</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-bold" href="/gestionGasto/propuesta/nuevaTemporal">Nueva Propuesta (Temporal)</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-bold" routerLink="/gestionGasto/propuestas">Propuestas</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-bold" href="/gestionGasto/cerrado">Cerrado</a>
      </li>
    </ul>
  </div>
</div>

