<div class="modal" > <!-- Modal wrapper -->
    <div class="modal-content"> <!-- Modal content box -->
        <h1 class="err" mat-dialog-title>¿Contrato de Obra?</h1>
        <div class="err" mat-dialog-content>
            <p>Se ha incluido un Expediente vacío. ¿Necesita esta Propuesta un Contrato de Obra?</p>
        </div>
        <div class="cierra" mat-dialog-actions>
            <button class="btn" mat-button (click)="onCancel()">NO</button>
            <button class="btn" mat-button (click)="onConfirm()">SI</button>
        </div>
    </div>
</div>
