<app-botonera></app-botonera>
<div class="escritorio container-fluid">
    <div class="row g-0">
      <div class="col-md-3">
      </div>
      <div class="col-md-5"></div>
    <div id="" class="">
      <div class="titulo-row">
        <h1><b>Dividir Propuesta</b></h1>
      </div>
      <div class="tabla-formulario">
        <form #form="ngForm" (ngSubmit)="onSubmit(form)" class="row g-3">
          <div class="col-md-4">
            <label for="codApliPresupuestaria">Aplicacion Presupuestaria*</label>
            <!-- app.component.html -->
            <span > ({{ propuestaForm.codApliPresupuestaria }}) {{desc_Apli}}</span>
            <div *ngIf="submitted && !propuestaForm.codApliPresupuestaria" class="text-danger">
              Aplicacion Presupuestaria es requerida.
            </div>
          </div>
          <div class="col-md-4">
            <label for="proponente">Proponente*</label>
            <!-- app.component.html -->
            <span  readonly> {{ propuestaForm.proponente }}, {{ propuestaForm.cargo }}</span>
            
          </div>
          <div class="col-md-4">
            <label for="responsable">Responsable*</label>
            <!-- app.component.html -->
            <span  readonly> {{ propuestaForm.responsable }}, {{ propuestaForm.cargoResponsable }}</span>
           
          </div>
          <div class="col-md-2">
            <label for="fechaPropuesta">Fecha de la Propuesta*</label>
            <input type="date" id="fechaPropuesta" name="fechaPropuesta" class="form-control"   [(ngModel)]="formattedFechaPropuesta"  required readonly>
          </div>
          <div class="col-md-12">
            <label for="objeto">Objeto del contrato*</label>
            <span id="objeto" name="objeto" class="form-control" readonly [(ngModel)]="propuestaForm.objeto">{{ propuestaForm.objeto }}</span>

          </div>
          <div class="col-md-12">
            <label for="necesidad">Necesidad</label>
            <span id="necesidad" name="necesidad" class="form-control"  readonly>{{propuestaForm.necesidad}}</span>
          </div>
          <div class="col-md-12">
            <label for="plazo">Plazo de entrega/duración y condiciones del contrato</label>
            <span id="plazo" name="plazo" class="form-control" readonly>{{propuestaForm.plazo}}</span>
          </div>
          <div class="col-md-4">
            <label for="importe">Saldo</label>
            <input type="text" id="saldo" name="saldo" class="form-control" [(ngModel)]="saldo" placeholder="0" readonly>
          </div>
          <div class="col-md-4">
            <label for="importe">Importe*</label>
            <input type="text" id="importe" name="importe" class="form-control" [(ngModel)]="propuestaForm.importe" placeholder="0" required>
            <div *ngIf="submitted && form.controls['importe']?.invalid" class="text-danger">
              Importe es requerido y debe ser un número válido.
            </div>
          </div>
          <div class="col-md-3">
            <label for="expediente">Expdt. Asociado</label>
            <span type="text" id="expediente" name="expediente" class="form-control" placeholder="XXXX/AAAA" [(ngModel)]="propuestaForm.expediente" readonly>{{propuestaForm.expediente}}</span>
            
            <div *ngIf="expdtComprobado" class="text-danger">
              {{ nombreExpdt }}
            </div>
          </div>
          <div class="col-md-5">
            <button type="submit" class="btn btn-primary col-md-5">Ramificar Propuesta</button>
          </div>
        </form>
      </div>
    </div>
    <app-taskbar></app-taskbar>
    <div hidden>
      <ng-container *ngIf="calculatedValues">
        <app-generar-pdf-propuesta [data]="calculatedValues" (dataReady)="recepcionPDF($event)"></app-generar-pdf-propuesta>
      </ng-container>
      <ng-container *ngIf="calculatedValuesDos">
        <app-generar-pdf-propuesta [data]="calculatedValuesDos" (dataReady)="recepcionPDF2($event)"></app-generar-pdf-propuesta>
      </ng-container>
    </div> 
  </div>
  