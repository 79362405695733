import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Constantes } from '../../../_services/constantes.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisionesFinalesComponent } from '../modal-previsiones-finales/modal-previsiones-finales.component';

@Component({
  selector: 'app-year-contable',
  standalone: true,
  templateUrl: './year-contable.component.html',
  styleUrl: './year-contable.component.css',
  imports: [
    CommonModule, // Asegúrate de importar CommonModule aquí
    BrowserModule,
    FormsModule 
  ]
})
export class YearContableComponent implements OnInit{
  constructor(private http: HttpClient, private router: Router, private authService: AuthService,  private dialog: MatDialog) {}

  typeRow: any[] = [];
  selectedYear: number = 0; // Año predeterminado
  years: number[] = []; // Lista de años únicos
  tokenJWT: string = "";
  ngOnInit(): void {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
          const storedYear = localStorage.getItem('yearContable');
          if (storedYear) {
            this.selectedYear = parseInt(storedYear, 10); // Convertir a número
          }
          this.http.get<any>(Constantes.GETVISTA_CONTABILIDAD, { headers: headers }).subscribe({
            next: data => {
              // Obtener años únicos
              this.typeRow = data;
              this.getUniqueYears();
               // Si no hay un año almacenado, seleccionar el más reciente (el primer año en la lista)
              if (!storedYear && this.years.length > 0) {
                this.selectedYear = this.years[0];
                localStorage.setItem('yearContable', this.selectedYear.toString());
                this.seleccionarContabilidad(this.selectedYear, headers);
              }
            },
            error: err => {
              console.log(err);
              this.showErrorModal(err);
            }
          });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear(); 
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear(); 
        this.router.navigate(['/login']); 
      }
    }
  }

  getUniqueYears(): void {
    const yearsSet = new Set<number>();
    this.typeRow.forEach(row => yearsSet.add(row.eje_conta));
    this.years = Array.from(yearsSet).sort((a, b) => b - a);
  }

  onChangeYear(): void {
    const token = localStorage.getItem('jwtToken');
    if(token != null){
      this.tokenJWT = token;
      if (!this.authService.isTokenExpired(this.tokenJWT)) {
        var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
        const headers = new HttpHeaders(strHead);
        // Actualizar la variable local
        localStorage.setItem('yearContable', this.selectedYear.toString());
        this.http.get<any>(Constantes.GETIDCONTA_FROMYEAR + `${this.selectedYear}`, { headers: headers })
        .subscribe({
            next: data => {
                // Obtener años únicos
                localStorage.setItem('contabilidad', data);
                // Recargar la página
                window.location.reload();
            },
            error: err => {
                console.log(err);
            }
        });
      }
    }
}


modalPrevisiones(): void {
  const token = localStorage.getItem('jwtToken');
  var idConta = "";
  if(token != null){
    this.tokenJWT = token;
    if (!this.authService.isTokenExpired(this.tokenJWT)) {
      var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
      const headers = new HttpHeaders(strHead);
      // Actualizar la variable local
      localStorage.setItem('yearContable', this.selectedYear.toString());
      this.http.get<any>(Constantes.GETIDCONTA_FROMYEAR + `${this.selectedYear}`, { headers: headers })
    .subscribe({
        next: data => {
            // Obtener años únicos
           idConta = data.toString();

           const dialogRef = this.dialog.open(ModalPrevisionesFinalesComponent, {
            data: {
              contabilidad: { actionType: idConta },
              eje: { actionType: this.selectedYear }
            }
          });
    
          dialogRef.afterClosed().subscribe();
        },
        error: err => {
            console.log(err);
        }
    });
    
    }
  }
}
  seleccionarContabilidad(selectedYear: number, headers: HttpHeaders) {
    this.http.get<any>(Constantes.GETIDCONTA_FROMYEAR + `${selectedYear}`, { headers: headers })
    .subscribe({
        next: data => {
            // Obtener años únicos
            localStorage.setItem('contabilidad', data.toString());
            // Recargar la página
            window.location.reload();
        },
        error: err => {
            console.log(err);
        }
    });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}



