<div id="escritorio">
    <div class="errorMantenimiento">
        <div class="maintenance-notice">
            <img src="./../../../assets/iconos/aviso.png" alt="Aviso" class="img-fluid aviso-img">
            <div class="text-container">
                <p class="text">La página web de la Intranet está ahora mismo en Mantenimiento.</p>
                <p class="text">Por favor, espere. Intentaremos volver lo antes posible.</p>
            </div>
        </div>
        
        <button type="button" class="btn btn-secondary botonOk" routerLink="/">Aceptar</button>
    </div>
    </div>
    