<div class="col-md-12">
    <div class="card card-container">
      <img
        class="profile-img-card"
      />
      @if (!isSuccessful) {
      <form
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control"
            name="username"
            [(ngModel)]="form.username"
            required
            minlength="3"
            maxlength="20"
            #username="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
          />
          @if (username.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (username.errors['required']) {
            <div>Username is required</div>
            }
            @if (username.errors['minlength']) {
            <div>Username must be at least 3 characters</div>
            }
            @if (username.errors['maxlength']) {
            <div>Username must be at most 20 characters</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            name="email"
            [(ngModel)]="form.email"
            required
            email
            #email="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
          />
          @if (email.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (email.errors['required']) {
            <div>Email is required</div>
            }
            @if (email.errors['email']) {
            <div>Email must be a valid email address</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            [(ngModel)]="form.password"
            required
            minlength="6"
            #password="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
          />
          @if (password.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (password.errors['required']) {
            <div>Password is required</div>
            }
            @if (password.errors['minlength']) {
            <div>Password must be at least 6 characters</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="nombreUsu">Nombre</label>
          <input
            type="text"
            class="form-control"
            name="nombreUsu"
            [(ngModel)]="form.nombreUsu"
            required
            minlength="3"
            maxlength="20"
            #nombreUsu="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && nombreUsu.errors }"
          />
          @if (nombreUsu.errors && f.submitted) {
            <div class="invalid-feedback">
              @if (nombreUsu.errors['required']) {
              <div>Nombre es requerido</div>
              }
              @if (nombreUsu.errors['minlength']) {
              <div>Nombre debe tener al menos 3 caracteres</div>
              }
            </div>
          }
        </div>
        <div class="form-group">
          <label for="apellidosUsu">Apellidos</label>
          <input
            type="text"
            class="form-control"
            name="apellidosUsu"
            [(ngModel)]="form.apellidosUsu"
            required
            minlength="2"
            maxlength="20"
            #apellidosUsu="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && apellidosUsu.errors }"
          />
          @if (apellidosUsu.errors && f.submitted) {
            <div class="invalid-feedback">
              @if (apellidosUsu.errors['required']) {
              <div>Apellidos es requerido</div>
              }
              @if (apellidosUsu.errors['minlength']) {
              <div>Apellidos debe tener al menos 2 caracteres</div>
              }
            </div>
          }
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block">Sign Up</button>
        </div>
  
        @if (f.submitted && isSignUpFailed) {
        <div class="alert alert-warning">
          Signup failed!<br />{{ errorMessage }}
        </div>
        }
      </form>
      } @else {
      <div class="alert alert-success">Your registration is successful!</div>
      }
    </div>
  </div>
  