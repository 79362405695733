import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';
@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrl: './editar-usuario.component.css'
})
export class EditarUsuarioComponent {
  nombreUsu: string = "nombreUsu";
  apellidoUsu: string = "apellidoUsu";
  nombreCompleto: string = "";
  isAdmin: boolean = false;
  tokenJWT: string = "false";
  userData: any[] = [];
  selectedOption: string = ""; // Property to store the selected option
  idUsu = '';
  formData = {
    username: '',
    password: '',
    nombre: '',
    apellidos: '',
    email: ''
  };
  dataReceived: boolean = false;
  message = '';
  permisosTotales: any[] = []; //La lista entera de permisos
  checkedPermissions: { [key: string]: boolean } = {}; // Object to store checked permissions 

  listaModulos: any[] = []; //Lista de Modulo  con (IdModulo, nombreModulo)
  mapaPermisosModulos = new Map<string, any[]>(); //Para agrupar por idModulo la lista de permisos _(cada permiso es (IdPermiso,Nombre))
  permisosMostrados = false;
  idUsuarioActual = "";
  listaGrupos: any[] = []; //La lista entera de permisos
  checkedGrupos: { [key: string]: boolean } = {}; // Object to store checked permissions 
  gruposMostrados = false;
  constructor(private router: Router, private http: HttpClient, private authService: AuthService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      const rutaCorrecta = localStorage.getItem('fromClick'); //Si no viene del click editar no se deja usar editarUsuario
      localStorage.removeItem('fromClick');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              if (data) { //Is Admin
                this.isAdmin = data;
                if (rutaCorrecta != 'true') {//Si no viene del click editar no se deja usar editarUsuario
                  localStorage.clear(); 
                  this.router.navigate(['/desktop']);
                } else {
                  const datosUsuario = JSON.parse(localStorage.getItem('usuarioGrid') || '{}'); //Lo pasamos de JSON a objeto de nuevo
                  localStorage.removeItem('usuarioGrid');
                  if (datosUsuario != null) {
                    this.idUsu = datosUsuario['idUsuario'];
                    this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                      next: dataW => {
                        this.idUsuarioActual = dataW.idUsuario;
                      },
                      error: err => {
                        localStorage.clear(); 
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      },
                    });
                    //Con el usuario debemos obtener todos los datos ya que es un GET con Admin:
                    let idu = this.idUsu;
                    let cadenita = Constantes.GETUSUARIO_BY_ID + idu;
                    this.http.get<any>(cadenita, { headers }).subscribe({
                      next: (datoU) => {
                        console.log(datoU);
                        this.formData.username = datoU.nombreLogin;
                        this.formData.email = datoU.emailUsuario;
                        this.formData.password = datoU.passwordUsuario;
                        this.formData.nombre = datoU.nombreUsuario;
                        this.formData.apellidos = datoU.apellidosUsuario;
                        this.isAdmin = datoU.isAdmin;

                        this.selectedOption = "false";
                        if (this.isAdmin) {
                          this.selectedOption = "true";
                        }
                        this.http.get<any>(Constantes.GETMODULOSDB, { headers: headers }).subscribe({
                          next: dataM => {
                            if (dataM) { //Se obtienen datos
                              this.listaModulos = dataM;

                              this.listaModulos.forEach(modulo => {
                                let idModulo = modulo.idModulo;
                                const cadenat = Constantes.GET_PERMISO_W_MODULO + `${idModulo}`;

                                //Por cada modulo obtenemos su lista de permisos
                                this.http.get<any>(cadenat, { headers: headers }).subscribe({
                                  next: dataWM => {
                                    this.mapaPermisosModulos.set(idModulo, dataWM);
                                    if (data.length != 0) {
                                      this.permisosTotales.push(dataWM); //introducimos los permisos de cada modulo que si tiene en el vector de permisos totales
                                    }


                                  },
                                  error: err => {
                                    localStorage.clear(); 
                                    this.showErrorModal(err);
                                    this.router.navigate(['/login']);
                                  },

                                });
                              });

                            } else { //No se obtienen datos
                              //console.error('No tienes permisos para acceder a esta pantalla');
                              localStorage.clear(); 
                              this.openModal('No se obtuvieron datos');
                              this.router.navigate(['/login']);
                            }
                          },
                          error: err => {
                            //console.error('Error');
                            localStorage.clear(); 
                            this.showErrorModal(err);
                            this.router.navigate(['/login']);
                          }
                        });
                        //Una vez tenemos la lista de los modulos y permisos, obtenemos los permisos asociados a cada usuario para seleccionarlos
                        const cadenaUsuP = Constantes.GET_PERMISO_W_USUARIO + `${idu}`;
                        this.http.get<any>(cadenaUsuP, { headers: headers }).subscribe({
                          next: listaDatos => {
                            //Si tenemos permisos a un usuario cada uno deberá estar seleccionado 
                            for (let i = 0; i < listaDatos.length; i++) {
                              this.checkedPermissions[listaDatos[i].idPermiso] = true; // Update checkedPermissions object 
                            }
                          },
                          error: err => {
                            localStorage.clear(); 
                            this.showErrorModal(err);
                            this.router.navigate(['/login']);
                          }
                        });

                        //Una vez obtenidos permisos obtenemos los grupos y los grupos ya seleccionados para cada usuario
                        this.http.get<any>(Constantes.GET_GRUPOS, { headers: headers }).subscribe({
                          next: dataGR => {
                            this.listaGrupos = dataGR;
                            const cadenaGr = Constantes.GET_GRUPO_W_USUARIO + `${idu}`;
                            this.http.get<any>(cadenaGr, { headers: headers }).subscribe({
                              next: dataUGr => {
                                for (var i = 0; i < dataUGr.length; i++) {
                                  this.checkedGrupos[dataUGr[i].idGrupo] = true;
                                }
                                this.gruposMostrados = true;

                              },
                              error: err => {
                                localStorage.clear(); 
                                this.showErrorModal(err);
                                this.router.navigate(['/login']);
                              },

                            });


                          },
                          error: err => {
                            localStorage.clear(); 
                            this.showErrorModal(err);
                            this.router.navigate(['/login']);
                          },

                        });

                        this.permisosMostrados = true;
                        this.dataReceived = true; // Set dataReceived to true when data is received
                      },
                      error: (err) => {
                        //console.log('Error', err);
                        localStorage.clear(); 
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      },
                    });
                  }

                }
              } else { //Is not Admin
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear();   
                this.openModal('No tienes permisos para acceder a esta pantalla');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear(); 
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }
          });
          // Manually trigger change detection
          this.cdr.detectChanges();
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          // console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear(); 
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.router.navigate(['/login']);
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }


  onSubmit() {
    console.log('Form submitted with data:', this.formData, this.isAdmin);
    // You can add further logic here, such as sending the form data to a server
    var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var username = this.formData.username;
    var pass = this.formData.password;
    var name = this.formData.nombre;
    var ape = this.formData.apellidos;
    var correo = this.formData.email;
    this.isAdmin = false;
    if (this.selectedOption == "true") {
      this.isAdmin = true;
    }
    this.authService.update_Usu(this.idUsu, username, correo, pass, name, ape, this.isAdmin, headers).subscribe({
      next: data => {
        //Una vez actualizado usuario actualizamos permisos
        //Primero borramos los permisos que tenian anteriormente
        this.authService.realizar_Auditoria(data, this.isAdmin, 'Usuario', 'UPDATE', this.idUsuarioActual, headers).subscribe({
          next: data => {
            //Auditoria Realizada.
            this.router.navigate(['/dashboard']);
          },
          error: err => {
            localStorage.clear(); 
            this.showErrorModal(err);
            this.router.navigate(['/login']);
          },
        });
        //Usuario-Permiso
        this.authService.delete_UsuPer_idUsu(this.idUsu, headers).subscribe({
          next: data => { //devuelve lista de borrados

            if (data) { //si habia datos que borrar
              for (var k = 0; k < data.length; k++) {
                //Una vez borrados volvemos a repasar la lista de permisos cambiados y los obtenemos y añadimos
                this.authService.realizar_Auditoria(data[k], this.isAdmin, 'Usuario_Permiso', 'DELETE', this.idUsuarioActual, headers).subscribe({
                  next: datUOU => {
                    //Auditoria Realizada.

                  },
                  error: err => {
                    
                    localStorage.removeItem('loginCorrecto');
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  },
                });
              }

            }
            for (const [idPermiso, isChecked] of Object.entries(this.checkedPermissions)) {
              if (isChecked) {
                this.authService.register_UsuPer(this.idUsu, idPermiso, headers).subscribe({
                  next: datoUP => {
                    this.authService.realizar_Auditoria(datoUP, this.isAdmin, 'Usuario_Permiso', 'INSERT', this.idUsuarioActual, headers).subscribe({
                      next: data => {
                        //Actualizados datos se redirige a dashboard
                        this.router.navigate(['/dashboard']);
                      },
                      error: err => {
                        localStorage.clear(); 
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      },
                    });

                  },
                  error: err => {
                    console.error('Error : ', err);

                    localStorage.clear(); 
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  }
                });
              }
            }

          },
          error: err => {
            localStorage.clear(); 
            this.showErrorModal(err);
            this.router.navigate(['/login']);
          }

        });
        //Usuario-Grupo
        this.authService.delete_UsuGru_idUsu(this.idUsu, headers).subscribe({
          next: dataBo => { //Esto tiene lista de borrados

            if (dataBo) { //Si ha borrado algo
              for (var x = 0; x < dataBo.length; x++) {
                //Una vez borrados volvemos a repasar la lista de permisos cambiados y los obtenemos y añadimos
                this.authService.realizar_Auditoria(dataBo[x], this.isAdmin, 'Usuario_Grupo', 'DELETE', this.idUsuarioActual, headers).subscribe({
                  next: datUOU => {
                    //Auditoria Realizada.

                  },
                  error: err => {
                    localStorage.clear(); 
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  },
                });
              }
            }

            for (const [idGrupo, isChecked] of Object.entries(this.checkedGrupos)) {
              if (isChecked) {
                this.authService.register_UsuGru(this.idUsu, idGrupo, headers).subscribe({
                  next: datoUG => {
                    this.authService.realizar_Auditoria(datoUG, this.isAdmin, 'Usuario_Grupo', 'INSERT', this.idUsuarioActual, headers).subscribe({
                      next: data => {
                        //Actualizados datos se redirige a dashboard
                        this.router.navigate(['/dashboard']);
                      },
                      error: err => {
                        localStorage.clear(); 
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      },
                    });

                  },
                  error: err => {
                    console.error('Error : ', err);
                    localStorage.clear(); 
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  }
                });
              }
            }
          },
          error: err => {
            localStorage.clear(); 
            this.showErrorModal(err);
            this.router.navigate(['/login']);
          }

        });
      },
      error: err => {
        // console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.showErrorModal(err);
        this.router.navigate(['/login']);
      }
    });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    this.message = errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onCheckboxChange(event: any, permiso: any) {
    const isChecked = event.target.checked;
    this.checkedPermissions[permiso.idPermiso] = isChecked; // Update checkedPermissions object
    console.log("Check ", permiso.idPermiso, " ?: ", this.checkedPermissions[permiso.idPermiso]);
  }

  onCheckboxChangeGr(event: any, grupo: any) {
    const isChecked = event.target.checked;
    this.checkedGrupos[grupo.idGrupo] = isChecked; // Update checkedPermissions object
    console.log("Check ", grupo.idGrupo, " ?: ", this.checkedGrupos[grupo.idGrupo]);
  }
}
