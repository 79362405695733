import { Component } from '@angular/core';

@Component({
  selector: 'app-avisomantenimiento',
  templateUrl: './avisomantenimiento.component.html',
  styleUrl: './avisomantenimiento.component.css'
})
export class AvisomantenimientoComponent {

}
