import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modales',
  templateUrl: './modales.component.html',
  styleUrls: ['./modales.component.css']
})
export class ModalesComponent {
  @Input() modalText: string = 'pepe';
  isOpen = false;

  constructor(public dialogRef: MatDialogRef<ModalesComponent>) { }

  ngOnInit() {
    this.modalText= String(localStorage.getItem('error') || '{}');
    localStorage.removeItem('error');
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
