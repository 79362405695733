import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';
import { ModalesComponent } from '../modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Constantes } from '../../_services/constantes.service';

@Component({
  selector: 'app-eliminar-fila',
  templateUrl: './eliminar-fila.component.html',
  styleUrl: './eliminar-fila.component.css'
})
export class EliminarFilaComponent {
  @Input() modalText: string = '';
  datosUsuario: any;
  tokenJWT: any;
  tipoTabla : any;
  isAdmin= false;
  idUsuarioActual = "";

  constructor(private router: Router, private http: HttpClient, private authService: AuthService, private dialogRef: MatDialogRef<EliminarFilaComponent>, private dialog: MatDialog){

  }

  ngOnInit(): void {
    this.datosUsuario = JSON.parse( localStorage.getItem('datosDelete')|| '{}'); //Lo pasamos de JSON a objeto de nuevo
    this.tipoTabla = localStorage.getItem('tipoTabla');
    localStorage.removeItem('datosDelete');
    localStorage.removeItem('tipoTabla');
    const token = localStorage.getItem('jwtToken');
    this.tokenJWT= token;
    if (!this.authService.isTokenExpired(this.tokenJWT)) {
      var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
      const headers = new HttpHeaders(strHead);

      switch(this.tipoTabla){
        case "usuario":
          this.modalText = this.datosUsuario['nombreUsuario'] + " " + this.datosUsuario['apellidosUsuario'] + ".";
          break;
        case "modulo":
          this.modalText = this.datosUsuario.nombreModulo;
          break;
        case "permiso":
          this.modalText = this.datosUsuario.nombrePermiso;
          break;
        case "template":
          this.modalText = this.datosUsuario.nombreTemplate;
          break;
        case "grupo":
          this.modalText = this.datosUsuario.nombreGrupo;
          break;
    }
    this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
      next: data => {
        if(data){ //Is Admin
          this.isAdmin=data;
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: datoU => {
             this.idUsuarioActual = datoU.idUsuario;
              
            },
            error: err => {
              console.error('Error: ', err);
              this.showErrorModal(err);
              localStorage.clear(); 
              this.router.navigate(['/login']); 
            }
          });
        }else{ //Is not Admin
          //console.error('No tienes permisos para acceder a esta pantalla');
          localStorage.clear(); 
          this.openModal('No tienes permisos para acceder a esta pantalla');
          this.router.navigate(['/login']);
        }
      },
      error: err => {
        //console.error('Error');
        localStorage.clear(); 
        this.openModal('Error');
        this.router.navigate(['/login']);
      }
    });
    }
    
  }

  clickYes(){

   
    var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
    const headers = new HttpHeaders(strHead);
    switch(this.tipoTabla){
      case "usuario":
        const idUsuario =  this.datosUsuario['idUsuario'];
        let email = this.datosUsuario['emailUsuario'];
        this.authService.delete_Usu(idUsuario, headers).subscribe({
          next: (datoU) => { 
            this.authService.realizar_Auditoria(datoU,this.isAdmin,'Usuario','DELETE',this.idUsuarioActual,headers).subscribe({
              next: data =>{
                //Actualizados datos se redirige a dashboard
                         //Antes de redirigir borramos las filas de Usuario_Permiso  relacionadas con este Usuario
            this.authService.delete_UsuPer_idUsu(idUsuario, headers).subscribe({
              next: idUBorrado =>{
                this.authService.realizar_Auditoria(idUBorrado, this.isAdmin, 'Usuario_Permiso', 'DELETE', this.idUsuarioActual,headers).subscribe({
                  next: datoU => {
                    this.authService.delete_UsuGru_idUsu(idUsuario, headers).subscribe({
                      next: idNBorrado =>{
                        this.authService.realizar_Auditoria(idNBorrado, this.isAdmin, 'Usuario_Grupo', 'DELETE', this.idUsuarioActual,headers).subscribe({
                          next: datoUP => {
                            this.authService.delete_AccesoDirecto_idUsu(idUsuario, headers).subscribe({
                              next: idUNBorrado =>{
                                this.authService.realizar_Auditoria(idUNBorrado, this.isAdmin, 'AccesoDirecto', 'DELETE', this.idUsuarioActual,headers).subscribe({
                                  next: datoUP => {
                                    //Tras borrar redirigimos a dashboard
                                    this.dialogRef.afterClosed().subscribe(() => {
                                      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['/dashboard']);
                                      });
                                    });
                                    
                                    this.dialogRef.close();
                                  },
                                  error: err => {
                                    console.error('Error: ', err);
                                    this.showErrorModal(err);
                                    localStorage.clear(); 
                                    this.router.navigate(['/login']); 
                                  }
                                });
                               
                              },
                              error: (err) => {
                                localStorage.clear(); 
                                this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                                this.showErrorModal(err);
                                this.router.navigate(['/login']); 
                              }
                            });
                          },
                          error: err => {
                            console.error('Error: ', err);
                            this.showErrorModal(err);
                            localStorage.clear(); 
                            this.router.navigate(['/login']); 
                          }
                        });
                       
                      },
                      error: (err) => {
                        localStorage.clear(); 
                        this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                        this.showErrorModal(err);
                        this.router.navigate(['/login']); 
                      }
                    });
                  },
                  error: err => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear(); 
                    this.router.navigate(['/login']); 
                  }
                });
               
              },
              error: (err) => {
                localStorage.clear(); 
                this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                this.showErrorModal(err);
                this.router.navigate(['/login']); 
              }
            });
              },
              error: err => {
                localStorage.clear(); 
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              },
            });
          
          },
          error: (err) => {
            localStorage.clear(); 
            this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
            this.showErrorModal(err);
            this.router.navigate(['/login']); 
          },
        });
        break;
      case "modulo":
        const idModulo = this.datosUsuario['idModulo'];
        this.authService.delete_Mod(idModulo, headers).subscribe({
          next: (datoU) => { //Tras borrar redirigimos a dashboard
          var idMod = datoU.idModulo;
            this.authService.realizar_Auditoria(datoU,this.isAdmin,'Modulo','DELETE',this.idUsuarioActual,headers).subscribe({
              next: data =>{
                //Actualizados datos se redirige a dashboard
                         //Antes de redirigir borramos las filas de Usuario_Permiso  relacionadas con este Usuario
            this.authService.delete_ModGru_idMod(idMod, headers).subscribe({
              next: idUBorrado =>{
                this.authService.realizar_Auditoria(idUBorrado, this.isAdmin, 'Modulo_Grupo', 'DELETE', this.idUsuarioActual,headers).subscribe({
                  next: datoU => {
                      //Tras borrar de Modulo_Grupo se borra de Modulo_Usuario
                      this.authService.delete_AccesoDirecto_idMod(idMod, headers).subscribe({
                        next: datoUP => {
                          this.authService.realizar_Auditoria(datoUP,this.isAdmin,'AccesoDirecto','DELETE', this.idUsuarioActual,headers ).subscribe({
                            next: datoU => {
                              this.dialogRef.afterClosed().subscribe(() => {
                                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                  this.router.navigate(['/dashboard']);
                                });
                              });
                              
                              this.dialogRef.close();
                            },
                            error: err => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);
                              localStorage.clear(); 
                              this.router.navigate(['/login']); 
                            } 
                          });
                        },
                        error: err => {
                          console.error('Error: ', err);
                          this.showErrorModal(err);
                          localStorage.clear(); 
                          this.router.navigate(['/login']); 
                        } 
                      });
                  },
                  error: err => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear(); 
                    this.router.navigate(['/login']); 
                  }
                });
               
              },
              error: (err) => {
                localStorage.clear(); 
                this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                this.showErrorModal(err);
                this.router.navigate(['/login']); 
              }
            });
              },
              error: err => {
                localStorage.clear(); 
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              },
            });
          },
          error: (err) => {
            localStorage.clear(); 
            this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
            this.showErrorModal(err);
            this.router.navigate(['/login']); 
          },
        });
        break;
      case "permiso": 
        const idPermiso = this.datosUsuario['idPermiso'];
        this.authService.delete_Perm(idPermiso, headers).subscribe({
          next: (datoU) => { //Tras borrar redirigimos a dashboard
            this.authService.realizar_Auditoria(datoU,this.isAdmin,'Permiso','DELETE',this.idUsuarioActual,headers).subscribe({
              next: data =>{
                   //Antes de redirigir borramos las filas de Usuario_Permiso  relacionadas con este Permiso
              this.authService.delete_UsuPer_idPer(idPermiso, headers).subscribe({
                next: idPBorrado =>{
                  //Tras borrar redirigimos a dashboard

                  this.authService.realizar_Auditoria(idPBorrado,this.isAdmin,'Usuario_Permiso','DELETE',this.idUsuarioActual,headers).subscribe({
                    next: data =>{
                      
                      //Auditoria Realizada. Redirigimos
                      this.dialogRef.afterClosed().subscribe(() => {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                          this.router.navigate(['/dashboard']);
                        });
                      });
                      
                      this.dialogRef.close();
                    },
                    error: err => {
                      localStorage.clear(); 
                      this.showErrorModal(err);
                      this.router.navigate(['/login']);
                    },
      
                  });
                 
                },
                error: (err) => {
                  localStorage.clear(); 
                  this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                  this.showErrorModal(err);
                  this.router.navigate(['/login']); 
                }
              });
                //Auditoria Realizada.
              },
              error: err => {
                localStorage.clear(); 
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              },

            });
             
          },
          error: (err) => {
            localStorage.clear(); 
            this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
            this.showErrorModal(err);
            this.router.navigate(['/login']); 
          },
        });
        break;
      case "template":
        const idTemplate = this.datosUsuario['idTemplate'];
        this.authService.delete_Templ(idTemplate, headers).subscribe({
          next: (datoU) => { //Tras borrar redirigimos a dashboard
            this.authService.realizar_Auditoria(datoU, this.isAdmin, 'Template', 'DELETE', this.idUsuarioActual,headers).subscribe({
              next: datoU => {
                this.dialogRef.afterClosed().subscribe(() => {
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['/dashboard']);
                  });
                });
                
                this.dialogRef.close();
              },
              error: err => {
                console.error('Error: ', err);
                this.showErrorModal(err);
                localStorage.clear(); 
                this.router.navigate(['/login']); 
              }
            });
           
          },
          error: (err) => {
            localStorage.clear(); 
            this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
            this.showErrorModal(err);
            this.router.navigate(['/login']); 
          },
        });
        break;
      case "grupo":
        const idGrupo =  this.datosUsuario['idGrupo'];
        this.authService.delete_Grupo(idGrupo, headers).subscribe({
          next: (datoU) => { 
            this.authService.realizar_Auditoria(datoU,this.isAdmin,'Grupo','DELETE',this.idUsuarioActual,headers).subscribe({
              next: data =>{
                //Actualizados datos se redirige a dashboard
                         //Antes de redirigir borramos las filas de Usuario_Permiso  relacionadas con este Usuario
            this.authService.delete_ModGru_idGru(idGrupo, headers).subscribe({
              next: idUBorrado =>{
                this.authService.realizar_Auditoria(idUBorrado, this.isAdmin, 'Modulo_Grupo', 'DELETE', this.idUsuarioActual,headers).subscribe({
                  next: datoU => {
                    this.authService.delete_UsuGru_idGru(idGrupo, headers).subscribe({
                      next: idNBorrado =>{
                        this.authService.realizar_Auditoria(idNBorrado, this.isAdmin, 'Usuario_Grupo', 'DELETE', this.idUsuarioActual,headers).subscribe({
                          next: datoUP => {
                            //Tras borrar redirigimos a dashboard
                            this.dialogRef.afterClosed().subscribe(() => {
                              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                this.router.navigate(['/dashboard']);
                              });
                            });
                            
                            this.dialogRef.close();
                          },
                          error: err => {
                            console.error('Error: ', err);
                            this.showErrorModal(err);
                            localStorage.clear(); 
                            this.router.navigate(['/login']); 
                          }
                        });
                       
                      },
                      error: (err) => {
                        localStorage.clear(); 
                        this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                        this.showErrorModal(err);
                        this.router.navigate(['/login']); 
                      }
                    });
                  },
                  error: err => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear(); 
                    this.router.navigate(['/login']); 
                  }
                });
               
              },
              error: (err) => {
                localStorage.clear(); 
                this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
                this.showErrorModal(err);
                this.router.navigate(['/login']); 
              }
            });
              },
              error: err => {
                localStorage.clear(); 
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              },
            });
          
          },
          error: (err) => {
            localStorage.clear();  
            this.closeDialog(); //Cerramos la ventana de Elimnar antes de mostrar error
            this.showErrorModal(err);
            this.router.navigate(['/login']); 
          },
        });
        break;
      }
      this.dialogRef.afterClosed().subscribe(() => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard']);
        });
      });
      
      this.dialogRef.close();

  }

  closeDialog(): void {
    // Close the dialog without performing any actions
    this.dialog.closeAll();
  }


  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}
