<div class="escritorio">
    <div class="common-componentes">
        <app-botonera></app-botonera>
        <app-year-contable></app-year-contable>
        <div id="grid-container" class="tab-content grid-container">
            <ag-grid-angular
            class="ag-theme-quartz"
            [rowData]="typeRow"
            [columnDefs]="columnDefsPropuesta"
            [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>
    <app-taskbar></app-taskbar>
</div>