import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';

@Component({
  selector: 'app-editar-permiso',
  templateUrl: './editar-permiso.component.html',
  styleUrl: './editar-permiso.component.css'
})
export class EditarPermisoComponent {
  isAdmin: boolean = false;
  tokenJWT: string = "false";
  idPerm = '';
  nombrePer = "";
  message = '';
  idUsuarioActual = "";
  listaModulos: any[] = [];
  listaTemplates: any[] = [];
  moduloChecked = "";
  templateChecked = "";
  modulosMostrados = false;
  templateMostrados = false;
  constructor(private router: Router, private http: HttpClient, private authService: AuthService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      const rutaCorrecta = localStorage.getItem('fromClick'); //Si no viene del click editar no se deja usar editarUsuario
      localStorage.removeItem('fromClick');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              if (data) { //Is Admin
                this.isAdmin = data;
                if (rutaCorrecta != 'true') {//Si no viene del click editar no se deja usar editarUsuario
                  localStorage.clear(); 
                  this.router.navigate(['/desktop']);
                } else {
                  const datosUsuario = JSON.parse(localStorage.getItem('permisoGrid') || '{}'); //Lo pasamos de JSON a objeto de nuevo
                  localStorage.removeItem('permisoGrid');
                  if (datosUsuario != null) {
                    this.http.get<any>(Constantes.GETMODULOSVM, { headers: headers }).subscribe({
                      next: modList => {
                        this.listaModulos = modList;

                        //Buscamos grupos
                        this.http.get<any>(Constantes.GET_TEMPLATES, { headers: headers }).subscribe({
                          next: templaList => {
                            this.listaTemplates = templaList;
                            //Obtenidos los datos pasamos a mostrarlos

                            this.idPerm = datosUsuario['idPermiso'];
                            //Con el usuario debemos obtener todos los datos ya que es un GET con Admin:
                            let idu = this.idPerm;
                            let cadenita = Constantes.GET_PERMISO_BY_ID + idu;
                            this.http.get<any>(cadenita, { headers }).subscribe({
                              next: (datoU) => {
                                this.nombrePer = datoU.nombrePermiso;
                                this.templateChecked = datoU.idTemplate;
                                this.moduloChecked = datoU.idModulo;
                              
                                this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                                  next: dataW => {
                                    this.idUsuarioActual = dataW.idUsuario;
                                    this.templateMostrados = true;
                                    this.modulosMostrados = true;
                                  },
                                  error: err => {
                                    localStorage.clear(); 
                                    this.showErrorModal(err);
                                    this.router.navigate(['/login']);
                                  },
                                });

                              },
                              error: (err) => {
                                //console.log('Error', err);
                                localStorage.clear(); 
                                this.showErrorModal(err);
                                this.router.navigate(['/login']);
                              },
                            });

                          },
                          error: err => {
                            console.error('Error: ', err);
                            this.showErrorModal(err);
                            localStorage.clear(); 
                            this.router.navigate(['/login']);
                          }
                        });
                      },
                      error: err => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        localStorage.clear(); 
                        this.router.navigate(['/login']);
                      }
                    });

                  }

                }
              } else { //Is not Admin
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear(); 
                this.openModal('No tienes permisos para acceder a esta pantalla');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear(); 
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }
          });
          // Manually trigger change detection
          this.cdr.detectChanges();
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          // console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear(); 
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.router.navigate(['/login']);
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }


  onSubmit() {
    // console.log('Form submitted with data:', this.formData, this.isAdmin);
    // You can add further logic here, such as sending the form data to a server
    var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var nombrePer = this.nombrePer;
    var idMod = this.moduloChecked;
    var idTemp = this.templateChecked;
    this.authService.update_Perm(this.idPerm, nombrePer, idTemp, idMod, headers).subscribe({
      next: data => {
        //Actualizados datos se redirige a dashboard
        this.authService.realizar_Auditoria(data, this.isAdmin, 'Permiso', 'UPDATE', this.idUsuarioActual, headers).subscribe({
          next: data => {
            //Auditoria Realizada.
            this.router.navigate(['/dashboard']);
          },
          error: err => {
            localStorage.clear(); 
            this.showErrorModal(err);
            this.router.navigate(['/login']);
          },
        });
      },
      error: err => {
        // console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.showErrorModal(err);
        this.router.navigate(['/login']);
      }
    });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    this.message = errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

