import { Component, ElementRef, ViewChild } from '@angular/core';
import { IToolPanel, IToolPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-custom-filter-panel-est-eje',
  templateUrl: './custom-filter-panel-est-eje.component.html',
  styleUrl: './custom-filter-panel-est-eje.component.css'
})
export class CustomFilterPanelEstEjeComponent implements IToolPanel {
  @ViewChild('toolPanel', { static: false }) toolPanel!: ElementRef;

  filtro1Checked: boolean = true;
  filtro2Checked: boolean = true;
  private params!: IToolPanelParams;
  selectedYear: number = 0;
  contabilidad: number = 0;
  filtroCap1Checked: boolean = false;
  filtroCap2Checked: boolean = false;
  filtroCap3Checked: boolean = false;
  filtroCap4Checked: boolean = false;
  filtroCap5Checked: boolean = false;
  filtroCap6Checked: boolean = false;
  filtroCap7Checked: boolean = false;
  filtroCap8Checked: boolean = false;
  filtroCap9Checked: boolean = false;

  agInit(params: IToolPanelParams): void {
    const storedYear = localStorage.getItem('yearContable');
    if (storedYear) {
      this.selectedYear = parseInt(storedYear, 10); // Convertir a número
    }
    const contabilidad = localStorage.getItem('contabilidad');
    if (contabilidad) {
      this.contabilidad = parseInt(contabilidad, 10); // Convertir a número
    }
    this.params = params;
    this.applyFiltro1();
    this.applyFiltro2();
  }

  getGui(): HTMLElement {
    return this.toolPanel.nativeElement;
  }

  refresh(): void {
    // Implement if needed
  }

  applyFiltro1(): void {
    // Obtener el modelo de filtro actual
    const filterModel = this.params.api.getFilterModel() || {};
  
    if (this.filtro1Checked) {
      // Si el checkbox está marcado, mostrar solo los que empiezan por '1'
      filterModel['apl_eco'] = {
        operator: 'OR',
        conditions: [
          { type: 'startsWith', filter: '9', filterType: 'text' },
          { type: 'startsWith', filter: '8', filterType: 'text' },
          { type: 'startsWith', filter: '7', filterType: 'text' },
          { type: 'startsWith', filter: '6', filterType: 'text' },
          { type: 'startsWith', filter: '5', filterType: 'text' },
          { type: 'startsWith', filter: '4', filterType: 'text' },
          { type: 'startsWith', filter: '3', filterType: 'text' },
          { type: 'startsWith', filter: '2', filterType: 'text' }
        ]
      };
    } else {
      // Si el checkbox está desmarcado, quitamos el filtro de 'apl_fun'
      delete filterModel['apl_eco'];
    }
  
    // Aplicar los filtros combinados (manteniendo otros existentes)
    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }
  

  applyFiltro2(): void {
    const filterModel = this.params.api.getFilterModel() || {};
  
    if (this.filtro2Checked) {
      // Aplicar filtro por año (apl_eje) y contabilidad (id_conta)
      filterModel['apl_eje'] = {
        filterType: 'number', // Cambiado a 'number'
        type: 'equals',
        filter: this.selectedYear // Año seleccionado
      };
  
      filterModel['id_conta'] = {
        filterType: 'number', // Cambiado a 'number'
        type: 'equals',
        filter: this.contabilidad // Contabilidad seleccionada
      };
    } else {
      delete filterModel['apl_eje'];
      filterModel['id_conta'] = {
        filterType: 'number', // Cambiado a 'number'
        type: 'equals',
        filter: this.contabilidad // Contabilidad seleccionada
      };
    }
  
    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }

  applyFiltersCodProg() {
    const filterModel = this.params.api.getFilterModel() || {};

    const conditions: any[] = [];

    if(this.filtroCap9Checked){
      conditions.push({ type: 'startsWith', filter: '9', filterType: 'text' });
    }
    if(this.filtroCap8Checked){
      conditions.push({ type: 'startsWith', filter: '8', filterType: 'text' });
    }
    if(this.filtroCap7Checked){
      conditions.push({ type: 'startsWith', filter: '7', filterType: 'text' });
    }
    if(this.filtroCap6Checked){
      conditions.push({ type: 'startsWith', filter: '6', filterType: 'text' });
    }
    if(this.filtroCap5Checked){
      conditions.push({ type: 'startsWith', filter: '5', filterType: 'text' });
    }
    if(this.filtroCap4Checked){
      conditions.push({ type: 'startsWith', filter: '4', filterType: 'text' });
    }
    if(this.filtroCap3Checked){
      conditions.push({ type: 'startsWith', filter: '3', filterType: 'text' });
    }
    if(this.filtroCap2Checked){
      conditions.push({ type: 'startsWith', filter: '2', filterType: 'text' });
    }
    if(this.filtroCap1Checked){
      conditions.push({ type: 'startsWith', filter: '1', filterType: 'text' });
    }

    if (conditions.length > 0) {
      filterModel['apl_eco'] = {
        filterType: 'text',
        operator: 'OR',
        conditions: conditions
      };
    } else {
      filterModel['apl_eco'] = {
        operator: 'OR',
        conditions: [
          { type: 'startsWith', filter: '9', filterType: 'text' },
          { type: 'startsWith', filter: '8', filterType: 'text' },
          { type: 'startsWith', filter: '7', filterType: 'text' },
          { type: 'startsWith', filter: '6', filterType: 'text' },
          { type: 'startsWith', filter: '5', filterType: 'text' },
          { type: 'startsWith', filter: '4', filterType: 'text' },
          { type: 'startsWith', filter: '3', filterType: 'text' },
          { type: 'startsWith', filter: '2', filterType: 'text' }
        ]
      };
    }

    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }
}


