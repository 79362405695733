import { Component, HostListener, Inject, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef, GridReadyEvent, RowNode, FilterChangedEvent, IRowNode } from 'ag-grid-community';
@Component({
  selector: 'app-modal-buscador',
  templateUrl: './modal-buscador.component.html',
  styleUrls: ['./modal-buscador.component.css']
})
export class ModalBuscadorComponent implements OnInit {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  terminoBusqueda: string = "";
  typeRow: any[] = [];
  columnDefsUsuario: ColDef[] = [
    {
      field: 'id',
      headerName: 'ID del Proponente',
      hide: true
    },
    {
      field: 'name',
      headerName: 'Nombre del Proponente',
      flex: 1,
      minWidth: 100,
      cellRenderer: (params: any) => {
        return `<a href="javascript:void(0)" class="name-link">${params.value}</a>`;
      }
    },
    { field: 'username',
      headerName: 'DNI del Proponente', 
      cellStyle: {
        textAlign: 'center'
      },
      flex: 1, minWidth: 100, maxWidth: 200
    }
  ];
  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false
  };

  @Output() rowSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<ModalBuscadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('jwtToken');
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    this.authService.getDatosGestiona(headers).subscribe({
      next: data => {
        this.typeRow = data; // Asignar los datos a typeRow
      },
      error: error => {
        console.log(error);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  applySearchFilter() {
    if (this.agGrid.api) {
      this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
    }
  }

  onCellClicked(event: any): void {
    if (event.colDef.field === 'name') {
      this.rowSelected.emit(event.data);
      this.closeDialog();
    }
  }
}


