import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constantes } from './constantes.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private lastActivity: number;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private Constantes: Constantes) {
    this.lastActivity = Date.now(); // Inicializa lastActivity con la hora actual
  }

  disableTokenIfInactive(): boolean {
    // Verificar si ha pasado más de 30 minutos desde la última actividad
    if (Date.now() - this.lastActivity > 30 * 60 * 1000) {
      // Si ha pasado más de 30 minutos desde la última actividad, deshabilitar el token JWT
      return false;
    }
    return true;
  }

  updateLastActivity(): void {
    this.lastActivity = Date.now(); // Actualiza lastActivity con la hora actual
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }
  //Cambiar
  login(jwt: JsonWebKey): Observable<any> {
    const jwte = jwt;
    return this.http.post(
      Constantes.AUTH_API + 'Login/',
      { //Le pasamos el jwt
        jwte
      },
      httpOptions
    );
  }

  realizar_Auditoria<T>(datosAudit: string[], isAdmin: boolean, tabla: string, nombreAuditoria: string, usuarioModificador: string, headers: HttpHeaders): Observable<T> {
    //Se supone que el vector de string tiene los datos de creación de Usuario/Permiso/Modulo/Elquesea
    var datosAudito = Object.values(datosAudit);
    switch (tabla) {
      case "Usuario":
        return this.add_Audit_Usu(datosAudito[0], datosAudito[1], datosAudito[2], datosAudito[3],
          datosAudito[4], datosAudito[5], datosAudito[6], datosAudito[7], nombreAuditoria, usuarioModificador, headers);

      case "Permiso":
        return this.add_Audit_Per(datosAudito[0], datosAudito[1], datosAudito[2], datosAudito[3], nombreAuditoria, usuarioModificador, headers);

      case "Modulo":
        return this.add_Audit_Mod(datosAudito[0], datosAudito[1], datosAudito[2], nombreAuditoria, usuarioModificador, headers);

      case "Template":
        return this.add_Audit_Templ(datosAudito[0], datosAudito[1], nombreAuditoria, usuarioModificador, headers);

      case "Grupo":
        return this.add_Audit_Grupo(datosAudito[0], datosAudito[1], nombreAuditoria, usuarioModificador, headers);

      case "Usuario_Permiso":
        return this.add_Audit_UsuPer(datosAudito[0], datosAudito[1], datosAudito[2], nombreAuditoria, usuarioModificador, headers);
      case "Usuario_Grupo":
        return this.add_Audit_UsuGru(datosAudito[0], datosAudito[1], datosAudito[2], nombreAuditoria, usuarioModificador, headers);
      case "Modulo_Grupo":
        return this.add_Audit_ModGru(datosAudito[0], datosAudito[1], datosAudito[2], nombreAuditoria, usuarioModificador, headers);
      case "AccesoDirecto":
        return this.add_Audit_AccesoDirecto(datosAudito[0], datosAudito[1], datosAudito[2], nombreAuditoria, usuarioModificador, headers);
      case "Propuesta":
        var idPROP = parseInt(datosAudito[0]);
        return this.add_Audit_Propuesta(idPROP, datosAudito[1], datosAudito[2], datosAudito[3], datosAudito[4], datosAudito[5],
          datosAudito[6], datosAudito[7], datosAudito[8], datosAudito[9], datosAudito[10], datosAudito[11], datosAudito[12], datosAudito[13],
          datosAudito[14], datosAudito[15], datosAudito[16], datosAudito[17], datosAudito[18], datosAudito[19], datosAudito[20], nombreAuditoria, usuarioModificador, headers);
      case "ConfiguracionGeneral":
        var idMant = datosAudito[5];
        return this.add_Audit_ConfiguracionGeneral(datosAudito[0], datosAudito[1], datosAudito[2],
          datosAudito[3], datosAudito[4], idMant, nombreAuditoria, usuarioModificador, headers);
      default:
        return throwError(() => new Error('Unsupported HTTP method'));
    }

  }



  register(username: string, email: string, password: string,
    nombreUsu: string, apellidoUsu: string, esAdmin: boolean, headers: HttpHeaders): Observable<any> {
    const passwordUsuario = this.TransformarPWDToToken(password);
    const idUsuario = "idUsuario";
    const nombreLogin = username;
    const nombreUsuario = nombreUsu;
    const apellidosUsuario = apellidoUsu;
    const emailUsuario = email;
    const isAdmin: boolean = esAdmin;
    return this.http.post(
      Constantes.AUTH_API + 'PostUsuario/',
      { //Le pasamos UusarioDB
        idUsuario,
        nombreLogin,
        passwordUsuario,
        nombreUsuario,
        apellidosUsuario,
        emailUsuario,
        isAdmin
      },
      { headers }
    );
  }

  add_Audit_Usu(idUsuari: string, username: string, password: string, nombreUsu: string, apellidoUsu: string,
    email: string, esAdmin: string, estad: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const passwordUsuario = this.TransformarPWDToToken(password);
    const idUsuario = idUsuari;
    const nombreLogin = username;
    const nombreUsuario = nombreUsu;
    const apellidosUsuario = apellidoUsu;
    const emailUsuario = email;
    var isAdmin: boolean;
    if (esAdmin == "true" || esAdmin == "True") {
      isAdmin = true;
    } else {
      isAdmin = false;
    }

    const estado = estad;

    const idAuditoriaUsuario = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_API + 'PostAuditoriaUsuario/',
      { //Le pasamos AuditoriaUsuario
        idAuditoriaUsuario,
        idUsuario,
        nombreLogin,
        passwordUsuario,
        nombreUsuario,
        apellidosUsuario,
        emailUsuario,
        isAdmin,
        estado,
        IdUsuarioMod,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
      },
      { headers }
    );
  }


  register_Permiso(username: string, idMod: string, idTempla: string, headers: HttpHeaders): Observable<any> {

    const idUsuario = "idUsuario";
    const nombrePermiso = username;
    const idModulo = idMod;
    const idTemplate = idTempla;
    return this.http.post(
      Constantes.AUTH_PERM + 'PostPermiso/',
      { //Le pasamos ModuloDB
        idUsuario,
        nombrePermiso,
        idTemplate,
        idModulo
      },
      { headers }
    );
  }

  add_Audit_Per(idPerm: string, username: string, idMod: string, idTempla: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const idPermiso = idPerm;
    const nombrePermiso = username;
    const idModulo = idMod;
    const idTemplate = idTempla;

    const idAuditoriaPermiso = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_PERM + 'PostAuditoriaPermiso/',
      { //Le idAuditoriaPermiso AuditoriaUsuario
        idAuditoriaPermiso,
        idPermiso,
        nombrePermiso,
        idTemplate,
        idModulo,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }

  register_UsuPer(idU: string, idP: string, headers: HttpHeaders): Observable<any> {

    const idUsuario_Permiso = "idUsuario";
    const idUsuario = idU;
    const idPermiso = idP;
    return this.http.post(
      Constantes.AUTH_USUPER + 'PostUsuarioPermiso/',
      { //Le pasamos UusarioDB
        idUsuario_Permiso,
        idUsuario,
        idPermiso
      },
      { headers }
    );
  }

  add_Audit_UsuPer(idUsuPer: string, idUsu: string, idPerm: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const idPermiso = idPerm;
    const idUsuario = idUsu;
    const idUsuarioPermiso = idUsuPer;

    const idAuditoriaUsuarioPermiso = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_USUPER + 'PostAuditoriaUsuarioPermiso/',
      { //Le idAuditoriaPermiso AuditoriaUsuario
        idAuditoriaUsuarioPermiso,
        idUsuario,
        idPermiso,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }



  register_Modulo(nombreMod: string, urlImg: string, headers: HttpHeaders): Observable<any> {

    const idUsuario = "idUsuario";
    const nombreModulo = nombreMod;
    const urlNueva = urlImg;
    return this.http.post(
      Constantes.AUTH_MOD + 'PostModulo/',
      { //Le pasamos ModuloDB
        idUsuario,
        nombreModulo,
        urlNueva
      },
      { headers }
    );
  }

  add_Audit_Mod(idModu: string, nombreMod: string, urlImg: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const idModulo = idModu;
    var nombreModulo = nombreMod;
    var iconoURL = urlImg;
    if (nombreModulo == undefined) {
      nombreModulo = "";
    }
    if (iconoURL == undefined) {
      iconoURL = "";
    }
    const idAuditoriaModulo = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_MOD + 'PostAuditoriaModulo/',
      { //Le idAuditoriaPermiso AuditoriaUsuario
        idAuditoriaModulo,
        idModulo,
        nombreModulo,
        iconoURL,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }



  register_Template(nombreTem: string, headers: HttpHeaders): Observable<any> {

    const idTemplate = "idUsuario";
    const nombreTemplate = nombreTem;
    return this.http.post(
      Constantes.AUTH_TEMPL + 'PostTemplate/',
      { //Le pasamos ModuloDB
        idTemplate,
        nombreTemplate
      },
      { headers }
    );
  }

  add_Audit_Templ(idModu: string, nombreMod: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const idTemplate = idModu;
    const nombreTemplate = nombreMod;

    const idAuditoriaUsuarioPermiso = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_TEMPL + 'PostAuditoriaTemplate/',
      { //Le idAuditoriaPermiso AuditoriaUsuario
        idAuditoriaUsuarioPermiso,
        idTemplate,
        nombreTemplate,
        IdUsuarioMod,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
      },
      { headers }
    );
  }

  register_Grupo(nombreTem: string, headers: HttpHeaders): Observable<any> {

    const idTemplate = "idUsuario";
    const nombreGrupo = nombreTem;
    return this.http.post(
      Constantes.AUTH_GRU + 'PostGrupo/',
      { //Le pasamos ModuloDB
        idTemplate,
        nombreGrupo
      },
      { headers }
    );
  }

  add_Audit_Grupo(idModu: string, nombreMod: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {
    const idGrupo = idModu;
    const nombreGrupo = nombreMod;

    const idAuditoriaUsuarioPermiso = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_GRU + 'PostAuditoriaGrupo/',
      { //Le idAuditoriaPermiso AuditoriaUsuario
        idAuditoriaUsuarioPermiso,
        idGrupo,
        nombreGrupo,
        IdUsuarioMod,
        nombreAuditoria,
        fechaMovimiento,
        correlationID,
      },
      { headers }
    );
  }

  register_UsuGru(idU: string, idP: string, headers: HttpHeaders): Observable<any> {

    const idUsuario_Grupo = "idUsuario";
    const idUsuario = idU;
    const idGrupo = idP;
    return this.http.post(
      Constantes.AUTH_USUGRU + 'PostUsuarioGrupo/',
      { //Le pasamos UusarioDB
        idUsuario_Grupo,
        idUsuario,
        idGrupo
      },
      { headers }
    );
  }

  add_Audit_UsuGru(idUs_P: string, idU: string, idP: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {

    const idUsuarioGrupo = idUs_P;
    const idUsuario = idU;
    const idGrupo = idP;

    const idAuditoriaUsuarioGrupo = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_USUGRU + 'PostAuditoriaUsuarioGrupo/',
      { //Le pasamos UusarioDB
        idAuditoriaUsuarioGrupo,
        idUsuarioGrupo,
        idUsuario,
        idGrupo,

        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }

  register_ModGru(idU: string, idP: string, headers: HttpHeaders): Observable<any> {

    const idModulo_Grupo = "idUsuario";
    const idModulo = idU;
    const idGrupo = idP;
    return this.http.post(
      Constantes.AUTH_MODGRU + 'PostModuloGrupo/',
      { //Le pasamos UusarioDB
        idModulo_Grupo,
        idModulo,
        idGrupo
      },
      { headers }
    );
  }

  add_Audit_ModGru(idUs_P: string, idU: string, idP: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {

    const idModuloGrupo = idUs_P;
    const idModulo = idU;
    const idGrupo = idP;

    const idAuditoriaModuloGrupo = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_MODGRU + 'PostAuditoriaModuloGrupo/',
      { //Le pasamos UusarioDB
        idAuditoriaModuloGrupo,
        idModuloGrupo,
        idModulo,
        idGrupo,

        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }

  add_Audit_AccesoDirecto(idUs_P: string, idU: string, idP: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {

    const idAccesoDirecto = idUs_P;
    const idModulo = idU;
    const idUsuario = idP;

    const idAuditoriaAccesoDirecto = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.AUTH_AUDIT_ACCESODIRECTO + 'PostAuditoriaAccesoDirectoModulo/',
      { //Le pasamos UusarioDB
        idAuditoriaAccesoDirecto,
        idAccesoDirecto,
        idModulo,
        idUsuario,

        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }


  registrar_Propuesta(proponent: string, responsabl: string, carg: string, cargoResponsabl: string, objet: string, necesity: string, plaz: string, importeNuevo: number, fechaProp: Date, exptAsoc: string, idConta: number, eje: number, codprog: string, codeco: string, usuControl: string, expdtGes: string, dniProp: string, estadoProp: number, headers: HttpHeaders): Observable<any> {
    const idPropuesta = 0;
    const proponente = proponent;
    const responsable = responsabl;
    const cargo = carg;
    const cargoResponsable = cargoResponsabl;
    const objeto = objet;
    const necesidad = necesity;
    const plazo = plaz;
    const usuarioControl = usuControl;
    const fechaControl = "1000-01-01";
    const importe = importeNuevo;
    const fechaPropuesta = fechaProp;
    const expdtAsoc = exptAsoc;
    const estadoPropuesta = estadoProp;
    const ejercicio = eje;
    const codProg = codprog;
    const codEco = codeco;
    const idDocumentoGestiona = "Ejemplo";
    const dniProponente = dniProp;
    const idContabilidad = idConta;
    const expdtGestiona = expdtGes;

    return this.http.post(
      Constantes.ADDPROPUESTA,
      {
        idPropuesta,
        proponente,
        responsable,
        cargo,
        cargoResponsable,
        objeto,
        necesidad,
        plazo,
        usuarioControl,
        fechaControl,
        importe,
        fechaPropuesta,
        expdtAsoc,
        estadoPropuesta,
        ejercicio,
        codProg,
        codEco,
        idDocumentoGestiona,
        dniProponente,
        idContabilidad,
        expdtGestiona
      },
      { headers }
    );
  }


  update_Usu(idUsu: string, username: string, email: string, password: string, nombreUsu: string, apellidoUsu: string, admin: boolean, headers: HttpHeaders): Observable<any> {
    const passwordUsuario = this.TransformarPWDToToken(password);
    const idUsuario = idUsu;
    const nombreLogin = username;
    const nombreUsuario = nombreUsu;
    const apellidosUsuario = apellidoUsu;
    const emailUsuario = email;
    const isAdmin = admin;
    return this.http.put(
      Constantes.AUTH_API + 'PutUsuario/',
      { //Le pasamos UusarioDB
        idUsuario,
        nombreLogin,
        passwordUsuario,
        nombreUsuario,
        apellidosUsuario,
        emailUsuario,
        isAdmin
      },
      { headers }
    );
  }

  update_Usu_AccesoDirecto(idUsu: string, username: string, email: string, password: string, nombreUsu: string, apellidoUsu: string, admin: boolean,
    estad: string, accDir1: string | null, accDir2: string | null, accDir3: string | null, headers: HttpHeaders): Observable<any> {
    const passwordUsuario = this.TransformarPWDToToken(password);
    const idUsuario = idUsu;
    const nombreLogin = username;
    const nombreUsuario = nombreUsu;
    const apellidosUsuario = apellidoUsu;
    const emailUsuario = email;
    const isAdmin = admin;
    const estado = estad;
    const idAccesoDirectoMod1 = accDir1;
    const idAccesoDirectoMod2 = accDir2;
    const idAccesoDirectoMod3 = accDir3;
    return this.http.put(
      Constantes.AUTH_API + 'PutUsuario/',
      { //Le pasamos UusarioDB
        idUsuario,
        nombreLogin,
        passwordUsuario,
        nombreUsuario,
        apellidosUsuario,
        emailUsuario,
        isAdmin,
        estado,
        idAccesoDirectoMod1,
        idAccesoDirectoMod2,
        idAccesoDirectoMod3
      },
      { headers }
    );
  }

  add_AccesoDirecto(idU: string, idP: string, headers: HttpHeaders): Observable<any> {

    const idAccesoDirecto = "idUsP";
    const idModulo = idP;
    const idUsuario = idU;
    return this.http.post(
      Constantes.AUTH_ACCESODIRECTO + 'PostAccesoDirecto_Modulo/',
      { //Le pasamos UusarioDB
        idAccesoDirecto,
        idModulo,
        idUsuario
      },
      { headers }
    );

  }

  update_Mod(idMod: string, nombreMod: string, iconoURL: string, headers: HttpHeaders): Observable<any> {

    const idModulo = idMod;
    const nombreModulo = nombreMod;
    return this.http.put(
      Constantes.AUTH_MOD + 'PutModulo/',
      { //Le pasamos UusarioDB
        idModulo,
        nombreModulo,
        iconoURL
      },
      { headers }
    );
  }

  update_Perm(idPerm: string, nom: string, idTemp: string, idMod: string, headers: HttpHeaders): Observable<any> {
    const idPermiso = idPerm;
    const nombrePermiso = nom;
    const idTemplate = idTemp;
    const idModulo = idMod;
    return this.http.put(
      Constantes.AUTH_PERM + 'PutPermiso/',
      { //Le pasamos UusarioDB
        idPermiso,
        nombrePermiso,
        idTemplate,
        idModulo
      },
      { headers }
    );
  }

  update_Templ(idTempl: string, nomTemp: string, headers: HttpHeaders): Observable<any> {
    const idTemplate = idTempl;
    const nombreTemplate = nomTemp;
    return this.http.put(
      Constantes.AUTH_TEMPL + 'PutTemplate/',
      { //Le pasamos UusarioDB
        idTemplate,
        nombreTemplate
      },
      { headers }
    );
  }

  update_Gru(idTempl: string, nomTemp: string, headers: HttpHeaders): Observable<any> {
    const idGrupo = idTempl;
    const nombreGrupo = nomTemp;
    return this.http.put(
      Constantes.AUTH_GRU + 'PutGrupo/',
      { //Le pasamos UusarioDB
        idGrupo,
        nombreGrupo
      },
      { headers }
    );
  }



  delete_Usu(idUsu: string, headers: HttpHeaders): Observable<any> {
    const idUsuario = idUsu;
    const url = `${Constantes.AUTH_API}DeleteUsuario/${idUsu}`;
    console.log(url);
    return this.http.delete(
      url,
      { headers: headers }
    );
  }

  delete_Mod(idMod: string, headers: HttpHeaders): Observable<any> {
    const idModulo = idMod;
    const url = `${Constantes.AUTH_MOD}DeleteModulo/${idModulo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_Perm(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idPermiso = idPerm;
    const url = `${Constantes.AUTH_PERM}DeletePermiso/${idPermiso}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_Templ(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idTemplate = idPerm;
    const url = `${Constantes.AUTH_TEMPL}DeleteTemplate/${idTemplate}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_Grupo(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idGrupo = idPerm;
    const url = `${Constantes.AUTH_GRU}DeleteGrupo/${idGrupo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_UsuPer_idUsu(idusu: string, headers: HttpHeaders): Observable<any> {
    const idUsuario = idusu;
    const url = `${Constantes.AUTH_USUPER}DeleteUsuarioPermiso_W_Usuario/${idUsuario}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_UsuPer_idPer(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idPermiso = idPerm;
    const url = `${Constantes.AUTH_USUPER}DeleteUsuarioPermiso_W_Permiso/${idPermiso}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_ModGru_idMod(idusu: string, headers: HttpHeaders): Observable<any> {
    const IdModulo = idusu;
    const url = `${Constantes.AUTH_MODGRU}DeleteModuloGrupo_W_Modulo/${IdModulo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_ModGru_idGru(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idGrupo = idPerm;
    const url = `${Constantes.AUTH_MODGRU}DeleteModuloGrupo_W_Grupo/${idGrupo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }


  delete_UsuGru_idUsu(idusu: string, headers: HttpHeaders): Observable<any> {
    const idUsuario = idusu;
    const url = `${Constantes.AUTH_USUGRU}DeleteUsuarioGrupo_W_Usuario/${idUsuario}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_UsuGru_idGru(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idGrupo = idPerm;
    const url = `${Constantes.AUTH_USUGRU}DeleteUsuarioGrupo_W_Grupo/${idGrupo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }


  delete_AccesoDirecto_idMod(idusu: string, headers: HttpHeaders): Observable<any> {
    const IdModulo = idusu;
    const url = `${Constantes.AUTH_ACCESODIRECTO}DeleteAccesoDirecto_Modulo_W_Modulo/${IdModulo}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  delete_AccesoDirecto_idUsu(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idUsuario = idPerm;
    const url = `${Constantes.AUTH_ACCESODIRECTO}DeleteAccesoDirecto_Modulo_W_Usuario/${idUsuario}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  setModoMantenimiento(headers: HttpHeaders): Observable<any> {
    const url = `${Constantes.AUTH_API}SetAllEstadoMantenimiento/`;
    return this.http.get(
      url, { headers: headers }
    );
  }

  setModoMantenimientoConfiguracionGeneral(isMantenimiento: boolean, headers: HttpHeaders): Observable<any> {
    const url = `${Constantes.MANTENIMIENTO_CONFIGGENERAL}${isMantenimiento}/`;
    return this.http.get(
      url, { headers: headers }
    );
  }

  resetModoMantenimiento(headers: HttpHeaders): Observable<any> {
    const url = `${Constantes.AUTH_API}ResetAllEstadoMantenimiento/`;
    return this.http.get(
      url, { headers: headers }
    );
  }

  logout(): Observable<any> {
    return this.http.post(Constantes.AUTH_API + 'signout', {}, httpOptions);
  }

  TransformarPWDToToken(password: string): string {
    const retStr = password;

    // retStr = CifradodePasswordaToken(password);
    return retStr;
  }

  getDescuentoAplicacion(ejercicio: number, codProg: string, codEco: string, headers: HttpHeaders): Observable<any> {

    var cadena = Constantes.GETDESCUENTO_APLICACION + `${ejercicio}/${codProg}/${codEco}`;
    var optins = { headers, responseType: 'text' as 'json' };
    return this.http.get(
      cadena, optins
    );
  }

  getAplicacionPresupuestaria(ejercicio: number, codProg: string, codEco: string, headers: HttpHeaders): Observable<any> {

    var cadena = Constantes.GETAPLICACION_PRESUPUESTARIA + `${ejercicio}/${codProg}/${codEco}`;
    var optins = { headers };
    return this.http.get(
      cadena, optins
    );
  }

  getDatosGestiona(headers: HttpHeaders): Observable<any> {
    var cadena = Constantes.GETALLPROPONENTESPAGED;
    var optins = { headers };
    return this.http.get(
      cadena, optins
    );
  }



  getExpdtGestiona(headers: HttpHeaders): Observable<any> {
    var cadena = Constantes.GETEXPDTPAGED;
    var optins = { headers };
    return this.http.get(
      cadena, optins
    );
  }

  getJobFromProponente(idProponente: string, headers: HttpHeaders): Observable<any> {
    var cadena = Constantes.GETJOBFROMPROPONENTE + `${idProponente}`;
    var optins = { headers, responseType: 'text' as 'text' };
    return this.http.get(
      cadena, optins
    );
  }

  getHrefFromProponente(idProponente: string, headers: HttpHeaders): Observable<any> {
    var cadena = Constantes.GETHREFFROMPROPONENTE + `${idProponente}`;
    var optins = { headers, responseType: 'text' as 'text' };
    return this.http.get(
      cadena, optins
    );
  }

  generarValorPropuesta(idContabilidad: string, ejercicio: string, headers: HttpHeaders) {

    var ret = "";
    var cadena = Constantes.GET_PREVISIONES + `${idContabilidad}` + "/" + `${ejercicio}`;
    var options = { headers, responseType: 'text' as 'json' }; // Indicate response is text
    return this.http.get<string>(
      cadena, options
    );
  }

  getDatosPropuesta(idPropuesta: string, headers: HttpHeaders) {
    var cadena = `${Constantes.GET_VALOR_PROPUESTA}${idPropuesta}`;
    var optins = { headers };
    return this.http.get<any>(
      cadena, optins
    );
  }

  getDatosCargosPropuesta(idPropuesta: string, headers: HttpHeaders) {
    var cadena = `${Constantes.GETVISTA_CARGOPROPUESTA}${idPropuesta}`;
    var optins = { headers };
    return this.http.get<any>(
      cadena, optins
    );
  }

  getDatosCargosPropuestaSuma(idPropuesta: string, headers: HttpHeaders) {
    var cadena = `${Constantes.GETVISTA_CARGOPROPUESTASUMA}${idPropuesta}`;
    var optins = { headers };
    return this.http.get<any>(
      cadena, optins
    );
  }

  updatePropuesta(idPropuest: number, proponent: string, responsabl: string, carg: string, cargoResponsabl: string, objet: string, necesity: string, plaz: string,
    usuControl: string, fecContr: string, importeNuevo: string, fechaProp: string, exptAsoc: string, estProp: string, eje: string,
    codprog: string, codeco: string, idDocGes: string, dniProp: string, idConta: string, expdtGes: string, headers: HttpHeaders): Observable<any> {

    const idPropuesta = idPropuest;
    const proponente = proponent;
    const responsable = responsabl;
    const cargo = carg;
    const cargoResponsable = cargoResponsabl;
    const objeto = objet;
    const necesidad = necesity;
    const plazo = plaz;
    const usuarioControl = usuControl;
    const fechaControl = fecContr;
    const importe = parseFloat(importeNuevo);
    const fechaPropuesta = fechaProp;
    const expdtAsoc = exptAsoc;
    const estadoPropuesta = parseInt(estProp);
    const ejercicio = parseInt(eje, 10);
    const codProg = codprog;
    const codEco = codeco;
    const idDocumentoGestiona = idDocGes;
    const dniProponente = dniProp;
    const idContabilidad = parseInt(idConta);
    const expdtGestiona = expdtGes;
    var temp = {
      idPropuesta,
      proponente,
      responsable,
      cargo,
      cargoResponsable,
      objeto,
      necesidad,
      plazo,
      usuarioControl,
      fechaControl,
      importe,
      fechaPropuesta,
      expdtAsoc,
      estadoPropuesta,
      ejercicio,
      codProg,
      codEco,
      idDocumentoGestiona,
      dniProponente,
      idContabilidad,
      expdtGestiona
    };
    return this.http.put(
      Constantes.UPDATE_PROPUESTA,
      {
        idPropuesta,
        proponente,
        responsable,
        cargo,
        cargoResponsable,
        objeto,
        necesidad,
        plazo,
        usuarioControl,
        fechaControl,
        importe,
        fechaPropuesta,
        expdtAsoc,
        estadoPropuesta,
        ejercicio,
        codProg,
        codEco,
        idDocumentoGestiona,
        dniProponente,
        idContabilidad,
        expdtGestiona
      },
      { headers }
    );
  }

  add_Audit_Propuesta(idPropuest: number, proponent: string, respon: string, carg: string, cargoRespon: string, objet: string, necesity: string, plaz: string,
    usuControl: string, fecContr: string, importeNuevo: string, fechaProp: string, exptAsoc: string, estProp: string, eje: string,
    codprog: string, codeco: string, idDocGes: string, dniProp: string, idConta: string, expdtGes: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {

    const idPropuesta = idPropuest;
    const proponente = proponent;
    const responsable = respon;
    const cargo = carg;
    const cargoResponsable = cargoRespon;
    const objeto = objet;
    const necesidad = necesity;
    const plazo = plaz;
    const usuarioControl = usuControl;
    const fechaControl = fecContr;
    const importe = parseFloat(importeNuevo);
    const fechaPropuesta = fechaProp;
    const expdtAsoc = exptAsoc;
    const estadoPropuesta = parseInt(estProp);
    const ejercicio = parseInt(eje, 10);
    const codProg = codprog;
    const codEco = codeco;
    const idDocumentoGestiona = idDocGes;
    const dniProponente = dniProp;
    const idContabilidad = parseInt(idConta);
    const expdtGestiona = expdtGes;

    const idAuditoriaUsuarioPropuesta = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;

    return this.http.post(
      Constantes.ADD_AUDIT_PROPUESTA,
      {
        idAuditoriaUsuarioPropuesta,
        idPropuesta,
        proponente,
        responsable,
        cargo,
        cargoResponsable,
        objeto,
        necesidad,
        plazo,
        usuarioControl,
        fechaControl,
        importe,
        fechaPropuesta,
        expdtAsoc,
        estadoPropuesta,
        ejercicio,
        codProg,
        codEco,
        idDocumentoGestiona,
        dniProponente,
        idContabilidad,
        expdtGestiona,
        IdUsuarioMod,
        nombreAuditoria,
        fechaMovimiento,
        correlationID
      },
      { headers }
    );
  }

  add_Audit_ConfiguracionGeneral(idCG: string, alcal: string, interven: string, tipoExpdte: string, grupoGestor: string,
    idMante: string, audit: string, emailUsu: string, headers: HttpHeaders): Observable<any> {

    const IdConfiguracionGeneral = idCG;
    const alcalde = alcal;
    const interventor = interven;
    const tipoExpdtePropuestaGasto = tipoExpdte;
    const grupoGestorExpdtePropuesta = grupoGestor;
    const isMantenimiento = idMante;

    const idAuditoriaConfiguracionGeneral = "idUsu";
    const nombreAuditoria = audit;
    const fechaMovimiento = "1000-01-01";
    const correlationID = "string";
    const IdUsuarioMod = emailUsu;
    return this.http.post(
      Constantes.ADD_AUDIT_CONFIGGENERAL,
      { //Le pasamos UusarioDB
        idAuditoriaConfiguracionGeneral,
        IdConfiguracionGeneral,
        alcalde,
        interventor,
        tipoExpdtePropuestaGasto,
        grupoGestorExpdtePropuesta,
        isMantenimiento,

        nombreAuditoria,
        fechaMovimiento,
        correlationID,
        IdUsuarioMod
      },
      { headers }
    );
  }


  generarURI(headers: HttpHeaders) {

    var optins = { headers, responseType: 'text' as 'text' };
    var lo = Constantes.GET_URI;
    return this.http.post(
      Constantes.GET_URI, {}, optins
    );
  }

  addArchivoToExpdt(href: string, name: string, id: string, headers: HttpHeaders) {

    var optins = { headers, responseType: 'text' as 'text' };
    var lo = Constantes.ADD_ARCHIVO_TO_EXPDT + `?href=${encodeURIComponent(href)}` + `&name=${name}` + `&id=${id}`;
    return this.http.post(
      lo, {}, optins
    );

  }

  delete_Propuesta(idPerm: string, headers: HttpHeaders): Observable<any> {
    const idTemplate = idPerm;
    const url = `${Constantes.DELETE_PROPUESTA}${idTemplate}`;
    return this.http.delete(
      url, { headers: headers }
    );
  }

  subidaPDF(blob: Blob, URI: string, headers: HttpHeaders) {
    headers = headers.set('Content-Type', 'application/pdf');
    var optins = { headers, responseType: 'text' as 'text' };

    const url = Constantes.UPLOAD_PDF + `?URI=${encodeURIComponent(URI)}`;

    return this.http.put(
      url, blob, optins
    );

  }

  BuscarExpdtGestiona(ejercicio: number, codProg: string, codEco: string, headers: HttpHeaders) {
    var optins = { headers, responseType: 'text' as 'text' };
    var cadena = Constantes.BUSCAR_EXPDT_GESTIONA + `${ejercicio}/${codProg}/${codEco}`;
    return this.http.get(
      cadena, optins
    );
  }

  CrearExpediente(headers: HttpHeaders) {
    var optins = { headers, responseType: 'text' as 'text' };
    var cadena = Constantes.CREAR_EXPEDIENTE;
    return this.http.post(
      cadena, {}, optins
    )
  }

  AbrirExpediente(href: string, name: string, headers: HttpHeaders) {
    var optins = { headers, responseType: 'text' as 'text' };
    var cadena = Constantes.ABRIR_EXPEDIENTE + `?href=${encodeURIComponent(href)}` + `&name=${name}`;
    return this.http.post(
      cadena, {}, optins
    )
  }

  CircuitoTramitacion(esAlcade: boolean, hrefProponente: string, href: string, nombreProponente: string, headers: HttpHeaders) {

    var optins = { headers, responseType: 'text' as 'text' };
    var lo = Constantes.CIRCUITO_TRAMITACION + `?esAlcade=${esAlcade}` + `&hrefProponente=${hrefProponente}` + `&href=${href}` + `&nombreProponente=${nombreProponente}`;
    return this.http.post(
      lo, {}, optins
    );

  }

  GetListaJustificantesAplicacion(ejercicio: number, codProg: string, codEco: string, headers: HttpHeaders): Observable<any> {

    var cadena = Constantes.GET_LIST_JUST_APLIC + `?apli_eje=${ejercicio}` + `&apli_fun=${codProg}` + `&apli_eco=${codEco}`;
    return this.http.get(
      cadena, { headers: headers }
    );
  }

  CompruebaExpdt(expdt: string, headers: HttpHeaders) {
    var cadena = Constantes.COMPRUEBA_EXPDT + `?exactCode=${expdt}`;
    return this.http.get(
      cadena, { headers: headers }
    );
  }

  descargaPDF(idDocumento: string, headers: HttpHeaders) {
    headers = headers.set('Content-Type', 'application/pdf');
    var optins = { headers, responseType: 'blob' as 'json' };

    const url = Constantes.DOWNLOAD_PDF + idDocumento;

    return this.http.get(
      url, optins
    );

  }

  anulaPDFGestiona(expdt: string, idDocGest : string,  headers: HttpHeaders) {
    var cadena = Constantes.ANULAPDF_GESTIONA+idDocGest + `?expdtePropuesta=${expdt}`;
    return this.http.delete(
      cadena, { headers: headers }
    );
  }
}