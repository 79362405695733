// auth.service.ts
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  constructor(private auth: AngularFireAuth) { }

  loginJwt(email: string, password: string) {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // El usuario ha iniciado sesión
        const user = userCredential.user;
      })
      .catch((error) => {
        // Ha ocurrido un error
        console.error(error);
      });
  }

  registerJwt(email: string, password: string): Promise<string> {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // The user has successfully signed up
        return userCredential.user + ""; // or whatever you want to return from userCredential
      })
      .catch((error) => {
        // An error occurred
        console.error(error);
        return "ERROR_" + error.toString();
      });
  }

  /*deleteUserByEmail(email: string) {
  try {
    const auth = getAuth()
    auth.
    const user = await admin.auth().getUserByEmail(email);
    await admin.auth().deleteUser(user.uid);
    console.log(`Successfully deleted user with email: ${email}`);
  } catch (error) {
    console.error('Error deleting user:', error);
  }
}*/

  logout() {
    return this.auth.signOut();
  }
}
