import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';
import { RoutingService } from '../../../_services/routing.service';
import { CustomFilterPanelEstadoPropuestasComponent } from '../../AgGridCustom/custom-filter-panel-estado-propuestas/custom-filter-panel-estado-propuestas.component';
import { right } from '@popperjs/core';

@Component({
  selector: 'app-estado-propuesta',
  templateUrl: './estado-propuesta.component.html',
  styleUrl: './estado-propuesta.component.css'
})
export class EstadoPropuestaComponent {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  typeRow: any[] = [];
  totalDisponibles: number = 0;
  totalGastado: number = 0;
  totalDefinitivo: number = 0;
  selectedYear: number = 0; // Año predeterminado
  contabilidad: number = 0; // Año predeterminado
  years: number[] = []; // Lista de años únicos
  filtro1Checked: boolean = true;
  filtro2Checked: boolean = true;
  originalTypeRow: any[] = [];

  sideBar: SideBarDef = {}
  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false,
    context: {
      componentParent: this,
    },
  };

  columnDefsUsuario: ColDef[] = [
    {
      field: 'propuesta',
      headerName: 'Propuesta',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: 'center'
      },
      filterParams: {
        defaultOption: 'startsWith',
      },
      onCellClicked: (params: any) => {
        // Call your method, e.g., this.onCodigoClicked(params.data)
        this.onCodigoClicked(params.data); // Trigger action with row data
      }
    },
    {
      headerName: 'Propuesta Ope',
      field: 'propuesta_ope',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: 'center'
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'concepto',
      headerName: 'Concepto',
      width: 500,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'contains',
      } 
    },
    { 
      field: 'autorizado',
      headerName: 'Autorizado',
      valueFormatter: (params) => this.formatNumber(params.value),
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'cargos',
      headerName: 'Cargos',
      valueFormatter: (params) => this.formatNumber(params.value),
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      } 
    },
    { 
      field: 'saldo',
      headerName: 'Saldo',
      valueFormatter: (params) => this.formatNumber(params.value),
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'eje_presu',
      headerName: 'Ejercicio',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'equals',
      } 
    },
    { 
      field: 'cod_cla_funci',
      headerName: 'Cod. Fun.',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'cod_cla_econo',
      headerName: 'Cod. Eco.',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        maxNumConditions: 8,
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'num_opera',
      headerName: 'Num. Operacion',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellStyle: {
        textAlign: 'center'
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    } 
  ];

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private redirige : RoutingService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
          this.http.get<any>(Constantes.GETVISTA_ESTADOPROPUESTAS, { headers: headers }).subscribe({
            next: data => {
              this.originalTypeRow = [...data]; // Guardar los datos originales
              this.typeRow = data;
              this.typeRow.sort((a, b) => b.propuesta - a.propuesta);

              this.sideBar = {
                toolPanels: [
                  {
                    id: 'customToolPanel',
                    labelDefault: 'Filtros',
                    labelKey: 'customFilters',
                    iconKey: 'menu',
                    toolPanel: CustomFilterPanelEstadoPropuestasComponent,  // Panel personalizado que vamos a crear
                  },
                ],
                defaultToolPanel: 'customToolPanelProp',
              }
            },
            error: err => {
              console.log(err);
            }
          });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  applySearchFilter() {
    if (this.agGrid.api) {
      this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
      // Calcular la suma de los pagos realizados

    }
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
  }

  onCodigoClicked(rowData: any) {
    console.log('Código clicked for row:', rowData);
    this.sendAplPresupuestaria(rowData, "cod");
  }

  sendAplPresupuestaria(rowData : any, llamada : string){
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
    var datosRow = rowData;
    this.authService
      .getAplicacionPresupuestaria(
        rowData.eje_opera,
        rowData.cod_cla_funci,
        rowData.cod_cla_econo,
        headers
      )
      .subscribe({

        next: data => {
          localStorage.setItem('aplicacionPresupuestariaSeleccionada', JSON.stringify(data));
          if(llamada=="apl"){
            var enlace = this.redirige.switchRoutes("Nueva Propuesta");
            this.router.navigate(['/',...enlace]);
          }else{
            if(llamada=="cod"){
              localStorage.setItem('justiPro', JSON.stringify(datosRow));
              var enlace = this.redirige.switchRoutes("JustificantePro");
              this.router.navigate(['/',...enlace]);
            }else{
              localStorage.setItem('aplicacionPresupuestariaSeleccionada',"");
            }
          }
        },
        error: err => {
          console.log(err);
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      });
    }
}
