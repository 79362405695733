import { Component, EventEmitter } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ButtonsAcctionsComponent } from '../buttons-acctions/buttons-acctions.component';
import { ManagedFocusFeature } from 'ag-grid-community';
import { Constantes } from '../../_services/constantes.service';
import { ModalesComponent } from '../modales/modales.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  content?: string;

  users: any[] = [];
  permisos: any[] = [];
  templates: any[] = [];
  modulos: any[] = [];
  grupos: any[] = [];
  configGeneral: any[] = [];
  activeTab: string = 'users'; // Variable to track which array to display
  modulesDataLoaded = false;
  usersDataLoaded = false;
  permDataLoaded = false;
  templDataLoaded = false;
  grupoDataLoaded = false;
  configGenDataLoaded = false;
  generalDataLoaded = false;
  mantenimiento = "false";
  typeRow: any[] = [];
  typeColumn: any[] = [];
  columnDefsUsuario = [
    { field: 'nombreLogin', filter: true },
    { field: 'nombreUsuario' },
    { field: 'apellidosUsuario' },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];

  columnPerm = [
    { field: 'nombrePermiso', filter: true },
    { field: 'idTemplate' },
    { field: 'idModulo' },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];
  columnTempl = [
    { field: 'idTemplate' },
    { field: 'nombreTemplate', filter: true },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];

  columnMods = [
    { field: 'idModulo' },
    { field: 'nombreModulo', filter: true },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];

  columnGrupos = [
    { field: 'idGrupo' },
    { field: 'nombreGrupo', filter: true },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];

  columnConfigGeneral = [
    { field: 'IdConfiguracionGeneral' },
    { field: 'alcalde' },
    { field: 'interventor' },
    { field: 'tipoExpdtePropuestaGasto' },
    { field: 'grupoGestorExpdtePropuesta' },
    { field: 'isMantenimiento' },
    { field: 'Acciones', cellRenderer: ButtonsAcctionsComponent }
  ];
  idUsuarioActual = "";
  gridOptions = {
    rowSelection: 'single',
    onRowDoubleClicked: this.onRowDoubleClicked.bind(this)
  };

  tokenJWT: string = "";

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private dialog: MatDialog) { }


  ngOnInit(): void {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: data => {
              this.idUsuarioActual= data.idUsuario;
            }, 
            
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });

          this.http.get<any[]>(Constantes.GETUSUARIOS, { headers: headers, }).subscribe({
            next: (userss) => {
              this.users = userss;
              this.usersDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });
          this.http.get<any[]>(Constantes.GET_PERMISOS, { headers: headers, }).subscribe({
            next: (perms) => {
              this.permisos = perms;
              this.permDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });
          this.http.get<any[]>(Constantes.GETMODULOSVM, { headers: headers, }).subscribe({
            next: (mods) => {
              this.modulos = mods;
              this.modulesDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });

          this.http.get<any[]>(Constantes.GET_TEMPLATES, { headers: headers, }).subscribe({
            next: (temp) => {
              this.templates = temp;
              this.templDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });

          this.http.get<any[]>(Constantes.GET_GRUPOSDB, { headers: headers, }).subscribe({
            next: (temp) => {
              this.grupos = temp;
              this.grupoDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });

          this.http.get<any[]>(Constantes.GET_CONFIGGEN, { headers: headers, }).subscribe({
            next: (temp) => {
              this.configGeneral = temp;
              this.configGenDataLoaded = true;
            },
            error: (err) => {
              console.log('Error', err);
              this.showErrorModal(err);
            },
          });
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.generalDataLoaded = true; //Esto TODO. Hay que cambiarlo para incluir la opcion a modo mantenimiento, pero ya se verá

    }
  }
  doubleClickOnNombreLogin: EventEmitter<any> = new EventEmitter<any>();

  onRowDoubleClicked(event: any) { //Cuando hace click en una columna
    // Get the target element of the double click event
    var targetElement = event.event.target;

    // Check if the target element is within a cell
    if (targetElement && targetElement.classList.contains('ag-cell')) {
      // Get the column ID of the clicked cell
      var clickedColumnId = targetElement.getAttribute('col-id');

      //Sustituimos un IF ELSE por un switch porque ya es demasiado grande el IF
      switch (clickedColumnId) {
        case 'nombreLogin':
          // Emit an event to indicate that a double click occurred on "nombreLogin" column
          localStorage.setItem('usuarioGrid', JSON.stringify(event.data)); //en json para poder obtenerlo
          localStorage.setItem('fromClick', 'true');
          this.router.navigate(['/editarUsuario']);
          break;
        case 'nombreModulo':
          localStorage.setItem('moduloGrid', JSON.stringify(event.data));
          localStorage.setItem('fromClick', 'true');
          this.router.navigate(['/editarModulo']);
          break;
        case 'nombrePermiso':
          localStorage.setItem('permisoGrid', JSON.stringify(event.data));
          localStorage.setItem('fromClick', 'true');
          this.router.navigate(['/editarPermiso']);
          break;
        case 'nombreTemplate':
          localStorage.setItem('templateGrid', JSON.stringify(event.data));
          localStorage.setItem('fromClick', 'true');
          this.router.navigate(['/editarPermiso']);
          break;
        case 'alcalde':
          localStorage.setItem('configGeneralGrid', JSON.stringify(event.data));
          localStorage.setItem('fromClick', 'true');
          this.router.navigate(['/editarConfigGeneral']);
          break;
        default: break;

      }
    }
  }

  switchTab(tab: string): void {
    this.activeTab = tab;
    switch (tab) {
      case 'users':
        this.typeRow = this.users;
        this.typeColumn = this.columnDefsUsuario;
        break;
      case 'modulos':
        this.typeRow = this.modulos;
        this.typeColumn = this.columnMods;
        break;
      case 'permisos':
        this.typeRow = this.permisos;
        this.typeColumn = this.columnPerm;
        break;
      case 'templates':
        this.typeRow = this.templates;
        this.typeColumn = this.columnTempl;
        break;
      case 'grupos':
        this.typeRow = this.grupos;
        this.typeColumn = this.columnGrupos;
        break;
      case 'configGen':
        this.typeRow = this.configGeneral;
        this.typeColumn = this.columnConfigGeneral;
        break;
      case 'general':
        //Acceso a dashboard con modo mantenimiento
        break;
    }
  }

  addRow(): void {

    switch (this.activeTab) {
      case 'users':  //Si le damos a añadir en la pestaña Usuarios, queremos añadir usuarios
        this.router.navigate(['/addUser']);
        break;
      case 'modulos': //Si le damos a añadir en la pestaña Modulos, queremos añadir modulos
        this.router.navigate(['/addModule']);
        break;
      case 'permisos': //Si le damos a añadir en la pestaña Permisos, queremos añadir permisos
        this.router.navigate(['/addPermiso']);
        break;
      case 'templates': //Si le damos a añadir en la pestaña Permisos, queremos añadir permisos
        this.router.navigate(['/addTemplate']);
        break;
      case 'grupos': //Si le damos a añadir en la pestaña Permisos, queremos añadir permisos
        this.router.navigate(['/addGrupo']);
        break;
      case 'general':
      default: //Si le damos a añadir en la pestaña General, por defecto añadimos personas
        this.router.navigate(['/addUser']);
        break;
    }

  }

  saveAdmin() {
    console.log("Modo mantenimiento:", this.mantenimiento);
    if (this.mantenimiento == "true") { //Se pone todos en modo mantenimiento
      var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);
      this.authService.setModoMantenimiento(headers).subscribe({
        next: (userss) => { //Si todo va bien hemos puesto el modo mantenimiento
          //Lo indicamos en Configuraion General tambien
          this.authService.setModoMantenimientoConfiguracionGeneral(true,headers).subscribe({
            next: (usersss) => { //Si todo va bien hemos puesto el modo mantenimiento
              //Lo indicamos en Configuraion General tambien
              
              this.authService.realizar_Auditoria(usersss,true,"ConfiguracionGeneral", "UPDATE",this.idUsuarioActual,headers).subscribe({
                next: (result) => {
                  this.router.navigate(['/desktop']);
                },
                error: (errr) => {
                  // Maneja el caso donde el token JWT no está presente en el localStorage
                  console.error('El token JWT no está presente en el localStorage');
                  this.showErrorModal(errr);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                },
              });
            },
            error: (errr) => {
              // Maneja el caso donde el token JWT no está presente en el localStorage
              console.error('El token JWT no está presente en el localStorage');
              this.showErrorModal(errr);
              localStorage.clear();
              this.router.navigate(['/login']);
            },
          });
        },
        error: (err) => {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('El token JWT no está presente en el localStorage');
          this.showErrorModal(err);
          localStorage.clear();
          this.router.navigate(['/login']);
        },
      });
    } else { //Se quita el modo mantenimiento
      var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);
      this.authService.resetModoMantenimiento(headers).subscribe({
        next: (userss) => { //Si todo va bien hemos quitado el modo mantenimiento
          this.authService.setModoMantenimientoConfiguracionGeneral(false,headers).subscribe({
            next: (usersss) => { //Si todo va bien hemos puesto el modo mantenimiento
              //Lo indicamos en Configuraion General tambien
              this.authService.realizar_Auditoria(usersss,true,"ConfiguracionGeneral", "UPDATE",this.idUsuarioActual,headers).subscribe({
                next: (result) => {
                  this.router.navigate(['/desktop']);
                },
                error: (errr) => {
                  // Maneja el caso donde el token JWT no está presente en el localStorage
                  this.showErrorModal(errr);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                },
              });
             
            },
            error: (errr) => {
              // Maneja el caso donde el token JWT no está presente en el localStorage
              console.error('El token JWT no está presente en el localStorage');
              this.showErrorModal(errr);
              localStorage.clear();
              this.router.navigate(['/login']);
            },
          });
        },
        error: (err) => {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('El token JWT no está presente en el localStorage');
          this.showErrorModal(err);
          localStorage.clear();
          this.router.navigate(['/login']);
        },
      });
    }

    
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}
