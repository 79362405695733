import { Component } from '@angular/core';
import { JwtService } from '../../_services/jwt.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';

@Component({
  selector: 'app-add-accesodirecto',
  templateUrl: './add-accesodirecto.component.html',
  styleUrl: './add-accesodirecto.component.css'
})
export class AddAccesodirectoComponent {

  tokenJWT="";
  idUsu= "";
  isAdmin=false;
  listaModulos: any[] = [];
  checkedModulos: { [key: string]: boolean } = {}; // Object to store checked permissions 
  numberOfChecked= 0;
  idUsuarioActual= "";
  constructor(private router: Router, private jwtService: JwtService , private http: HttpClient, private authService: AuthService,  private dialog: MatDialog){}

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto' ) {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
             
                this.isAdmin=data;
             
            },
            error: err => {
              //console.error('Error');
              localStorage.clear(); 
              this.openModal('Error');
              this.router.navigate(['/login']);
            }
          });
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: data => {
              this.idUsuarioActual= data.idUsuario;
              var idUsuario=data.idUsuario;

             this.http.get<any>(Constantes.GET_ACCESOS_DIRECTOS_BY_USU+idUsuario,{ headers: headers } ).subscribe({
              next: data => {
                var arrayMod : any[] = [];
                for (var i = 0; i< data.length;i++){
                  var arrayDat = Object.entries(data[i]);
                  var auxDos = Object.entries(arrayDat[2])[1];
                  arrayMod.push(auxDos[1]); //En el array que crea cada AccesoDirecto, idModulo es el elemento 3
                }
                //Una vez tenemos los idModulos, buscamos relaciones 

                var cadenaGet = Constantes.GET_PERMISO_W_USUARIO + `${idUsuario}`;
              this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
                next: dataN=>{
                  
                  for (let i = 0; i < dataN.length; i++) {
                    //Tenemos los ID de Permiso que tienen relacion con idUsuario. Ahora obtenemos cada uno de los
                    //nombres de cada Permiso y comparamos con modulo Gasto
                    var cadenaGetP = Constantes.GET_PERMISO_BY_ID + `${dataN[i].idPermiso}`;
                    this.http.get<any>(cadenaGetP, { headers: headers }).subscribe({
                      next: dataPerm=>{
                        if(dataPerm.nombrePermiso.includes("Acceso")){ //Ahora como ejemplo es permiso12 pero será el permiso de acceso a ModGasto
                          
                          var idMod = dataPerm.idModulo;
                          var cadenaGetM = Constantes.GETMODULO_BY_ID + `${idMod}`;
                          this.http.get<any>(cadenaGetM, { headers: headers }).subscribe({
                            next: dataMod=>{
                              this.listaModulos.push(dataMod); 
                              for( var i=0;i< arrayMod.length;i++){
                                if(  arrayMod[i]==dataMod.idModulo ){ //Si encuentra el modulo en la lista de AccesosDirectos, se checkea
                                  this.checkedModulos[dataMod.idModulo]=true;
                                } else{
                                  this.checkedModulos[dataMod.idModulo]=false;
                                }
                              }
                            
                            },
                            error: err=>{
                              localStorage.clear(); 
                              // Redirigir al usuario a la página de inicio de sesión
                              this.showErrorModal(err);
                              this.router.navigate(['/login']);
                            }
                          });
                        }
                      },
                      error: err=>{
                        localStorage.clear(); 
                        // Redirigir al usuario a la página de inicio de sesión
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      }
                    });
                  }

                  console.log('Checked Modulos:', this.checkedModulos);
                },
                error: err=>{
                  localStorage.clear(); 
                  // Redirigir al usuario a la página de inicio de sesión
                  this.showErrorModal(err);
                  this.router.navigate(['/login']);
                }
              });
                  
              },
              error: err => {
                  console.log(err);
                  localStorage.clear(); 
                  // Redirigir al usuario a la página de inicio de sesión
                  this.showErrorModal(err);
                  this.router.navigate(['/login']);
              }
            });
                
            },
            error: err => {
                console.log(err);
                localStorage.clear(); 
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
            }
          });
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
         // console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
         localStorage.clear(); 
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.router.navigate(['/login']); 
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }

  onSubmit(){
    var contador=0;
    var idAccDirMod1 : string | null = null; //Para poder ponerlo como null  en BBDD
    var idAccDirMod2 : string | null = null; //Para poder ponerlo como null en BBDD
    var idAccDirMod3 : string | null = null; //Para poder ponerlo como null en BBDD
    var arrSelected: any[] = [];

    for (const [idModulo, isChecked] of Object.entries(this.checkedModulos)) {
      
      if(isChecked){
        for(var i=0; i< this.listaModulos.length;i++){
          if(this.listaModulos[i].idModulo == idModulo){
            arrSelected.push(this.listaModulos[i]); //metemos los 3 idmodulo que están sel
          }
        }
      }
    }

    //Una vez tenemos la lista de Modulos que estan checked queremos ordenarlo alfabeticamente por nombre Modulo
    arrSelected.sort((a, b) => {
      if (a.nombreModulo < b.nombreModulo) {
        return -1;
      }
      if (a.nombreModulo > b.nombreModulo) {
        return 1;
      }
      return 0;
    });

    //Una vez ordenados, añadimos los accesos directos

    switch(arrSelected.length){
      case 1:
        if(arrSelected[0].idModulo !=null){
          idAccDirMod1= arrSelected[0].idModulo;
        } 
        break;
      case 2:
        if(arrSelected[0].idModulo !=null){
          idAccDirMod1= arrSelected[0].idModulo;
        } 
        if(arrSelected[1].idModulo !=null){
          idAccDirMod2= arrSelected[1].idModulo;
        }
        break;
      case 3:
        if(arrSelected[0].idModulo !=null){
          idAccDirMod1= arrSelected[0].idModulo;
        } 
        if(arrSelected[1].idModulo !=null){
          idAccDirMod2= arrSelected[1].idModulo;
        }
        
        if(arrSelected[2].idModulo !=null){
          idAccDirMod3= arrSelected[2].idModulo;
        }
        break;
      case 0:
      default:
        break;
    }
   
    var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
    const headers = new HttpHeaders(strHead);

    this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
      next: data => {
        this.idUsu= data.idUsuario;
        let cadenita =  Constantes.GETUSUARIO_BY_ID + this.idUsu;
        this.http.get<any>(cadenita, { headers: headers }).subscribe({
          next: data => {
            var logi = data.nombreLogin;
            var pass = data.passwordUsuario;
            var nombreUsu = data.nombreUsuario;
            var apeUsu = data.apellidosUsuario;
            var email= data.emailUsuario;
            var est = data.estado;

            this.http.get<any>(Constantes.GET_ACCESOS_DIRECTOS, { headers: headers }).subscribe({
              next: data => {
                var accesosDirectosLista = data;
                var encontradoAccDir1= false;
                var encontradoAccDir2= false;
                var encontradoAccDir3= false;

                if(idAccDirMod1!= null){
                  for(var i=0; i<accesosDirectosLista.length;i++){
                    if(idAccDirMod1== accesosDirectosLista[i].idModulo && accesosDirectosLista[i].idUsuario==this.idUsuarioActual){
                      encontradoAccDir1=true;
                    }
                    if(idAccDirMod2== accesosDirectosLista[i].idModulo && accesosDirectosLista[i].idUsuario==this.idUsuarioActual){
                      encontradoAccDir2=true;
                    }
                    if(idAccDirMod3== accesosDirectosLista[i].idModulo && accesosDirectosLista[i].idUsuario==this.idUsuarioActual){
                      encontradoAccDir3=true;
                    }
                  }

                  if(!encontradoAccDir1){
                    this.authService.add_AccesoDirecto(this.idUsu,idAccDirMod1, headers).subscribe({
                      next:datoUP =>{
                        //Actualizamos en auditoria
                       this.authService.realizar_Auditoria(datoUP,this.isAdmin,'AccesoDirecto','INSERT',this.idUsuarioActual,headers).subscribe({
                        next:datoUPO =>{
      
      
                          //Relizada la auditoria, seguimos con el siguiente o terminamos
                          if(idAccDirMod2!= null){
                            if(!encontradoAccDir2){
                              this.authService.add_AccesoDirecto(this.idUsu,idAccDirMod2, headers).subscribe({
                                next:datoUPD =>{
                                  //Actualizamos en auditoria
                                 this.authService.realizar_Auditoria(datoUPD,this.isAdmin,'AccesoDirecto','INSERT',this.idUsuarioActual,headers).subscribe({
                                  next:datoUPOD =>{
                                    //Relizada la auditoria, seguimos con el siguiente o terminamos
        
        
                                    if(idAccDirMod3!= null){
                                      if(!encontradoAccDir3){
                                        this.authService.add_AccesoDirecto(this.idUsu,idAccDirMod3, headers).subscribe({
                                          next:datoUPT =>{
                                            //Actualizamos en auditoria
                                           this.authService.realizar_Auditoria(datoUPT,this.isAdmin,'AccesoDirecto','INSERT',this.idUsuarioActual,headers).subscribe({
                                            next:datoUPOT =>{
                                              //Relizada la auditoria, seguimos con el siguiente o terminamos
                                              
          
                                              //Por ahora solo hay 3 enlaces, asi que se cierra
                                              //Actualizados datos se redirige a modulos
                                                this.router.navigate(['/modulos']);
                                            },
                                            error: err =>{
                                              console.error('Error : ', err);
                                              localStorage.clear(); 
                                              this.showErrorModal(err);
                                              this.router.navigate(['/login']); 
                                            }
                                          });
                                          },
                                          error: err =>{
                                            console.error('Error : ', err);
                                            localStorage.clear(); 
                                            this.showErrorModal(err);
                                            this.router.navigate(['/login']); 
                                          }
                                        });
                                      }else{
                                          //Actualizados datos se redirige a modulos
                                          this.router.navigate(['/modulos']);
                                      }
                                    }else{
                                        //Actualizados datos se redirige a modulos
                                        this.http.get<any>(Constantes.GET_ACCESOS_DIRECTOS, { headers: headers }).subscribe({
                                          next: data => {
                                            if(data){ //Todos los accesos
                                              for(var i=0; i< data.length;i++){
                                              if(data[i].idModulo!= idAccDirMod1 && data[i].idModulo!= idAccDirMod2 ){
                                                this.authService.delete_AccesoDirecto_idMod(data[i].idModulo, headers).subscribe({ //Se borra modulos
                                                  next: data => {
                                                    if(data){ //Is Admin
        
                                                        //se hace auditoria
                                                        this.authService.realizar_Auditoria(data,this.isAdmin,'AccesoDirecto','DELETE',this.idUsuarioActual,headers).subscribe({
                                                          next: data => {
                                                          
                                                          },
                                                          error: err => {
                                                            //console.error('Error');
                                                            localStorage.clear(); 
                                                            this.openModal('Error');
                                                            this.router.navigate(['/login']);
                                                          }
                                                        });
        
        
                                                    }else{ //Is not Admin
                                                      //console.error('No tienes permisos para acceder a esta pantalla');
                                                      localStorage.clear(); 
                                                      this.openModal('No tienes permisos para acceder a esta pantalla');
                                                      this.router.navigate(['/login']);
                                                    }
                                                  },
                                                  error: err => {
                                                    //console.error('Error');
                                                    localStorage.clear(); 
                                                    this.openModal('Error');
                                                    this.router.navigate(['/login']);
                                                  }
                                                });
                                              }
                                             
                                             }
                                              //Terminados los borrados, redigirimos
                                             //Actualizados datos se redirige a modulos
                                             this.router.navigate(['/modulos']);
                                            }else{ //Is not Admin
                                              //console.error('No tienes permisos para acceder a esta pantalla');
                                              localStorage.clear(); 
                                              this.openModal('No tienes permisos para acceder a esta pantalla');
                                              this.router.navigate(['/login']);
                                            }
                                          },
                                          error: err => {
                                            //console.error('Error');
                                            localStorage.clear(); 
                                            this.openModal('Error');
                                            this.router.navigate(['/login']);
                                          }
                                        });
                                       
                                    }
                                  },
                                  error: err =>{
                                    console.error('Error : ', err);
                                    localStorage.clear(); 
                                    this.showErrorModal(err);
                                    this.router.navigate(['/login']); 
                                  }
                                });
                                },
                                error: err =>{
                                  console.error('Error : ', err);
                                  localStorage.clear(); 
                                  this.showErrorModal(err);
                                  this.router.navigate(['/login']); 
                                }
                              });
                            }else{
                                //Actualizados datos se redirige a modulos
                                this.router.navigate(['/modulos']);
                            }
                          }else{
                            this.http.get<any>(Constantes.GET_ACCESOS_DIRECTOS, { headers: headers }).subscribe({
                              next: data => {
                                if(data){ //Todos los accesos
                                 for(var i=0; i< data.length;i++){
                                  if(data[i].idModulo!= idAccDirMod1){
                                    this.authService.delete_AccesoDirecto_idMod(data[i].idModulo, headers).subscribe({ //Se borra modulos
                                      next: datoU => {
                                        
                                        if(!datoU){ //Si es null se crea un usuario vacio
                                          datoU= [['NULL','NULL',this.idUsu ]]; //idAccesoDirecto, idModulo, idUsuario
                                        }
                                          for(var i = 0; i<datoU.length;i++){
                                            //se hace auditoria
                                          this.authService.realizar_Auditoria(datoU[i],this.isAdmin,'AccesoDirecto','DELETE',this.idUsuarioActual,headers).subscribe({
                                            next: data => {

                                            },
                                            error: err => {
                                              //console.error('Error');
                                              localStorage.clear(); 
                                              this.showErrorModal(err);
                                              this.router.navigate(['/login']);
                                            }
                                          }); 
                                          }
                                          this.router.navigate(['/modulos']);
                                        
                                      },
                                      error: err => {
                                        //console.error('Error');
                                        localStorage.clear(); 
                                        this.openModal('Error');
                                        this.router.navigate(['/login']);
                                      }
                                    });
                                  }
                                
                                 }
                                   //Terminados los borrados, redigirimos
                                 //Actualizados datos se redirige a modulos
                                 this.router.navigate(['/modulos']);
                                }else{ //Is not Admin
                                  //console.error('No tienes permisos para acceder a esta pantalla');
                                  localStorage.clear(); 
                                  this.openModal('No tienes permisos para acceder a esta pantalla');
                                  this.router.navigate(['/login']);
                                }
                              },
                              error: err => {
                                //console.error('Error');
                                localStorage.clear(); 
                                this.showErrorModal(err);
                                this.router.navigate(['/login']);
                              }
                            });
                            
                             
                          }
                        },
                        error: err =>{
                          console.error('Error : ', err);
                        
                          localStorage.clear(); 
                          this.showErrorModal(err);
                          this.router.navigate(['/login']); 
                        }
                      });
                      },
                      error: err =>{
                        console.error('Error : ', err);
                        localStorage.clear();  
                        this.showErrorModal(err);
                        this.router.navigate(['/login']); 
                      }
                    });
                  }else{
                      //Actualizados datos se redirige a modulos
                      this.router.navigate(['/modulos']);
                  }
                }else{
                  //Si se borra se elimina de la relacion:
                  this.authService.delete_AccesoDirecto_idUsu(this.idUsu, headers).subscribe({
                    next: datoU => {
                        if(!datoU){ //Si es null se crea un usuario vacio
                          datoU= [['NULL','NULL',this.idUsu ]]; //idAccesoDirecto, idModulo, idUsuario
                        }
                        for(var i =0;i< datoU.length;i++){ //Es una lista de elementos borrados, hay que recorrerla

                           //Una vez borrados todos los accdir se redirige
                       //SE HACE AUDITORIA
                        this.authService.realizar_Auditoria(datoU[i],this.isAdmin,'AccesoDirecto','DELETE',this.idUsuarioActual,headers).subscribe({
                          next: data => {
                            
                          },
                          error: err => {
                            //console.error('Error');
                            localStorage.clear(); 
                            this.showErrorModal(err);
                            this.router.navigate(['/login']);
                          }
                        });
                        }
                        //Actualizados datos se redirige a modulos
                           
                        this.router.navigate(['/modulos']);
    
                      
                    },
                    error: err => {
                      //console.error('Error');
                      localStorage.clear(); 
                      this.showErrorModal(err);
                      this.router.navigate(['/login']);
                    }
                  });
                   
                }

              },
              error: err=>{
                console.error('Error : ', err);
                localStorage.clear(); 
                this.showErrorModal(err);
                this.router.navigate(['/login']); 
              }
            });
        
            
          },
          error: err => {
              console.log(err);
              localStorage.clear(); 
              // Redirigir al usuario a la página de inicio de sesión
              this.showErrorModal(err);
              this.router.navigate(['/login']);
          }
        });
      },
      error: err => {
          console.log(err);
          localStorage.clear(); 
          // Redirigir al usuario a la página de inicio de sesión
          this.showErrorModal(err);
          this.router.navigate(['/login']);
      }
    });

  }
  
  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }

 onCheckboxChange(event: Event, modulo: { idModulo: string }) {
  const checkbox = event.target as HTMLInputElement;
  this.checkedModulos[modulo.idModulo] = checkbox.checked;

  const selectedCount = Object.values(this.checkedModulos).filter(value => value).length;
  if (selectedCount > 3) {
    this.checkedModulos[modulo.idModulo] = false;
    checkbox.checked = false; // Revert the change
    alert('Solo puedes añadir 3 modulos favoritos');
  }
}

}
