<div class="modal"> <!-- Modal wrapper -->
  <div class="modal-content"> <!-- Modal content box -->
      <div class="form-row">
          <div class="col-md-4">
              <label>Aplicacion Presupuestaria:</label>
              <span>{{ propuestaForm.codApliPresupuestaria }}</span>
              <span> {{this.desc_Apli }}</span>
          </div>
          <div class="col-md-4">
              <label>Proponente:</label>
              <span>{{ propuestaForm.proponente }}, {{ propuestaForm.cargo }}</span>
          </div>
          <div class="col-md-4">
              <label>Responsable:</label>
              <span>{{ propuestaForm.responsable }}, {{ propuestaForm.cargoResponsable }}</span>
          </div>
          <div class="col-md-6">
            <button type="button" class="close-button" (click)="closeDialog()">X</button>
        </div>
      </div>
      
      <div class="form-row">
          <div class="col-md-4">
              <label>Fecha de la Propuesta:</label>
              <span>{{ formattedFechaPropuesta }}</span>
          </div>
      </div>

      <div class="form-row">
          <div class="col-md-12">
              <label>Objeto del contrato:</label>
              <span>{{ propuestaForm.objeto }}</span>
          </div>
      </div>

      <div class="form-row">
          <div class="col-md-12">
              <label>Necesidad:</label>
              <span>{{ propuestaForm.necesidad }}</span>
          </div>
      </div>

      <div class="form-row">
          <div class="col-md-12">
              <label>Plazo de entrega/duración y condiciones del contrato:</label>
              <span>{{ propuestaForm.plazo }}</span>
          </div>
      </div>

      <div class="form-row">
          <div class="col-md-4">
              <label>Importe:</label>
              <span>{{ propuestaForm.importe }}</span>
          </div>

          <div class="col-md-4">
              <label>Expdt. Asociado:</label>
              <span>{{ propuestaForm.expediente }}</span>
              <div class="text-danger">{{ nombreExpdt }}</div>
          </div>
      </div>

      <div class="form-row">
          <div class="col-md-4">
              <label>Usuario Control:</label>
              <span>{{ usuControl }}</span>
          </div>

          <div class="col-md-4">
              <label>Fecha Control:</label>
              <span>{{ fechaControl }}</span>
          </div>
      </div>
  </div>
</div>
