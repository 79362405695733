import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './base/login/login.component';
import { RegisterComponent } from './base/register/register.component';
import { ProfileComponent } from './base/profile/profile.component';
import { DashboardComponent } from './base/dashboard/dashboard.component';
import { httpInterceptorProviders } from './_helpers/http.interceptor';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AgGridModule } from 'ag-grid-angular';
import { JwtModule } from '@auth0/angular-jwt';
import { ConfigService } from './_services/config.service';
import { lastValueFrom } from 'rxjs';
import 'ag-grid-enterprise';
import './../ag-grid-license';

// Importar todos los componentes personalizados
import { DesktopComponent } from './base/desktop/desktop.component';
import { TaskbarComponent } from './base/taskbar/taskbar.component';
import { EditarUsuarioComponent } from './base/editar-usuario/editar-usuario.component';
import { EstadoEjecucionComponent } from './modulos/gestionGasto/estado-ejecucion/estado-ejecucion.component';
import { gestionGastoDesktopComponent } from './modulos/gestionGasto/gestion-gasto-desktop/gestion-gasto-desktop.component';
import { BotoneraComponent } from './modulos/gestionGasto/botonera/botonera.component';
import { AddUsuarioComponent } from './base/add-usuario/add-usuario.component';
import { AddModuloComponent } from './base/add-modulo/add-modulo.component';
import { AddPermisoComponent } from './base/add-permiso/add-permiso.component';
import { ModalesComponent } from './base/modales/modales.component';
import { EditarModuloComponent } from './base/editar-modulo/editar-modulo.component';
import { EditarPermisoComponent } from './base/editar-permiso/editar-permiso.component';
import { YearContableComponent } from './modulos/gestionGasto/year-contable/year-contable.component';
import { EliminarFilaComponent } from './base/eliminar-fila/eliminar-fila.component';
import { AddTemplateComponent } from './base/add-template/add-template.component';
import { EditarTemplateComponent } from './base/editar-template/editar-template.component';
import { ModulosComponent } from './base/modulos/modulos.component';
import { AvisomantenimientoComponent } from './base/avisomantenimiento/avisomantenimiento.component';
import { PropuestaGastoComponent } from './modulos/gestionGasto/propuesta-gasto/propuesta-gasto.component';
import { AddAccesodirectoComponent } from './base/add-accesodirecto/add-accesodirecto.component';
import { NuevaPropuestaComponent } from './modulos/gestionGasto/nueva-propuesta/nueva-propuesta.component';
import { GenerarPdfPropuestaComponent } from './modulos/gestionGasto/generar-pdf-propuesta/generar-pdf-propuesta.component';
import { AddGrupoComponent } from './base/add-grupo/add-grupo.component';
import { EditarGrupoComponent } from './base/editar-grupo/editar-grupo.component';
import { ModalBuscadorComponent } from './modulos/gestionGasto/modal-buscador/modal-buscador.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Constantes } from './_services/constantes.service';
import { JustificanteAplComponent } from './modulos/gestionGasto/justificante-apl/justificante-apl.component';
import { TodasPropuestasComponent } from './modulos/gestionGasto/todas-propuestas/todas-propuestas.component';
import { EstadoPropuestaComponent } from './modulos/gestionGasto/estado-propuesta/estado-propuesta.component';
import { PropuestasCerradasComponent } from './modulos/gestionGasto/propuestas-cerradas/propuestas-cerradas.component';
import { DetalleCerradaComponent } from './modulos/gestionGasto/detalle-cerrada/detalle-cerrada.component';
import { NuevaPropuestaTemporalComponent } from './modulos/gestionGasto/nueva-propuesta-temporal/nueva-propuesta-temporal.component';
import { ModificarPropuestaComponent } from './modulos/gestionGasto/modificar-propuesta/modificar-propuesta.component';
import { ModalBuscaAplpresupuestariaComponent } from './modulos/gestionGasto/modal-busca-aplpresupuestaria/modal-busca-aplpresupuestaria.component';
import { ModalMuestrapropuestaComponent } from './modulos/gestionGasto/modal-muestrapropuesta/modal-muestrapropuesta.component';
import { ButtonsActionsPropuestaComponent } from './modulos/gestionGasto/buttons-actions-propuesta/buttons-actions-propuesta.component';
import { CommonModule } from '@angular/common';  
import { DividirPropuestaComponent } from './modulos/gestionGasto/dividir-propuesta/dividir-propuesta.component';
import { CustomFilterPanelEstEjeComponent } from './modulos/AgGridCustom/custom-filter-panel-est-eje/custom-filter-panel-est-eje.component';
import { LinearGaugePropuestaComponent } from './modulos/Graficos/linear-gauge-propuesta/linear-gauge-propuesta.component';
import { CustomFilterPanelPropuestaComponent } from './modulos/AgGridCustom/custom-filter-panel-propuesta/custom-filter-panel-propuesta.component';
import { CustomLeyendaPropuestaComponent } from './modulos/AgGridCustom/custom-leyenda-propuesta/custom-leyenda-propuesta.component';
import { CustomFilterPanelTodasPropuestasComponent } from './modulos/AgGridCustom/custom-filter-panel-todas-propuestas/custom-filter-panel-todas-propuestas.component';
import { CustomFilterPanelEstadoPropuestasComponent } from './modulos/AgGridCustom/custom-filter-panel-estado-propuestas/custom-filter-panel-estado-propuestas.component';
import { CustomFilterPanelEstadoCerradoComponent } from './modulos/AgGridCustom/custom-filter-panel-estado-cerrado/custom-filter-panel-estado-cerrado.component';
import { ModalConfirmacionComponent } from './modulos/gestionGasto/modal-confirmacion/modal-confirmacion.component';
import { DividirPropuestaV2Component } from './modulos/gestionGasto/dividir-propuesta-v2/dividir-propuesta-v2.component';
import { JustificanteProComponent } from './modulos/gestionGasto/justificante-pro/justificante-pro.component';
import { LinearGaugeJustPropComponent } from './modulos/Graficos/linear-gauge-just-prop/linear-gauge-just-prop.component';
import { ModalConfirmaLimitesComponent } from './modulos/gestionGasto/modal-confirma-limites/modal-confirma-limites.component';


// Función para inicializar la aplicación cargando la configuración
export function initializeApp(configService: ConfigService): () => Promise<any> {
  return async (): Promise<any> => {
    const config = await lastValueFrom(configService.loadConfig());
    configService.setConfig(config);
    return config;
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DesktopComponent,
    TaskbarComponent,
    RegisterComponent,
    ProfileComponent,
    DashboardComponent,
    EditarUsuarioComponent,
    EditarModuloComponent,
    EditarPermisoComponent,
    EstadoEjecucionComponent,
    gestionGastoDesktopComponent,
    PropuestaGastoComponent,
    BotoneraComponent,
    AddUsuarioComponent,
    AddModuloComponent,
    AddPermisoComponent,
    ModalesComponent,
    EliminarFilaComponent,
    AddTemplateComponent,
    EditarTemplateComponent,
    ModulosComponent,
    AvisomantenimientoComponent,
    AddAccesodirectoComponent,
    NuevaPropuestaComponent,
    GenerarPdfPropuestaComponent,
    AddGrupoComponent,
    ModalBuscadorComponent,
    EditarGrupoComponent,
    JustificanteAplComponent,
    TodasPropuestasComponent,
    EstadoPropuestaComponent,
    PropuestasCerradasComponent,
    DetalleCerradaComponent,
    ModalBuscadorComponent,
    CustomFilterPanelEstEjeComponent,
    CustomFilterPanelPropuestaComponent,
    CustomLeyendaPropuestaComponent,
    CustomFilterPanelTodasPropuestasComponent,
    CustomFilterPanelEstadoPropuestasComponent,
    CustomFilterPanelEstadoCerradoComponent,
    NuevaPropuestaTemporalComponent,
    ModificarPropuestaComponent,
    ModalBuscaAplpresupuestariaComponent,
    ModalMuestrapropuestaComponent,
    LinearGaugePropuestaComponent,
    ButtonsActionsPropuestaComponent,
    DividirPropuestaComponent,
    ModalConfirmacionComponent,
    DividirPropuestaV2Component,
    JustificanteProComponent,
    LinearGaugeJustPropComponent,
    ModalConfirmaLimitesComponent
  ],
  providers: [
    httpInterceptorProviders,
    provideAnimationsAsync(),
    ConfigService,
    Constantes,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: (configService: ConfigService) => configService.getConfig().firebaseConfig,
      deps: [ConfigService]
    }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('jwtToken'),
        allowedDomains: [
          'http://localhost:4200',
          'http://82.223.102.173:32301',
          'http://82.223.118.254:32301',
          'http://82.223.102.173:32302',
          'http://82.223.118.254:32302',
          'http://82.223.102.173:32303',
          'http://82.223.118.254:32303'
        ],
        disallowedRoutes: [
          'http://localhost:4200/login',
          'http://82.223.102.173:32301',
          'http://82.223.118.254:32301',
          'http://82.223.102.173:32302',
          'http://82.223.118.254:32302',
          'http://82.223.102.173:32303',
          'http://82.223.118.254:32303'
        ]
      }
    }),
    AngularFireModule,
    YearContableComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
