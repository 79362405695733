<app-botonera></app-botonera>
<div class="escritorio">
    <div class="common-componentes">
        <app-year-contable></app-year-contable>
    </div>
    <div id="grid-container" class="tab-content grid-container">
      <div class="titulo-label"><h1><b>Todas las Propuestas</b></h1></div>
      <div class="new-row">
          <input type="text" id="searchInput" [(ngModel)]="terminoBusqueda" (keyup)="applySearchFilter()" class="form-control mb-2">
      </div>
    <!-- Ag-Grid con configuración -->
    <ag-grid-angular 
      class="ag-theme-quartz" 
      [rowData]="typeRow" 
      [columnDefs]="columnDefsUsuario"
      [sideBar]="sideBar"
      [gridOptions]="gridOptions"
      style="height: 50vh;">
    </ag-grid-angular>
  <app-taskbar></app-taskbar>
  </div>
</div>