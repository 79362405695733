import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-linear-gauge-just-prop',
  templateUrl: './linear-gauge-just-prop.component.html',
  styleUrl: './linear-gauge-just-prop.component.css'
})
export class LinearGaugeJustPropComponent implements OnInit {
  @Input() value: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() width: number = 800;
  @Input() height: number = 40;

  percentage: number = 0;
  gradientId: string = 'horizontalGradient';
  ticks: number[] = [];

  ngOnInit() {
    this.calculatePercentage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] || changes['min'] || changes['max']) {
      this.calculatePercentage();
      this.generateTicks();
    }
  }

  private calculatePercentage() {
    this.percentage = Math.min(100, Math.max(0, ((this.value - this.min) / (this.max - this.min)) * 100));
  }

  private generateTicks() {
    this.ticks = [this.min, this.max * 0.2, this.max * 0.4, this.max * 0.6, this.max * 0.8, this.max];
  }
}
