<div id="escritorio">
    <div class="container">
        <div class="subcontainer">
            <div class="subsubcontainer" >
                <br>
                <h2 class="h2T">TUS MÓDULOS</h2>
                <div id="div_top_hypers">
                    <h3 class="h2T"> Accesos Directos</h3>
                    <ul id="ul_top_hypers">
                        <li *ngIf="accesoDirectoUnoExist" >
                            <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoUno.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">{{accesoDirectoUno.nombreModulo}}</a>
                        </li> 
                        <li *ngIf="accesoDirectoDosExist">
                            <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoDos.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">{{accesoDirectoDos.nombreModulo}}</a>
                        </li>
                        <li *ngIf="accesoDirectoTresExist">
                            <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoTres.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">{{accesoDirectoTres.nombreModulo}}</a>
                        </li>
                    </ul>
                    <button type="submit" routerLink="/addAccesoDirecto"   class="btn btn-primary botonAddAD">Añadir Accesos Directos</button>
                </div>
                <div id="div_top_hypers">
                    <h3 class="h2T"> Todos tus módulos</h3>
                    <ul id="ul_top_hypers" *ngFor="let modulo of listaModulos">
                        <li>
                            <a class="hoverModuloN text-red" (click)="routeUrl(modulo.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">{{modulo.nombreModulo}}</a>
                        </li>
                        
                    </ul>
                </div>
               
                
            </div>
        </div>
    </div>
    <app-taskbar></app-taskbar>
    </div>
    