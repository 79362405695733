import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnApi } from 'ag-grid';
import { GridApi, IToolPanel, IToolPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-custom-leyenda-propuesta',
  templateUrl: './custom-leyenda-propuesta.component.html',
  styleUrl: './custom-leyenda-propuesta.component.css'
})
export class CustomLeyendaPropuestaComponent {
  @ViewChild('toolPanel', { static: false }) toolPanel!: ElementRef;

  agInit(): void {
  }

  getGui(): HTMLElement {
    return this.toolPanel.nativeElement;
  }

  refresh(): void {
    // Implement if needed
  }
}
