import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { RoutingService } from '../../../_services/routing.service';
import { AuthService } from '../../../_services/auth.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Constantes } from '../../../_services/constantes.service';
import { ModalMuestrapropuestaComponent } from '../modal-muestrapropuesta/modal-muestrapropuesta.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';
@Component({
  selector: 'app-buttons-actions',
  template: `
    <button *ngIf="muestraEliminar" (click)="clickEliminar(params.data)" class="boton-eliminar"></button>
<button *ngIf="muestraEditar" (click)="clickEditar(params.data)" class="boton-editar"></button>
<button *ngIf="muestraCopiar" (click)="clickCopiar(params.data)" class="boton-copiar"></button>
<button *ngIf="muestraVerPDF" (click)="clickVerPDF(params.data)" class="boton-ver"></button>
<button *ngIf="muestraContabilizar" (click)="clickContabilizar(params.data)" class="boton-cont"></button>
<button *ngIf="muestraAnular" (click)="clickAnular(params.data)" class="boton-anu"></button>
<button (click)="clickRamificar(params.data)" class="boton-ramificar"></button>

  `,
  styleUrls: ['./buttons-actions-propuesta.component.css']
})
export class ButtonsActionsPropuestaComponent {
  params: any;
  isContabiliza: boolean = false;
  idUsuarioActual: string = "";
  @Input() componentName: string | undefined;
  muestraEliminar: boolean = true;
  muestraEstado: boolean = true;
  muestraEditar: boolean = true;
  muestraCopiar: boolean = true;
  muestraVerPDF: boolean = true;
  muestraAnular: boolean = true;
  muestraContabilizar: boolean = true;

  constructor(private router: Router, public dialog: MatDialog, private redirige: RoutingService, private authService: AuthService,
    private cdr: ChangeDetectorRef, private http: HttpClient, private clipboard: Clipboard) { }


  agInit(params: any): void {
    this.params = params;
    this.isContabiliza = !(this.params.data.estadoPropuesta === 4);
    this.componentName = params.componentName;  // Make sure componentName is passed

    //PropuestasCerradas solo tendrá el boton de ramificar
    if (this.componentName == "Cerradas") {
      this.muestraEliminar = false;
      this.muestraEstado = false;
      this.muestraEditar = false;
      this.muestraCopiar = false;
      this.muestraVerPDF = false;
      this.muestraAnular = false;
      this.muestraContabilizar = false;
    } else {
      this.muestraEliminar = true;
      this.muestraEstado = true;
      this.muestraEditar = true;
      this.muestraCopiar = true;
      this.muestraVerPDF = true;
      this.muestraAnular = true;
      this.muestraContabilizar = true;

    }

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    var jwTok = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${jwTok}` };
    var headers = new HttpHeaders(strHead);



    this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
      next: data => {
        this.idUsuarioActual = data.idUsuario;

      },
      error: err => {
        console.log(err);
        // Redirigir al usuario a la página de inicio de sesión
        this.showErrorModal(err);
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }


  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  clickEditar(rowData: any) {
    console.log('Edit clicked for row:', rowData);
    //Se modifica si su estado es anterior a Contabilizado
    if (rowData.estadoPropuesta != 1 && rowData.estadoPropuesta != 2) {
      //Modal "Seguro que quieres modificar?"
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        data: { actionType: "Modificar" }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) { // YES
          localStorage.setItem("propuestaPresupuestariaModificacion", JSON.stringify(rowData));
          var enlace = this.redirige.switchRoutes("Modificar Propuesta");
          this.router.navigate(['/', ...enlace]);
        } else { // NO

        }
      });
    } else {
      //Mostrar modal: "No puedes modificarlo. Está contabilizado. Puede crear una nueva a partir de esta con Dividir"
      this.openModal("No puedes modificar esta propuesta. Puede crear una nueva a partir de esta con Dividir");
    }

  }
  clickRamificar(rowData: any) {
    console.log('Edit clicked for row:', rowData);
    if (this.componentName == "Cerradas") { //Si se obtienen los datos desde cerrada, hay que obtener datos de diferente forma
      if (rowData.estadoPropuesta != 3) {
        localStorage.setItem("propuestaRamificada", JSON.stringify(rowData));
        var enlace = this.redirige.switchRoutes("Dividir Propuesta Cerrada");
        this.router.navigate(['/', ...enlace]);
      }

    } else { //Si la operacion no es Cerrada se hace igual
      if (rowData.estadoPropuesta != 3) {
        localStorage.setItem("propuestaRamificada", JSON.stringify(rowData));
        var enlace = this.redirige.switchRoutes("Dividir Propuesta");
        this.router.navigate(['/', ...enlace]);
      }
    }


  }

  clickContabilizar(rowData: any) {
    console.log('Edit clicked for row:', rowData);
    var jwTok = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${jwTok}` };
    const headers = new HttpHeaders(strHead);
    const idProp = rowData.idPropuesta;
    if (rowData.importe < 10) {
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        data: { actionType: "Contabilizar" }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) { // YES
          this.http.get<any>(Constantes.GETPROPUESTA + idProp, { headers: headers }).subscribe({
            next: data => {
              data.estadoPropuesta = 2; //Contabilizado
              this.authService.updatePropuesta(data.idPropuesta, data.proponente, data.responsable, data.cargo, data.cargoResponsable,
                data.objeto, data.necesidad, data.plazo, data.usuarioControl, data.fechaControl, data.importe, data.fechaPropuesta,
                data.expdtAsoc, data.estadoPropuesta, data.ejercicio, data.codProg, data.codEco,
                data.idDocumentoGestiona, data.dniProponente, data.idContabilidad, data.expdtGestiona, headers).subscribe({

                  next: (putDevuelto) => {
                    this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                      next: (datoString) => {
                        console.log("SuccessAudit");
                        window.location.reload();
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        localStorage.clear();
                        this.router.navigate(['/login']);
                      },
                    });

                  },
                  error: (err) => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear();
                    this.router.navigate(['/login']);
                  }
                });

            },
            error: err => {

              this.showErrorModal(err);
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          });
        } else { // NO

        }
      });
    } else {
      if (rowData.estadoPropuesta == 1) { //Firmado
        const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
          data: { actionType: "Contabilizar" }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) { // YES
            this.http.get<any>(Constantes.GETPROPUESTA + idProp, { headers: headers }).subscribe({
              next: data => {
                data.estadoPropuesta = 2; //Contabilizado
                this.authService.updatePropuesta(data.idPropuesta, data.proponente, data.responsable, data.cargo, data.cargoResponsable,
                  data.objeto, data.necesidad, data.plazo, data.usuarioControl, data.fechaControl, data.importe, data.fechaPropuesta,
                  data.expdtAsoc, data.estadoPropuesta, data.ejercicio, data.codProg, data.codEco,
                  data.idDocumentoGestiona, data.dniProponente, data.idContabilidad, data.expdtGestiona, headers).subscribe({

                    next: (putDevuelto) => {
                      this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                        next: (datoString) => {
                          console.log("SuccessAudit");
                          window.location.reload();
                        },
                        error: (err) => {
                          console.error('Error: ', err);
                          this.showErrorModal(err);
                          localStorage.clear();
                          this.router.navigate(['/login']);
                        },
                      });

                    },
                    error: (err) => {
                      console.error('Error: ', err);
                      this.showErrorModal(err);
                      localStorage.clear();
                      this.router.navigate(['/login']);
                    }
                  });

              },
              error: err => {

                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/login']);
              }
            });
          } else { // NO

          }
        });

      } else {
        this.openModal("No puedes contabilizar esta propuesta.Debe esperar a que sea firmada");
      }
    }



  }


  clickAnular(rowData: any) {
    console.log('Estado clicked');

    var jwTok = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${jwTok}` };
    const headers = new HttpHeaders(strHead);
    const idProp = rowData.idPropuesta;

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      data: { actionType: "Anular" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) { // YES
        this.http.get<any>(Constantes.GETPROPUESTA + idProp, { headers: headers }).subscribe({
          next: data => {
            data.estadoPropuesta = 3; //Anulado
            this.authService.updatePropuesta(data.idPropuesta, data.proponente, data.responsable, data.cargo, data.cargoResponsable,
              data.objeto, data.necesidad, data.plazo, data.usuarioControl, data.fechaControl, data.importe, data.fechaPropuesta,
              data.expdtAsoc, data.estadoPropuesta, data.ejercicio, data.codProg, data.codEco,
              data.idDocumentoGestiona, data.dniProponente, data.idContabilidad, data.expdtGestiona, headers).subscribe({

                next: (putDevuelto) => {
                  this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                    next: (datoString) => {
                      console.log("SuccessAudit");
                      window.location.reload();
                    },
                    error: (err) => {
                      console.error('Error: ', err);
                      this.showErrorModal(err);
                      localStorage.clear();
                      this.router.navigate(['/login']);
                    },
                  });

                  var expedt = "";
                  if (data.expdtGestiona == "" || data.expdtGestiona == null) {
                    expedt = data.expdtAsoc;
                  } else {
                    expedt = data.expdtGestiona;
                  }
                  this.authService.anulaPDFGestiona(expedt, data.idDocumentoGestiona, headers).subscribe({
                    next: (datoString) => {
                      console.log("Codigo de Anulacion:", datoString);
                      window.location.reload();
                    },
                    error: (err) => {
                      console.error('Error: ', err);
                      this.showErrorModal(err);
                    },
                  });

                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.showErrorModal(err);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
              });

          },
          error: err => {

            this.showErrorModal(err);
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        });
      } else { // NO

      }
    });
  }

  clickVerPDF(rowData: any) {
    var idDocumento = rowData.idDocumentoGestiona;
    var nomPropuesta = rowData.objeto;
    var jwTok = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${jwTok}` };
    const headers = new HttpHeaders(strHead);
    this.authService.descargaPDF(idDocumento, headers).subscribe({
      next: (pdfRecibido: any) => {
        const blob = new Blob([pdfRecibido], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Set the href and download attributes for the link
        link.href = url;
        link.download = `${nomPropuesta}.pdf`; // Set the file name for download

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        console.error('Error: ', err);
        this.openModal("No se encuentra ningun PDF relacionado con esta Propuesta");
      },
    });
  }
  clickCopiar(rowData: any) {
    const texto = `(${rowData.idPropuesta}) ${rowData.objeto}`;
    this.clipboard.copy(texto); //Copia al portapapeles
  }

  clickEliminar(rowData: any) {
    var jwTok = localStorage.getItem('jwtToken');
    var strHead = { 'Authorization': `Bearer ${jwTok}` };
    const headers = new HttpHeaders(strHead);
    var idDelete = rowData.idPropuesta;
    if (rowData.estadoPropuesta != 1 && rowData.estadoPropuesta != 2) { //Si no esrá ni firmado ni contabilizado, se puede borrar

      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        data: { actionType: "Borrar" }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) { // YES
          this.authService.delete_Propuesta(rowData.idPropuesta, headers).subscribe({
            next: data => {
              //Con los datos llamamos a auditoria
              this.authService.realizar_Auditoria(data, false, "Propuesta", "DELETE", this.idUsuarioActual, headers).subscribe({
                next: (datoString) => {
                  console.log("SuccessAudit");
                  window.location.reload();
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.showErrorModal(err);
                },
              });
              var expedt = "";
              if (rowData.expdtGestiona == "" || rowData.expdtGestiona == null) {
                expedt = rowData.expdtAsoc;
              } else {
                expedt = rowData.expdtGestiona;
              }
              //Mientras hacemos auditoria, anulamos en gestiona
              this.authService.anulaPDFGestiona(expedt, rowData.idDocumentoGestiona, headers).subscribe({
                next: (datoString) => {
                  console.log("Codigo de Anulacion:", datoString);
                  window.location.reload();
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.showErrorModal(err);
                },
              });

            },
            error: err => {
              // Redirigir al usuario a la página de inicio de sesión
              this.showErrorModal(err);
            }

          });
        } else { // NO

        }
      });

    } else { //Se anula: Operacion 200 NEGATIVA
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        data: { actionType: "Anular" }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) { // YES
          //EstadoPropuesta se pone a estado Anulado
          this.http.get<any>(Constantes.GETPROPUESTA + idDelete, { headers: headers }).subscribe({
            next: data => {
              data.estadoPropuesta = 3; //Anulado
              this.authService.updatePropuesta(data.idPropuesta, data.proponente, data.responsable, data.cargo, data.cargoResponsable,
                data.objeto, data.necesidad, data.plazo, data.usuarioControl, data.fechaControl, data.importe, data.fechaPropuesta,
                data.expdtAsoc, data.estadoPropuesta, data.ejercicio, data.codProg, data.codEco,
                data.idDocumentoGestiona, data.dniProponente, data.idContabilidad, data.expdtGestiona, headers).subscribe({

                  next: (putDevuelto) => {
                    this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                      next: (datoString) => {
                        console.log("Codigo de Anulacion:", datoString);
                        window.location.reload();
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                      },
                    });

                    //Mientras hacemos auditoria, anulamos en gestiona

                    var expedt = "";
                    if (data.expdtGestiona == "" || data.expdtGestiona == null) {
                      expedt = data.expdtAsoc;
                    } else {
                      expedt = data.expdtGestiona;
                    }
                    this.authService.anulaPDFGestiona(expedt, data.idDocumentoGestiona, headers).subscribe({
                      next: (datoString) => {
                        console.log("Anulado con exito");
                        window.location.reload();
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                      },
                    });

                    //Se llama a 200 NEGATIVA:

                  },
                  error: (err) => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                  }
                });

            },
            error: err => {

              this.showErrorModal(err);
            }
          });
        } else { // NO

        }
      });
    }

  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


}
