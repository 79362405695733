import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef, GridReadyEvent, RowNode, FilterChangedEvent, IRowNode } from 'ag-grid-community';
import { ButtonsActionsPropuestaComponent } from '../buttons-actions-propuesta/buttons-actions-propuesta.component';
import { Constantes } from '../../../_services/constantes.service';
import { MatDialog } from '@angular/material/dialog';
import { RoutingService } from '../../../_services/routing.service';
import { CustomLeyendaPropuestaComponent } from '../../AgGridCustom/custom-leyenda-propuesta/custom-leyenda-propuesta.component';
import { CustomFilterPanelEstadoCerradoComponent } from '../../AgGridCustom/custom-filter-panel-estado-cerrado/custom-filter-panel-estado-cerrado.component';import { ModalesComponent } from '../../../base/modales/modales.component';


@Component({
  selector: 'app-propuestas-cerradas',
  templateUrl: './propuestas-cerradas.component.html',
  styleUrl: './propuestas-cerradas.component.css'
})
export class PropuestasCerradasComponent {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  selectedRowData: any;
  typeRow: any[] = [];
  tienePermisosVista = false;
  
  columnDefsUsuario: ColDef[] = [];

  sideBar: SideBarDef = {};
  
  gridOptions: GridOptions = {
    context: {
      componentParent: this,
    },
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private redirige: RoutingService
  ) { }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');

    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
          
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: data => {
              var idUsuario=data.idUsuario;

              var cadenaGet = Constantes.GET_PERMISO_W_USUARIO + `${idUsuario}`;
              this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
                next: dataN=>{
                  var encontrado= false;

                  var cadenaGetP = Constantes.GET_PERMISO_BY_NOMBREPERMISO + Constantes.VER_ACCIONES_PROPUESTA;
                    this.http.get<any>(cadenaGetP, { headers: headers }).subscribe({
                      next: dataPerm=>{
                        
                        this.http.get<any>(Constantes.GETVISTA_PROPUESTASCERRADAS, { headers: headers }).subscribe({
                          next: data => {
                            for (let i = 0; i < dataN.length && !encontrado; i++) {
                              if(dataN[i].idPermiso==dataPerm.idPermiso){ //Hemos encontrado el permiso
                                this.tienePermisosVista=true;
                              }
                              
                            }

                            this.columnDefsUsuario= [
                              {
                                filter: 'agTextColumnFilter',
                                width: 70,
                                cellRenderer: (params: any) => {
                                  const div = document.createElement('div');  // Creamos un div para el círculo
                                  div.className = 'estado-circle';  // Aplicamos una clase CSS para el estilo del círculo
                                  // Definimos el color del círculo basado en el valor de estadoPropuesta
                                  div.style.backgroundColor = '#84D2A5'; // Contabilizada
                                  // Ajustes de estilo para el círculo
                                  div.style.width = '20px';
                                  div.style.height = '20px';
                                  div.style.borderRadius = '50%';  // Para que sea un círculo
                                  div.style.border = '1pt solid #000';  // Borde del círculo
                                  div.style.margin = 'auto';  // Centrar el círculo en la celda
                              
                                  return div;  // Devolvemos el div como el contenido de la celda
                                },
                                autoHeight: true,  // Asegura que la altura de la fila se ajuste automáticamente
                                cellStyle: {
                                  'display': 'flex',  // Utilizamos flexbox para centrar el contenido
                                  'justify-content': 'center',  // Centrar horizontalmente
                                  'align-items': 'center',  // Centrar verticalmente
                                },
                              },
                              {
                                field: 'fec_opera',
                                headerName: 'Fecha',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                cellStyle: {
                                  textAlign: 'center'
                                },
                                valueFormatter: (params) => { //Formatea a DD/MM/YYYY
                                  if (params.value) {
                                    const date = new Date(params.value);
                                    const day = ('0' + date.getDate()).slice(-2);
                                    const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                  }
                                  return '';
                                },
                              },
                              {
                                field: 'txt_opera',
                                headerName: 'Descripción',
                                cellRenderer: (params: any) => {
                                  // Add a clickable span or button
                                  return `<span class="clickable-code">${params.value}</span>`;
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onDescClicked(params.data); // Trigger action with row data
                                },
                                width: 600,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'contains',
                                }
                              },
                              { 
                                field: 'imp_liqui',
                                headerName: 'Importe',
                                valueFormatter: (params) => this.formatNumber(params.value),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                cellStyle:{
                                  textAlign: 'right'
                                },
                                filterParams: {
                                  defaultOption: 'startsWith',
                                }
                              },
                              { 
                                field: 'imp_rectif',
                                headerName: 'Rectif',
                                valueFormatter: (params) => this.formatNumber(params.value),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                cellStyle:{
                                  textAlign: 'right'
                                },
                                filterParams: {
                                  defaultOption: 'startsWith',
                                }
                              },
                              { 
                                field: 'saldo',
                                headerName: 'Saldo',
                                valueFormatter: (params) => this.formatNumber(params.value),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                cellStyle:{
                                  textAlign: 'right'
                                },
                                filterParams: {
                                  defaultOption: 'startsWith',
                                }
                              },
                              {
                                field: 'cod_cla_funci',
                                headerName: 'Cod. Prog',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'startsWith',
                                  
                                }
                              },
                              { 
                                field: 'cod_cla_econo',
                                headerName: 'Cod. Eco.', 
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                                filterParams: {
                                  caseSensitive: true,
                                  defaultOption: 'startsWith',
                                  suppressAndOrCondition: true, 
                                  maxNumConditions: 8,
                                }
                              },
                              { 
                                field: 'eje_presu',
                                headerName: 'Año',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'equals',
                                }
                              },
                              { field: 'Acciones',
                                cellRenderer: ButtonsActionsPropuestaComponent,
                                cellRendererParams: {componentName: 'Cerradas'}, width: 150,
                                hide: !this.tienePermisosVista
                              },
                              {
                                field: 'id_conta',
                                headerName: 'Contabilidad',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'equals',
                                },
                                hide: true
                              }
                            ];
                            this.typeRow = data;
                            this.sideBar = {
                              toolPanels: [
                                {
                                  id: 'customToolPanel',
                                  labelDefault: 'Filtros',
                                  labelKey: 'customFilters',
                                  iconKey: 'menu',
                                  toolPanel: CustomFilterPanelEstadoCerradoComponent,  // Panel personalizado que vamos a crear
                                },
                                {
                                  id: 'leyenda',
                                  labelDefault: 'Leyenda',
                                  labelKey: 'customFilters',
                                  iconKey: 'eye',
                                  toolPanel: CustomLeyendaPropuestaComponent,  // Panel personalizado que vamos a crear
                                },
                              ],
                              defaultToolPanel: 'customToolPanelProp',
                            }
              
                            this.typeRow.sort((a, b) => b.eje_presu - a.eje_presu);
                          },
                          error: err => {
                            console.log(err);
                            this.showErrorModal(err);
                          }
                        });
                        
                      },
                      error: err=>{
                        // Redirigir al usuario a la página de inicio de sesión
                        this.showErrorModal(err);
                        this.router.navigate(['/gestionGasto/estadoEjecucion']);
                      }
                    });
                  

                  

                },
                error: err=>{ 
                  // Redirigir al usuario a la página de inicio de sesión
                  this.showErrorModal(err);
                  this.router.navigate(['/gestionGasto/estadoEjecucion']);
                }
              });
                
            },
            error: err => {
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/gestionGasto/estadoEjecucion']);
            }
          });

          
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  onDescClicked(rowData: any) {
    console.log('Código clicked for row:', rowData);
    localStorage.setItem('id_ope', rowData.id_ope_padre);
    var enlace = this.redirige.switchRoutes("Detalles Cerrada");
    this.router.navigate(['/', ...enlace]);
  }

  applySearchFilter() {
    if (this.agGrid.api) {
      this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
    }
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }

}
