import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirma-limites',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modal-confirma-limites.component.html',
  styleUrl: './modal-confirma-limites.component.css'
})
export class ModalConfirmaLimitesComponent {
  objeto : string= "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ModalConfirmaLimitesComponent>,   private cdr: ChangeDetectorRef) {
    // Based on the actionType passed, set the modal text
    this.objeto= data.actionType;
    console.log(this.objeto);
  }

  ngAfterViewInit(): void {
    // Force change detection after the view has been initialized
    this.cdr.detectChanges();
  }

  onConfirm(): void {
    this.dialogRef.close(true); // Return true if Yes is clicked
  }

  onCancel(): void {
    this.dialogRef.close(false); // Return false if No is clicked
  }
}
