import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-botonera',
  templateUrl: './botonera.component.html',
  styleUrls: ['./botonera.component.css']
})
export class BotoneraComponent {

  isDropdownOpen = false;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
