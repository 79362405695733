import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef, GridReadyEvent, RowNode, FilterChangedEvent, IRowNode } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';
import { RoutingService } from '../../../_services/routing.service';
import { CustomFilterPanelEstEjeComponent } from '../../AgGridCustom/custom-filter-panel-est-eje/custom-filter-panel-est-eje.component';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { right } from '@popperjs/core';

@Component({
  selector: 'app-estado-ejecucion',
  templateUrl: './estado-ejecucion.component.html',
  styleUrls: ['./estado-ejecucion.component.css']
})
export class EstadoEjecucionComponent implements OnInit {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  typeRow: any[] = [];
  totalDisponibles: number = 0;
  totalGastado: number = 0;
  totalDefinitivo: number = 0;
  years: number[] = []; // Lista de años únicos
  originalTypeRow: any[] = [];

  columnDefsUsuario: ColDef[] = [
    {
      field: 'des_aplic',
      headerName: 'Descripción',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: (params: ICellRendererParams) => {
        const link = document.createElement('a');
        link.innerText = params.value;
        link.style.cursor = 'pointer';
        link.addEventListener('click', (event) => {
          event.preventDefault();
          localStorage.setItem('aplicacionPresupuestariaSeleccionada', JSON.stringify(params.data));
          var enlace = this.redirige.switchRoutes("Nueva Propuesta");
          this.router.navigate(['/',...enlace]);
        })
        return link;
      },
      width: 500,
      filterParams: {
        defaultOption: 'contains',
      }
    },
    {
      headerName: 'Justificantes',
      field: 'justificantes',
      cellRenderer: (params: any) => {
        const button = document.createElement('button');
        button.innerText = 'Ver';
        button.className = 'btn-ver';  // Clase CSS
        button.style.backgroundColor = '#992325';  // Color de fondo personalizado
        button.style.color = 'white';  // Color del texto
        button.style.border = '1pt solid #dddddd';
        button.style.borderRadius = '5pt';
        button.style.cursor = 'pointer';
        button.style.width = '60%';  // Asegura que el botón sea responsive
        button.style.height = '80%';  // Asegura que el botón sea responsive
        button.addEventListener('click', () => {
          // Almacenar los datos de la fila en localStorage y navegar a la página de justificantes
          localStorage.setItem('aplicacionPresupuestariaSeleccionada', JSON.stringify(params.data));
          var enlace = this.redirige.switchRoutes("Justificante Apl");
          this.router.navigate(['/',...enlace]);
        });
        return button;
      },
      autoHeight: true,
      cellStyle: {
        'text-align': 'center',  // Centra el botón en la celda
      },
    },
    { 
      field: 'creditos_definitivos',
      headerName: 'Definitivos',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'credito_gastado',
      headerName: 'Gastados',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'credito_disponible',
      headerName: 'Disponibles',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'disponible_bolsa',
      headerName: 'Bolsa',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'pagos_realizados',
      headerName: 'Pagado',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'modif_rectif',
      headerName: 'Modificado',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    {
      field: 'apl_fun',
      headerName: 'Cod. Prog',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'startsWith',
      }
    },
    { 
      field: 'apl_eco',
      headerName: 'Cod. Eco.', 
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        defaultOption: 'startsWith',
        suppressAndOrCondition: true, 
        maxNumConditions: 8,
      }
    },
    { field: 'apl_eje',
      headerName: 'Año',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'equals',
      }
    },
    { field: 'id_conta',
      headerName: 'Contabilidad',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        defaultOption: 'equals',
      },
      hide: true
    }
  ];

  gridOptions: GridOptions = {}
  sideBar: SideBarDef = {}

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private redirige : RoutingService,  private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.fetchData();
  }
  onRowClicked(selectedRowData: any) {
    // Aquí puedes realizar cualquier lógica adicional antes de navegar a la nueva página
    // Al hacer clic en el enlace, almacenar los datos de la fila en localStorage y navegar al componente destino
    localStorage.setItem('aplicacionPresupuestariaSeleccionada', JSON.stringify(selectedRowData.data));
    var enlace = this.redirige.switchRoutes("Nueva Propuesta");
    this.router.navigate(['/',...enlace]);
  }

  fetchData(): void {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
          this.http.get<any>(Constantes.GETVISTA_ESTADOEJECUCION, { headers: headers }).subscribe({
            next: data => {
              this.originalTypeRow = [...data]; // Guardar los datos originales
              this.typeRow = data;
              // Filtrar los datos por año seleccionado
              this.gridOptions = {
                context: {
                  componentParent: this, // Este es el padre que contiene `applyFilters`
                }
              }

              this.sideBar = {
                toolPanels: [
                  {
                    id: 'customToolPanel',
                    labelDefault: 'Filtros',
                    labelKey: 'customFilters',
                    iconKey: 'menu',
                    toolPanel: CustomFilterPanelEstEjeComponent,  // Panel personalizado que vamos a crear
                  },
                ],
                defaultToolPanel: 'customToolPanelEstEje',
              }
              this.calculateDefinitivo();
              this.calculateGastado();
              this.calculateDisponible();
            },
            error: err => {
              console.log(err);
              this.showErrorModal(err);
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  calculateTotals(): void {
    if (this.agGrid && this.agGrid.api) {
      let totalDefinitivo = 0;
      let totalGastado = 0;
      let totalDisponibles = 0;

      this.agGrid.api.forEachNodeAfterFilter((rowNode: IRowNode<any>) => {
        totalDefinitivo += Number(rowNode.data.creditos_definitivos) || 0;
        totalGastado += Number(rowNode.data.credito_gastado) || 0;
        totalDisponibles += Number(rowNode.data.credito_disponible) || 0;
      });

      this.totalDefinitivo = totalDefinitivo;
      this.totalGastado = totalGastado;
      this.totalDisponibles = totalDisponibles;
    }
  }

  calculateDefinitivo(): void {
    this.totalDefinitivo = 0; // Reinicia el total
    this.totalDefinitivo = this.typeRow.reduce((sum, row) => sum + (Number(row.creditos_definitivos) || 0), 0);
  }

  calculateGastado(): void {
    this.totalGastado = 0; // Reinicia el total
    this.totalGastado = this.typeRow.reduce((sum, row) => sum + (Number(row.credito_gastado) || 0), 0);
  }

  calculateDisponible(): void {
    this.totalDisponibles = 0; // Reinicia el total
    this.totalDisponibles = this.typeRow.reduce((sum, row) => sum + (Number(row.credito_disponible) || 0), 0);
  }

  onGridReady(params: GridReadyEvent) {
    this.calculateTotals();
  }

  onFilterChanged(event: FilterChangedEvent) {
    this.calculateTotals();
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}
