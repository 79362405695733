import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegisterComponent } from './base/register/register.component';
import { LoginComponent } from './base/login/login.component';
import { ProfileComponent } from './base/profile/profile.component';
import { DashboardComponent } from './base/dashboard/dashboard.component';
import { DesktopComponent } from './base/desktop/desktop.component';
import { TaskbarComponent } from './base/taskbar/taskbar.component';
import { EditarUsuarioComponent } from './base/editar-usuario/editar-usuario.component';
import { EstadoEjecucionComponent } from './modulos/gestionGasto/estado-ejecucion/estado-ejecucion.component';
import { gestionGastoDesktopComponent } from './modulos/gestionGasto/gestion-gasto-desktop/gestion-gasto-desktop.component';
import { PropuestaGastoComponent} from './modulos/gestionGasto/propuesta-gasto/propuesta-gasto.component'
import { AddUsuarioComponent } from './base/add-usuario/add-usuario.component';
import { AddModuloComponent } from './base/add-modulo/add-modulo.component';
import { AddPermisoComponent } from './base/add-permiso/add-permiso.component';
import { EditarModuloComponent } from './base/editar-modulo/editar-modulo.component';
import { EditarPermisoComponent } from './base/editar-permiso/editar-permiso.component';
import { AddTemplateComponent } from './base/add-template/add-template.component';
import { EditarTemplateComponent } from './base/editar-template/editar-template.component';
import { ModulosComponent } from './base/modulos/modulos.component';
import { AvisomantenimientoComponent } from './base/avisomantenimiento/avisomantenimiento.component';
import { AddAccesodirectoComponent } from './base/add-accesodirecto/add-accesodirecto.component';
import { NuevaPropuestaComponent } from './modulos/gestionGasto/nueva-propuesta/nueva-propuesta.component';
import { GenerarPdfPropuestaComponent } from './modulos/gestionGasto/generar-pdf-propuesta/generar-pdf-propuesta.component';
import { AddGrupoComponent } from './base/add-grupo/add-grupo.component';
import { EditarGrupoComponent } from './base/editar-grupo/editar-grupo.component';
import { ModalBuscadorComponent } from './modulos/gestionGasto/modal-buscador/modal-buscador.component';
import { JustificanteAplComponent } from './modulos/gestionGasto/justificante-apl/justificante-apl.component';
import { TodasPropuestasComponent } from './modulos/gestionGasto/todas-propuestas/todas-propuestas.component';
import { EstadoPropuestaComponent } from './modulos/gestionGasto/estado-propuesta/estado-propuesta.component';
import { PropuestasCerradasComponent } from './modulos/gestionGasto/propuestas-cerradas/propuestas-cerradas.component';
import { DetalleCerradaComponent } from './modulos/gestionGasto/detalle-cerrada/detalle-cerrada.component';
import { NuevaPropuestaTemporalComponent } from './modulos/gestionGasto/nueva-propuesta-temporal/nueva-propuesta-temporal.component';
import { ModificarPropuestaComponent } from './modulos/gestionGasto/modificar-propuesta/modificar-propuesta.component';
import { DividirPropuestaComponent } from './modulos/gestionGasto/dividir-propuesta/dividir-propuesta.component';
import { DividirPropuestaV2Component } from './modulos/gestionGasto/dividir-propuesta-v2/dividir-propuesta-v2.component';
import { JustificanteProComponent } from './modulos/gestionGasto/justificante-pro/justificante-pro.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'desktop', component: DesktopComponent },
  { path: 'editarUsuario', component: EditarUsuarioComponent},
  { path: 'editarModulo', component: EditarModuloComponent},
  { path: 'editarPermiso', component: EditarPermisoComponent},
  { path: 'editarTemplate', component: EditarTemplateComponent},
  { path: 'addUser', component: AddUsuarioComponent},
  { path: 'addModule', component: AddModuloComponent},
  { path: 'addPermiso', component: AddPermisoComponent},
  { path: 'addTemplate', component: AddTemplateComponent},
  { path: 'gestionGasto/estadoEjecucion', component: EstadoEjecucionComponent},
  { path: 'gestionGasto/estadoEjecucion/justificanteApl', component: JustificanteAplComponent},
  { path: 'gestionGasto', component: gestionGastoDesktopComponent},
  { path: 'modulos', component: ModulosComponent},
  { path: 'mantenimiento', component: AvisomantenimientoComponent},
  { path: 'addAccesoDirecto', component: AddAccesodirectoComponent},
  { path: 'gestionGasto/propuesta', component: PropuestaGastoComponent},
  { path: 'gestionGasto/propuesta/nueva', component: NuevaPropuestaComponent},
  { path: 'addGrupo', component: AddGrupoComponent},
  { path: 'editarGrupo', component: EditarGrupoComponent},
  { path: 'buscador', component: ModalBuscadorComponent},
  { path: 'gestionGasto/propuestas', component: TodasPropuestasComponent},
  { path: 'gestionGasto/estadoPropuesta', component: EstadoPropuestaComponent},
  { path: 'gestionGasto/propuesta/nuevaTemporal', component: NuevaPropuestaTemporalComponent},
  { path: 'gestionGasto/cerrado', component: PropuestasCerradasComponent},
  { path: 'gestionGasto/cerrado/detalles', component: DetalleCerradaComponent},
  { path: 'gestionGasto/propuesta/modificarPropuesta', component: ModificarPropuestaComponent},
  { path: 'gestionGasto/propuesta/dividirPropuesta', component: DividirPropuestaComponent},
  { path: 'gestionGasto/propuesta/dividirPropuestaCerrada', component: DividirPropuestaV2Component},
  { path: 'gestionGasto/propuestas/justificantePro', component: JustificanteProComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
