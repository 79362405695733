import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor() { }

  switchRoutes(nombreMod: string): string[] {
    let ret: string[] = [];
    switch (nombreMod) {
      case "Gestion Gastos":
      case "Gestion Gasto":
        ret = ["gestionGasto", "estadoEjecucion"];
        break;
      case "NuevaPropuesta":
      case "Nueva Propuesta":
        ret = ["gestionGasto", "propuesta"];
        break;
      case "NuevaPropuestaTemporal":
      case "Nueva Propuesta Temporal":
      case "Temporal":
        ret = ["gestionGasto", "propuesta", "nuevaTemporal"];
        break;
      case "Justificante Apl":
      case "JustificanteApl":
        ret = ["gestionGasto", "estadoEjecucion", "justificanteApl"];
        break;
        case "Justificante Pro":
          case "JustificantePro":
            ret = ["gestionGasto", "propuestas", "justificantePro"];
            break;
      case "Propuestas":
        ret = ["gestionGasto", "propuestas"];
        break;
      case "EstadoPropuesta":
      case "EstadoPropuestas":
        ret = ["gestionGasto", "estadoPropuesta"];
        break;

      case "Cerrado":
      case "Cerrados":
      case "Cerradas":
        ret = ["gestionGasto", "cerrado"];
        break;

      case "Detalles Cerrado":
      case "DetallesCerrados":
      case "Detalles Cerradas":
      case "Detalles Cerrada":
        ret = ["gestionGasto", "cerrado", "detalles"];
        break;

      case "Modificar Propuesta":
      case "ModificarPropuesta":
        ret = ["gestionGasto", "propuesta", "modificarPropuesta"];
        break;
      case "Dividir Propuesta":
        ret = ["gestionGasto", "propuesta", "dividirPropuesta"];
        break;
        case "Dividir Propuesta Cerrada":
        ret = ["gestionGasto", "propuesta", "dividirPropuestaCerrada"];
        break;
      case "login":
      default:
        ret = ["login"];
        break;
    }
    return ret;
  }

}
