<div class="modal-body">
    <input 
      type="text" 
      id="searchInput" 
      [(ngModel)]="terminoBusqueda" 
      (keyup)="applySearchFilter()" 
      class="form-control mb-2" 
      placeholder="Buscar...">
    
    <ag-grid-angular 
      class="ag-theme-quartz" 
      [rowData]="typeRow" 
      [columnDefs]="columnDefsUsuario" 
      style="height: 50vh; width: 100%;" 
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
      (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
    <button type="button" class="close-button" (click)="closeDialog()">Cerrar</button>
  </div>
  