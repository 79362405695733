import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnApi } from 'ag-grid';
import { GridApi, IToolPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-custom-filter-panel-estado-propuestas',
  templateUrl: './custom-filter-panel-estado-propuestas.component.html',
  styleUrl: './custom-filter-panel-estado-propuestas.component.css'
})
export class CustomFilterPanelEstadoPropuestasComponent {
  @ViewChild('toolPanel', { static: false }) toolPanel!: ElementRef;

  filtroCap1Checked: boolean = false;
  filtroCap2Checked: boolean = false;
  filtroCap3Checked: boolean = false;
  filtroCap4Checked: boolean = false;
  filtroCap5Checked: boolean = false;
  filtroCap6Checked: boolean = false;
  filtroCap7Checked: boolean = false;
  filtroCap8Checked: boolean = false;
  filtroCap9Checked: boolean = false;
  selectedYear: number = 0;
  filtroCorrienteChecked:  boolean = true;
  filtroYearAnteriorChecked:  boolean = false;
  private params!: IToolPanelParams;
  private gridApi!: GridApi;
  private columnApi!: ColumnApi;

  agInit(params: IToolPanelParams): void {
    const storedYear = localStorage.getItem('yearContable');
    if (storedYear) {
      this.selectedYear = parseInt(storedYear, 10); // Convertir a número
    }
    this.params = params;
    this.gridApi = params.api;

    this.applyFiltroCorriente();
    this.applyFiltersCodProg();
  }

  getGui(): HTMLElement {
    return this.toolPanel.nativeElement;
  }

  refresh(): void {
    // Implement if needed
  }
  applyFiltersCodProg() {
    const filterModel = this.params.api.getFilterModel() || {};

    const conditions: any[] = [];

    if(this.filtroCap9Checked){
      conditions.push({ type: 'startsWith', filter: '9', filterType: 'text' });
    }
    if(this.filtroCap8Checked){
      conditions.push({ type: 'startsWith', filter: '8', filterType: 'text' });
    }
    if(this.filtroCap7Checked){
      conditions.push({ type: 'startsWith', filter: '7', filterType: 'text' });
    }
    if(this.filtroCap6Checked){
      conditions.push({ type: 'startsWith', filter: '6', filterType: 'text' });
    }
    if(this.filtroCap5Checked){
      conditions.push({ type: 'startsWith', filter: '5', filterType: 'text' });
    }
    if(this.filtroCap4Checked){
      conditions.push({ type: 'startsWith', filter: '4', filterType: 'text' });
    }
    if(this.filtroCap3Checked){
      conditions.push({ type: 'startsWith', filter: '3', filterType: 'text' });
    }
    if(this.filtroCap2Checked){
      conditions.push({ type: 'startsWith', filter: '2', filterType: 'text' });
    }
    if(this.filtroCap1Checked){
      conditions.push({ type: 'startsWith', filter: '1', filterType: 'text' });
    }

    if (conditions.length > 0) {
      filterModel['cod_cla_econo'] = {
        filterType: 'text',
        operator: 'OR',
        conditions: conditions
      };
    } else {
      delete filterModel['cod_cla_econo']
    }

    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }

  applyFiltroCorriente() {
    const filterModel = this.params.api.getFilterModel() || {};
    this.filtroYearAnteriorChecked = false;
  
    if (this.filtroCorrienteChecked) {
      filterModel['eje_presu'] = {
        filterType: 'text', // Cambiado a 'number'
        type: 'equals',
        filter: this.selectedYear // Contabilidad seleccionada
      };
    } else {
      delete filterModel['eje_presu'];
    }
  
    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }

  applyFiltroCorriente2() {
    const filterModel = this.params.api.getFilterModel() || {};
    this.filtroCorrienteChecked = false;
  
    if (this.filtroYearAnteriorChecked) {
      filterModel['eje_presu'] = {
        filterType: 'text', // Cambiado a 'number'
        type: 'equals',
        filter: this.selectedYear - 1 // Contabilidad seleccionada
      };
    } else {
      delete filterModel['eje_presu'];
    }
  
    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }
}
