<app-botonera></app-botonera>  
  <div class="escritorio">
      <div class="common-componentes">
          <div class="datos-propuestas">
            <h4>{{ selectedRowData.des_aplic }}, 
                ({{ selectedRowData.apl_eje }}.{{ selectedRowData.apl_fun }}.{{ selectedRowData.apl_eco }})</h4>
            <div class="label-value">Definitivos: {{ selectedRowData.creditos_definitivos }} €</div>
            <div class="label-value">Gastados: {{ selectedRowData.credito_gastado }} €</div>
            <div class="label-value">Disponibles: {{ selectedRowData.credito_disponible }} €</div>
            <div class="label-value">Bolsa: {{ selectedRowData.disponible_bolsa }} €</div>
            <div class="label-value">Pagado: {{ selectedRowData.pagos_realizados }} €</div>
        </div>
      </div>
    <div id="grid-container" class="tab-content grid-container">
      <div class="search-container">
        <!-- Etiqueta -->
        <label for="searchInput" class="search-label"><b>Buscar:</b></label>
        <!-- Input de búsqueda -->
        <input type="text" id="searchInput" [(ngModel)]="terminoBusqueda" (keyup)="applySearchFilter()" class="form-control mb-2">
      </div>
      <!-- Ag-Grid con configuración -->
      <ag-grid-angular 
        class="ag-theme-quartz" 
        [rowData]="typeRow" 
        [columnDefs]="columnDefsUsuario"
        style="height: 50vh;">
      </ag-grid-angular>
    <app-taskbar></app-taskbar>
  </div>