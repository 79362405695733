<app-botonera></app-botonera>
<div class="escritorio">
    <div class="common-componentes">
      <div class="datos-just">
        <h4>{{ nombrePropuesta }}</h4>
        <div class="label-value">Importe Propuesta: {{ importePropuesta }} €</div>
        <div class="label-value">Gastado: {{ sumJustificantes }} €</div>
        <div class="label-value">Disponibles: {{disponibles}} €</div>
      </div>      
      <div class="datos-propuestas">
          <h4>{{ aplSelecionada.des_aplic }}, 
              ({{ aplSelecionada.apl_eje }}.{{ aplSelecionada.apl_fun }}.{{ aplSelecionada.apl_eco }})</h4>
          <div class="label-value">Definitivos: {{ aplSelecionada.creditos_definitivos }} €</div>
          <div class="label-value">Gastados: {{ aplSelecionada.credito_gastado }} €</div>
          <div class="label-value">Disponibles: {{ aplSelecionada.credito_disponible }} €</div>
          <div class="label-value">Bolsa: {{ aplSelecionada.disponible_bolsa }} €</div>
          <div class="label-value">Pagado: {{ aplSelecionada.pagos_realizados }} €</div>
      </div>
    </div>
    <div class="demo-container">
      <h1>Gráfica estado del Justificante</h1>
      <app-linear-gauge-propuesta
        [value]="this.sumJustificantes"
        [min]="0"
        [max]="this.importePropuesta"
        [width]="1000"
        [height]="20"
      ></app-linear-gauge-propuesta>
  </div>
  <div id="grid-container" class="tab-content grid-container">
    <div class="search-container">
      <!-- Etiqueta -->
      <label for="searchInput" class="search-label">Buscar:</label>
      <!-- Input de búsqueda -->
      <input type="text" id="searchInput" [(ngModel)]="terminoBusqueda" (keyup)="applySearchFilter()" class="form-control mb-2">
    </div>
    <!-- Ag-Grid con configuración -->
    <ag-grid-angular 
      class="ag-theme-quartz" 
      [rowData]="typeRow" 
      [columnDefs]="columnDefsUsuario"
      style="height: 50vh;">
    </ag-grid-angular>
    <div class="total-row">
      <div class="total-label">Total:</div>
      <div class="total-value"><p>{{ formatNumber(sumJustificantes) }}</p></div>
    </div>
  <app-taskbar></app-taskbar>
</div>