import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef, GridReadyEvent, RowNode, FilterChangedEvent, IRowNode } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { right } from '@popperjs/core';



@Component({
  selector: 'app-detalle-cerrada',
  templateUrl: './detalle-cerrada.component.html',
  styleUrl: './detalle-cerrada.component.css'
})
export class DetalleCerradaComponent {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  selectedRowData: any;
  typeRow: any[] = [];
  columnDefsUsuario: ColDef[] = [
    { 
      field: 'eje_presu',
      headerName: 'Ejercicio',
      cellStyle: {
        textAlign: 'center'
      },
      width: 120
    },
    { 
      field: 'num_opera',
      headerName:'Operación',
      cellStyle: {
        textAlign: 'center'
      },
      width: 130
    },
    { field: 'imp_total',
      headerName: 'Importe',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle: {
        textAlign: right
      },
      width: 120
    },
    {
      field: 'txt_opera',
      headerName: 'Descripción',
      width: 800
    }
  ];

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false
  };

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');

    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('id_ope') != null || localStorage.getItem('id_ope') == '') {
            const id_ope = localStorage.getItem('id_ope') || "";
            localStorage.removeItem('id_ope');
            const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
            const cad=  `${Constantes.GETVISTA_DETALLECERRADA_W_OPERAPADRE}${id_ope}`
            this.http.get<any>(cad , { headers: headers }).subscribe({
              next: data => {
                this.typeRow = data;
                this.typeRow.sort((a, b) => b.id_conta - a.id_conta);
              },
              error: err => {
                console.log(err);
                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/gestionGasto']);
              }
            });
          } else {
            console.error('No se esta accediendo desde una Propuesta Cerrada');
            this.openModal('No se esta accediendo desde una Propuesta Cerrada');
            localStorage.clear();
            this.router.navigate(['/gestionGasto']);
          }
         
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }
  onDescClicked(rowData: any) {
    console.log('Código clicked for row:', rowData);
    //this.sendAplPresupuestaria(rowData, "cod");
  }

  applySearchFilter() {
    if (this.agGrid.api) {
      this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
    }
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}


  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }


}
