import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-contrato-obra',
  templateUrl: './modal-contrato-obra.component.html',
  styleUrl: './modal-contrato-obra.component.css'
})
export class ModalContratoObraComponent {
  constructor(public dialogRef: MatDialogRef<ModalContratoObraComponent>) {}
  onConfirm(): void {
    this.dialogRef.close(true); // Return true if Yes is clicked
  }

  onCancel(): void {
    this.dialogRef.close(false); // Return false if No is clicked
  }
}
