
<body>
    <div class="legend">
        <h4 class="titulo-label"><b>Filtros Predeterminados</b></h4>
        <div class="legend-item">
            <div class="circle case-0"></div>
            <span>Sin Firmar</span>
        </div>
        <div class="legend-item">
            <div class="circle case-1"></div>
            <span>Firmada</span>
        </div>
        <div class="legend-item">
            <div class="circle case-2"></div>
            <span>Contabilizada</span>
        </div>
        <div class="legend-item">
            <div class="circle case-3"></div>
            <span>Anulado</span>
        </div>
        <div class="legend-item">
            <div class="circle case-4"></div>
            <span>Sin Finalizar</span>
        </div>
    </div>
</body>