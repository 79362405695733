import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import {  Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ModalesComponent } from '../modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { RoutingService } from '../../_services/routing.service';
import { Constantes } from '../../_services/constantes.service';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrl: './modulos.component.css'
})
export class ModulosComponent {
  jwtToken: string = '';
  isAdmin: any;
  moduloPropuestaGastoAccesible= false;
  listaModulos: any[] = [];
  accesoDirectoUnoExist=false;
  accesoDirectoDosExist=false;
  accesoDirectoTresExist=false;
  accesoDirectoUno = {idModulo: "", nombreModulo: ""};
  accesoDirectoDos = {idModulo: "", nombreModulo: ""};
  accesoDirectoTres = {idModulo: "", nombreModulo: ""};
  constructor(private http: HttpClient, private router: Router,  private dialog: MatDialog, private cdr: ChangeDetectorRef, private redirige : RoutingService){
  }
  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const token = localStorage.getItem('jwtToken');
    if (token !== null) {
      this.jwtToken = token;
    }
    // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    if (this.jwtToken) {
      var strHead = {'Authorization': `Bearer ${this.jwtToken}`};
      const headers = new HttpHeaders(strHead);

      this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
        next: data => {
          this.isAdmin = data;
        },
        error: err => {
            console.log(err);
        }
      });

      this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
        next: data => {
          var idUsuario = data.idUsuario;
          var accDirUn: any;
          var accDirDos : any;
          var accDirTres: any;

          var cadenaGeto = Constantes.GET_ACCESOS_DIRECTOS_BY_USU + `${idUsuario}`;
          this.http.get<any>(cadenaGeto, { headers: headers }).subscribe({
            next: dataN=>{
              switch(dataN.length){
                default:
                  case 0 :
                    break;
                  case 1:
                    accDirUn= dataN[0].idModulo;
                    break;
                  case 2:
                    accDirUn= dataN[0].idModulo;
                    accDirDos= dataN[1].idModulo;
                    break;
                  case 3:
                    accDirUn= dataN[0].idModulo;
                    accDirDos= dataN[1].idModulo;
                    accDirTres= dataN[2].idModulo;
                    break;
              }

                //Buscamos los accesos directos a cada modulo
          if(accDirUn != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID + `${accDirUn}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoUno=datoMod;
                this.accesoDirectoUnoExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }
          if(accDirDos != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID+ `${accDirDos}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoDos=datoMod;
                this.accesoDirectoDosExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }
          if(accDirTres != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID+ `${accDirTres}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoTres=datoMod;
                this.accesoDirectoTresExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }

            },
            error: err=>{
              localStorage.clear();
              // Redirigir al usuario a la página de inicio de sesión
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }});

          
          
          var cadenaGet = Constantes.GET_PERMISO_W_USUARIO + `${idUsuario}`;
          this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
            next: dataN=>{
              for (let i = 0; i < dataN.length; i++) {
                //Tenemos los ID de Permiso que tienen relacion con idUsuario. Ahora obtenemos cada uno de los
                //nombres de cada Permiso y comparamos con modulo Gasto
                var cadenaGetP = Constantes.GET_PERMISO_BY_ID + `${dataN[i].idPermiso}`;
                this.http.get<any>(cadenaGetP, { headers: headers }).subscribe({
                  next: dataPerm=>{
                    if(dataPerm.nombrePermiso.includes("Acceso")){ //Ahora como ejemplo es permiso12 pero será el permiso de acceso a ModGasto
                      
                      var idMod = dataPerm.idModulo;
                      var cadenaGetM = Constantes.GETMODULO_BY_ID + `${idMod}`;
                      this.http.get<any>(cadenaGetM, { headers: headers }).subscribe({
                        next: dataMod=>{
                          this.listaModulos.push(dataMod);
                        },
                        error: err=>{
                          localStorage.clear();
                          // Redirigir al usuario a la página de inicio de sesión
                          this.showErrorModal(err);
                          this.router.navigate(['/login']);
                        }
                      });
                    }
                  },
                  error: err=>{
                    localStorage.clear();
                    // Redirigir al usuario a la página de inicio de sesión
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  }
                });
              }
            },
            error: err=>{
              localStorage.clear();
              // Redirigir al usuario a la página de inicio de sesión
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }
          });
            
        },
        error: err => {
            console.log(err);
            localStorage.clear();
            // Redirigir al usuario a la página de inicio de sesión
            this.showErrorModal(err);
            this.router.navigate(['/login']);
        }
      });
    } else {
      // Maneja el caso donde el token JWT no está presente en el localStorage
      console.error('El token JWT no está presente en el localStorage');
    }
  }

  routeUrl(nombreMod: string){
    var enlace = this.redirige.switchRoutes(nombreMod);
    this.router.navigate(['/', ...enlace]);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }

}
