<div class="custom-filter-panel">
    <h4 class="titulo-label"><b>Filtros Estado</b></h4>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp" [(ngModel)]="filtro1Checked" (change)="applyFilters()">
        <label for="filtroProp">Propuesta sin firmar</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp2" [(ngModel)]="filtro3Checked" (change)="applyFilters()">
        <label for="filtroProp2">Propuestas firmadas</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp3" [(ngModel)]="filtro4Checked" (change)="applyFilters()">
        <label for="filtroProp3">Propuestas contabilizadas</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtroProp4" [(ngModel)]="filtro5Checked" (change)="applyFilters()">
        <label for="filtroProp4">Propuestas anuladas</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroProp" [(ngModel)]="filtro2Checked" (change)="applyFilters()">
      <label for="filtroProp">Propuestas sin finalizar</label>
  </div>
    <h4 class="titulo-label"><b>Filtros Cod. Prog</b></h4>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap9" [(ngModel)]="filtroCap9Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap9">Capitulo 9</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap8" [(ngModel)]="filtroCap8Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap8">Capitulo 8</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap7" [(ngModel)]="filtroCap7Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap7">Capitulo 7</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap6" [(ngModel)]="filtroCap6Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap6">Capitulo 6</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap5" [(ngModel)]="filtroCap5Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap5">Capitulo 5</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap4" [(ngModel)]="filtroCap4Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap4">Capitulo 4</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap3" [(ngModel)]="filtroCap3Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap3">Capitulo 3</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap2" [(ngModel)]="filtroCap2Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap2">Capitulo 2</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap1" [(ngModel)]="filtroCap1Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap1">Capitulo 1</label>
    </div>
    <!-- Agrega más filtros aquí si es necesario -->
</div>