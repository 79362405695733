import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import {  Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ButtonsActionsPropuestaComponent } from '../buttons-actions-propuesta/buttons-actions-propuesta.component';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { Constantes } from '../../../_services/constantes.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';

@Component({
  selector: 'app-gestion-gasto-desktop',
  templateUrl: './gestion-gasto-desktop.component.html',
  styleUrl: './gestion-gasto-desktop.component.css'
})
export class gestionGastoDesktopComponent {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  selectedRowData: any;
  typeRow: any[] = [];
  columnDefsPropuesta: ColDef[] = [
    { field: 'idPropuesta', headerName: 'Codigo' },
    { field: 'objeto', headerName: 'Propuesta', width: 500 },
    { field: 'importe', headerName: 'Importe' },
    {
      field: 'estadoPropuesta',
      headerName: 'Tramitado',
      cellRenderer: (params: any) => {
        switch (params.value) {
          case 0:
            return 'Sin Firmar';
          case 1:
            return 'Firmada';
          case 2:
            return 'Contabilizada';
          case 3:
            return 'Anulado';
          case 4:
            return 'Sin Finalizar';
          default:
            return params.value;
        }
      }
    },                                 //Se le pasa el nombre del componente que llama a los botones para decir cuales se muestran
    { field: 'Acciones', cellRenderer: ButtonsActionsPropuestaComponent, cellRendererParams: {componentName: 'GestionGasto'}, width: 265 }
  ];

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false
  };
  
  constructor(private http: HttpClient, private router: Router, private authService: AuthService,  private dialog: MatDialog) { }


  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto' ) {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
          if(localStorage.getItem('yearContable') != null && localStorage.getItem('contabilidad') != null){
            localStorage.getItem('yearContable')
            localStorage.getItem('contabilidad');
            const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
            this.http.get<any>(Constantes.GETPROPUESTAS, { headers: headers }).subscribe({
              next: data => {
                this.typeRow = data;
                this.typeRow.sort((a, b) => b.idPropuesta - a.idPropuesta);
              },
              error: err => {
                console.log(err);
                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/login']);
              }
            });
          } else {
            localStorage.setItem('yearContable', '2024');
            localStorage.setItem('contabilidad', '20');
          }
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear(); 
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear(); 
        this.router.navigate(['/login']); 
      }
    }
  }


  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}

