<div class="custom-filter-panel">
    <h4 class="titulo-label"><b>Filtros por Año del Ejercicio</b></h4>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtro2" [(ngModel)]="filtroCorrienteChecked" (change)="applyFiltroCorriente()">
      <label for="filtro2">Año actual del Ejercicio</label>
    </div>
    <div class="filter-item">
        <input type="checkbox" class="custom-checkbox" id="filtro1" [(ngModel)]="filtroYearAnteriorChecked" (change)="applyFiltroCorriente2()">
        <label for="filtro1">Año anterior del Ejercicio</label>
      </div>
    <h4 class="titulo-label"><b>Filtros por Capítulos</b></h4>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap9" [(ngModel)]="filtroCap9Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap9">Capitulo 9</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap8" [(ngModel)]="filtroCap8Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap8">Capitulo 8</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap7" [(ngModel)]="filtroCap7Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap7">Capitulo 7</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap6" [(ngModel)]="filtroCap6Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap6">Capitulo 6</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap5" [(ngModel)]="filtroCap5Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap5">Capitulo 5</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap4" [(ngModel)]="filtroCap4Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap4">Capitulo 4</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap3" [(ngModel)]="filtroCap3Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap3">Capitulo 3</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap2" [(ngModel)]="filtroCap2Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap2">Capitulo 2</label>
    </div>
    <div class="filter-item">
      <input type="checkbox" class="custom-checkbox" id="filtroCap1" [(ngModel)]="filtroCap1Checked" (change)="applyFiltersCodProg()">
      <label for="filtroCap1">Capitulo 1</label>
    </div>
    <!-- Agrega más filtros aquí si es necesario -->
</div>