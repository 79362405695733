import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef, GridReadyEvent, RowNode, FilterChangedEvent, IRowNode } from 'ag-grid-community';
import { Constantes } from '../../../_services/constantes.service';
import { RoutingService } from '../../../_services/routing.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { MatDialog } from '@angular/material/dialog';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

@Component({
  selector: 'app-justificante-apl',
  templateUrl: './justificante-apl.component.html',
  styleUrls: ['./justificante-apl.component.css']
})
export class JustificanteAplComponent implements OnInit {
  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  typeRow: any[] = [];
  selectedRowData: any;
  aplicacion: boolean = false;

  columnDefsUsuario: ColDef[] = [
    {
      field: 'concepto',
      headerName: 'Descripción',
      width: 500
    },
    { field: 'importe',
      headerName: 'Importe',
      valueFormatter: (params) => this.formatNumber(params.value),
      cellStyle:{
        textAlign: 'right'
      }
    },
    { field: 'nif_tercero',
      headerName: 'NIF/CIF',
      cellStyle: {
        textAlign: 'center'
      },
    },
    { field: 'tercero', headerName: 'Tercero' },
  ];
  
  

  constructor(private http: HttpClient, private router: Router, private authService: AuthService,  private dialog: MatDialog) { }
  
  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('aplicacionPresupuestariaSeleccionada') != null) {
            this.aplicacion = true;
            this.selectedRowData = JSON.parse(localStorage.getItem('aplicacionPresupuestariaSeleccionada') || '{}');
            console.log(this.selectedRowData);
            if (this.selectedRowData != null) {
              const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
              this.authService.GetListaJustificantesAplicacion(this.selectedRowData.apl_eje, this.selectedRowData.apl_fun, this.selectedRowData.apl_eco, headers).subscribe({
                next: data => {
                  this.typeRow = data;
                },
                error: err => {
                  console.log(err);
                  this.showErrorModal(err);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
              });
              

            } else {
              this.openModal('selectedRowData viene vacío');
              localStorage.clear(); 
              this.router.navigate(['/login']);
            }
          }
        }
      }
    }
  }
  applySearchFilter() {
    this.agGrid.api.setGridOption('quickFilterText', this.terminoBusqueda);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}
}
