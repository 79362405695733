<div id="escritorio">
   
    <div class="container" *ngIf="modulosMostrados && templateMostrados">
        <div class="subcontainer">
          <h2 class="h2T">Editar Permiso</h2>
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="labelT" for="nombre">Nombre de Permiso:</label>
                <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="nombrePer" required>
              </div>
              <div class="form-group">
                <label for="idTemplate"> Template al que se asocia el permiso:&emsp;</label>
                <select [(ngModel)]="templateChecked" name="seleccionTemplate" id="seleccionModulo">
                  <option *ngFor="let templ of listaTemplates" [value]="templ.idTemplate">
                    {{ templ.nombreTemplate }}
                  </option>
                </select>
              </div>
              <br>
              <div class="form-group">
                <label for="idModulo"> Modulo al que se asocia el permiso:&emsp;</label>
                <select [(ngModel)]="moduloChecked" name="seleccionModulo" id="seleccionModulo">
                  <option *ngFor="let modulo of listaModulos" [value]="modulo.idModulo">
                    {{ modulo.nombreModulo }}
                  </option>
                </select>
              </div>
              <br>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button type="submit" routerLink="/dashboard" class="btn btn-warning">Cancelar </button>
        </form>
        </div>
      </div>
      
    <app-taskbar></app-taskbar>
</div>