import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { JwtService } from '../../_services/jwt.service';
import { Constantes } from '../../_services/constantes.service';
@Component({
  selector: 'app-add-usuario',
  templateUrl: './add-usuario.component.html',
  styleUrl: './add-usuario.component.css'
})
export class AddUsuarioComponent {
  nombreUsu: string = "nombreUsu";
  apellidoUsu: string = "apellidoUsu";
  nombreCompleto: string = "";
  isAdmin: boolean = false;
  tokenJWT: string = "false";
  userData: any[] = [];
  selectedOption: string = ""; // Property to store the selected option
  formData = {
    username: '',
    password: '',
    nombre: '',
    apellidos: '',
    email: ''
  };
  permisosTotales: any[] = []; //La lista entera de permisos
  checkedPermissions: { [key: string]: boolean } = {}; // Object to store checked permissions 
  listaGrupos: any[] = []; //La lista entera de permisos
  checkedGrupos: { [key: string]: boolean } = {}; // Object to store checked permissions 

  listaModulos: any[] = []; //Lista de Modulo  con (IdModulo, nombreModulo)
  mapaPermisosModulos = new Map<string, any[]>(); //Para agrupar por idModulo la lista de permisos _(cada permiso es (IdPermiso,Nombre))
  permisosMostrados = false;
  message = '';
  idUsuarioActual: string = "";
  constructor(private router: Router, private jwtService: JwtService, private http: HttpClient, private authService: AuthService, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              if (data) { //Is Admin
                //CON EMAIL COJO ISUDUARIO
                this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                  next: data => {
                    this.idUsuarioActual = data.idUsuario;
                    this.http.get<any>(Constantes.GET_GRUPOS, { headers: headers }).subscribe({
                      next: listGr => {
                        this.listaGrupos = listGr;
                      },
                      error: err => {
                        localStorage.clear();
                        this.showErrorModal(err);
                        this.router.navigate(['/login']);
                      },
                    });
                  },
                  error: err => {
                    localStorage.clear();
                    this.showErrorModal(err);
                    this.router.navigate(['/login']);
                  },
                });
              } else { //Is not Admin
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear();
                this.openModal('No tienes permisos para acceder a esta pantalla');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear();
              this.openModal('Error');
              this.router.navigate(['/login']);
            }
          });
          this.http.get<any>(Constantes.GETMODULOSDB, { headers: headers }).subscribe({
            next: data => {
              if (data) { //Se obtienen datos
                this.listaModulos = data;

                this.listaModulos.forEach(modulo => {
                  let idModulo = modulo.idModulo;
                  const cadenat = Constantes.GET_PERMISO_W_MODULO + `${idModulo}`;

                  //Por cada modulo obtenemos su lista de permisos
                  this.http.get<any>(cadenat, { headers: headers }).subscribe({
                    next: data => {
                      this.mapaPermisosModulos.set(idModulo, data);
                      if (data.length != 0) {
                        this.permisosTotales.push(data); //introducimos los permisos de cada modulo que si tiene en el vector de permisos totales
                      }

                    },
                    error: err => {
                      localStorage.clear();
                      this.showErrorModal(err);
                      this.router.navigate(['/login']);
                    },

                  });
                });
                this.permisosMostrados = true;
              } else { //No se obtienen datos
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear();
                this.openModal('No se obtuvieron datos');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear();
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }
          });
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          // console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.router.navigate(['/login']);
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }


  onSubmit() {
    // You can add further logic here, such as sending the form data to a server
    var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
    console.log("token: ", this.tokenJWT);
    const headers = new HttpHeaders(strHead);
    var username = this.formData.username;
    var pass = this.formData.password;
    var name = this.formData.nombre;
    var ape = this.formData.apellidos;
    var correo = this.formData.email;
    if (this.selectedOption == "true") {
      this.isAdmin = true;
    } else { //por si es null o undefined
      this.isAdmin = false;
    }
    let addFirebase = "";
    this.jwtService.registerJwt(correo, pass).then(result => { //Primero lo registra en Firebase
      addFirebase = result;// This will be either the user ID or the error message
      if (addFirebase.startsWith("ERROR_")) { //El ADD tuvo error
        localStorage.clear();
        this.openModal(addFirebase);
        this.router.navigate(['/login']);
      } else {
        this.authService.register(username, correo, pass, name, ape, this.isAdmin, headers).subscribe({
          next: data => {
            const idUsuario = data.idUsuario;
            //Llamoamos a auditoria
            this.authService.realizar_Auditoria(data, this.isAdmin, 'Usuario', 'INSERT', this.idUsuarioActual, headers).subscribe({
              next: data => {
                //Auditoria Realizada.
                if (this.checkedPermissions) {
                  //Añadimos a usuarioPermiso
                  for (const [idPermiso, isChecked] of Object.entries(this.checkedPermissions)) {
                    if (isChecked) {
                      this.authService.register_UsuPer(idUsuario, idPermiso, headers).subscribe({
                        next: datoUP => {
                          //Una vez tenemos el dato, lo introducimos en auditoria
                          this.authService.realizar_Auditoria(datoUP, this.isAdmin, 'Usuario_Permiso', 'INSERT', this.idUsuarioActual, headers).subscribe({
                            next: data => {
                              //Auditoria Realizada.
                              //Hacemos ahora Usuario_Grupo y su auditoria

                            },
                            error: err => {
                              localStorage.clear();
                              this.showErrorModal(err);
                              this.router.navigate(['/login']);
                            },

                          });
                        },
                        error: err => {
                          console.error('Error : ', err);

                          localStorage.clear();
                          this.showErrorModal(err);
                          this.router.navigate(['/login']);
                        }
                      });
                    }
                  }
                }
                if (this.checkedGrupos) {
                  for (const [idGrupo, isChecked] of Object.entries(this.checkedGrupos)) {
                    if (isChecked) {
                      this.authService.register_UsuGru(idUsuario, idGrupo, headers).subscribe({
                        next: datoUG => {
                          //Una vez tenemos el dato, lo introducimos en auditoria
                          this.authService.realizar_Auditoria(datoUG, this.isAdmin, 'Usuario_Grupo', 'INSERT', this.idUsuarioActual, headers).subscribe({
                            next: data => {
                              //Auditoria Realizada.
                            },
                            error: err => {
                              localStorage.clear();
                              this.showErrorModal(err);
                              this.router.navigate(['/login']);
                            },

                          });
                        },
                        error: err => {
                          console.error('Error : ', err);
                          localStorage.clear();
                          this.showErrorModal(err);
                          this.router.navigate(['/login']);
                        }
                      });
                    }
                  }
                }
                this.router.navigate(['/dashboard']);
              },
              error: err => {
                localStorage.clear();
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              },

            });



          },
          error: err => {
            console.error('El token JWT no está presente en el localStorage');
            localStorage.clear();
            this.showErrorModal(err);
            this.router.navigate(['/login']);
          }
        });
      }
    }); //Registramos en el firebase



  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    this.message = errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  /*
  showSelectedPermissions() {
   console.log('Checked Permissions:', this.checkedPermissions); // Log the current state of checkedPermissions
   const selectedPermissions = Object.entries(this.checkedPermissions)
     .filter(([idPermiso, isChecked]) => isChecked)
     .map(([idPermiso, isChecked]) => idPermiso);
   
   console.log('Selected Permissions:', selectedPermissions);
 }
 */

  onCheckboxChange(event: any, permiso: any) {
    const isChecked = event.target.checked;
    this.checkedPermissions[permiso.idPermiso] = isChecked; // Update checkedPermissions object
    console.log("Check ", permiso.idPermiso, " ?: ", this.checkedPermissions[permiso.idPermiso]);
  }

  onCheckboxChangeGr(event: any, grupo: any) {
    const isChecked = event.target.checked;
    this.checkedGrupos[grupo.idGrupo] = isChecked; // Update checkedPermissions object
    console.log("Check ", grupo.idGrupo, " ?: ", this.checkedGrupos[grupo.idGrupo]);
  }
}
