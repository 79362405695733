<div id="escritorio">
   
    <div class="container">
        <div class="subcontainer">
          <h2 class="h2T" >Añadir Template</h2>
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="nombre">Nombre de Template:</label>
                <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="formData.nombre" required>
              </div>
          <br>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button type="submit" routerLink="/dashboard" class="btn btn-warning">Cancelar </button>
        </form>
        
        </div>
      </div>
      
    <app-taskbar></app-taskbar>
</div>