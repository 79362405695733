<div id="escritorio">
    <div class="container">
        <div class="subcontainer">
            <div class="subsubcontainer" >
                <br>
                <h1 class="h2T">Seleccione 3 módulos para acceso directo</h1>
                    <ul *ngFor="let modulo of listaModulos">
                        <label>
                            <input type="checkbox" [value]="modulo.idModulo" [checked]="checkedModulos[modulo.idModulo]" (change)="onCheckboxChange($event, modulo)">
                            {{ modulo.nombreModulo }}
                        </label>
                        
                    </ul>
                  
            </div>
            <button type="submit" (click)="onSubmit()" class="btn btn-primary botonAddAD">Guardar</button>
        </div>
    </div>
    
    </div>
    