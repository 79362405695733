import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridOptions, SideBarDef } from 'ag-grid-community';
import { RoutingService } from '../../../_services/routing.service';
import { ButtonsActionsPropuestaComponent } from '../buttons-actions-propuesta/buttons-actions-propuesta.component';
import { Constantes } from '../../../_services/constantes.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { ModalMuestrapropuestaComponent } from '../modal-muestrapropuesta/modal-muestrapropuesta.component';
import { CustomFilterPanelPropuestaComponent } from '../../AgGridCustom/custom-filter-panel-propuesta/custom-filter-panel-propuesta.component';
import { CustomLeyendaPropuestaComponent } from '../../AgGridCustom/custom-leyenda-propuesta/custom-leyenda-propuesta.component';
import { CustomFilterPanelTodasPropuestasComponent } from '../../AgGridCustom/custom-filter-panel-todas-propuestas/custom-filter-panel-todas-propuestas.component';
@Component({
  selector: 'app-todas-propuestas',
  templateUrl: './todas-propuestas.component.html',
  styleUrl: './todas-propuestas.component.css'
})
export class TodasPropuestasComponent {

  @ViewChild(AgGridAngular, { static: false }) agGrid!: AgGridAngular;

  tokenJWT: string = "";
  terminoBusqueda: string = "";
  typeRow: any[] = [];
  selectedRowData: any;
  aplicacion: boolean = false;
  aplicacionCod: string = "";
  tienePermisosVista= false;

  columnDefsUsuario: ColDef[] = [];

  sideBar: SideBarDef = {}
  gridOptions: GridOptions = {
    //groupDefaultExpanded: 1,
    //rowGroupPanelShow: 'always',
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false,
    context: {
      componentParent: this,
    },
  }

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private redirige: RoutingService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });

          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: data => {
              var idUsuario=data.idUsuario;

              var cadenaGet = Constantes.GET_PERMISO_W_USUARIO + `${idUsuario}`;
              this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
                next: dataN=>{
                  var encontrado= false;

                  var cadenaGetP = Constantes.GET_PERMISO_BY_NOMBREPERMISO + Constantes.VER_ACCIONES_PROPUESTA;
                    this.http.get<any>(cadenaGetP, { headers: headers }).subscribe({
                      next: dataPerm=>{
                        

                        this.http.get<any>(Constantes.GETPROPUESTAS, { headers: headers }).subscribe({
                          next: data => {

                            for (let i = 0; i < dataN.length && !encontrado; i++) {
                              if(dataN[i].idPermiso==dataPerm.idPermiso){ //Hemos encontrado el permiso
                                this.tienePermisosVista=true;
                              }
                              
                            }
                            
                            this.columnDefsUsuario= [
                              {
                                field: 'estadoPropuesta',
                                headerName: 'Estado',
                                filter: 'agTextColumnFilter',
                                width: 70,
                                filterParams: {      
                                  defaultOption: 'equals',
                                  maxNumConditions: 5,
                                },
                                cellRenderer: (params: any) => {
                                  const div = document.createElement('div');  // Creamos un div para el círculo
                                  div.className = 'estado-circle';  // Aplicamos una clase CSS para el estilo del círculo
                                  // Definimos el color del círculo basado en el valor de estadoPropuesta
                                  switch (params.value) {
                                    case 0:
                                      div.style.backgroundColor = '#F7F7F7'; // Sin Firmar
                                      break;
                                    case 1:
                                      div.style.backgroundColor = '#F6E962'; // Firmada
                                      break;
                                    case 2:
                                      div.style.backgroundColor = '#84D2A5'; // Contabilizada
                                      break;
                                    case 3:
                                      div.style.backgroundColor = '#D55455'; // Anulado
                                      break;
                                    case 4:
                                      div.style.backgroundColor = '#BED3F2'; // Sin Finalizar
                                      break;
                                    default:
                                      div.innerText = params.value;  // Si no coincide con ningún caso, mostramos el valor
                                  }
                              
                                  // Ajustes de estilo para el círculo
                                  div.style.width = '20px';
                                  div.style.height = '20px';
                                  div.style.borderRadius = '50%';  // Para que sea un círculo
                                  div.style.border = '1pt solid #000';  // Borde del círculo
                                  div.style.margin = 'auto';  // Centrar el círculo en la celda
                              
                                  return div;  // Devolvemos el div como el contenido de la celda
                                },
                                autoHeight: true,  // Asegura que la altura de la fila se ajuste automáticamente
                                cellStyle: {
                                  'display': 'flex',  // Utilizamos flexbox para centrar el contenido
                                  'justify-content': 'center',  // Centrar horizontalmente
                                  'align-items': 'center',  // Centrar verticalmente
                                },
                              },
                              {
                                field: 'idPropuesta',
                                headerName: 'Codigo',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'startsWith',
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onCodigoClicked(params.data); // Trigger action with row data
                                }
                              },
                              { field: 'objeto',
                                headerName: 'Propuesta',
                                width: 400,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                                filterParams: {
                                  defaultOption: 'contains',
                                },
                                cellRenderer: (params: any) => {
                                  // Add a clickable span or button
                                  return `<span class="clickable-code">${params.value}</span>`;
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onPropuestaObjetoClicked(params.data); // Trigger action with row data
                                  
                                }
                               },
                              { field: 'ejercicio',
                                headerName: 'Ejercicio',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'equals',
                                },// No need to specify a field here
                                cellRenderer: (params: any) => {
                                  // Add a clickable span or button
                                  return `<span class="clickable-code">${params.value}</span>`;
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onAplicClicked(params.data); // Trigger action with row data
                                }
                              },
                              {
                                field: 'codProg',
                                headerName: 'Cod. Prog.',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'startsWith',
                                },// No need to specify a field here
                                cellRenderer: (params: any) => {
                                  // Add a clickable span or button
                                  return `<span class="clickable-code">${params.value}</span>`;
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onAplicClicked(params.data); // Trigger action with row data
                                }
                              },
                              { field: 'codEco',
                                headerName: 'Cod. Eco.',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  maxNumConditions: 9,
                                  defaultOption: 'startsWith',
                                },
                                cellRenderer: (params: any) => {
                                  // Add a clickable span or button
                                  return `<span class="clickable-code">${params.value}</span>`;
                                },
                                onCellClicked: (params: any) => {
                                  // Call your method, e.g., this.onCodigoClicked(params.data)
                                  this.onAplicClicked(params.data); // Trigger action with row data
                                }
                              },
                              { field: 'importe',
                                headerName: 'Importe',
                                valueFormatter: (params) => this.formatNumber(params.value),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'startsWith',
                                } 
                              },
                              { field: 'Acciones',
                                cellRenderer: ButtonsActionsPropuestaComponent,
                                cellRendererParams: {componentName: 'Propuestas'},
                                width: 280,
                                hide: !this.tienePermisosVista
                              },
                              { 
                                field: 'idContabilidad',
                                headerName: 'Contabilidad',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                filterParams: {
                                  defaultOption: 'equals',
                                },
                                hide: true
                              }
                            ];

                            this.typeRow = data;
                            this.typeRow.sort((a, b) => b.idPropuesta - a.idPropuesta);
              
                            this.sideBar = {
                              toolPanels: [
                                {
                                  id: 'customToolPanel',
                                  labelDefault: 'Filtros',
                                  labelKey: 'customFilters',
                                  iconKey: 'menu',
                                  toolPanel: CustomFilterPanelTodasPropuestasComponent,  // Panel personalizado que vamos a crear
                                },
                                {
                                  id: 'leyenda',
                                  labelDefault: 'Leyenda',
                                  labelKey: 'customFilters',
                                  iconKey: 'eye',
                                  toolPanel: CustomLeyendaPropuestaComponent,  // Panel personalizado que vamos a crear
                                },
                              ],
                              defaultToolPanel: 'customToolPanelProp',
                            }
                          },
                          error: err => {
                            console.log(err);
                            this.showErrorModal(err);
                          }
                        });

                        
                      },
                      error: err=>{
                        // Redirigir al usuario a la página de inicio de sesión
                        this.showErrorModal(err);
                        this.router.navigate(['/gestionGasto/estadoEjecucion']);
                      }
                    });
                  
                  
                 
                },
                error: err=>{ 
                  // Redirigir al usuario a la página de inicio de sesión
                  this.showErrorModal(err);
                  this.router.navigate(['/gestionGasto/estadoEjecucion']);
                }
              });
                
            },
            error: err => {
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/gestionGasto/estadoEjecucion']);
            }
          });
        
        } else {
          // Redirigir al usuario a la página de inicio de sesión
          this.openModal("No existe JWT Token");
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    }
  }
  onCodigoClicked(rowData: any) {
    console.log('Código clicked for row:', rowData);
    this.sendAplPresupuestaria(rowData, "cod");
  }

  onAplicClicked(rowData: any) {
    console.log('Código clicked for row:', rowData);
    this.sendAplPresupuestaria(rowData, "apl");
  }


  onPropuestaObjetoClicked(rowData: any) {
    localStorage.setItem("idPropuestaVisualizacion", rowData.idPropuesta);
    const dialogRef = this.dialog.open(ModalMuestrapropuestaComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  sendAplPresupuestaria(rowData : any, llamada : string){
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.tokenJWT}` });
    var datosRow = rowData;
    this.authService
      .getAplicacionPresupuestaria(
        rowData.ejercicio,
        rowData.codProg,
        rowData.codEco,
        headers
      )
      .subscribe({

        next: data => {
          localStorage.setItem('aplicacionPresupuestariaSeleccionada', JSON.stringify(data));
          if(llamada=="apl"){
            var enlace = this.redirige.switchRoutes("Nueva Propuesta");
            this.router.navigate(['/',...enlace]);
          }else{
            if(llamada=="cod"){
              localStorage.setItem('justiPro', JSON.stringify(datosRow));
              var enlace = this.redirige.switchRoutes("JustificantePro");
              this.router.navigate(['/',...enlace]);
            }else{
              localStorage.setItem('aplicacionPresupuestariaSeleccionada',"");
            }
          }
        },
        error: err => {
          console.log(err);
          this.showErrorModal(err);
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  applySearchFilter() {
    throw new Error('Method not implemented.');
  }

  formatNumber(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }
    
    const number = typeof value === 'string' ? parseFloat(value) : value;

    // Formateamos el número como un string con dos decimales fijos
    const formattedNumber = number.toFixed(2);

    // Usamos una expresión regular para insertar puntos cada tres cifras en la parte entera
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${parts[0]},${parts[1]} €`; // Aseguramos el formato deseado, con coma para decimales y punto para miles
}
}
