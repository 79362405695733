<div id="escritorio">
   
    <div class="container" *ngIf="permisosMostrados">
      <div class="subcontainer">

        <h2 class="h2T">Añadir Usuario</h2>
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="labelT" for="firstName">Nombre de Usuario:</label>
                <input type="text" class="form-control" id="username" name="username" [(ngModel)]="formData.username" required>
              </div>
              <div class="form-group">
                <label class="labelT" for="firstName">Contraseña:</label>
                <input type="password" class="form-control" id="password" name="password" [(ngModel)]="formData.password" required>
              </div>
          <div class="form-group">
            <label class="labelT" for="firstName">Nombre:</label>
            <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="formData.nombre" required>
          </div>
          <div class="form-group">
            <label class="labelT" for="lastName">Apellidos:</label>
            <input type="text" class="form-control" id="apellidos" name="apellidos" [(ngModel)]="formData.apellidos" >
          </div>
          <div class="form-group">
            <label class="labelT" for="email">Email:</label>
            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="formData.email" required>
          </div>
          <div class="form-group">
            <label class="labelT" for="email"> Is Admin? </label>
            <select [(ngModel)]="selectedOption"  name="admin" id="admin">
              <option value="true">Yes</option>
              <option value="false" selected>No</option>
            </select>
          </div>
          <br>
          <h3 class="h2T">Permisos a añadir</h3>
          
          <ul *ngFor="let modulo of listaModulos">
            <h4 class="h2T">{{ modulo.nombreModulo }}</h4>
            <ul *ngIf="mapaPermisosModulos.get(modulo.idModulo)">
              <li *ngFor="let permiso of mapaPermisosModulos.get(modulo.idModulo)">
                <label>
                  <input type="checkbox" [value]="permiso.idPermiso" (change)="onCheckboxChange($event, permiso)">
                  {{ permiso.nombrePermiso }}
                </label>
              </li>
            </ul>
          </ul>
          <h3 class="h2T">Grupos a añadir</h3>
          
          <ul *ngFor="let grupo of listaGrupos">
            <label>
              <input type="checkbox" [value]="grupo.idGrupo" (change)="onCheckboxChangeGr($event, grupo)">
              {{ grupo.nombreGrupo }}
            </label>
          </ul>

        
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button type="submit" routerLink="/dashboard" class="btn btn-warning">Cancelar </button>
        </form>
      </div>
        
      </div>
      
    <app-taskbar></app-taskbar>
</div>