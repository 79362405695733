<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-bottom">
  <div class="container-fluid">
      <a (click)="toggleDropdown()" type="button">
          <img src="/assets/escudo.png" alt="Escudo" class="img-fluid">
      </a>
      <div  *ngIf="textoMantenimiento">
        <p class="modoMant" >Modo Mantenimiento</p> <!--Solo se informa si esta en mantenimiento cuando lo está-->
      </div>

      <a (click)="toggleDropdownProfile()" type="button">
          <img src="../../assets/usuario.png" width=100 class="img-fluid" alt="Perfil">
      </a>
      <div class="dropdown-menu" [ngClass]="{ 'show': isDropdownOpen }">
          <ul>
              <h5><b>Permisos Base</b></h5>
              <li class="textLi">
                  <a class="hoverInicio text-red" routerLink="/desktop"><img class="hoverInicioImg" alt="Inicio" src="../../assets/iconos/inicio.png" width="20" height="20"> <span class="icon-text">&nbsp;Inicio</span></a>
              </li>
              <!--<li class="textLi">
                  <a class="hoverInfo text-red" routerLink="/info"><img class="hoverInfoImg" alt="Info" src="../../assets/iconos/info.png" width="20" height="20">Datos del Centro</a>
              </li>-->
              @if(this.isAdmin){
              <li class="textLi">
                  <a class="hoverConfig text-red" routerLink="/dashboard"><img class="hoverConfigImg" alt="Config" src="../../assets/iconos/config.png" width="20" height="20">&nbsp;Configuracion</a>
              </li>
              }
              <li class="textLi">
                  <a class="hoverDoc text-red" routerLink="/documentation"><img class="hoverDocImg" alt="Doc" src="../../assets/iconos/documentacion.png" width="20" height="20">&nbsp;Documentacion</a>
              </li>
          </ul>

          <ul>
              <h5><b>Permisos Usuario</b></h5>
              <!--AQUI HABRA N PERMISOS, 4 ES SOLO UN NUMERO-->
           
              <li class="textLi">
                  <a class="hoverModuloN text-red" routerLink="/modulos"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">&nbsp;Modulos</a>
              </li>
              <li *ngIf="accesoDirectoUnoExist" >
                <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoUno.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">&nbsp;{{accesoDirectoUno.nombreModulo}}</a>
            </li> 
            <li *ngIf="accesoDirectoDosExist">
                <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoDos.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">&nbsp;{{accesoDirectoDos.nombreModulo}}</a>
            </li>
            <li *ngIf="accesoDirectoTresExist">
                <a class="hoverModuloN text-red" (click)="routeUrl(accesoDirectoTres.nombreModulo)"><img class="hoverModuloNImg" alt="Config" src="../../assets/iconos/modulo.png" width="20" height="20">&nbsp;{{accesoDirectoTres.nombreModulo}}</a>
            </li>
          </ul>

          <ul>
              <div (click)="darkMode()" id="darkmode"><img class="darkmodeImg" alt="ModoOscuro" src={{imgUrlSolLuna}} width="20" height="20"></div>
          </ul>
      </div>
     
      <div class="dropdown-profileimg" [ngClass]="{ 'show': isDropdownOpenProfile }" id="dropdown-profileimg">

          <ul>
              <li class="textLi"><a class="profileUser text-red" routerLink="/profile">&nbsp;Bienvenidx {{nombreUsu}} {{apellidoUsu}}</a></li>
              <li class="textLi"><a class="logOutClass text-red" (click)="logout()">&nbsp;Cerrar Sesion</a></li>
          </ul>

      </div>
  </div>

</nav>