<body>
    <app-botonera></app-botonera>
    <div class="escritorio">
        <div class="common-componentes">
            <div class="datos-propuestas">
                <h4>{{ selectedRowData.des_aplic }}, 
                    ({{ selectedRowData.apl_eje }}.{{ selectedRowData.apl_fun }}.{{ selectedRowData.apl_eco }})</h4>
                <div class="label-value">Definitivos: {{ selectedRowData.creditos_definitivos }} €</div>
                <div class="label-value">Gastados: {{ selectedRowData.credito_gastado }} €</div>
                <div class="label-value">Disponibles: {{ selectedRowData.credito_disponible }} €</div>
                <div class="label-value">Bolsa: {{ selectedRowData.disponible_bolsa }} €</div>
                <div class="label-value">Pagado: {{ selectedRowData.pagos_realizados }} €</div>
            </div>
        </div>
        <div class="titulo-label"><h1><b>Propuestas Asociadas</b></h1></div>
        <div id="grid-container" class="tab-content grid-container">
            <div class="new-row">
                <input type="text" id="searchInput" [(ngModel)]="terminoBusqueda" (keyup)="applySearchFilter()" class="form-control mb-2">
                <button class="nueva-prop-button" (click)="nuevaPropuesta()">Nueva Propuesta</button>
            </div>
            <ag-grid-angular
            class="ag-theme-quartz"
            [rowData]="typeRow"
            [columnDefs]="columnDefsPropuesta"
            [sideBar]="sideBar"
            [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
        <button class="button-grafica" (click)="verGrafico()">Ver gráfica</button>
        <div class="demo-container" [hidden]="isHidden">
            <h1>Grafica de propuesta con respecto al total</h1>
            <app-linear-gauge-propuesta
              [value]="this.currentValue"
              [min]="0"
              [max]="this.totalBolsa"
              [width]="1000"
              [height]="20"
            ></app-linear-gauge-propuesta>
          </div>
        <app-taskbar></app-taskbar>
    </div>
</body>