import { ChangeDetectorRef, Component } from '@angular/core';
import {  Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { RoutingService } from '../../_services/routing.service';
import { Constantes } from '../../_services/constantes.service';
@Component({
  selector: 'app-taskbar',
  templateUrl: './taskbar.component.html',
  styleUrl: './taskbar.component.css'
})
export class TaskbarComponent {

  private isDarkMode = false;
  isDropdownOpen: boolean = false; isDropdownOpenProfile: boolean = false;   
  nombreUsu : string = "nombreUsu";
  apellidoUsu : string = "apellidoUsu";
  imgUrlSolLuna : string ="../../assets/iconos/luna.png";
  jwtToken: string = '';
  isAdmin: any;
  textoMantenimiento=false;
  accesoDirectoUnoExist=false;
  accesoDirectoDosExist=false;
  accesoDirectoTresExist=false;
  accesoDirectoUno = {idModulo: "", nombreModulo: ""};
  accesoDirectoDos = {idModulo: "", nombreModulo: ""};
  accesoDirectoTres = {idModulo: "", nombreModulo: ""};
  
  constructor(private http: HttpClient, private router: Router,  private dialog: MatDialog, private cdr: ChangeDetectorRef, private redirige : RoutingService){
  }
  ngOnInit(): void {
    this.textoMantenimiento=false;
    // Recupera el token JWT del localStorage
    const token = localStorage.getItem('jwtToken');
    if (token !== null) {
      this.jwtToken = token;
    }
    // Si el token está presente, puedes usarlo en tus solicitudes HTTPS
    if (this.jwtToken) {
      var strHead = {'Authorization': `Bearer ${this.jwtToken}`};
      const headers = new HttpHeaders(strHead);

      this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
        next: data => {
          this.isAdmin = data;
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
        next: data => {
          this.apellidoUsu= data.apellidosUsuario;
          this.nombreUsu=data.nombreUsuario;
          var idUsuario= data.idUsuario;
          var accDirUn: any;
          var accDirDos : any;
          var accDirTres: any;

          var cadenaGeto = Constantes.GET_ACCESOS_DIRECTOS_BY_USU + `${idUsuario}`;
          this.http.get<any>(cadenaGeto, { headers: headers }).subscribe({
            next: dataN=>{
              switch(dataN.length){
                default:
                  case 0 :
                    break;
                  case 1:
                    accDirUn= dataN[0].idModulo;
                    break;
                  case 2:
                    accDirUn= dataN[0].idModulo;
                    accDirDos= dataN[1].idModulo;
                    break;
                  case 3:
                    accDirUn= dataN[0].idModulo;
                    accDirDos= dataN[1].idModulo;
                    accDirTres= dataN[2].idModulo;
                    break;
              }

                //Buscamos los accesos directos a cada modulo
          if(accDirUn != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID + `${accDirUn}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoUno=datoMod;
                this.accesoDirectoUnoExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }
          if(accDirDos != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID+ `${accDirDos}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoDos=datoMod;
                this.accesoDirectoDosExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }
          if(accDirTres != null){
            var cadenaGet = Constantes.GETMODULO_BY_ID+ `${accDirTres}`;
            this.http.get<any>(cadenaGet, { headers: headers }).subscribe({
              next: datoMod=>{
                this.accesoDirectoTres=datoMod;
                this.accesoDirectoTresExist=true;
              },
              error: err=>{
                localStorage.clear();
                // Redirigir al usuario a la página de inicio de sesión
                this.showErrorModal(err);
                this.router.navigate(['/login']);
              }

            });
          }

            },
            error: err=>{
              localStorage.clear();
              // Redirigir al usuario a la página de inicio de sesión
              this.showErrorModal(err);
              this.router.navigate(['/login']);
            }});

          
          //Buscamos los accesos directos a cada modulo
          
          if(data.estado == 2){
            if(this.isAdmin){
              this.textoMantenimiento=true; //Ponemos que estamos en modo mantenimiento y a otra cosa
            }else{
              localStorage.clear();
              this.router.navigate(['/mantenimiento']);
            }
          }
          //Si no esta en mantenimiento todo bien. Seguimos
        },
        error: err => {
            console.log(err);
            localStorage.clear();
            // Redirigir al usuario a la página de inicio de sesión
            this.showErrorModal(err);
            this.router.navigate(['/login']);
        }
      });
        },
        error: err => {
          localStorage.clear();
            // Redirigir al usuario a la página de inicio de sesión
            this.showErrorModal(err);
            this.router.navigate(['/login']);
        }
      });

      
    } else {
      // Maneja el caso donde el token JWT no está presente en el localStorage
      console.error('El token JWT no está presente en el localStorage');
      localStorage.clear();
      // Redirigir al usuario a la página de inicio de sesión
      this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
      this.router.navigate(['/login']);
    }
  }

  ngDoCheck() {
    this.darkMode();
  }

  gotoprofile(): void{
    this.router.navigate(['/profile']); 
  }

  darkMode(): void{
    // Get the image element
    var img = document.querySelector('.darkmodeImg') as HTMLImageElement;
    // Check the current source

    img.addEventListener('click', () => {
      img.src = 'new.jpg'; // Change 'new.jpg' to the path of your new image
      if (!this.isDarkMode) {
        // Change the image source when clicked
        this.imgUrlSolLuna = '../../assets/iconos/sol.png';
        img.src=this.imgUrlSolLuna;
    } else {
        // Change it back to the original source
        this.imgUrlSolLuna = '../../assets/iconos/luna.png';
        img.src=this.imgUrlSolLuna;
    }
    this.isDarkMode= !this.isDarkMode;
  });
    
    
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleDropdownProfile() {
    this.isDropdownOpenProfile = !this.isDropdownOpenProfile;
  }

  logout(){
    localStorage.clear();  
    // Redirigir al usuario a la página de inicio de sesión
    this.router.navigate(['/login']);
  }

  isHovered: boolean = false;

  // Otro código de tu componente

  onHover(): void {
    this.isHovered = true;
  }

  onLeave(): void {
    this.isHovered = false;
  }

  routeUrl(nombreMod: string) {
    const enlace = this.redirige.switchRoutes(nombreMod);
    this.router.navigate(['/', ...enlace]); // Spread the array into navigate
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}
