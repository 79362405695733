<app-botonera></app-botonera>
<div class="escritorio container-fluid">
  <div class="row g-0">
    <div class="col-md-3">
    </div>
    <div class="col-md-5"></div>
    <div class="col-md-4 datos-propuestas" *ngIf="aplicacion">
      <h4>{{ selectedRowData.des_aplic }},
        ({{ selectedRowData.apl_eje }}.{{ selectedRowData.apl_fun }}.{{ selectedRowData.apl_eco }})</h4>
      <div class="label-value">Definitivos: {{ selectedRowData.creditos_definitivos }} €</div>
      <div class="label-value">Gastados: {{ selectedRowData.credito_gastado }} €</div>
      <div class="label-value">Disponibles: {{ selectedRowData.credito_disponible }} €</div>
      <div class="label-value">Bolsa: {{ selectedRowData.disponible_bolsa }} €</div>
      <div class="label-value">Pagado: {{ selectedRowData.pagos_realizados }} €</div>
    </div>
    <div class="col-md-4 datos-propuestas" *ngIf="!aplicacion">
      <h4>No se ha asignado ninguna partida presupuestaria, por favor adjuntela después</h4>
    </div>
  </div>
  <div id="" class="">
    <div class="titulo-row">
      <h1><b>Nueva Propuesta</b></h1>
    </div>
    <div class="tabla-formulario">
      <form #form="ngForm" (ngSubmit)="onSubmit(form)" class="row g-3">
        <div class="col-md-4">
          <label for="proponente">Proponente*</label>
          <!-- app.component.html -->
          <button class="btn-primary" (click)="openDialog('proponente'); $event.preventDefault()">Seleccionar:</button>
          <span *ngIf="propuestaForm.proponente"> {{ propuestaForm.proponente }}, {{ propuestaForm.cargo }}</span>
          <div *ngIf="!submitted && !propuestaForm.proponente" class="text-danger">
            Proponente es requerido.
          </div>
        </div>
        <div class="col-md-4">
          <label for="responsable">Responsable*</label>
          <!-- app.component.html -->
          <button class="btn-primary" (click)="openDialog('responsable'); $event.preventDefault()">Seleccionar:</button>
          <span *ngIf="propuestaForm.responsable"> {{ propuestaForm.responsable }}, {{ propuestaForm.cargoResponsable }}</span>
          <div *ngIf="!submitted && !propuestaForm.responsable" class="text-danger">
            Responsable es requerido.
          </div>
        </div>
        <div class="col-md-2">
          <label for="fechaPropuesta">Fecha de la Propuesta*</label>
          <input type="date" id="fechaPropuesta" name="fechaPropuesta" class="form-control" [(ngModel)]="propuestaForm.fechaPropuesta" required>
          <div *ngIf="!submitted && form.controls['fechaPropuesta']?.invalid" class="text-danger">
            Fecha Propuesta es requerida.
          </div>
        </div>
        <div class="col-md-12">
          <label for="objeto">Objeto del contrato*</label>
          <textarea id="objeto" name="objeto" class="form-control" [(ngModel)]="propuestaForm.objeto" required></textarea>
          <div *ngIf="!submitted && form.controls['objeto']?.invalid" class="text-danger">
            Objeto del contrato es requerido.
          </div>
        </div>
        <div class="col-md-12">
          <label for="necesidad">Necesidad</label>
          <textarea id="necesidad" name="necesidad" class="form-control" [(ngModel)]="propuestaForm.necesidad"></textarea>
        </div>
        <div class="col-md-12">
          <label for="plazo">Plazo de entrega/duración y condiciones del contrato</label>
          <textarea id="plazo" name="plazo" class="form-control" [(ngModel)]="propuestaForm.plazo"></textarea>
        </div>
        <div class="col-md-4">
          <label for="importe">Importe*</label>
          <input type="text" id="importe" name="importe" class="form-control" [(ngModel)]="propuestaForm.importe" placeholder="0" required>
          <div *ngIf="!submitted && form.controls['importe']?.invalid" class="text-danger">
            Importe es requerido y debe ser un número válido.
          </div>
        </div>
        <div class="col-md-3">
          <label for="expediente">Expdt. Asociado</label>
          <input type="text" id="expediente" name="expediente" class="form-control" placeholder="XXXX/AAAA" [(ngModel)]="propuestaForm.expediente">
          <div *ngIf="!expdtComprobado" class="text-danger">
            El expediente debe de comprobarse si se introduce
          </div>
          <div *ngIf="expdtComprobado" class="text-danger">
            {{ nombreExpdt }}
          </div>
          <button class="btn-primary" (click)="comprobarExpdt(); $event.preventDefault()">Comprobar</button>
        </div>
        <div class="col-md-5">
          <button type="submit" class="btn btn-primary col-md-5">Crear Nueva Propuesta</button>
        </div>
      </form>
    </div>
  </div>
  <app-taskbar></app-taskbar>
  <div hidden>
    <ng-container *ngIf="calculatedValues">
      <app-generar-pdf-propuesta [data]="calculatedValues" (dataReady)="recepcionPDF($event)"></app-generar-pdf-propuesta>
    </ng-container>
  </div> 
</div>
