import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';
@Component({
  selector: 'app-add-permiso',
  templateUrl: './add-permiso.component.html',
  styleUrl: './add-permiso.component.css'
})
export class AddPermisoComponent {
  tokenJWT: string = "false";
  userData: any[] = [];
  selectedOption: string = ""; // Property to store the selected option
  nombrePer="";
  message = '';
  isAdmin = false;
  idUsuarioActual = "";
  listaModulos: any[] = [];
  listaTemplates: any[] = [];
  moduloChecked = "";
  templateChecked = "";
  modulosMostrados = false;
  templateMostrados = false;
  constructor(private router: Router, private http: HttpClient, private authService: AuthService, private dialog: MatDialog) {

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              if (data) { //Is Admin
                this.isAdmin = data;
                this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                  next: datoU => {
                    this.idUsuarioActual = datoU.idUsuario;
                    //Buscamos modulos
                    this.http.get<any>(Constantes.GETMODULOSVM, { headers: headers }).subscribe({
                      next: modList => {
                        this.listaModulos = modList;
                        this.modulosMostrados = true;
                        //Buscamos grupos
                        this.http.get<any>(Constantes.GET_TEMPLATES, { headers: headers }).subscribe({
                          next: templaList => {
                            this.listaTemplates = templaList;
                            this.templateMostrados = true;
                          },
                          error: err => {
                            console.error('Error: ', err);
                            this.showErrorModal(err);
                            localStorage.clear();
                            this.router.navigate(['/login']);
                          }
                        });
                      },
                      error: err => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        localStorage.clear();
                        this.router.navigate(['/login']);
                      }
                    });


                  },
                  error: err => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear();
                    this.router.navigate(['/login']);
                  }
                });
              } else { //Is not Admin
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear();
                this.openModal('No tienes permisos para acceder a esta pantalla');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear();
              this.openModal('Error');
              this.router.navigate(['/login']);
            }
          });

        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          //console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        this.router.navigate(['/login']);
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }


  onSubmit() {
    // You can add further logic here, such as sending the form data to a server
    var strHead = { 'Authorization': `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var nombre = this.nombrePer;
    var idMod = this.moduloChecked;
    var idTempla = this.templateChecked;

    this.authService.register_Permiso(nombre, idMod, idTempla, headers).subscribe({
      next: data => {

        this.authService.realizar_Auditoria(data, this.isAdmin, 'Permiso', 'INSERT', this.idUsuarioActual, headers).subscribe({
          next: data => {
            this.router.navigate(['/dashboard']);
          },
          error: err => {
            console.error('Error: ', err);
            this.showErrorModal(err);
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        });
      },
      error: err => {
        console.error('Error: ', err);
        this.showErrorModal(err);
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    this.message = errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
