import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';
@Component({
  selector: 'app-add-modulo',
  templateUrl: './add-modulo.component.html',
  styleUrl: './add-modulo.component.css'
})
export class AddModuloComponent {
  tokenJWT: string = "false";
  userData: any[] = [];
  selectedOption: string=""; // Property to store the selected option
  formData = {
    nombre: '',
    urlImg: ''
  };
  message= '';
  isAdmin = false;
  idUsuarioActual = ""
  constructor(private router: Router, private http: HttpClient, private authService: AuthService,  private dialog: MatDialog){

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto' ) {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              if(data){ //Is Admin
                this.isAdmin=data;
                this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                  next: datoU => {
                   this.idUsuarioActual = datoU.idUsuario;
                    
                  },
                  error: err => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    localStorage.clear();
                    this.router.navigate(['/login']); 
                  }
                });
              }else{ //Is not Admin
                //console.error('No tienes permisos para acceder a esta pantalla');
                localStorage.clear();localStorage.clear();
                this.openModal('No tienes permisos para acceder a esta pantalla');
                this.router.navigate(['/login']);
              }
            },
            error: err => {
              //console.error('Error');
              localStorage.clear();
              this.openModal('Error');
              this.router.navigate(['/login']);
            }
          });
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          //console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        //console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.'); 
        this.router.navigate(['/login']); 
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }

    
  onSubmit() {
    console.log('Form submitted with data:', this.formData);
    // You can add further logic here, such as sending the form data to a server
    var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
    const headers = new HttpHeaders(strHead);
    var nombre = this.formData.nombre;
    var urlImagen = this.formData.urlImg;
    
    this.authService.register_Modulo(nombre, urlImagen, headers).subscribe({
      next: data => {
        console.log(data);
        const idModuloGenerado=data.idModulo;
        this.authService.realizar_Auditoria(data, this.isAdmin,'Modulo','INSERT', this.idUsuarioActual, headers).subscribe({
          next: data => {
            console.log(data);
            this.http.get<any>(Constantes.GET_GRUPOS,{headers: headers}).subscribe({
              next: datosGr => {
                for(var i=0; i<datosGr.length;i++){
                  this.authService.register_ModGru(idModuloGenerado,datosGr[i].idGrupo, headers).subscribe({
                    
                    next: datosRegistro => { //Para el modulo se añade cada Grupo existente en el momento de la operacion
                      this.authService.realizar_Auditoria(datosRegistro,this.isAdmin,'Modulo_Grupo','INSERT', this.idUsuarioActual, headers).subscribe({
                        next: datosAudit => {
                        },
                        error: err => {
                          console.error('Error: ', err);
                          this.showErrorModal(err);
                          localStorage.clear();
                          this.router.navigate(['/login']); 
                        }
                      });
                    },
                    error: err => {
                      console.error('Error: ', err);
                      this.showErrorModal(err);
                      localStorage.clear(); 
                      this.router.navigate(['/login']); 
                    }
                  });
                }
                //Ahora añadimos todos los grupos al modulo
                this.router.navigate(['/dashboard']);
              },
              error: err => {
                console.error('Error: ', err);
                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/login']); 
              }
            });
          },
          error: err => {
            console.error('Error: ', err);
            this.showErrorModal(err);
            localStorage.clear();
            this.router.navigate(['/login']); 
          }
        });
      },
      error: err => {
        console.error('Error: ', err);
        this.showErrorModal(err);
        localStorage.clear(); 
        this.router.navigate(['/login']); 
      }
    });
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    this.message=errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
}
