<div id="escritorio">
    <h1 class="h2T">Configuración General</h1>
   
  
    <ul class="nav nav-tabs tab-container">
        <li class="nav-item">
            <button type="button" class="btn btn-secondary" (click)="switchTab('users')">Usuarios</button>
        </li>
        <li class="nav-item">
            <button type="button" class="btn btn-secondary" (click)="switchTab('modulos')">Módulos</button>
        </li>
        <li class="nav-item">
            <button  type="button" class="btn btn-secondary" (click)="switchTab('permisos')">Permisos</button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-secondary" (click)="switchTab('templates')">Templates</button>
      </li>
          <li class="nav-item">
            <button type="button" class="btn btn-secondary" (click)="switchTab('grupos')">Grupos</button>
        </li>
        <li class="nav-item">
            <button type="button" class="btn btn-secondary" (click)="switchTab('configGen')">Config. General</button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-secondary" (click)="switchTab('general')">General</button>
      </li>

    </ul>
    
    <div class="tab-content grid-container">
        <div *ngIf="activeTab === 'users'">
            <ag-grid-angular *ngIf="usersDataLoaded"  [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for users data -->
            </ag-grid-angular>
            <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>
          </div>
          
          <div *ngIf="activeTab === 'modulos'">
            <ag-grid-angular *ngIf="modulesDataLoaded"  [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for modulos data -->
            </ag-grid-angular>
            <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>
          </div>
          
          <div *ngIf="activeTab === 'permisos'">
            <ag-grid-angular *ngIf="permDataLoaded" [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for permisos data -->
            </ag-grid-angular>
            <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>
          </div>

          <div *ngIf="activeTab === 'templates'">
            <ag-grid-angular *ngIf="templDataLoaded" [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for permisos data -->
            </ag-grid-angular>
            <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>
          </div>

          <div *ngIf="activeTab === 'grupos'">
            <ag-grid-angular *ngIf="grupoDataLoaded" [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for permisos data -->
            </ag-grid-angular>
            <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>
          </div>

          <div *ngIf="activeTab === 'configGen'">
            <ag-grid-angular *ngIf="configGenDataLoaded" [rowData]="typeRow" [columnDefs]="typeColumn" (rowDoubleClicked)="onRowDoubleClicked($event)" class="ag-theme-quartz"
            style="height: 50vh;"> <!-- Configure ag-Grid for permisos data -->
            </ag-grid-angular>
           <!-- <button type="button" class="btn btn-secondary" (click)="addRow()" class="btn btn-primary">Añadir </button>-->
          </div>
          
          
          <div *ngIf="activeTab === 'general'">
            Modo Mantenimiento:
            <select [(ngModel)]="mantenimiento"  name="admin" id="admin">
              <option value="true">Yes</option>
              <option value="false" selected>No</option>
            </select>
            <br>
            <button type="button" class="btn btn-secondary" (click)="saveAdmin()" class="btn btn-primary">Guardar </button>
            <br>
            <br>
          </div>
    </div>
   
    <app-taskbar></app-taskbar>
</div>
