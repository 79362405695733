import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { Constantes } from '../../../_services/constantes.service';
import { NgForm } from '@angular/forms'; // Importa NgForm
import { ModalBuscadorComponent } from '../modal-buscador/modal-buscador.component';
import { ModalContratoObraComponent } from '../modal-contrato-obra/modal-contrato-obra.component';
import { ModalBuscaAplpresupuestariaComponent } from '../modal-busca-aplpresupuestaria/modal-busca-aplpresupuestaria.component';

@Component({
  selector: 'app-modificar-propuesta',
  templateUrl: './modificar-propuesta.component.html',
  styleUrl: './modificar-propuesta.component.css'
})
export class ModificarPropuestaComponent {
  submitted = false;
  idProponente: string = '';
  contabilidad: number = 0;
  propuestaForm: {
    codApliPresupuestaria: string,
    proponente: string,
    responsable: string,
    cargo: string,
    cargoResponsable: string,
    expediente: string,
    fechaPropuesta: Date | null,
    objeto: string,
    necesidad: string,
    plazo: string,
    importe: number,
    contratoDeObra: boolean
  } = {
      codApliPresupuestaria: '',
      proponente: '',
      responsable: '',
      cargo: '',
      cargoResponsable: '',
      expediente: '',
      fechaPropuesta: new Date(),
      objeto: '',
      necesidad: '',
      plazo: '',
      importe: 0,
      contratoDeObra: false,
    };
  tokenJWT: string = '';
  selectedRowData: any;
  aplicacion: boolean = false;
  usuControl: string = '';
  ejercicio: number = 0;
  codProg: string = '';
  codEco: string = '';
  dniProp: string = '';
  boolIdGes: boolean = false;
  idPropuesta: string = "";
  calculatedValues: any; //Para traspasar datos
  pdfRecibido: any; //El pdf obtenido de la generacion de PDF
  idUsuarioActual = "";
  hrefSolicitado = "";
  contratoObraCB: boolean = false;
  idExpdtAsoc: string = "";
  nombreExpdt: string = "";
  expdtComprobado: boolean = false;
  expedienteGestiona: string = "";
  desc_aplic: string = "";
  fechaControl = new Date();
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('propuestaPresupuestariaModificacion') != null) {
            this.aplicacion = true;
            this.selectedRowData = JSON.parse(
              localStorage.getItem('propuestaPresupuestariaModificacion') || '{}'
            );
            if (this.selectedRowData != null) {
              this.ejercicio = parseInt(this.selectedRowData.ejercicio, 10);
              this.codProg = this.selectedRowData.codProg;
              this.codEco = this.selectedRowData.codEco;
              //Asociamos las variables para que se escriban en la interfaz
              this.propuestaForm.cargo = this.selectedRowData.cargo;
              this.propuestaForm.proponente = this.selectedRowData.proponente;
              this.propuestaForm.responsable = this.selectedRowData.responsable;
              this.propuestaForm.cargoResponsable = this.selectedRowData.cargoResponsable;
              this.propuestaForm.expediente = this.selectedRowData.expdtAsoc;
              this.propuestaForm.fechaPropuesta = new Date(this.selectedRowData.fechaPropuesta);
              this.propuestaForm.objeto = this.selectedRowData.objeto;
              this.propuestaForm.necesidad = this.selectedRowData.necesidad;
              this.propuestaForm.plazo = this.selectedRowData.plazo;
              this.propuestaForm.importe = this.selectedRowData.importe;
              this.idPropuesta = this.selectedRowData.idPropuesta;
              this.dniProp = this.selectedRowData.dniProponente;
              this.fechaControl = this.selectedRowData.fechaControl;
            } else {
              this.ejercicio = 0;
              this.codProg = '';
              this.codEco = '';
            }
            var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
            const headers = new HttpHeaders(strHead);
            this.http
              .get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers })
              .subscribe({
                next: (data) => {
                  this.usuControl = `${data.nombreUsuario} ${data.apellidosUsuario}`;
                  this.idUsuarioActual = data.idUsuario;
                },
                error: (err) => {
                  console.error(err);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                },
              });
          } else {
            this.aplicacion = false;
            localStorage.removeItem('propuestaPresupuestariaModificacion');
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  // Getter to format the Date object as a string for the input field
  get formattedFechaPropuesta(): string | undefined {
    return this.propuestaForm.fechaPropuesta?.toISOString().split('T')[0];
  }

  // Setter to convert the input string back into a Date object
  set formattedFechaPropuesta(value: string) {
    this.propuestaForm.fechaPropuesta = new Date(value);
  }

  onSubmit(form: NgForm) {
    // Añade form como argumento
    this.submitted = true;


    if (this.propuestaForm.expediente === "" && !this.expdtComprobado) {
      this.expdtComprobado = true;
    }
    if (
      !this.propuestaForm.proponente ||
      form.controls['fechaPropuesta']?.invalid ||
      !this.propuestaForm.objeto ||
      form.controls['importe']?.invalid ||
      !this.expdtComprobado
    ) {
      return;
    }

    if (this.propuestaForm.expediente == "") { //Si no hay expediente asociado puede hacer falta codigo de expediente
      const dialogRef = this.dialog.open(ModalContratoObraComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) { //SI
          this.contratoObraCB = true;

        } else { //NO
          this.contratoObraCB = false;
        }
        this.creaPropuesta(form);
      });
    } else {
      this.creaPropuesta(form); //Si hay expediente asociado nos da igual que contrato de obra sea true o false porque no lo vamos a usar
    }

  }

  creaPropuesta(form: NgForm) {

    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);

    const contabilidad = localStorage.getItem('contabilidad') || '0';
    this.contabilidad = parseInt(contabilidad, 10); // Convertir a número

    var proponente = this.propuestaForm.proponente;
    var responsable = this.propuestaForm.responsable;
    var cargo = this.propuestaForm.cargoResponsable;
    var cargoResponsable = this.propuestaForm.cargo;
    var objeto = this.propuestaForm.objeto;
    var necesidad = this.propuestaForm.necesidad;
    var plazo = this.propuestaForm.plazo;
    var importe = parseFloat(this.propuestaForm.importe.toString().replace(',', '.'));
    var fechaPropuesta = this.propuestaForm.fechaPropuesta ? new Date(this.propuestaForm.fechaPropuesta) : new Date();
    var expediente = this.propuestaForm.expediente;
    this.expedienteGestiona = '';
    var dniPropon = this.dniProp;
    var desc_apli = this.propuestaForm.codApliPresupuestaria;
    var nombrePartida =
      desc_apli +
      ' (' +
      this.ejercicio +
      '.' +
      this.codProg +
      '.' +
      this.codEco +
      ')';
    var contratoObra = this.contratoObraCB;
    if (expediente == null || expediente == '') {
      this.boolIdGes = true;
      this.authService
        .BuscarExpdtGestiona(this.ejercicio, this.codProg, this.codEco, headers)
        .subscribe({
          next: (data) => {
            this.expedienteGestiona = data;
            if (this.expedienteGestiona == '') {
              //Crear Expdt
              this.authService.CrearExpediente(headers).subscribe({
                next: (data) => {
                  this.authService
                    .AbrirExpediente(data, nombrePartida, headers)
                    .subscribe({
                      next: (dataExpdt: any) => {
                        let parsedDataExpdt;
                        try {
                          parsedDataExpdt = JSON.parse(dataExpdt);
                        } catch (error) {
                          console.error("Error al analizar la cadena JSON:", error);
                          this.openModal("Error al procesar los datos del documento.");
                          return;
                        }
                        this.expedienteGestiona = parsedDataExpdt.code;

                        //Se usa Update porqwue estamos cogiendo una propuesta para modificarla, todo en Modificacion es un update
                        var fechaContrString = this.fechaControl.toString();
                        var fechaPropString = fechaPropuesta.toISOString();
                        this.authService.updatePropuesta(parseInt(this.idPropuesta, 10), proponente, responsable, cargo, cargoResponsable, objeto, necesidad,
                          plazo, this.usuControl, fechaContrString, importe + "", fechaPropString, expediente, 0 + "",
                          this.ejercicio + "", this.codProg, this.codEco, "", dniPropon, this.contabilidad + "", this.expedienteGestiona, headers).subscribe({
                            next: (data) => {
                              var desc_apli = '';
                              this.idPropuesta = data.idPropuesta;

                              this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                                next: (datoString) => {
                                  console.log("SuccessAudit");
                                },
                                error: (err) => {
                                  console.error('Error: ', err);
                                  this.showErrorModal(err);
                                  localStorage.clear();
                                  this.router.navigate(['/login']);
                                },
                              });


                              this.authService
                                .getDescuentoAplicacion(
                                  data.ejercicio,
                                  data.codProg,
                                  data.codEco,
                                  headers
                                )
                                .subscribe({
                                  next: (datoString) => {
                                    desc_apli = datoString;

                                    var vecArr: any[] = [];
                                    vecArr.push(data);
                                    vecArr.push(desc_apli);
                                    vecArr.push(contratoObra);
                                    //Necesitamos hacer una variable auxiliar para que calculatedValues
                                    //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                                    this.calculatedValues = vecArr;

                                    // Manually trigger change detection
                                    this.cdr.detectChanges();
                                  },
                                  error: (err) => {
                                    console.error('Error: ', err);
                                    this.openModal("No se ha encontrado Aplicacion Presupuestaria relacionada");

                                    this.router.navigate(['/gestionGasto/propuestas']);
                                  },
                                });
                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);
                              localStorage.clear();
                              this.router.navigate(['/login']);
                            },
                          });
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.openModal("No se pudo abrir el expediente");

                        this.router.navigate(['/gestionGasto/propuestas']);
                      },
                    });
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.openModal("No se pudo crear un Expediente");

                  this.router.navigate(['/gestionGasto/propuestas']);
                },
              });
            } else {

              //Se usa Update porqwue estamos cogiendo una propuesta para modificarla, todo en Modificacion es un update
              var fechaContrString = this.fechaControl.toString();
              var fechaPropString = fechaPropuesta.toISOString();
              this.authService.updatePropuesta(parseInt(this.idPropuesta, 10), proponente, responsable, cargo, cargoResponsable, objeto, necesidad,
                plazo, this.usuControl, fechaContrString, importe + "", fechaPropString, expediente, 0 + "",
                this.ejercicio + "", this.codProg, this.codEco, "", dniPropon, this.contabilidad + "", this.expedienteGestiona, headers).subscribe({
                  next: (data) => {
                    var desc_apli = '';
                    this.idPropuesta = data.idPropuesta;

                    this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                      next: (datoString) => {
                        console.log("SuccessAudit");
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        localStorage.clear();
                        this.router.navigate(['/login']);
                      },
                    });
                    this.authService
                      .getDescuentoAplicacion(
                        data.ejercicio,
                        data.codProg,
                        data.codEco,
                        headers
                      )
                      .subscribe({
                        next: (datoString) => {
                          desc_apli = datoString;

                          var vecArr: any[] = [];
                          vecArr.push(data);
                          vecArr.push(desc_apli);
                          vecArr.push(contratoObra);
                          //Necesitamos hacer una variable auxiliar para que calculatedValues
                          //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                          this.calculatedValues = vecArr;

                          // Manually trigger change detection
                          this.cdr.detectChanges();
                        },
                        error: (err) => {
                          console.error('Error: ', err);
                          this.openModal("No se ha encontrado Aplicacion Presupuestaria relacionada");

                          this.router.navigate(['/gestionGasto/propuestas']);
                        },
                      });
                  },
                  error: (err) => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);

                    this.router.navigate(['/gestionGasto/propuestas']);
                  },
                });
            }
          },
        });
    } else {
      //Se usa Update porqwue estamos cogiendo una propuesta para modificarla, todo en Modificacion es un update
      var fechaContrString = this.fechaControl.toString();
      var fechaPropString = fechaPropuesta.toISOString();
      this.authService.updatePropuesta(parseInt(this.idPropuesta, 10), proponente, responsable, cargo, cargoResponsable, objeto, necesidad,
        plazo, this.usuControl, fechaContrString, importe + "", fechaPropString, expediente, 0 + "",
        this.ejercicio + "", this.codProg, this.codEco, "", dniPropon, this.contabilidad + "", this.expedienteGestiona, headers).subscribe({
          next: (data) => {
            var desc_apli = '';
            this.idPropuesta = data.idPropuesta;

            this.authService.realizar_Auditoria(data, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
              next: (datoString) => {
                console.log("SuccessAudit");
              },
              error: (err) => {
                console.error('Error: ', err);
                this.showErrorModal(err);

                this.router.navigate(['/gestionGasto/propuestas']);
              },
            });
            this.authService
              .getDescuentoAplicacion(
                data.ejercicio,
                data.codProg,
                data.codEco,
                headers
              )
              .subscribe({
                next: (datoString) => {
                  desc_apli = datoString;

                  var vecArr: any[] = [];
                  vecArr.push(data);
                  vecArr.push(desc_apli);
                  vecArr.push(contratoObra);
                  //Necesitamos hacer una variable auxiliar para que calculatedValues
                  //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                  this.calculatedValues = vecArr;

                  // Manually trigger change detection
                  this.cdr.detectChanges();
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.openModal("No se ha encontrado Aplicacion Presupuestaria relacionada");

                  this.router.navigate(['/gestionGasto/propuestas']);
                },
              });
          },
          error: (err) => {
            console.error('Error: ', err);
            this.showErrorModal(err);

            this.router.navigate(['/gestionGasto/propuestas']);
          },
        });
    }
  }




  openDialog(tipo: string): void {  // tipo puede ser 'proponente' o 'responsable'
    const dialogRef = this.dialog.open(ModalBuscaAplpresupuestariaComponent, {
      width: '70%',
      height: '55%',
      data: {
        tipoBusqueda: tipo  // Aquí pasamos si estamos buscando un proponente o responsable
      },
    });

    dialogRef.componentInstance.rowSelected.subscribe((data: any) => {
      var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);

      if (tipo === 'codApliPresupuestaria') {
        // Lógica para el proponente
        this.propuestaForm.codApliPresupuestaria = data.des_aplic;
        this.ejercicio = data.apl_eje;
        this.codEco = data.apl_eco;
        this.codProg = data.apl_fun;
      }
    });
  }


  recepcionPDF(data: any): void {
    // Handle the data received from the child component
    this.comprobarExpdt();
    this.pdfRecibido = data;
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    //Tras generar el PDF, generamos el URI / Enlace de envio:
    this.authService.generarURI(headers).subscribe({
      next: (datoMod) => {
        //Teniendo el enlace, subimos el PDF al enlace:
        this.authService.subidaPDF(this.pdfRecibido, datoMod, headers).subscribe({
          next: dataUPL => {
            console.log("EL RESULTADO DE LA SUBIDA ES: ", dataUPL);
            //Aqui se añadiria el PDF
            this.authService.addArchivoToExpdt(dataUPL, this.propuestaForm.objeto, this.idExpdtAsoc, headers).subscribe({
              next: (dataDoc: any) => {
                let parsedDataDoc;
                try {
                  parsedDataDoc = JSON.parse(dataDoc);
                } catch (error) {
                  console.error("Error al analizar la cadena JSON:", error);
                  this.openModal("Error al procesar los datos del documento.");
                  return;
                }
                var id_doc_Gest = parsedDataDoc.idDocGestiona;
                //Con el id_documento_Gestiona se hace uin Put a Propuesta
                this.authService.getDatosPropuesta(this.idPropuesta, headers).subscribe({
                  next: (datosPropuesta: any) => {
                    //Con los datos de propuesta actualizamos el id_doc_gestiona y lo insertamos
                    var idPropuesta = datosPropuesta.idPropuesta;
                    var proponent = datosPropuesta.proponente;
                    var responsabl = datosPropuesta.responsable
                    var carg = datosPropuesta.cargo;
                    var cargoResponsabl = datosPropuesta.cargoResponsable;
                    var objet = datosPropuesta.objeto;
                    var necesity = datosPropuesta.necesidad;
                    var plaz = datosPropuesta.plazo;
                    var usuControl = datosPropuesta.usuarioControl;
                    var fecContr = datosPropuesta.fechaControl;
                    var importeNuevo = datosPropuesta.importe;
                    var fechaProp = datosPropuesta.fechaControl;
                    var exptAsoc = datosPropuesta.expdtAsoc;
                    var estProp = datosPropuesta.estadoPropuesta;
                    var eje = datosPropuesta.ejercicio;
                    var codprog = datosPropuesta.codProg;
                    var codeco = datosPropuesta.codEco;
                    var idDocGes = id_doc_Gest;
                    var dniProp = datosPropuesta.dniProponente;
                    var idConta = datosPropuesta.idContabilidad;
                    var expdtGes = datosPropuesta.expdtGestiona;
                    this.authService.updatePropuesta(idPropuesta, proponent, responsabl, carg, cargoResponsabl, objet, necesity, plaz,
                      usuControl, fecContr, importeNuevo, fechaProp, exptAsoc, estProp, eje,
                      codprog, codeco, idDocGes, dniProp, idConta, expdtGes, headers).subscribe({

                        next: (putDevuelto) => {
                          //Con el put devuelto, devolvemos
                          console.log("Succesfull");

                          this.authService.realizar_Auditoria(putDevuelto, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                            next: (datoString) => {
                              this.authService
                                .getHrefFromProponente(this.dniProp, headers)
                                .subscribe({
                                  next: (href: string) => {
                                    //Necesitamos el hrefSolicitado
                                    this.hrefSolicitado = href; //self
                                    this.CircuitoTramitacion(parsedDataDoc.hrefCircuito, cargoResponsabl, this.hrefSolicitado, responsabl, headers);
                                  },
                                  error: (err: any) => {
                                    console.error('Error:', err);
                                    this.openModal("No se pudo obtener proponente");
                                    this.router.navigate(['/gestionGasto/propuestas']);
                                  },
                                });

                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);
                              localStorage.clear();
                              this.router.navigate(['/login']);
                            },
                          });
                        },
                        error: (err) => {
                          localStorage.clear();
                          // Redirigir al usuario a la página de inicio de sesión
                          console.log(err);
                          this.showErrorModal(err);

                          this.router.navigate(['/gestionGasto/propuestas']);
                        }

                      });
                  },
                  error: (err) => {
                    // Redirigir al usuario a la página de inicio de sesión
                    console.log(err);
                    console.log("Error en el update");
                    this.showErrorModal(err);

                    this.router.navigate(['/gestionGasto/propuestas']);
                  }

                });

              },
              error: (err) => {
                // Redirigir al usuario a la página de inicio de sesión
                console.log(err);
                console.log("Error al añadir el documento");
                this.openModal("Error al añadir PDF al expediente");
                this.router.navigate(['/gestionGasto/propuestas']);
              }
            });
          },
          error: err => {
            // Redirigir al usuario a la página de inicio de sesión
            console.log(err);
            this.openModal("No pudo subirse el PDF a la URI determinada");
            this.router.navigate(['/gestionGasto/propuestas']);
          }
        });
      },
      error: (err) => {
        // Redirigir al usuario a la página de inicio de sesión
        this.showErrorModal(err);
        this.openModal("No se pudo generar una URI para el PDF");
        this.router.navigate(['/gestionGasto/propuestas']);
      },
    });
  }

  CircuitoTramitacion(href: string, cargo: string, hrefProponente: string, name: string, headers: HttpHeaders) {
    var esAlcade: boolean = false;

    if (cargo.toLowerCase() == "alcalde") {
      esAlcade = true;
    } else {
      esAlcade = false;
    }

    if (cargo != "" && cargo != null && cargo != undefined) {
      this.authService.CircuitoTramitacion(esAlcade, hrefProponente, href, name, headers).subscribe({
        next: (tramitado) => {
          this.router.navigate(['gestionGasto/propuestas']);
        },
        error: (err) => {
          console.log(err);
          this.showErrorModal(err);
          this.router.navigate(['/gestionGasto/propuestas']);
        }
      });
    } else {
      console.error('El cargo se encuentra vacio');
    }
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  comprobarExpdt() {
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var expedienteBuscar: string = "";
    if (this.propuestaForm.expediente == "") {
      expedienteBuscar = this.expedienteGestiona;
    } else {
      expedienteBuscar = this.propuestaForm.expediente;
    }

    this.authService.CompruebaExpdt(expedienteBuscar, headers).subscribe({
      next: (dataComprobado: any) => {
        if (dataComprobado.error) {
          this.expdtComprobado = false
          //this.nombreExpdt= "No se ha encontrado Expediente Asociado";
        } else {
          this.idExpdtAsoc = dataComprobado.content[0].id;
          this.nombreExpdt = dataComprobado.content[0].name;
          this.expdtComprobado = true
        }
      },
      error: (err) => {
        this.expdtComprobado = false
      }
    });
  }
}
