<div class="login">
  <div class="container login-container">
    <h1 class="bienvenido">BIENVENIDO</h1>
    <div class="card login-box mx-auto">
      <div class="card-body caja">
        <div class="logodiv">
          <img src="/assets/logo.png" class="logo" width="100">
        </div>
        <form form action="/action_page.php" (ngSubmit)="onSubmit()" #loginForm="ngForm">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Email" name="Email" [(ngModel)]="form.email" required>
            <div class="invalid-feedback">¡Ups! Parece que olvidaste ingresar tu nombre de usuario.</div>
            <div class="input-group-btn"></div>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" placeholder="Contraseña" name="Contraseña" [(ngModel)]="form.password" required>
            <div class="invalid-feedback">¡Ups! Parece que olvidaste ingresar tu contraseña.</div>
            <div class="input-group-btn"></div>
          </div>

          <div class="botondiv">
            <button type="submit" class="boton botonlogin">Login</button>
          </div>
          <!--<button type="button" class="boton botonregistrarme">Registrarme</button> -->
          <!--<button type="button" class="boton botoncontraseña">¿Has olvidado tu contraseña?</button>-->
        </form>
      </div>
    </div>
  </div>
</div>
