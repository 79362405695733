import { Component, HostListener, OnInit } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { ConfigService } from './_services/config.service';
import { Subscription } from 'rxjs';
import { StorageService } from './_services/storage.service';
import { AuthService } from './_services/auth.service';
import { EventBusService } from './_shared/event-bus.service';
import { Constantes } from './_services/constantes.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  userLogin?: string;
  userNombre?: string;
  eventBusSub?: Subscription;

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private eventBusService: EventBusService,
    private configService: ConfigService,
    private Constantes: Constantes
  ) {}

  //Borra localStorage cuando cierra browser
/*
  @HostListener("window:beforeunload",["$event"])
    clearLocalStorage(){
        localStorage.clear();
    }*/

  ngOnInit(): void {
    const config = this.configService.getConfig();
    const app = initializeApp(config.firebaseConfig);
    if (config.production) {
      getAnalytics(app);
    }

    this.isLoggedIn = this.storageService.isLoggedIn();

    this.userNombre = '';
    this.userLogin = '';
    if (this.isLoggedIn) {
      const user = this.storageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = user.isAdmin;
      this.userNombre = user.nombreUsuario;
      this.userLogin = user.nombreLogin;
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
  }

  logout(): void {
    this.authService.logout().subscribe({
      next: res => {
        this.storageService.clean();
        window.location.reload();
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
