import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../_services/storage.service';
import { JwtService } from '../../_services/jwt.service';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../modales/modales.component';
import { Constantes } from '../../_services/constantes.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  form: any = {
    email: null, //en BE se llama "nombreLogin"
    password: null
  };
  email ='';
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  isAdmin = '';
  roles: string[] = [];
  message = '';
  

  constructor(private http: HttpClient, private storageService: StorageService, private jwtService: JwtService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    document.body.className = "selector";
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.roles = this.storageService.getUser().roles;
      this.isAdmin=this.storageService.getUser().isAdmin;
    }
  }

  onSubmit(): void {
    const { email, password } = this.form;
    this.email = email;
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // El usuario ha iniciado sesión
        const user = userCredential.user;
        var tok;
        user.getIdToken(/* forceRefresh */ false)
          .then((idToken) => {
            tok=idToken;
            // Now you have the token string!

            var strHead= {'Authorization': `Bearer ${tok}`};
            const headers = new HttpHeaders(strHead);
            console.log("LA URL de llamada es:" + Constantes.AUTH_LOGIN);  // Verificar que la URL sea correcta
            this.http.get<any>(Constantes.AUTH_LOGIN, { headers: headers }).subscribe({
              next: data => {
                // Aquí debes guardar el token JWT en el localStorage
                if(idToken !== '' && idToken !==null){
                  this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
                    next: data => {
                      if(data!=null){
                        //En caso de hacer falta a futuro
                        localStorage.setItem('jwtToken', idToken);
                        localStorage.setItem('loginCorrecto', 'correcto');
                        this.router.navigate(['/desktop']); 
                      }else{
                        //console.error('Usuario no registrado');
                        localStorage.clear();
                        this.openModal('Usuario no registrado');
                        this.router.navigate(['/login']);
                      } 
                    },
                    error: err => {
                     // console.error('Error en BBDD');
                        localStorage.clear();
                        this.openModal('Error en BBDD');
                        this.router.navigate(['/login']);
                    }
                  });
                } else {
                  this.openModal("Login Incorrecto.\n Compruebe que sus credenciales sean correctas.");
                  this.router.navigate(['/login']); 
                }
                
              },
              error: err => {
                this.errorMessage = err.error.message;
                this.openModal(this.errorMessage);

                this.isLoginFailed = true;
              }
            });
          })  
          .catch((error) => {
            //console.error('Error fetching ID token:', error);
            this.openModal("Login Incorrecto.\n Error al comprobar token.");
            tok = "Token Invalid";
          });

      })
      .catch((error) => {
        // Ha ocurrido un error
        //console.error(error);
        this.openModal("Login Incorrecto.\n Compruebe que sus credenciales sean correctas.");
      });
  }

  reloadPage(): void {
    window.location.reload();
  }
  openModal(errorMsg: string ) {
    this.message=errorMsg;
    localStorage.setItem('error', this.message);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }

}

