<app-botonera></app-botonera>
<div class="escritorio">
  <div class="common-componentes">
    <app-year-contable></app-year-contable>
  </div>
  <div id="grid-container" class="tab-content grid-container">
    <div class="titulo-label"><h1><b>Estado de Ejecución</b></h1></div>
    <!-- Ag-Grid con configuración -->
    <ag-grid-angular 
      class="ag-theme-quartz" 
      [rowData]="typeRow"
      [columnDefs]="columnDefsUsuario"
      [sideBar]="sideBar"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      style="height: 50vh;">
    </ag-grid-angular>
    <!-- Fila de total -->
    <div class="total-row">
      <div class="total-label">Total:</div>
      <div class="total-value"><p><b>Definitivos: </b> {{ formatNumber(totalDefinitivo) }}</p></div>
      <div class="total-value"><p><b>Gastados: </b> {{ formatNumber(totalGastado) }}</p></div>
      <div class="total-value"><p><b>Disponibles: </b> {{ formatNumber(totalDisponibles) }}</p></div>
    </div>
  </div>
  <app-taskbar></app-taskbar>
</div>
