import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { Constantes } from '../../../_services/constantes.service';
import { NgForm } from '@angular/forms'; // Importa NgForm

@Component({
  selector: 'app-modal-muestrapropuesta',
  templateUrl: './modal-muestrapropuesta.component.html',
  styleUrl: './modal-muestrapropuesta.component.css'
})
export class ModalMuestrapropuestaComponent {
  submitted = false;
  idProponente: string = '';
  contabilidad: number = 0;
  propuestaForm: {
    codApliPresupuestaria: string,
    proponente: string,
    responsable: string,
    cargo: string,
    cargoResponsable: string,
    expediente: string,
    fechaPropuesta: Date | null,
    objeto: string,
    necesidad: string,
    plazo: string,
    importe: number,
    contratoDeObra: boolean
  } = {
      codApliPresupuestaria: '',
      proponente: '',
      responsable: '',
      cargo: '',
      cargoResponsable: '',
      expediente: '',
      fechaPropuesta: new Date(),
      objeto: '',
      necesidad: '',
      plazo: '',
      importe: 0,
      contratoDeObra: false,
    };
  tokenJWT: string = '';
  selectedRowData: any;
  aplicacion: boolean = false;
  usuControl: string = '';
  ejercicio: number = 0;
  codProg: string = '';
  codEco: string = '';
  dniProp: string = '';
  boolIdGes: boolean = false;
  idPropuesta: string = "";
  calculatedValues: any; //Para traspasar datos
  pdfRecibido: any; //El pdf obtenido de la generacion de PDF
  idUsuarioActual = "";
  hrefSolicitado = "";
  contratoObraCB: boolean = false;
  idExpdtAsoc: string = "";
  nombreExpdt: string = "";
  expdtComprobado: boolean = false;
  expedienteGestiona: string = "";
  desc_aplic: string = "";
  fechaControl = new Date();
  estadoPropuesta = "";
  idDocumentoGestiona = "";
  desc_Apli = "";
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private dialogRef : MatDialogRef<ModalMuestrapropuestaComponent>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) { //No hay aplicacion presupuestaria
          this.ejercicio = 0;
          this.codProg = '';
          this.codEco = '';
          var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http
            .get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers })
            .subscribe({
              next: (data) => {
                this.usuControl = `${data.nombreUsuario} ${data.apellidosUsuario}`;
                this.idUsuarioActual = data.idUsuario;

                var idPropuesta = localStorage.getItem('idPropuestaVisualizacion');
                localStorage.removeItem('idPropuestaVisualizacion');
                this.http.get<any>(Constantes.GETPROPUESTA + idPropuesta, { headers: headers }).subscribe({
                  next: data => {
                    if (data) { //Is Admin
                      var expedienteBuscar: string = "";
                      if (data.expdtAsoc == "") {
                        expedienteBuscar = this.expedienteGestiona;
                      } else {
                        expedienteBuscar = data.expdtAsoc;
                      }
                      this.propuestaForm.codApliPresupuestaria = data.ejercicio + "." + data.codProg + "." + data.codEco;
                      this.propuestaForm.proponente = data.proponente;
                      this.propuestaForm.responsable = data.responsable;
                      this.propuestaForm.expediente = data.expdtAsoc;
                      this.propuestaForm.importe = data.importe;
                      this.propuestaForm.cargo = data.cargo;
                      this.propuestaForm.cargoResponsable = data.cargoResponsable;
                      this.propuestaForm.fechaPropuesta = new Date(data.fechaPropuesta);
                      this.propuestaForm.objeto = data.objeto;
                      this.propuestaForm.necesidad = data.necesidad;
                      this.propuestaForm.plazo = data.plazo;
                      this.idPropuesta = data.idPropuesta;
                      this.dniProp = data.dniProponente;
                      this.fechaControl = data.fechaControl;
                      this.estadoPropuesta = data.estadoPropuesta;
                      this.idDocumentoGestiona = data.idDocumentoGestiona;
                      this.usuControl = data.usuarioControl;
                      this.estadoPropuesta = this.expresaEstadoPropuesta(data.estadoPropuesta);
                      this.authService
                      .getDescuentoAplicacion(
                        data.ejercicio,
                        data.codProg,
                        data.codEco,
                        headers
                      )
                      .subscribe({
                        next: (datoString) => {
                          this.desc_Apli = datoString;
                          this.authService.CompruebaExpdt(expedienteBuscar, headers).subscribe({
                            next: (dataComprobado: any) => {
                              if (dataComprobado.error) {
                                this.expdtComprobado = false
                                //this.nombreExpdt= "No se ha encontrado Expediente Asociado";
                              } else {
                                this.idExpdtAsoc = dataComprobado.content[0].id;
                                this.nombreExpdt = dataComprobado.content[0].name;
                                this.expdtComprobado = true
    
                               
                              }
                            },
                            error: (err) => {
                              this.expdtComprobado = false
                            }
                          });

                        },
                        error: (err) => {
                          this.desc_Apli = "No se ha encontrado Aplicacion Presupuestaria";
                        },
                      });
                     

                    } else { //Is not Admin
                      this.openModal("No se encontró propuesta");
                      //console.error('No tienes permisos para acceder a esta pantalla');
                      localStorage.clear();
                      this.router.navigate(['/login']);
                    }
                  },
                  error: err => {
                    //console.error('Error');
                    
                    this.showErrorModal(err);
                    localStorage.clear();
                    this.router.navigate(['/login']);
                  }
                });
              },
              error: (err) => {
                console.error(err);
                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/login']);
              },
            });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }
  expresaEstadoPropuesta(estadoPropuesta: number): string {
    switch (estadoPropuesta) {
      case 0:
        return 'Sin Firmar';
      case 1:
        return 'Firmada';
      case 2:
        return 'Contabilizada';
      case 3:
        return 'Anulado';
      case 4:
        return 'Sin Finalizar';
      default:
        return estadoPropuesta + "";
    }
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }
  
  // Getter to format the Date object as a string for the input field
  get formattedFechaPropuesta(): string | undefined {
    return this.propuestaForm.fechaPropuesta?.toISOString().split('T')[0];
  }

  // Setter to convert the input string back into a Date object
  set formattedFechaPropuesta(value: string) {
    this.propuestaForm.fechaPropuesta = new Date(value);
  }
  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
