<div class="modal" > <!-- Modal wrapper -->
    <div class="modal-content"> <!-- Modal content box -->
        <h1 class="err" mat-dialog-title>{{objeto}}</h1>
        <div class="err" mat-dialog-content>
            <p>¿Seguro que quiere {{objeto}} esta Propuesta?</p>
        </div>
        <div class="cierra" mat-dialog-actions>
            <button class="btn" mat-button (click)="onCancel()">NO</button>
            <button class="btn" mat-button (click)="onConfirm()">SI</button>
        </div>
    </div>
</div>
