import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmacion',
  templateUrl: './modal-confirmacion.component.html',
  styleUrl: './modal-confirmacion.component.css'
})
export class ModalConfirmacionComponent {
  objeto : string= "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ModalConfirmacionComponent>) {
    // Based on the actionType passed, set the modal text
    if (data.actionType === 'Modificar') {
      this.objeto = 'Modificar';
    } else if (data.actionType === 'Borrar') {
      this.objeto = 'Borrar';
    }else if (data.actionType === 'Anular') {
      this.objeto = 'Anular';
    }else if (data.actionType === 'Contabilizar') {
      this.objeto = 'Contabilizar';
    }
  }
  onConfirm(): void {
    this.dialogRef.close(true); // Return true if Yes is clicked
  }

  onCancel(): void {
    this.dialogRef.close(false); // Return false if No is clicked
  }
}
