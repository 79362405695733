<body>
  <app-botonera></app-botonera>
  <div class="escritorio">
      <div class="common-componentes">
        <app-year-contable></app-year-contable>
      </div>
      <div id="grid-container" class="tab-content grid-container">
        <div class="titulo-label"><h1><b>Cerrado </b></h1></div>
        <!-- Ag-Grid con configuración -->
        <ag-grid-angular 
          class="ag-theme-quartz" 
          [rowData]="typeRow" 
          [columnDefs]="columnDefsUsuario"
          [gridOptions]="gridOptions"
          [sideBar]="sideBar"
          style="height: 50vh;">
        </ag-grid-angular>
      </div>
      <app-taskbar></app-taskbar>
</div>
</body>