import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { Constantes } from '../../../_services/constantes.service';
import { NgForm } from '@angular/forms'; // Importa NgForm
import { ModalBuscadorComponent } from '../modal-buscador/modal-buscador.component';
import { ModalContratoObraComponent } from '../modal-contrato-obra/modal-contrato-obra.component';
@Component({
  selector: 'app-nueva-propuesta-temporal',
  templateUrl: './nueva-propuesta-temporal.component.html',
  styleUrl: './nueva-propuesta-temporal.component.css'
})
export class NuevaPropuestaTemporalComponent {
  submitted = false;
  idProponente: string = '';
  contabilidad: number = 0;
  propuestaForm = {
    proponente: '',
    responsable: '',
    cargo: '',
    cargoResponsable: '',
    expediente: '',
    fechaPropuesta: new Date(),
    objeto: '',
    necesidad: '',
    plazo: '',
    importe: 0,
    contratoDeObra: false,
  };
  tokenJWT: string = '';
  selectedRowData: any;
  aplicacion: boolean = false;
  usuControl: string = '';
  ejercicio: number = 0;
  codProg: string = '';
  codEco: string = '';
  dniProp: string = '';
  boolIdGes: boolean = false;
  idPropuesta : string= "";
  calculatedValues: any; //Para traspasar datos
  pdfRecibido: any; //El pdf obtenido de la generacion de PDF
  idUsuarioActual= "";
  hrefSolicitado="";
  contratoObraCB : boolean= false;
  idExpdtAsoc : string = "";
  nombreExpdt : string = "";
  expdtComprobado: boolean = false;
  expedienteGestiona : string = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) { //No hay aplicacion presupuestaria
          this.ejercicio = 0;
          this.codProg = '';
          this.codEco = '';
        var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
        const headers = new HttpHeaders(strHead);
        this.http
          .get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers })
          .subscribe({
            next: (data) => {
              this.usuControl = `${data.nombreUsuario} ${data.apellidosUsuario}`;
              this.idUsuarioActual=data.idUsuario;
            },
            error: (err) => {
              console.error(err);
              this.showErrorModal(err);
              localStorage.clear(); 
              this.router.navigate(['/login']);
            },
          });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear(); 
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear(); 
        this.router.navigate(['/login']);
      }
    }
  }

  onSubmit(form: NgForm) {
    // Añade form como argumento
    this.submitted = true;
    

    if(this.propuestaForm.expediente === "" && !this.expdtComprobado){
      this.expdtComprobado = true;
    }
    if (
      !this.propuestaForm.proponente ||
      form.controls['fechaPropuesta']?.invalid ||
      !this.propuestaForm.objeto ||
      form.controls['importe']?.invalid ||
      !this.expdtComprobado
    ) {
      return;
    }

    if(this.propuestaForm.expediente ==""){ //Si no hay expediente asociado puede hacer falta codigo de expediente
      const dialogRef = this.dialog.open(ModalContratoObraComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) { //SI
          this.contratoObraCB=true;

        } else { //NO
          this.contratoObraCB=false;
        }
        this.creaPropuesta(form);
      });
    }else{
      this.creaPropuesta(form); //Si hay expediente asociado nos da igual que contrato de obra sea true o false porque no lo vamos a usar
    }

  }

  creaPropuesta(form : NgForm){

    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);

    const contabilidad = localStorage.getItem('contabilidad') || '0';
    this.contabilidad = parseInt(contabilidad, 10); // Convertir a número

    var proponente = this.propuestaForm.proponente;
    var responsable = this.propuestaForm.responsable;
    var cargo = this.propuestaForm.cargoResponsable;
    var cargoResponsable = this.propuestaForm.cargo;
    var objeto = this.propuestaForm.objeto;
    var necesidad = this.propuestaForm.necesidad;
    var plazo = this.propuestaForm.plazo;
    var importe = parseFloat(this.propuestaForm.importe.toString().replace(',', '.'));
    var fechaPropuesta = this.propuestaForm.fechaPropuesta;
    var expediente = this.propuestaForm.expediente;
    this.expedienteGestiona = '';
    var dniPropon = this.dniProp;
    if (expediente == null || expediente == '') {
      this.boolIdGes = true;
      this.authService
      .registrar_Propuesta(
        proponente,
        responsable,
        cargo,
        cargoResponsable,
        objeto,
        necesidad,
        plazo,
        importe,
        fechaPropuesta,
        expediente,
        this.contabilidad,
        this.ejercicio,
        this.codProg,
        this.codEco,
        this.usuControl,
        this.expedienteGestiona, //expteGestiona vacio
        dniPropon,
        4,
        headers
      )
      .subscribe({
        next: (data) => {
          var desc_apli = '';
          this.idPropuesta= data.idPropuesta;

          this.authService.realizar_Auditoria(data,false,"Propuesta","ADD",this.idUsuarioActual,headers).subscribe({
            next: (datoString) => {
              console.log("SuccessAudit");
              this.router.navigate(['gestionGasto/propuestas']);
            },
            error: (err) => {
              console.error('Error: ', err);
              this.showErrorModal(err);
              this.router.navigate(['/gestionGasto/propuestas']);
            },
          });


        },
        error: (err) => {
          console.error('Error: ', err);
          this.showErrorModal(err);
          this.router.navigate(['/gestionGasto/propuestas']);
        },
      });
    } else {
      this.authService
      .registrar_Propuesta(
        proponente,
        responsable,
        cargo,
        cargoResponsable,
        objeto,
        necesidad,
        plazo,
        importe,
        fechaPropuesta,
        expediente,
        this.contabilidad,
        this.ejercicio,
        this.codProg,
        this.codEco,
        this.usuControl,
        this.expedienteGestiona,
        dniPropon,
        4,
        headers
      )
        .subscribe({
          next: (data) => {
            var desc_apli = '';
            this.idPropuesta= data.idPropuesta;

            this.authService.realizar_Auditoria(data,false,"Propuesta","ADD",this.idUsuarioActual,headers).subscribe({
              next: (datoString) => {
                console.log("SuccessAudit");
                this.router.navigate(['gestionGasto/propuestas']);
              },
              error: (err) => {
                console.error('Error: ', err);
                this.showErrorModal(err);
                this.router.navigate(['/gestionGasto/propuestas']);
              },
            });
          
          },
          error: (err) => {
            console.error('Error: ', err);
            this.showErrorModal(err);
            this.router.navigate(['/gestionGasto/propuestas']);
          },
        });
    }
  }


  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

  if (errorMsg.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = `Error: ${errorMsg.error.message}`;
  } else {
    // Server-side error
    errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
    if (errorMsg.error && errorMsg.error.message) {
      // Extract the error message from the server response
      errorMessage += `\nServer Message: ${errorMsg.error.message}`;
    }
  }
  console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string ) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
 }
  openDialog(tipo: string): void {  // tipo puede ser 'proponente' o 'responsable'
    const dialogRef = this.dialog.open(ModalBuscadorComponent, {
      width: '70%',
      height: '55%',
      data: {
        tipoBusqueda: tipo  // Aquí pasamos si estamos buscando un proponente o responsable
      },
    });
  
    dialogRef.componentInstance.rowSelected.subscribe((data: any) => {
      var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);
      
      if (tipo === 'proponente') {
        // Lógica para el proponente
        this.propuestaForm.proponente = data.name;
        this.idProponente = data.id;
        this.authService
          .getJobFromProponente(this.idProponente, headers)
          .subscribe({
            next: (job: string) => {
              this.dniProp = data.username;
              this.propuestaForm.cargo = job;
            },
            error: (err: any) => {
              console.error('Error:', err);
            },
          });
      } else if (tipo === 'responsable') {
        // Lógica para el responsable
        this.propuestaForm.responsable = data.name;
        this.authService  
        .getJobFromProponente(data.id, headers)
        .subscribe({
          next: (job: string) => {
            this.dniProp = data.username;
            this.propuestaForm.cargoResponsable = job;
            this.hrefSolicitado = data.links[0].href; //self
          },
          error: (err: any) => {
            console.error('Error:', err);
          },
        });
      }
    });
  }
  

  CircuitoTramitacion(href: string, cargo: string, hrefProponente:string, name: string, headers: HttpHeaders){
    var esAlcade: boolean = false;
    
    if(cargo.toLowerCase() == "alcalde"){
      esAlcade = true;
    } else{
      esAlcade = false;
    }
    
    if (cargo!= "" && cargo != null && cargo!= undefined) {
      this.authService.CircuitoTramitacion(esAlcade, hrefProponente, href, name, headers).subscribe({
        next: (tramitado) => {
          this.router.navigate(['gestionGasto/propuesta']);
        },
        error: (err) => {
          localStorage.clear();
          console.log(err);
          this.openModal("Error al realizar la Tramitación del documento");
          this.router.navigate(['/gestionGasto/propuestas']);
        }
      });
    } else {
      console.error('El cargo se encuentra vacio');
    }
  }

  comprobarExpdt() {
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var expedienteBuscar: string = "";
    if(this.propuestaForm.expediente == ""){
      expedienteBuscar = this.expedienteGestiona;
    } else {
      expedienteBuscar = this.propuestaForm.expediente;
    }

    this.authService.CompruebaExpdt(expedienteBuscar, headers).subscribe({
      next: (dataComprobado: any) => {
        if(dataComprobado.error){
          this.expdtComprobado = false
          //this.nombreExpdt= "No se ha encontrado Expediente Asociado";
        } else{
          this.idExpdtAsoc = dataComprobado.content[0].id;
          this.nombreExpdt = dataComprobado.content[0].name;
          this.expdtComprobado = true
        }
      },
      error: (err) => {
        this.expdtComprobado = false
      }
    });
  }
}
