import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import {  Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.css']
})
export class DesktopComponent {
  tokenJWT: string = "";
  constructor(private http: HttpClient, private router: Router, private authService: AuthService){}

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto' ) {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']); 
      }
    }
  }
}
