<div class="gauge-container" [style.width.px]="width" [style.height.px]="height">
    <svg [attr.width]="width" [attr.height]="height" class="gauge-svg">
        <defs>
        <linearGradient [id]="gradientId" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color: #4CAF50" />
            <stop offset="50%" style="stop-color: #FFC107" />
            <stop offset="90%" style="stop-color: #FF9800" />
            <stop offset="100%" style="stop-color: #ff0000" />
        </linearGradient>
        </defs>
        
        <!-- Background -->
        <rect x="0" y="0" [attr.width]="width" [attr.height]="height" fill="#2C3E50" [attr.rx]="height / 2" [attr.ry]="height / 2" />
        
        <!-- Gauge fill -->
        <rect 
        x="0" 
        y="0" 
        [attr.width]="(percentage / 100) * width" 
        [attr.height]="height" 
        [attr.fill]="'url(#' + gradientId + ')'" 
        [attr.rx]="height / 2" 
        [attr.ry]="height / 2"
        />
    </svg>

    <!-- Labels -->
    <div class="gauge-labels">
        <ng-container *ngFor="let label of ticks">
        <span class="gauge-label" [style.left]="((label - min) / (max - min)) * width + 'px'">
            {{label | number:'1.0-0'}}
        </span>
        </ng-container>
    </div>
</div>