import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { Constantes } from '../../../_services/constantes.service';
import { NgForm } from '@angular/forms'; // Importa NgForm
import { ModalBuscadorComponent } from '../modal-buscador/modal-buscador.component';
import { ModalContratoObraComponent } from '../modal-contrato-obra/modal-contrato-obra.component';
import { ModalConfirmaLimitesComponent } from '../modal-confirma-limites/modal-confirma-limites.component';

@Component({
  selector: 'app-nueva-propuesta',
  templateUrl: './nueva-propuesta.component.html',
  styleUrls: ['./nueva-propuesta.component.css'],
})
export class NuevaPropuestaComponent implements OnInit {

  submitted = false;
  idProponente: string = '';
  contabilidad: number = 0;
  propuestaForm = {
    proponente: '',
    responsable: '',
    cargo: '',
    cargoResponsable: '',
    expediente: '',
    fechaPropuesta: new Date(),
    objeto: '',
    necesidad: '',
    plazo: '',
    importe: 0,
    contratoDeObra: false,
  };
  tokenJWT: string = '';
  selectedRowData: any;
  aplicacion: boolean = false;
  usuControl: string = '';
  ejercicio: number = 0;
  codProg: string = '';
  codEco: string = '';
  dniProp: string = '';
  boolIdGes: boolean = false;
  idPropuesta: string = "";
  calculatedValues: any; //Para traspasar datos
  pdfRecibido: any; //El pdf obtenido de la generacion de PDF
  idUsuarioActual = "";
  hrefSolicitado = "";
  contratoObraCB: boolean = false;
  idExpdtAsoc: string = "";
  nombreExpdt: string = "";
  expdtComprobado: boolean = false;
  expedienteGestiona: string = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          if (localStorage.getItem('aplicacionPresupuestaria') != null) {
            this.aplicacion = true;
            this.selectedRowData = JSON.parse(
              localStorage.getItem('aplicacionPresupuestaria') || '{}'
            );
            if (this.selectedRowData != null) {
              this.ejercicio = parseInt(this.selectedRowData.apl_eje, 10);
              this.codProg = this.selectedRowData.apl_fun;
              this.codEco = this.selectedRowData.apl_eco;
              var cuantoGastado = this.selectedRowData.credito_gastado;

              var porcentajeEjecutado = cuantoGastado / this.selectedRowData.creditos_definitivos;
              if (porcentajeEjecutado > 0.7) {
                this.propuestaForm.plazo = "Se informa que el crédito de la aplicación presupuestaria ha superado el 70%";
              }
            } else {
              this.ejercicio = 0;
              this.codProg = '';
              this.codEco = '';
            }
            var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
            const headers = new HttpHeaders(strHead);
            this.http
              .get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers })
              .subscribe({
                next: (data) => {
                  this.usuControl = `${data.nombreUsuario} ${data.apellidosUsuario}`;
                  this.idUsuarioActual = data.idUsuario;

                },
                error: (err) => {
                  console.error(err);
                  localStorage.clear();
                  this.router.navigate(['/login']);
                },
              });
          } else {
            this.aplicacion = false;
            localStorage.removeItem('aplicacionPresupuestaria');
          }
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  onCheckboxChange(event: any) {
    this.contratoObraCB = event.target.checked;
  }


  onSubmit(form: NgForm) {
    // Añade form como argumento
    this.submitted = true;

    if (this.propuestaForm.expediente === "" && !this.expdtComprobado) {
      this.expdtComprobado = true;
    }
    if (
      !this.propuestaForm.proponente ||
      !this.propuestaForm.responsable ||
      form.controls['fechaPropuesta']?.invalid ||
      !this.propuestaForm.objeto ||
      form.controls['importe']?.invalid ||
      !this.expdtComprobado
    ) {
      this.submitted = false;
      return;
    }

    var importe = parseFloat(this.propuestaForm.importe.toString().replace(',', '.'));
    this.confirmarPropuesta(this.selectedRowData.credito_gastado, this.selectedRowData.creditos_definitivos, importe, form);

  }

  confirmarPropuesta(gastado: number, definitivos: number, importe: number, form: NgForm) {
    var cuantoGastado = gastado + importe;

    var porcentajeEjecutado = cuantoGastado / definitivos;
    var desfase = cuantoGastado - porcentajeEjecutado;

    if (porcentajeEjecutado > 0.7) {
      if (porcentajeEjecutado > 1) {
        var texto = "Se informa que con esta propuesta de gasto la aplicación presupuestaria quedará con saldo negativo de " + desfase.toFixed(2) + " euros. ¿Confirmas la creación de dicha propuesta?";
        this.propuestaForm.plazo = 'Se informa que con esta propuesta de gasto la aplicación presupuestaria quedará con saldo negativo de ' + desfase.toFixed(2) + " euros. No obstante, se autoriza el gasto por existir bolsa de vinculación.";
        setTimeout(() => {
          const dialogRef = this.dialog.open(ModalConfirmaLimitesComponent, {
            data: { actionType: texto }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) { //SI
  
  
              if (this.propuestaForm.expediente == "") { //Si no hay expediente asociado puede hacer falta codigo de expediente
                const dialogRef = this.dialog.open(ModalContratoObraComponent);
                dialogRef.afterClosed().subscribe(result => {
                  if (result) { //SI
                    this.contratoObraCB = true;
  
                  } else { //NO
                    this.contratoObraCB = false;
                  }
                  this.creaPropuesta(form);
  
                });
              } else {
                this.creaPropuesta(form);
              }
  
  
  
            } else { //NO
            }
          });
  
        }, 0);
       
      } else {
        var textoP = "Se informa que con esta propuesta el crédito de la aplicación presupuestaria superará el 70%.";
        this.propuestaForm.plazo = 'Se informa que el crédito de la aplicación presupuestaria ha superado el 70%.';
        setTimeout(() => {
          const dialogRef = this.dialog.open(ModalConfirmaLimitesComponent, {
            data: { actionType: textoP }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) { //SI
  
              if (this.propuestaForm.expediente == "") { //Si no hay expediente asociado puede hacer falta codigo de expediente
                const dialogRef = this.dialog.open(ModalContratoObraComponent);
                dialogRef.afterClosed().subscribe(result => {
                  if (result) { //SI
                    this.contratoObraCB = true;
  
                  } else { //NO
                    this.contratoObraCB = false;
                  }
                  this.creaPropuesta(form);
  
                });
              } else {
                this.creaPropuesta(form);
              }
            } else { //NO
            }
          });
        }, 0);
        
        
      }
    } else {
      if (this.propuestaForm.expediente == "") { //Si no hay expediente asociado puede hacer falta codigo de expediente
        const dialogRef = this.dialog.open(ModalContratoObraComponent);
        dialogRef.afterClosed().subscribe(result => {
          if (result) { //SI
            this.contratoObraCB = true;

          } else { //NO
            this.contratoObraCB = false;
          }
          this.creaPropuesta(form);

        });
      } else {
        this.creaPropuesta(form);
      }
    }

  }

  creaPropuesta(form: NgForm) {

    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);

    const contabilidad = localStorage.getItem('contabilidad') || '0';
    this.contabilidad = parseInt(contabilidad, 10); // Convertir a número

    var proponente = this.propuestaForm.proponente;
    var responsable = this.propuestaForm.responsable;
    var cargo = this.propuestaForm.cargoResponsable;
    var cargoResponsable = this.propuestaForm.cargo;
    var objeto = this.propuestaForm.objeto;
    var necesidad = this.propuestaForm.necesidad;
    var plazo = this.propuestaForm.plazo;
    var importe = parseFloat(this.propuestaForm.importe.toString().replace(',', '.'));
    var fechaPropuesta = this.propuestaForm.fechaPropuesta;
    var expediente = this.propuestaForm.expediente;
    this.expedienteGestiona = '';
    var dniPropon = this.dniProp;
    var desc_apli = this.selectedRowData.des_aplic;
    var nombrePartida =
      desc_apli +
      ' (' +
      this.ejercicio +
      '.' +
      this.codProg +
      '.' +
      this.codEco +
      ')';
    var contratoObra = this.contratoObraCB;
    if (expediente == null || expediente == '') {
      this.boolIdGes = true;
      this.authService
        .BuscarExpdtGestiona(this.ejercicio, this.codProg, this.codEco, headers)
        .subscribe({
          next: (data) => {
            this.expedienteGestiona = data;
            if (this.expedienteGestiona == '') {
              //Crear Expdt
              this.authService.CrearExpediente(headers).subscribe({
                next: (data) => {
                  this.authService
                    .AbrirExpediente(data, nombrePartida, headers)
                    .subscribe({
                      next: (dataExpdt: any) => {
                        let parsedDataExpdt;
                        try {
                          parsedDataExpdt = JSON.parse(dataExpdt);
                        } catch (error) {
                          console.error("Error al analizar la cadena JSON:", error);
                          this.openModal("Error al procesar los datos del documento.");
                          return;
                        }
                        this.expedienteGestiona = parsedDataExpdt.code;
                        this.authService
                          .registrar_Propuesta(
                            proponente,
                            responsable,
                            cargo,
                            cargoResponsable,
                            objeto,
                            necesidad,
                            plazo,
                            importe,
                            fechaPropuesta,
                            expediente,
                            this.contabilidad,
                            this.ejercicio,
                            this.codProg,
                            this.codEco,
                            this.usuControl,
                            this.expedienteGestiona,
                            dniPropon,
                            0,
                            headers
                          )
                          .subscribe({
                            next: (data) => {
                              var desc_apli = '';
                              this.idPropuesta = data.idPropuesta;

                              this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                                next: (datoString) => {
                                  console.log("SuccessAudit");
                                },
                                error: (err) => {
                                  console.error('Error: ', err);
                                  this.showErrorModal(err);
                                  this.router.navigate(['/gestionGasto/propuestas']);
                                },
                              });


                              this.authService
                                .getDescuentoAplicacion(
                                  data.ejercicio,
                                  data.codProg,
                                  data.codEco,
                                  headers
                                )
                                .subscribe({
                                  next: (datoString) => {
                                    desc_apli = datoString;

                                    var vecArr: any[] = [];
                                    vecArr.push(data);
                                    vecArr.push(desc_apli);
                                    vecArr.push(contratoObra);
                                    //Necesitamos hacer una variable auxiliar para que calculatedValues
                                    //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                                    this.calculatedValues = vecArr;

                                    // Manually trigger change detection
                                    this.cdr.detectChanges();
                                  },
                                  error: (err) => {
                                    console.error('Error: ', err);
                                    this.showErrorModal(err);
                                    this.router.navigate(['/gestionGasto/propuestas']);
                                  },
                                });
                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);
                              this.router.navigate(['/gestionGasto/propuestas']);
                            },
                          });
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        this.router.navigate(['/gestionGasto/propuestas']);
                      },
                    });
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.showErrorModal(err);
                  this.router.navigate(['/gestionGasto/propuestas']);
                },
              });
            } else {
              this.authService
                .registrar_Propuesta(
                  proponente,
                  responsable,
                  cargo,
                  cargoResponsable,
                  objeto,
                  necesidad,
                  plazo,
                  importe,
                  fechaPropuesta,
                  expediente,
                  this.contabilidad,
                  this.ejercicio,
                  this.codProg,
                  this.codEco,
                  this.usuControl,
                  this.expedienteGestiona,
                  dniPropon,
                  0,
                  headers
                )
                .subscribe({
                  next: (data) => {
                    var desc_apli = '';
                    this.idPropuesta = data.idPropuesta;

                    this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                      next: (datoString) => {
                        console.log("SuccessAudit");
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);
                        this.router.navigate(['/gestionGasto/propuestas']);
                      },
                    });
                    this.authService
                      .getDescuentoAplicacion(
                        data.ejercicio,
                        data.codProg,
                        data.codEco,
                        headers
                      )
                      .subscribe({
                        next: (datoString) => {
                          desc_apli = datoString;

                          var vecArr: any[] = [];
                          vecArr.push(data);
                          vecArr.push(desc_apli);
                          vecArr.push(contratoObra);
                          //Necesitamos hacer una variable auxiliar para que calculatedValues
                          //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                          this.calculatedValues = vecArr;

                          // Manually trigger change detection
                          this.cdr.detectChanges();
                        },
                        error: (err) => {
                          console.error('Error: ', err);
                          this.showErrorModal(err);
                          this.router.navigate(['/gestionGasto/propuestas']);
                        },
                      });
                  },
                  error: (err) => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);
                    this.router.navigate(['/gestionGasto/propuestas']);

                  },
                });
            }
          },
        });
    } else {
      this.authService
        .registrar_Propuesta(
          proponente,
          responsable,
          cargo,
          cargoResponsable,
          objeto,
          necesidad,
          plazo,
          importe,
          fechaPropuesta,
          expediente,
          this.contabilidad,
          this.ejercicio,
          this.codProg,
          this.codEco,
          this.usuControl,
          this.expedienteGestiona,
          dniPropon,
          0,
          headers
        )
        .subscribe({
          next: (data) => {
            var desc_apli = '';
            this.idPropuesta = data.idPropuesta;

            this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
              next: (datoString) => {
                console.log("SuccessAudit");
              },
              error: (err) => {
                console.error('Error: ', err);
                this.showErrorModal(err);
                this.router.navigate(['/gestionGasto/propuestas']);
              },
            });
            this.authService
              .getDescuentoAplicacion(
                data.ejercicio,
                data.codProg,
                data.codEco,
                headers
              )
              .subscribe({
                next: (datoString) => {
                  desc_apli = datoString;

                  var vecArr: any[] = [];
                  vecArr.push(data);
                  vecArr.push(desc_apli);
                  vecArr.push(contratoObra);
                  //Necesitamos hacer una variable auxiliar para que calculatedValues
                  //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                  this.calculatedValues = vecArr;

                  // Manually trigger change detection
                  this.cdr.detectChanges();
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.showErrorModal(err);
                  this.router.navigate(['/gestionGasto/propuestas']);
                },
              });
          },
          error: (err) => {
            console.error('Error: ', err);
            this.showErrorModal(err);
            this.router.navigate(['/gestionGasto/propuestas']);
          },
        });
    }
  }


  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialog(tipo: string): void {  // tipo puede ser 'proponente' o 'responsable'
    const dialogRef = this.dialog.open(ModalBuscadorComponent, {
      width: '70%',
      height: '55%',
      data: {
        tipoBusqueda: tipo  // Aquí pasamos si estamos buscando un proponente o responsable
      },
    });

    dialogRef.componentInstance.rowSelected.subscribe((data: any) => {
      var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);

      if (tipo === 'proponente') {
        // Lógica para el proponente
        this.propuestaForm.proponente = data.name;
        this.idProponente = data.id;
        this.authService
          .getJobFromProponente(this.idProponente, headers)
          .subscribe({
            next: (job: string) => {
              this.propuestaForm.cargo = job;
            },
            error: (err: any) => {
              console.error('Error:', err);
            },
          });
      } else if (tipo === 'responsable') {
        // Lógica para el responsable
        this.propuestaForm.responsable = data.name;
        this.authService
          .getJobFromProponente(data.id, headers)
          .subscribe({
            next: (job: string) => {
              this.dniProp = data.username;
              this.propuestaForm.cargoResponsable = job;
              this.hrefSolicitado = data.links[0].href; //self
            },
            error: (err: any) => {
              console.error('Error:', err);
            },
          });
      }
    });
  }


  recepcionPDF(data: any): void {
    // Handle the data received from the child component
    this.comprobarExpdt();
    this.pdfRecibido = data;
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    //Tras generar el PDF, generamos el URI / Enlace de envio:
    this.authService.generarURI(headers).subscribe({
      next: (datoMod) => {
        //Teniendo el enlace, subimos el PDF al enlace:
        this.authService.subidaPDF(this.pdfRecibido, datoMod, headers).subscribe({
          next: dataUPL => {
            console.log("EL RESULTADO DE LA SUBIDA ES: ", dataUPL);
            //Aqui se añadiria el PDF
            this.authService.addArchivoToExpdt(dataUPL, this.propuestaForm.objeto, this.idExpdtAsoc, headers).subscribe({
              next: (dataDoc: any) => {
                let parsedDataDoc;
                try {
                  parsedDataDoc = JSON.parse(dataDoc);
                } catch (error) {
                  console.error("Error al analizar la cadena JSON:", error);
                  this.openModal("Error al procesar los datos del documento.");
                  return;
                }
                var id_doc_Gest = parsedDataDoc.idDocGestiona;
                //Con el id_documento_Gestiona se hace uin Put a Propuesta
                this.authService.getDatosPropuesta(this.idPropuesta, headers).subscribe({
                  next: (datosPropuesta: any) => {
                    //Con los datos de propuesta actualizamos el id_doc_gestiona y lo insertamos
                    var idPropuesta = datosPropuesta.idPropuesta;
                    var proponent = datosPropuesta.proponente;
                    var responsabl = datosPropuesta.responsable
                    var carg = datosPropuesta.cargo;
                    var cargoResponsabl = datosPropuesta.cargoResponsable;
                    var objet = datosPropuesta.objeto;
                    var necesity = datosPropuesta.necesidad;
                    var plaz = datosPropuesta.plazo;
                    var usuControl = datosPropuesta.usuarioControl;
                    var fecContr = datosPropuesta.fechaControl;
                    var importeNuevo = datosPropuesta.importe;
                    var fechaProp = datosPropuesta.fechaControl;
                    var exptAsoc = datosPropuesta.expdtAsoc;
                    var estProp = datosPropuesta.estadoPropuesta;
                    var eje = datosPropuesta.ejercicio;
                    var codprog = datosPropuesta.codProg;
                    var codeco = datosPropuesta.codEco;
                    var idDocGes = id_doc_Gest;
                    var dniProp = datosPropuesta.dniProponente;
                    var idConta = datosPropuesta.idContabilidad;
                    var expdtGes = datosPropuesta.expdtGestiona;
                    this.authService.updatePropuesta(idPropuesta, proponent, responsabl, carg, cargoResponsabl, objet, necesity, plaz,
                      usuControl, fecContr, importeNuevo, fechaProp, exptAsoc, estProp, eje,
                      codprog, codeco, idDocGes, dniProp, idConta, expdtGes, headers).subscribe({

                        next: (putDevuelto) => {
                          //Con el put devuelto, devolvemos
                          console.log("Succesfull");

                          this.authService.realizar_Auditoria(putDevuelto, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                            next: (datoString) => {
                              this.CircuitoTramitacion(parsedDataDoc.hrefCircuito, cargoResponsabl, this.hrefSolicitado, responsabl, headers);
                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);
                              this.router.navigate(['/gestionGasto/propuestas']);
                            },
                          });
                        },
                        error: (err) => {

                          // Redirigir al usuario a la página de inicio de sesión
                          console.log(err);
                          this.showErrorModal(err);
                          this.router.navigate(['/gestionGasto/propuestas']);
                        }

                      });
                  },
                  error: (err) => {

                    // Redirigir al usuario a la página de inicio de sesión
                    console.log(err);
                    console.log("Error en el update");
                    this.showErrorModal(err);
                    this.router.navigate(['/gestionGasto/propuestas']);
                  }

                });

              },
              error: (err) => {

                // Redirigir al usuario a la página de inicio de sesión
                console.log(err);
                this.openModal("No se ha podido añadir archivo PDF a expediente de Gestiona");
                this.router.navigate(['/gestionGasto/propuestas']);

              }
            });
          },
          error: err => {

            // Redirigir al usuario a la página de inicio de sesión
            console.log(err);
            this.openModal("No se ha podido subir PDF");
            this.router.navigate(['/gestionGasto/propuestas']);
          }
        });
      },
      error: (err) => {

        // Redirigir al usuario a la página de inicio de sesión
        this.openModal("No se pudo generar la URI para el PDF");
        console.log(err);
        this.router.navigate(['/gestionGasto/propuestas']);
      },
    });
  }

  CircuitoTramitacion(href: string, cargo: string, hrefProponente: string, name: string, headers: HttpHeaders) {
    var esAlcade: boolean = false;

    if (cargo.toLowerCase() == "alcalde") {
      esAlcade = true;
    } else {
      esAlcade = false;
    }

    if (cargo != "" && cargo != null && cargo != undefined) {
      this.authService.CircuitoTramitacion(esAlcade, hrefProponente, href, name, headers).subscribe({
        next: (tramitado) => {
          this.router.navigate(['gestionGasto/propuesta']);
        },
        error: (err) => {
          console.log(err);
          this.openModal("Ha fallado la tramitación del documento");
          this.router.navigate(['/gestionGasto/propuestas']);
        }
      });
    } else {
      console.error('El cargo se encuentra vacio');
    }
  }

  comprobarExpdt() {
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var expedienteBuscar: string = "";
    if (this.propuestaForm.expediente == "") {
      expedienteBuscar = this.expedienteGestiona;
    } else {
      expedienteBuscar = this.propuestaForm.expediente;
    }

    this.authService.CompruebaExpdt(expedienteBuscar, headers).subscribe({
      next: (dataComprobado: any) => {
        if (dataComprobado.error) {
          this.expdtComprobado = false
          //this.nombreExpdt= "No se ha encontrado Expediente Asociado";
        } else {
          this.idExpdtAsoc = dataComprobado.content[0].id;
          this.nombreExpdt = dataComprobado.content[0].name;
          this.expdtComprobado = true
        }
      },
      error: (err) => {
        this.expdtComprobado = false
      }
    });
  }
}
