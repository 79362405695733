<div id="escritorio">
   
    <div class="container">
      <div class="subcontainer">
        <h2>Añadir Modulo</h2>
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="labelT" for="nombre">Nombre de Modulo:</label>
                <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="formData.nombre" required>
              </div>
          <div class="form-group">
            <label class="labelT" for="idTemplate">URL de imagen del modulo:</label>
            <input type="text" class="form-control" id="idTemplate" name="idTemplate" [(ngModel)]="formData.urlImg" >
          </div>
          <br>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button type="submit" routerLink="/dashboard" class="btn btn-warning">Cancelar </button>
        </form>
      </div>
        
      </div>
      
    <app-taskbar></app-taskbar>
</div>