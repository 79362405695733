import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';
import { Constantes } from '../../_services/constantes.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: false // Asegúrate de que standalone esté establecido en false
})
export class ProfileComponent {
  nombreUsu : string = "nombreUsu";
  apellidoUsu : string = "apellidoUsu";
  nombreCompleto : string = "";
  isAdmin: any;
  tokenJWT: string = "";
  userEmail : string = '';
  constructor(private router: Router, private http: HttpClient, private authService: AuthService){

  }

  ngOnInit(): void {
    // Recupera el token JWT del localStorage
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto' ) {
      this.router.navigate(['/login']); 
    } else {
      const token = localStorage.getItem('jwtToken');
      if(token != null){
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) {
          var strHead = {'Authorization': `Bearer ${this.tokenJWT}`};
          const headers = new HttpHeaders(strHead);
          this.http.get<any>(Constantes.GET_ROL, { headers: headers }).subscribe({
            next: data => {
              this.isAdmin = data;
            },
            error: err => {
                console.log(err);
            }
          });
          this.http.get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers }).subscribe({
            next: data => {
              this.apellidoUsu= data.apellidosUsuario;
              this.nombreUsu=data.nombreUsuario;
              this.nombreCompleto = this.nombreUsu + " " + this.apellidoUsu;
            },
            error: err => {
                console.log(err);
            }
          });
          this.http.get<any>(Constantes.GETUSUARIODB, { headers: headers }).subscribe({
            next: data => {
              this.userEmail = data.emailUsuario;
            },
            error: err => {
                console.log(err);
            }
          });
        } else {
          // Maneja el caso donde el token JWT no está presente en el localStorage
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        // Maneja el caso donde el token JWT no está presente en el localStorage
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear(); 
        this.router.navigate(['/login']); 
      }
      // Si el token está presente, puedes usarlo en tus solicitudes HTTP
    }
  }
}
