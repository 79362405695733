import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-previsiones-finales',
  templateUrl: './modal-previsiones-finales.component.html',
  styleUrl: './modal-previsiones-finales.component.css'
})
export class ModalPrevisionesFinalesComponent {

  previsionesFinales : string = "";
  idConta = "";
  ejercicio = "";
  tokenJWT="";
  constructor(public dialogRef: MatDialogRef<ModalPrevisionesFinalesComponent>,private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idConta= data.contabilidad.actionType;
    this.ejercicio = data.eje.actionType;

  }
 
  closeDialog(): void {
    this.dialogRef.close(true);
  }

  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) { //No hay aplicacion presupuestaria
        
          var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.authService.generarValorPropuesta(this.idConta, this.ejercicio, headers)
            .subscribe({
              next: (previFin) => {
                this.previsionesFinales = this.formatBudget(parseFloat(previFin.replace(',', '.')));
                
              },
              error: (err) => {
                console.error(err);
                this.showErrorModal(err);
                this.router.navigate(['/gestionGasto/estadoEjecucion']);
              },
            });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        this.openModal('Sesión caducada. Vuelve a introducir tus credenciales.');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  formatBudget(budget: number): string {

    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2 // Optional: limits the maximum digits after the decimal
    }).format(budget);
  }
}
