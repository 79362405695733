import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnApi } from 'ag-grid';
import { GridApi, IToolPanel, IToolPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-custom-filter-panel-propuesta',
  templateUrl: './custom-filter-panel-propuesta.component.html',
  styleUrl: './custom-filter-panel-propuesta.component.css'
})
export class CustomFilterPanelPropuestaComponent {
  @ViewChild('toolPanel', { static: false }) toolPanel!: ElementRef;

  filtro1Checked: boolean = false;
  filtro2Checked: boolean = false;
  filtro3Checked: boolean = false;
  filtro4Checked: boolean = false;
  filtro5Checked: boolean = false;
  private params!: IToolPanelParams;
  private gridApi!: GridApi;
  private columnApi!: ColumnApi;

  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.gridApi = params.api;
  }

  getGui(): HTMLElement {
    return this.toolPanel.nativeElement;
  }

  refresh(): void {
    // Implement if needed
  }

  applyFiltro1(): void {
    this.applyFilters();
  }

  applyFiltro3(): void {
    this.applyFilters();
  }

  applyFiltro4(): void {
    this.applyFilters();
  }

  applyFiltro5(): void {
    this.applyFilters();
  }

  applyFilters(): void {
    const filterModel = this.gridApi.getFilterModel() || {};
    
    const conditions: any[] = [];
    
    if (this.filtro1Checked) {
      conditions.push({ type: 'equals', filter: '0', filterType: 'text' });
    }
    if (this.filtro3Checked) {
      conditions.push({ type: 'equals', filter: 1, filterType: 'text' });
    }
    if (this.filtro4Checked) {
      conditions.push({ type: 'equals', filter: 2, filterType: 'text' });
    }
    if (this.filtro5Checked) {
      conditions.push({ type: 'equals', filter: 3, filterType: 'text' });
    }

    if (conditions.length > 0) {
      filterModel['estadoPropuesta'] = {
        filterType: 'text',
        operator: 'OR',
        conditions: conditions
      };
    } else {
      delete filterModel['estadoPropuesta'];
    }

    this.gridApi.setFilterModel(filterModel);
    this.gridApi.onFilterChanged();
  }
}