<!-- <div id="app">
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a href="#" class="navbar-brand">IntranetFront</a>
    <ul class="navbar-nav mr-auto" routerLinkActive="active">
      <li class="nav-item">
        <a href="/login" class="nav-link" routerLink="login">Home </a>
      </li>
      @if (showAdminBoard) {
        <li class="nav-item">
          <a href="/admin" class="nav-link" routerLink="admin">Configuracion</a>
        </li>
      }
    
      @if (isLoggedIn) {
        <li class="nav-item">
          <a href="/user" class="nav-link" routerLink="user">User</a>
        </li>
      }
    </ul>

    @if (!isLoggedIn) {
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="/register" class="nav-link" routerLink="register">Sign Up</a>
        </li>
        <li class="nav-item">
          <a href="/login" class="nav-link" routerLink="login">Login</a>
        </li>
      </ul>
    } @else {
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="/profile" class="nav-link" routerLink="profile">{{ this?.userNombre  }}({{ this?.userLogin }})</a>
        </li>
        <li class="nav-item">
          <a href class="nav-link" (click)="logout()">LogOut</a>
        </li>
      </ul>
    }
  </nav>
-->

<div id="app">
    <router-outlet></router-outlet>
  </div>
<!-- </div>-->
