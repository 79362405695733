import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnApi } from 'ag-grid';
import { GridApi, IToolPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-custom-filter-panel-estado-cerrado',
  templateUrl: './custom-filter-panel-estado-cerrado.component.html',
  styleUrl: './custom-filter-panel-estado-cerrado.component.css'
})
export class CustomFilterPanelEstadoCerradoComponent {
  @ViewChild('toolPanel', { static: false }) toolPanel!: ElementRef;

  filtro2Checked: boolean = true;
  selectedYear: number = 0;
  contabilidad: number = 0;
  private params!: IToolPanelParams;
  private gridApi!: GridApi;
  private columnApi!: ColumnApi;

  agInit(params: IToolPanelParams): void {
    const storedYear = localStorage.getItem('yearContable');
    if (storedYear) {
      this.selectedYear = parseInt(storedYear, 10); // Convertir a número
    }
    const contabilidad = localStorage.getItem('contabilidad');
    if (contabilidad) {
      this.contabilidad = parseInt(contabilidad, 10); // Convertir a número
    }
    this.params = params;
    this.gridApi = params.api;

    this.applyFiltro2();
  }

  getGui(): HTMLElement {
    return this.toolPanel.nativeElement;
  }

  refresh(): void {
    // Implement if needed
  }

  applyFiltro2(): void {
    const filterModel = this.params.api.getFilterModel() || {};
  
    if (this.filtro2Checked) {
      // Aplicar filtro por año (apl_eje) y contabilidad (id_conta)
      filterModel['id_conta'] = {
        filterType: 'number', // Cambiado a 'number'
        type: 'equals',
        filter: this.contabilidad // Contabilidad seleccionada
      };
    } else {
      delete filterModel['id_conta'];
    }
  
    this.params.api.setFilterModel(filterModel);
    this.params.api.onFilterChanged();
  }
}
