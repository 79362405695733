import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { EliminarFilaComponent } from '../eliminar-fila/eliminar-fila.component';

@Component({
  selector: 'app-buttons-acctions',
  template: `
    <button (click)="clickEditar()" class="btn btn-primary">Editar</button>
    <button (click)="clickEliminar()" class="btn btn-danger">Eliminar</button>
  `,
  styles: [

  ]
})
export class ButtonsAcctionsComponent implements OnInit, ICellRendererAngularComp {
  private params: any;

  value: any;
  private valorAntiguo : any;

  constructor(private router: Router, public dialog: MatDialog){}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    var key = Object.keys(params.data)[0];
    if(key=="idConfiguracionGeneral"){
      this.valorAntiguo=this.params.data;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  ngOnInit(): void {
  }

  clickEditar(): void {
    console.log('Edit clicked for row:',  this.params.data);
    const keys = Object.keys(this.params.data);
    switch(keys[0]){ //Si obtenemos idUsuario de las keys del objeto es un Usuario a editar, por lo que nos vamos a editarUsuario y asi con Modulos y Permisos
      default:
      case 'idUsuario':
        localStorage.setItem('usuarioGrid',JSON.stringify(this.params.data)); //en json para poder obtenerlo
        localStorage.setItem('fromClick','true');
        this.router.navigate(['/editarUsuario']);
        break;
      case 'idModulo':
        localStorage.setItem('moduloGrid',JSON.stringify(this.params.data));
        localStorage.setItem('fromClick','true');
        this.router.navigate(['/editarModulo']);
        break;
      case 'idPermiso':
        localStorage.setItem('permisoGrid',JSON.stringify(this.params.data));
        localStorage.setItem('fromClick','true');
        this.router.navigate(['/editarPermiso']);
        break;
      case 'idTemplate':
        localStorage.setItem('templateGrid',JSON.stringify(this.params.data));
        localStorage.setItem('fromClick','true');
        this.router.navigate(['/editarTemplate']);
        break;
      case 'idGrupo':
        localStorage.setItem('grupoGrid',JSON.stringify(this.params.data));
        localStorage.setItem('fromClick','true');
        this.router.navigate(['/editarGrupo']);
        break;
        case 'idConfiguracionGeneral':
          if(this.valorAntiguo!=this.params.data){ //Si difieren, se  ha editado algun dato con AGGrid. Si no, esta igual

            
          }
          break;
        //Se modificarán si se añaden mas columnas
      
    }
  }

  clickEliminar(): void {
    console.log('Edit clicked for row:',  this.params.data);

    const keys = Object.keys(this.params.data);
    switch(keys[0]){ //Si obtenemos idUsuario de las keys del objeto es un Usuario a editar, por lo que nos vamos a editarUsuario y asi con Modulos y Permisos
      default:
      case 'idUsuario':
        localStorage.setItem('tipoTabla',"usuario");
        break;
      case 'idModulo':
        localStorage.setItem('tipoTabla',"modulo");
        break;
      case 'idPermiso':
        localStorage.setItem('tipoTabla',"permiso");
        break;
      case 'idTemplate':
        localStorage.setItem('tipoTabla',"template");
        break;
      case 'idGrupo':
        localStorage.setItem('tipoTabla',"grupo");
        break;
        //Se modificarán si se añaden mas columnas
    }
    localStorage.setItem('datosDelete',JSON.stringify(this.params.data));
    const dialogRef = this.dialog.open(EliminarFilaComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas."}
    });
  }
}

