import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class Constantes {
  public static ENLACE_BACK: string;
  public static AUTH_LOGIN: string;
  public static GET_ROL: string;
  public static GETUSUARIO_BY_ID: string;
  public static GETUSUARIO_BY_EMAIL: string;
  public static GETUSUARIOS: string;
  public static GETUSUARIODB: string;

  // Modulo
  public static GETMODULO_BY_ID: string;
  public static GETMODULOSDB: string;
  public static GETMODULOSVM: string;

  // Permiso
  public static GET_PERMISO_W_MODULO: string;
  public static GET_PERMISOS: string;
  public static GET_PERMISO_BY_ID: string;
  public static GET_PERMISO_BY_NOMBREPERMISO: string;

  // Template
  public static GET_TEMPLATES: string;
  public static GET_TEMPLATE_BY_ID: string;

  // Usuario_Permiso
  public static GET_PERMISO_W_USUARIO: string;

  // VistaEstadoEjecucion
  public static GETVISTA_ESTADOEJECUCION: string;
  public static GETVISTA_CONTABILIDAD: string;
  public static GETIDCONTA_FROMYEAR: string;
  public static GETDESCUENTO_APLICACION: string;
  public static GETAPLICACION_PRESUPUESTARIA: string;

  // Propuesta
  public static GETPROPUESTA: string;
  public static GETPROPUESTAS: string;
  public static ADDPROPUESTA: string;
  public static BUSCAR_EXPDT_GESTIONA: string;
  public static GET_VALOR_PROPUESTA: string;
  public static UPDATE_PROPUESTA: string;
  public static DELETE_PROPUESTA: string;


  // GESTIONA
  public static GETALLPROPONENTESPAGED: string;
  public static GETEXPDTPAGED: string;
  public static GETJOBFROMPROPONENTE: string;
  public static GET_URI: string;
  public static UPLOAD_PDF: string;
  public static ADD_ARCHIVO_TO_EXPDT: string;
  public static CREAR_EXPEDIENTE: string;
  public static ABRIR_EXPEDIENTE: string;
  public static CIRCUITO_TRAMITACION: string;
  public static COMPRUEBA_EXPDT: string;
  public static GETHREFFROMPROPONENTE: string;
  public static DOWNLOAD_PDF: string;
  public static ANULAPDF_GESTIONA: string;

  //JUSTIFICANTES APLIC
  public static GET_LIST_JUST_APLIC: string;

  // Grupo
  public static GET_GRUPOS: string;
  public static GET_GRUPOSDB: string;
  public static GET_GRUPOS_BY_ID: string;

  // Usuario_Grupo
  public static GET_GRUPO_W_USUARIO: string;
  public static GET_USUARIO_W_GRUPO: string;

  // Modulo_Grupo
  public static GET_GRUPO_W_MOD: string;
  public static GET_MOD_W_GRUPO: string;

  // Configuracion
  public static GET_CONFIGGEN: string;
  public static GET_CONFIGGEN_BYID: string;
  public static ADD_CONFIGGEN: string;
  public static UPDATE_CONFIGGEN: string;
  public static DELETE_CONFIGGEN: string;
  public static MANTENIMIENTO_CONFIGGENERAL: string;

  // Auditoria Usuario
  public static GET_AUDIT_USU: string;
  public static GET_AUDIT_USU_BY_USU: string;
  public static GET_AUDIT_USU_BY_ID: string;

  // Auditoria Permiso
  public static GET_AUDIT_PER: string;
  public static GET_AUDIT_PER_BY_PER: string;
  public static GET_AUDIT_PER_BY_ID: string;

  // Auditoria Usuario_Permiso
  public static GET_AUDIT_USU_PER: string;
  public static GET_AUDIT_USU_PER_BY_USU: string;
  public static GET_AUDIT_USU_PER_BY_PER: string;
  public static GET_AUDIT_USU_PER_BY_ID: string;

  // Auditoria Modulo
  public static GET_AUDIT_MOD: string;
  public static GET_AUDIT_MOD_BY_MOD: string;
  public static GET_AUDIT_MOD_BY_ID: string;

  // Auditoria Template
  public static GET_AUDIT_TEMPL: string;
  public static GET_AUDIT_TEMPL_BY_TEMPL: string;
  public static GET_AUDIT_TEMPL_BY_ID: string;

  // Auditoria Grupo
  public static GET_AUDIT_GRU: string;
  public static GET_AUDIT_GRU_BY_GRU: string;
  public static GET_AUDIT_GRU_BY_ID: string;

  // Auditoria Usuario_Grupo
  public static GET_AUDIT_USU_GRU: string;
  public static GET_AUDIT_USU_GRU_BY_USU: string;
  public static GET_AUDIT_USU_GRU_BY_GRU: string;
  public static GET_AUDIT_USU_GRU_BY_ID: string;

  // Auditoria Modulo_Grupo
  public static GET_AUDIT_MOD_GRU: string;
  public static GET_AUDIT_MOD_GRU_BY_MOD: string;
  public static GET_AUDIT_MOD_GRU_BY_GRU: string;
  public static GET_AUDIT_MOD_GRU_BY_ID: string;

  // Tabla AccesosDirectos (Usuario_Modulo)
  public static GET_ACCESOS_DIRECTOS: string;
  public static GET_ACCESOS_DIRECTOS_BY_MOD: string;
  public static GET_ACCESOS_DIRECTOS_BY_USU: string;
  public static GET_ACESSOS_DIRECTOS_BY_ID: string;

  // Auditoria AccesosDirectos (Usuario_Modulo)
  public static GET_AUDIT_ACCESOS_DIRECTOS: string;
  public static GET_AUDIT_ACCESOS_DIRECTOS_BY_MOD: string;
  public static GET_AUDIT_ACCESOS_DIRECTOS_BY_USU: string;
  public static GET_AUDIT_ACESSOS_DIRECTOS_BY_ID: string;
  // Auditoria Propuesta
  public static ADD_AUDIT_PROPUESTA: string;
  public static UPDATE_AUDIT_PROPUESTA: string;

  // Auditoria ConfiguracionGeneral
  public static ADD_AUDIT_CONFIGGENERAL: string;
  public static UPDATE_AUDIT_CONFIGGENERAL: string;
  public static GET_AUDIT_CONFIGGENERAL_BYID: string;

  // Nuevas constantes
  public static AUTH_API: string;
  public static AUTH_MOD: string;
  public static AUTH_PERM: string;
  public static AUTH_TEMPL: string;
  public static AUTH_GRU: string;
  public static AUTH_USUPER: string;
  public static AUTH_USUGRU: string;
  public static AUTH_MODGRU: string;
  public static AUTH_ACCESODIRECTO: string;
  public static AUTH_AUDIT_API: string;
  public static AUTH_AUDIT_MOD: string;
  public static AUTH_AUDIT_PERM: string;
  public static AUTH_AUDIT_TEMPL: string;
  public static AUTH_AUDIT_GRU: string;
  public static AUTH_AUDIT_USUPER: string;
  public static AUTH_AUDIT_USUGRU: string;
  public static AUTH_AUDIT_MODGRU: string;
  public static AUTH_AUDIT_ACCESODIRECTO: string;
  public static VER_ACCIONES_PROPUESTA :string ;


  // VistaIngresosPrevisionesFinales
  public static GET_PREVISIONES: string;

  // VistaEstadoPropuesta
  public static GETVISTA_ESTADOPROPUESTAS: string;
  public static GETVISTA_ESTADOPROPUESTA: string;

  // VistaPropuestasCerradas
  public static GETVISTA_PROPUESTACERRADA: string;
  public static GETVISTA_PROPUESTASCERRADAS: string;
  public static GETVISTA_PROPUESTACERRADARAMIFICA: string;

  // VistaDetalleCerradas
  public static GETVISTA_DETALLECERRADA: string;
  public static GETVISTA_DETALLECERRADAS: string;
  public static GETVISTA_DETALLECERRADA_W_OPERAPADRE: string;

  // VistaCargoPropuestas
  public static GETVISTA_CARGOPROPUESTAS: string;
  public static GETVISTA_CARGOPROPUESTA: string;

  // VistaCargoPropuestasSuma
  public static GETVISTA_CARGOPROPUESTASSUMAS: string;
  public static GETVISTA_CARGOPROPUESTASUMA: string;

  constructor(private configService: ConfigService) {
    this.initializeConstants();
  }

  private initializeConstants() {
    const config = this.configService.getConfig();

    if (config) {
      Constantes.ENLACE_BACK = config.ENLACE_BACK;

      // Login
      Constantes.AUTH_LOGIN = Constantes.ENLACE_BACK + 'Auth/Login/';

      // Usuario
      Constantes.GET_ROL = Constantes.ENLACE_BACK + 'Usuario/GetRol/';
      Constantes.GETUSUARIO_BY_ID = Constantes.ENLACE_BACK + 'Usuario/GetUsuarioDBByIDs/';
      Constantes.GETUSUARIO_BY_EMAIL = Constantes.ENLACE_BACK + 'Usuario/GetUsuarioByEmail/';
      Constantes.GETUSUARIOS = Constantes.ENLACE_BACK + 'Usuario/GetUsuarios/';
      Constantes.GETUSUARIODB = Constantes.ENLACE_BACK + 'Usuario/GetUsuarioDB/';

      // Modulo
      Constantes.GETMODULO_BY_ID = Constantes.ENLACE_BACK + 'Modulo/GetModuloDBByIDs/';
      Constantes.GETMODULOSDB = Constantes.ENLACE_BACK + 'Modulo/GetModulosDB/';
      Constantes.GETMODULOSVM = Constantes.ENLACE_BACK + 'Modulo/GetModulos/';

      // Permiso
      Constantes.GET_PERMISO_W_MODULO = Constantes.ENLACE_BACK + 'Permiso/GetPermisosDB_W_Modulo/';
      Constantes.GET_PERMISOS = Constantes.ENLACE_BACK + 'Permiso/GetPermisos/';
      Constantes.GET_PERMISO_BY_ID = Constantes.ENLACE_BACK + 'Permiso/GetPermisoDBByIDs/';
      Constantes.GET_PERMISO_BY_NOMBREPERMISO = Constantes.ENLACE_BACK + 'Permiso/GetPermisoDBByNombrePermiso/';

      // Template
      Constantes.GET_TEMPLATES = Constantes.ENLACE_BACK + 'Template/GetTemplates/';
      Constantes.GET_TEMPLATE_BY_ID = Constantes.ENLACE_BACK + 'Template/GetTemplateDBByIDs/';

      // Usuario_Permiso
      Constantes.GET_PERMISO_W_USUARIO = Constantes.ENLACE_BACK + 'UsuarioPermiso/GetPermiso_W_Usuario/';

      // VistaEstadoEjecucion
      Constantes.GETVISTA_ESTADOEJECUCION = Constantes.ENLACE_BACK + 'VistaEstadoEjecucion/GetVistaEstadoEjecucion/';
      Constantes.GETVISTA_CONTABILIDAD = Constantes.ENLACE_BACK + 'VistaEstadoEjecucion/GetVistaCoContabilidad/';
      Constantes.GETIDCONTA_FROMYEAR = Constantes.ENLACE_BACK + 'VistaEstadoEjecucion/GetIdContaFromYear/';
      Constantes.GETDESCUENTO_APLICACION = Constantes.ENLACE_BACK + 'VistaEstadoEjecucion/GetDescuentoAplicacion/';
      Constantes.GETAPLICACION_PRESUPUESTARIA = Constantes.ENLACE_BACK + 'VistaEstadoEjecucion/GetAplicacionPresupuestaria/';


      // Propuesta
      Constantes.GETPROPUESTA = Constantes.ENLACE_BACK + 'Propuesta/GetPropuesta/';
      Constantes.GETPROPUESTAS = Constantes.ENLACE_BACK + 'Propuesta/GetPropuestas/';
      Constantes.ADDPROPUESTA = Constantes.ENLACE_BACK + 'Propuesta/AddPropuesta/';
      Constantes.BUSCAR_EXPDT_GESTIONA = Constantes.ENLACE_BACK + 'Propuesta/BuscarExpdtGestiona/';
      Constantes.GET_VALOR_PROPUESTA = Constantes.ENLACE_BACK + 'Propuesta/GetPropuesta/';
      Constantes.UPDATE_PROPUESTA = Constantes.ENLACE_BACK + 'Propuesta/PutPropuesta/';
      Constantes.DELETE_PROPUESTA = Constantes.ENLACE_BACK + 'Propuesta/DeletePropuesta/';

      // Gestiona
      Constantes.GETALLPROPONENTESPAGED = Constantes.ENLACE_BACK + 'Gestiona/GetAllProponentesPaged/';
      Constantes.GETEXPDTPAGED = Constantes.ENLACE_BACK + 'Gestiona/GetAllExpdtPaged/';
      Constantes.GETJOBFROMPROPONENTE = Constantes.ENLACE_BACK + 'Gestiona/GetCargoFromIdPropoponente/';
      Constantes.GET_URI = Constantes.ENLACE_BACK + 'Gestiona/CrearURI';
      Constantes.UPLOAD_PDF = Constantes.ENLACE_BACK + 'Gestiona/UploadPDF';
      Constantes.ADD_ARCHIVO_TO_EXPDT = Constantes.ENLACE_BACK + "Gestiona/AddArchivoToExpdt";
      Constantes.CREAR_EXPEDIENTE = Constantes.ENLACE_BACK + "Gestiona/CrearExpediente";
      Constantes.ABRIR_EXPEDIENTE = Constantes.ENLACE_BACK + "Gestiona/AbrirExpediente";
      Constantes.CIRCUITO_TRAMITACION = Constantes.ENLACE_BACK + "Gestiona/CircuitoTramitacion";
      Constantes.COMPRUEBA_EXPDT = Constantes.ENLACE_BACK + "Gestiona/CompruebaExpdt";
      Constantes.GETHREFFROMPROPONENTE = Constantes.ENLACE_BACK + 'Gestiona/GetHrefFromIdPropoponente/';
      Constantes.DOWNLOAD_PDF = Constantes.ENLACE_BACK + 'Gestiona/GetPDFFromId/';
      Constantes.ANULAPDF_GESTIONA = Constantes.ENLACE_BACK + 'Gestiona/CancelarTramitacion/';

      // Justificantes Aplic
      Constantes.GET_LIST_JUST_APLIC = Constantes.ENLACE_BACK + "JustificantesAplicacion/GetListaJustificantesAplicacion/"

      // Grupo
      Constantes.GET_GRUPOS = Constantes.ENLACE_BACK + 'Grupo/GetGrupos/';
      Constantes.GET_GRUPOSDB = Constantes.ENLACE_BACK + 'Grupo/GetGruposDB/';
      Constantes.GET_GRUPOS_BY_ID = Constantes.ENLACE_BACK + 'Grupo/GetGrupoDBByIDs/';

      // Usuario_Grupo
      Constantes.GET_GRUPO_W_USUARIO = Constantes.ENLACE_BACK + 'UsuarioGrupo/GetGrupo_W_Usuario/';
      Constantes.GET_USUARIO_W_GRUPO = Constantes.ENLACE_BACK + 'UsuarioGrupo/GetUsuarios_W_Grupo/';

      // Modulo_Grupo
      Constantes.GET_GRUPO_W_MOD = Constantes.ENLACE_BACK + 'ModuloGrupo/GetGrupo_W_Modulo/';
      Constantes.GET_MOD_W_GRUPO = Constantes.ENLACE_BACK + 'ModuloGrupo/GetModulos_W_Grupo/';

      //Configuracion
      Constantes.GET_CONFIGGEN = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/GetConfiguracionGeneral/';
      Constantes.GET_CONFIGGEN_BYID = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/GetConfiguracionGeneralByIDs/';
      Constantes.ADD_CONFIGGEN = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/PostConfiguracionGeneral/';
      Constantes.UPDATE_CONFIGGEN = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/PutConfiguracionGeneral';
      Constantes.DELETE_CONFIGGEN = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/DeleteConfiguracionGeneral/';
      Constantes.MANTENIMIENTO_CONFIGGENERAL = Constantes.ENLACE_BACK + 'ConfiguracionGeneral/SetMantenimiento/';



      // Auditoria Usuario
      Constantes.GET_AUDIT_USU = Constantes.ENLACE_BACK + 'AuditoriaUsuario/GetAuditoriaUsuarios/';
      Constantes.GET_AUDIT_USU_BY_USU = Constantes.ENLACE_BACK + 'AuditoriaUsuario/GetAuditoriaUsuarios_ByUsuario/';
      Constantes.GET_AUDIT_USU_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaUsuario/GetAuditoriaUsuarioDBByIDs/';

      // Auditoria Permiso
      Constantes.GET_AUDIT_PER = Constantes.ENLACE_BACK + 'AuditoriaPermiso/GetAuditoriaPermisos/';
      Constantes.GET_AUDIT_PER_BY_PER = Constantes.ENLACE_BACK + 'AuditoriaPermiso/GetAuditoriaPermisos_ByPermiso/';
      Constantes.GET_AUDIT_PER_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaPermiso/GetAuditoriaPermisoDBByIDs/';

      // Auditoria Usuario_Permiso
      Constantes.GET_AUDIT_USU_PER = Constantes.ENLACE_BACK + 'AuditoriaUsuarioPermiso/GetAuditoriaUsuarioPermisos/';
      Constantes.GET_AUDIT_USU_PER_BY_USU = Constantes.ENLACE_BACK + 'AuditoriaUsuarioPermiso/GetAuditoriaUsuarioPermisos_ByUsuario/';
      Constantes.GET_AUDIT_USU_PER_BY_PER = Constantes.ENLACE_BACK + 'AuditoriaUsuarioPermiso/GetAuditoriaUsuarioPermisos_ByPermiso/';
      Constantes.GET_AUDIT_USU_PER_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaUsuarioPermiso/GetAuditoriaUsuarioPermisoDBByIDs/';

      // Auditoria Modulo
      Constantes.GET_AUDIT_MOD = Constantes.ENLACE_BACK + 'AuditoriaModulo/GetAuditoriaModulos/';
      Constantes.GET_AUDIT_MOD_BY_MOD = Constantes.ENLACE_BACK + 'AuditoriaModulo/GetAuditoriaModulos_ByModulo/';
      Constantes.GET_AUDIT_MOD_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaModulo/GetAuditoriaModuloDBByIDs/';

      // Auditoria Template
      Constantes.GET_AUDIT_TEMPL = Constantes.ENLACE_BACK + 'AuditoriaTemplate/GetAuditoriaTemplates/';
      Constantes.GET_AUDIT_TEMPL_BY_TEMPL = Constantes.ENLACE_BACK + 'AuditoriaTemplate/GetAuditoriaTemplates_ByTemplate/';
      Constantes.GET_AUDIT_TEMPL_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaTemplate/GetAuditoriaTemplateDBByIDs/';

      // Auditoria Grupo
      Constantes.GET_AUDIT_GRU = Constantes.ENLACE_BACK + 'AuditoriaGrupo/GetAuditoriaGrupos/';
      Constantes.GET_AUDIT_GRU_BY_GRU = Constantes.ENLACE_BACK + 'AuditoriaGrupo/GetAuditoriaGrupos_ByGrupo/';
      Constantes.GET_AUDIT_GRU_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaGrupo/GetAuditoriaGrupoDBByIDs/';

      // Auditoria Usuario_Grupo
      Constantes.GET_AUDIT_USU_GRU = Constantes.ENLACE_BACK + 'AuditoriaUsuarioGrupo/GetAuditoriaUsuarioGrupos/';
      Constantes.GET_AUDIT_USU_GRU_BY_USU = Constantes.ENLACE_BACK + 'AuditoriaUsuarioGrupo/GetAuditoriaUsuarioGrupos_ByUsuario/';
      Constantes.GET_AUDIT_USU_GRU_BY_GRU = Constantes.ENLACE_BACK + 'AuditoriaUsuarioGrupo/GetAuditoriaUsuarioGrupos_ByGrupo/';
      Constantes.GET_AUDIT_USU_GRU_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaUsuarioGrupo/GetAuditoriaUsuarioGrupoDBByIDs/';

      // Auditoria Modulo_Grupo
      Constantes.GET_AUDIT_MOD_GRU = Constantes.ENLACE_BACK + 'AuditoriaModuloGrupo/GetAuditoriaModuloGrupos/';
      Constantes.GET_AUDIT_MOD_GRU_BY_MOD = Constantes.ENLACE_BACK + 'AuditoriaModuloGrupo/GetAuditoriaModuloGrupos_ByModulo/';
      Constantes.GET_AUDIT_MOD_GRU_BY_GRU = Constantes.ENLACE_BACK + 'AuditoriaModuloGrupo/GetAuditoriaModuloGrupos_ByGrupo/';
      Constantes.GET_AUDIT_MOD_GRU_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaModuloGrupo/GetAuditoriaModuloGrupoDBByIDs/';

      // Tabla AccesosDirectos (Usuario_Modulo)
      Constantes.GET_ACCESOS_DIRECTOS = Constantes.ENLACE_BACK + 'AccesoDirecto_Modulo/GetAccesoDirecto_Modulos/';
      Constantes.GET_ACCESOS_DIRECTOS_BY_MOD = Constantes.ENLACE_BACK + 'AccesoDirecto_Modulo/GetAccesoDirectoModulos_W_Modulo/';
      Constantes.GET_ACCESOS_DIRECTOS_BY_USU = Constantes.ENLACE_BACK + 'AccesoDirecto_Modulo/GetAccesoDirectoModulos_W_Usuario/';
      Constantes.GET_ACESSOS_DIRECTOS_BY_ID = Constantes.ENLACE_BACK + 'AccesoDirecto_Modulo/GetAccesoDirectoModuloById';

      // Auditoria AccesosDirectos (Usuario_Modulo)
      Constantes.GET_AUDIT_ACCESOS_DIRECTOS = Constantes.ENLACE_BACK + 'AuditoriaAccesoDirectoModulo/GetAuditoriaAccesoDirectoModulos';
      Constantes.GET_AUDIT_ACCESOS_DIRECTOS_BY_MOD = Constantes.ENLACE_BACK + 'AuditoriaAccesoDirectoModulo/GetAuditoriaAccesoDirectoModulos_ByModulo/';
      Constantes.GET_AUDIT_ACCESOS_DIRECTOS_BY_USU = Constantes.ENLACE_BACK + 'AuditoriaAccesoDirectoModulo/GetAuditoriaAccesoDirectoModulos_ByUsuario/';
      Constantes.GET_AUDIT_ACESSOS_DIRECTOS_BY_ID = Constantes.ENLACE_BACK + 'AuditoriaAccesoDirectoModulo/GetAuditoriaAccesoDirectoModulos_ById';

      // VistaIngresosPrevisionesFinales
      Constantes.GET_PREVISIONES = Constantes.ENLACE_BACK + 'VistaIngresosPrevisiones/GetPrevisionesFinales/';

      // VistaEstadoPropuestas
      Constantes.GETVISTA_ESTADOPROPUESTAS = Constantes.ENLACE_BACK + 'VistaEstadoPropuesta/GetVistaEstadoPropuestas/';
      Constantes.GETVISTA_ESTADOPROPUESTA = Constantes.ENLACE_BACK + 'VistaEstadoPropuesta/GetVistaEstadoPropuesta/';

      // VistaPropuestaCerrada
      Constantes.GETVISTA_PROPUESTASCERRADAS = Constantes.ENLACE_BACK + 'VistaPropuestaCerrada/GetVistaPropuestaCerradas/';
      Constantes.GETVISTA_PROPUESTACERRADA = Constantes.ENLACE_BACK + 'VistaPropuestaCerrada/GetVistaPropuestaCerrada/';
      Constantes.GETVISTA_PROPUESTACERRADARAMIFICA = Constantes.ENLACE_BACK + 'VistaPropuestaCerrada/GetVistaPropuestaCerradaRamifica/';

      // VistaDetalleCerrado
      Constantes.GETVISTA_DETALLECERRADAS = Constantes.ENLACE_BACK + 'VistaDetalleCerrada/GetVistaDetalleCerradas/';
      Constantes.GETVISTA_DETALLECERRADA = Constantes.ENLACE_BACK + 'VistaDetalleCerrada/GetVistaDetalleCerrada/';
      Constantes.GETVISTA_DETALLECERRADA_W_OPERAPADRE = Constantes.ENLACE_BACK + 'VistaDetalleCerrada/GetVistaDetalleCerradas_Operapadre/';

      // VistaCargoPropuestas
      Constantes.GETVISTA_CARGOPROPUESTAS = Constantes.ENLACE_BACK + 'VistaCargosPropuestas/GetVistaCargoPropuestas/';
      Constantes.GETVISTA_CARGOPROPUESTA = Constantes.ENLACE_BACK + 'VistaCargosPropuestas/GetVistaCargoPropuesta/';

      // VistaCargoPropuestasSuma
      Constantes.GETVISTA_CARGOPROPUESTASSUMAS = Constantes.ENLACE_BACK + 'VistaCargosPropuestasSuma/GetVistaCargoPropuestasSuma/';
      Constantes.GETVISTA_CARGOPROPUESTASUMA = Constantes.ENLACE_BACK + 'VistaCargosPropuestasSuma/GetVistaCargoPropuestaSuma/';


      //Auditoria Propuesta
      Constantes.ADD_AUDIT_PROPUESTA = Constantes.ENLACE_BACK + "AuditoriaPropuesta/PostAuditoriaPropuesta/";
      Constantes.UPDATE_AUDIT_PROPUESTA = Constantes.ENLACE_BACK + "AuditoriaPropuesta/PutAuditoriaPropuesta/";

      //Auditoria Configuracion General
      Constantes.ADD_AUDIT_CONFIGGENERAL = Constantes.ENLACE_BACK + "AuditoriaConfiguracionGeneral/PostAuditoriaConfiguracionGeneral/";

      Constantes.UPDATE_AUDIT_CONFIGGENERAL = Constantes.ENLACE_BACK + "AuditoriaConfiguracionGeneral/PutAuditoriaConfiguracionGeneral/";

      Constantes.GET_AUDIT_CONFIGGENERAL_BYID = Constantes.ENLACE_BACK + "AuditoriaConfiguracionGeneral/GetAuditoriaConfiguracionGeneralByIDs/";

      // Nuevas constantes
      Constantes.AUTH_API = Constantes.ENLACE_BACK + 'Usuario/';
      Constantes.AUTH_MOD = Constantes.ENLACE_BACK + 'Modulo/';
      Constantes.AUTH_PERM = Constantes.ENLACE_BACK + 'Permiso/';
      Constantes.AUTH_TEMPL = Constantes.ENLACE_BACK + 'Template/';
      Constantes.AUTH_GRU = Constantes.ENLACE_BACK + 'Grupo/';
      Constantes.AUTH_USUPER = Constantes.ENLACE_BACK + 'UsuarioPermiso/';
      Constantes.AUTH_USUGRU = Constantes.ENLACE_BACK + 'UsuarioGrupo/';
      Constantes.AUTH_MODGRU = Constantes.ENLACE_BACK + 'ModuloGrupo/';
      Constantes.AUTH_ACCESODIRECTO = Constantes.ENLACE_BACK + 'AccesoDirecto_Modulo/';

      Constantes.AUTH_AUDIT_API = Constantes.ENLACE_BACK + 'AuditoriaUsuario/';
      Constantes.AUTH_AUDIT_MOD = Constantes.ENLACE_BACK + 'AuditoriaModulo/';
      Constantes.AUTH_AUDIT_PERM = Constantes.ENLACE_BACK + 'AuditoriaPermiso/';
      Constantes.AUTH_AUDIT_TEMPL = Constantes.ENLACE_BACK + 'AuditoriaTemplate/';
      Constantes.AUTH_AUDIT_GRU = Constantes.ENLACE_BACK + 'AuditoriaGrupo/';

      Constantes.AUTH_AUDIT_USUPER = Constantes.ENLACE_BACK + 'AuditoriaUsuarioPermiso/';
      Constantes.AUTH_AUDIT_USUGRU = Constantes.ENLACE_BACK + 'AuditoriaUsuarioGrupo/';
      Constantes.AUTH_AUDIT_MODGRU = Constantes.ENLACE_BACK + 'AuditoriaModuloGrupo/';
      Constantes.AUTH_AUDIT_ACCESODIRECTO = Constantes.ENLACE_BACK + 'AuditoriaAccesoDirectoModulo/';
      Constantes.VER_ACCIONES_PROPUESTA = "Ver acciones de propuesta";
    } else {
      console.error('Config not loaded!');
    }
  }
}
