import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalesComponent } from '../../../base/modales/modales.component';
import { Constantes } from '../../../_services/constantes.service';
import { NgForm } from '@angular/forms'; // Importa NgForm
import { ModalBuscadorComponent } from '../modal-buscador/modal-buscador.component';
import { ModalContratoObraComponent } from '../modal-contrato-obra/modal-contrato-obra.component';
import { ModalBuscaAplpresupuestariaComponent } from '../modal-busca-aplpresupuestaria/modal-busca-aplpresupuestaria.component';

@Component({
  selector: 'app-dividir-propuesta-v2',
  templateUrl: './dividir-propuesta-v2.component.html',
  styleUrl: './dividir-propuesta-v2.component.css'
})
export class DividirPropuestaV2Component {
  submitted = false;
  idProponente: string = '';
  contabilidad: number = 0;
  propuestaForm: {
    codApliPresupuestaria: string,
    proponente: string,
    responsable: string,
    cargo: string,
    cargoResponsable: string,
    expediente: string,
    fechaPropuesta: Date | null,
    objeto: string,
    necesidad: string,
    plazo: string,
    importe: number,
    contratoDeObra: boolean
  } = {
      codApliPresupuestaria: '',
      proponente: '',
      responsable: '',
      cargo: '',
      cargoResponsable: '',
      expediente: '',
      fechaPropuesta: new Date(),
      objeto: '',
      necesidad: '',
      plazo: '',
      importe: 0,
      contratoDeObra: false,
    };
  tokenJWT: string = '';
  selectedRowData: any;
  aplicacion: boolean = false;
  usuControl: string = '';
  ejercicio: number = 0;
  codProg: string = '';
  codEco: string = '';
  dniProp: string = '';
  boolIdGes: boolean = false;
  idPropuesta: string = "";
  nombrePropuesta: string = "";
  calculatedValues: any; //Para traspasar datos
  calculatedValuesDos: any; //Para traspasar datos del segundo pdf ramificado
  pdfRecibido: any; //El pdf obtenido de la generacion de PDF
  pdfRecibidoDos: any; //El pdf obtenido de la generacion de PDF
  idUsuarioActual = "";
  hrefSolicitado = "";
  contratoObraCB: boolean = false;
  idExpdtAsoc: string = "";
  nombreExpdt: string = "";
  expdtComprobado: boolean = false;
  expedienteGestiona: string = "";
  desc_aplic: string = "";
  fechaControl = new Date();
  saldo: number = 0;
  desc_Apli = "";
  datosPadre: any;
  creditoRetenido: number = 0;
  importeUtilizado: number = 0;
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const loginCorrecto = localStorage.getItem('loginCorrecto');
    if (loginCorrecto == null || loginCorrecto !== 'correcto') {
      this.router.navigate(['/login']);
    } else {
      const token = localStorage.getItem('jwtToken');
      if (token != null) {
        this.tokenJWT = token;
        if (!this.authService.isTokenExpired(this.tokenJWT)) { //No hay aplicacion presupuestaria
          this.ejercicio = 0;
          this.codProg = '';
          this.codEco = '';
          var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
          const headers = new HttpHeaders(strHead);
          this.http
            .get<any>(Constantes.GETUSUARIO_BY_EMAIL, { headers: headers })
            .subscribe({
              next: (data) => {
                this.usuControl = `${data.nombreUsuario} ${data.apellidosUsuario}`;
                this.idUsuarioActual = data.idUsuario;
                const valoresPropuesta = JSON.parse(localStorage.getItem('propuestaRamificada') || '{}'); //Lo pasamos de JSON a objeto de nuevo
                // localStorage.removeItem('propuestaRamificada');
                this.datosPadre = valoresPropuesta;
                this.datosPadre.estadoPropuesta = 3; //Es 3=Anulado
                var cadena = Constantes.GETVISTA_PROPUESTACERRADARAMIFICA + `${valoresPropuesta.id_conta}` + "/" + `${valoresPropuesta.id_opera}/`;
                this.http
                  .get<any>(cadena, { headers: headers }).subscribe({
                    next: (datosCerrado: any) => {
                      this.ejercicio = datosCerrado.eje_presu;
                      this.codProg = datosCerrado.cod_cla_funci;
                      this.codEco = datosCerrado.cod_cla_econo;
                      this.desc_Apli = datosCerrado.des_aplic;
                      this.nombrePropuesta = datosCerrado.txt_opera;
                      this.saldo = datosCerrado.saldo;
                      this.creditoRetenido = datosCerrado.imp_liqui;
                      this.importeUtilizado = datosCerrado.imp_rectif;
                      this.propuestaForm.codApliPresupuestaria = datosCerrado.eje_presu + "." + datosCerrado.cod_cla_funci + "." + datosCerrado.cod_cla_econo;
                      this.contabilidad = datosCerrado.id_conta;
                      var dividePropuesta = this.nombrePropuesta.split("(");
                      var dividePropuestaDos = dividePropuesta[1].split(")");
                      this.idPropuesta = dividePropuestaDos[0];
                      if (this.idPropuesta.length != 8) {
                        this.openModal("No se puede dividir una propuesta cerrada sin IDPropuesta. Pruebe con otra");
                        this.router.navigate(['/gestionGasto/cerrado']);
                      } else {
                        this.http
                          .get<any>(Constantes.GETPROPUESTA + this.idPropuesta, { headers: headers }).subscribe({ //Cogemos los datos para posteriormente actualizar la propuesta
                            next: (datosCerrado: any) => {
                              this.datosPadre = datosCerrado;
                              this.datosPadre.estadoPropuesta = 3; //anulado
                            },
                            error: (err) => {
                              this.showErrorModal(err);
                              localStorage.clear();
                              this.router.navigate(['/login']);
                            }
                          });
                      }

                    },
                    error: (err) => {
                      this.expdtComprobado = false
                    }
                  });

              },
              error: (err) => {
                console.error(err);
                this.showErrorModal(err);
                localStorage.clear();
                this.router.navigate(['/login']);
              },
            });
        } else {
          console.error('Sesión caducada. Vuelve a introducir tus credenciales.');
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      } else {
        console.error('El token JWT no está presente en el localStorage');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }
  }

  // Getter to format the Date object as a string for the input field
  get formattedFechaPropuesta(): string | undefined {
    return (this.propuestaForm.fechaPropuesta?.toISOString().split('T')[0]);
  }

  // Setter to convert the input string back into a Date object
  set formattedFechaPropuesta(value: string) {
    this.propuestaForm.fechaPropuesta = new Date(value);
  }

  onSubmit(form: NgForm) {
    // Añade form como argumento
    this.submitted = true;


    if (this.propuestaForm.expediente === "" && !this.expdtComprobado) {
      this.expdtComprobado = true;
    }
    if (
      !this.propuestaForm.proponente ||
      form.controls['fechaPropuesta']?.invalid ||
      !this.propuestaForm.objeto ||
      form.controls['importe']?.invalid ||
      !this.expdtComprobado
    ) {
      return;
    }

    if (this.propuestaForm.importe > this.saldo || this.propuestaForm.importe < 0) {
      return;
    }
    if (this.propuestaForm.expediente == "") { //Si no hay expediente asociado puede hacer falta codigo de expediente
      const dialogRef = this.dialog.open(ModalContratoObraComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) { //SI
          this.contratoObraCB = true;

        } else { //NO
          this.contratoObraCB = false;
        }
        this.creaPropuesta(form);
      });
    } else {
      this.creaPropuesta(form); //Si hay expediente asociado nos da igual que contrato de obra sea true o false porque no lo vamos a usar
    }

  }

  creaPropuesta(form: NgForm) {

    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);

    const contabilidad = localStorage.getItem('contabilidad') || '0';
    this.contabilidad = parseInt(contabilidad, 10); // Convertir a número

    //HIJO1 Tiene datos de Padre. HIJO2 tiene datos de modificacion
    var proponente = this.propuestaForm.proponente;
    var responsable = this.propuestaForm.responsable;
    var cargo = this.propuestaForm.cargoResponsable;
    var cargoResponsable = this.propuestaForm.cargo;
    var objeto = this.propuestaForm.objeto;
    var necesidad = this.propuestaForm.necesidad;
    var plazo = this.propuestaForm.plazo;
    console.log("Nuevos datos de tabla: ", objeto, ",", necesidad, ",", plazo);
    var importe = parseFloat(this.propuestaForm.importe.toString().replace(',', '.'));
    var fechaPropuesta = this.propuestaForm.fechaPropuesta ? new Date(this.propuestaForm.fechaPropuesta) : new Date();
    var expediente = this.propuestaForm.expediente;
    this.expedienteGestiona = '';
    var dniPropon = this.dniProp;
    var desc_apli = this.desc_Apli;
    var nombrePartida =
      desc_apli +
      ' (' +
      this.ejercicio +
      '.' +
      this.codProg +
      '.' +
      this.codEco +
      ')';

    var importeUno: number = this.saldo - importe;
    var importeDos: number = importe;

    var contratoObra = this.contratoObraCB;

    this.authService.updatePropuesta(this.datosPadre.idPropuesta, this.datosPadre.proponente, this.datosPadre.responsable, this.datosPadre.cargo, this.datosPadre.cargoResponsable,
      this.datosPadre.objeto, this.datosPadre.necesidad, this.datosPadre.plazo,
      this.datosPadre.usuarioControl, this.datosPadre.fechaControl, this.datosPadre.importe, this.datosPadre.fechaPropuesta, this.datosPadre.expdtAsoc,
      this.datosPadre.estadoPropuesta, this.datosPadre.ejercicio, this.datosPadre.codProg,
      this.datosPadre.codEco, this.datosPadre.idDocumentoGestiona, this.datosPadre.dniProponente, this.datosPadre.idContabilidad,
      this.datosPadre.expdtGestiona, headers).subscribe({

        next: (putDevuelto) => {
          //Con el put devuelto, devolvemos
          console.log("Succesfull");

          this.authService.realizar_Auditoria(putDevuelto, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
            next: (datoStringu) => {
              //Una vez anulado el padre, creamos los hijos:

            },
            error: (err) => {
              console.error('Error: ', err);
              this.showErrorModal(err);

              this.router.navigate(['/login']);
            },
          });
        },
        error: (err) => {

          // Redirigir al usuario a la página de inicio de sesión
          console.log(err);
          this.showErrorModal(err);
          this.router.navigate(['/login']);
        }

      });



    if (expediente == null || expediente == '') {
      this.boolIdGes = true;
      this.authService
        .BuscarExpdtGestiona(this.ejercicio, this.codProg, this.codEco, headers)
        .subscribe({
          next: (data) => {
            this.expedienteGestiona = data;
            if (this.expedienteGestiona == '') {
              //Crear Expdt
              this.authService.CrearExpediente(headers).subscribe({
                next: (data) => {
                  this.authService
                    .AbrirExpediente(data, nombrePartida, headers)
                    .subscribe({
                      next: (dataExpdt: any) => {
                        let parsedDataExpdt;
                        try {
                          parsedDataExpdt = JSON.parse(dataExpdt);
                        } catch (error) {
                          console.error("Error al analizar la cadena JSON:", error);
                          this.openModal("Error al procesar los datos del documento.");
                          return;
                        }
                        this.expedienteGestiona = parsedDataExpdt.code;
                        this.authService
                          .registrar_Propuesta( //HIJO1 tiene los datos de PADRE
                            proponente,
                            responsable,
                            cargo,
                            cargoResponsable,
                            this.datosPadre.objeto, //el nombre de objeto será el mismo
                            necesidad,
                            plazo,
                            importeUno,
                            fechaPropuesta,
                            expediente,
                            this.contabilidad,
                            this.ejercicio,
                            this.codProg,
                            this.codEco,
                            this.usuControl,
                            this.expedienteGestiona,
                            dniPropon,
                            0,
                            headers
                          )
                          .subscribe({
                            next: (data) => {
                              this.idPropuesta = data.idPropuesta;

                              this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                                next: (datoString) => {
                                  console.log("SuccessAudit");


                                  var vecArr: any[] = [];
                                  vecArr.push(data);
                                  vecArr.push(this.desc_Apli);
                                  vecArr.push(contratoObra);
                                  //Necesitamos hacer una variable auxiliar para que calculatedValues
                                  //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                                  this.calculatedValues = vecArr;

                                  // Manually trigger change detection
                                  //this.cdr.detectChanges();
                                  //PropuestaDos
                                  this.authService
                                    .registrar_Propuesta(
                                      proponente,
                                      responsable,
                                      cargo,
                                      cargoResponsable,
                                      objeto,
                                      necesidad,
                                      plazo,
                                      importeDos,
                                      fechaPropuesta,
                                      expediente,
                                      this.contabilidad,
                                      this.ejercicio,
                                      this.codProg,
                                      this.codEco,
                                      this.usuControl,
                                      this.expedienteGestiona,
                                      dniPropon,
                                      0,
                                      headers
                                    )
                                    .subscribe({
                                      next: (data) => {
                                        this.idPropuesta = data.idPropuesta;

                                        this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                                          next: (datoString) => {
                                            console.log("SuccessAudit");
                                          },
                                          error: (err) => {
                                            console.error('Error: ', err);
                                            this.showErrorModal(err);

                                            this.router.navigate(['/login']);
                                          },
                                        });
                                        var vecArr: any[] = [];
                                        vecArr.push(data);
                                        vecArr.push(this.desc_Apli);
                                        vecArr.push(contratoObra);
                                        //Necesitamos hacer una variable auxiliar para que calculatedValues
                                        //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                                        this.calculatedValuesDos = vecArr;

                                        // Manually trigger change detection
                                        this.cdr.detectChanges();
                                      },
                                      error: (err) => {
                                        console.error('Error: ', err);
                                        this.showErrorModal(err);

                                        this.router.navigate(['/login']);
                                      },
                                    });
                                },
                                error: (err) => {
                                  console.error('Error: ', err);
                                  this.showErrorModal(err);

                                  this.router.navigate(['/login']);
                                },
                              });


                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);

                              this.router.navigate(['/login']);
                            },
                          });


                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.openModal("No se ha podido abrir expediente");

                        this.router.navigate(['/gestionGasto/propuestas']);
                      },
                    });
                },
                error: (err) => {
                  console.error('Error: ', err);
                  this.openModal("No se ha podido crear expediente");

                  this.router.navigate(['/gestionGasto/propuestas']);
                },
              });
            } else {
              this.authService
                .registrar_Propuesta(
                  proponente,
                  responsable,
                  cargo,
                  cargoResponsable,
                  this.datosPadre.objeto, //el nombre de objeto será el mismo
                  necesidad,
                  plazo,
                  importeUno,
                  fechaPropuesta,
                  expediente,
                  this.contabilidad,
                  this.ejercicio,
                  this.codProg,
                  this.codEco,
                  this.usuControl,
                  this.expedienteGestiona,
                  dniPropon,
                  0,
                  headers
                )
                .subscribe({
                  next: (data) => {
                    this.idPropuesta = data.idPropuesta;

                    this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                      next: (datoString) => {
                        console.log("SuccessAudit");

                        var vecArr: any[] = [];
                        vecArr.push(data);
                        vecArr.push(this.desc_Apli);
                        vecArr.push(contratoObra);
                        //Necesitamos hacer una variable auxiliar para que calculatedValues
                        //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                        this.calculatedValues = vecArr;

                        // Manually trigger change detection
                        //this.cdr.detectChanges();

                        //PropuestaDos
                        this.authService
                          .registrar_Propuesta(
                            proponente,
                            responsable,
                            cargo,
                            cargoResponsable,
                            objeto,
                            necesidad,
                            plazo,
                            importeDos,
                            fechaPropuesta,
                            expediente,
                            this.contabilidad,
                            this.ejercicio,
                            this.codProg,
                            this.codEco,
                            this.usuControl,
                            this.expedienteGestiona,
                            dniPropon,
                            0,
                            headers
                          )
                          .subscribe({
                            next: (data) => {
                              this.idPropuesta = data.idPropuesta;

                              this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                                next: (datoString) => {
                                  console.log("SuccessAudit");
                                },
                                error: (err) => {
                                  console.error('Error: ', err);
                                  this.showErrorModal(err);

                                  this.router.navigate(['/login']);
                                },
                              });

                              var vecArr: any[] = [];
                              vecArr.push(data);
                              vecArr.push(this.desc_Apli);
                              vecArr.push(contratoObra);
                              //Necesitamos hacer una variable auxiliar para que calculatedValues
                              //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                              this.calculatedValuesDos = vecArr;

                              // Manually trigger change detection
                              this.cdr.detectChanges();
                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);

                              this.router.navigate(['/login']);
                            },
                          });
                      },
                      error: (err) => {
                        console.error('Error: ', err);
                        this.showErrorModal(err);

                        this.router.navigate(['/login']);
                      },
                    });

                  },
                  error: (err) => {
                    console.error('Error: ', err);
                    this.showErrorModal(err);

                    this.router.navigate(['/login']);
                  },
                });


            }
          },
        });
    } else {
      this.authService
        .registrar_Propuesta(
          proponente,
          responsable,
          cargo,
          cargoResponsable,
          this.datosPadre.objeto, //el nombre de objeto será el mismo
          necesidad,
          plazo,
          importeUno,
          fechaPropuesta,
          expediente,
          this.contabilidad,
          this.ejercicio,
          this.codProg,
          this.codEco,
          this.usuControl,
          this.expedienteGestiona,
          dniPropon,
          0,
          headers
        )
        .subscribe({
          next: (data) => {
            this.idPropuesta = data.idPropuesta;

            this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
              next: (datoString) => {
                console.log("SuccessAudit");


                var vecArr: any[] = [];
                vecArr.push(data);
                vecArr.push(this.desc_Apli);
                vecArr.push(contratoObra);
                //Necesitamos hacer una variable auxiliar para que calculatedValues
                //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                this.calculatedValues = vecArr;

                // Manually trigger change detection
                //this.cdr.detectChanges();
                //PropuestaDos
                this.authService
                  .registrar_Propuesta(
                    proponente,
                    responsable,
                    cargo,
                    cargoResponsable,
                    objeto,
                    necesidad,
                    plazo,
                    importeDos,
                    fechaPropuesta,
                    expediente,
                    this.contabilidad,
                    this.ejercicio,
                    this.codProg,
                    this.codEco,
                    this.usuControl,
                    this.expedienteGestiona,
                    dniPropon,
                    0,
                    headers
                  )
                  .subscribe({
                    next: (data) => {
                      this.idPropuesta = data.idPropuesta;

                      this.authService.realizar_Auditoria(data, false, "Propuesta", "ADD", this.idUsuarioActual, headers).subscribe({
                        next: (datoString) => {
                          console.log("SuccessAudit");

                        },
                        error: (err) => {
                          console.error('Error: ', err);
                          this.showErrorModal(err);

                          this.router.navigate(['/login']);
                        },
                      });

                      var vecArr: any[] = [];
                      vecArr.push(data);
                      vecArr.push(this.desc_Apli);
                      vecArr.push(contratoObra);
                      //Necesitamos hacer una variable auxiliar para que calculatedValues
                      //deje de ser null a estar relleno del todo o si no empezaria el ngOnInit del PDF sin estar terminado de crear
                      this.calculatedValuesDos = vecArr;

                      // Manually trigger change detection
                      this.cdr.detectChanges();
                    },
                    error: (err) => {
                      console.error('Error: ', err);
                      this.showErrorModal(err);

                      this.router.navigate(['/login']);
                    },
                  });
              },
              error: (err) => {
                console.error('Error: ', err);
                this.showErrorModal(err);

                this.router.navigate(['/login']);
              },
            });

          },
          error: (err) => {
            console.error('Error: ', err);
            this.showErrorModal(err);

            this.router.navigate(['/login']);
          },
        });


    }


  }



  showErrorModal(errorMsg: HttpErrorResponse) { //Mostrar el modal con el error
    let errorMessage = 'Unknown error occurred';

    if (errorMsg.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${errorMsg.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${errorMsg.status}\nMessage: ${errorMsg.message}`;
      if (errorMsg.error && errorMsg.error.message) {
        // Extract the error message from the server response
        errorMessage += `\nServer Message: ${errorMsg.error.message}`;
      }
    }
    console.log(errorMessage);
    this.openModal(errorMessage);
  }

  openModal(errorMsg: string) {
    localStorage.setItem('error', errorMsg);
    const dialogRef = this.dialog.open(ModalesComponent, {
      data: { modalText: "Login Incorrecto.\n Compruebe que sus credenciales sean correctas." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  openDialog(tipo: string): void {  // tipo puede ser 'proponente' o 'responsable'
    const dialogRef = this.dialog.open(ModalBuscadorComponent, {
      width: '70%',
      height: '55%',
      data: {
        tipoBusqueda: tipo  // Aquí pasamos si estamos buscando un proponente o responsable
      },
    });

    dialogRef.componentInstance.rowSelected.subscribe((data: any) => {
      var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
      const headers = new HttpHeaders(strHead);

      if (tipo === 'proponente') {
        // Lógica para el proponente
        this.propuestaForm.proponente = data.name;
        this.idProponente = data.id;
        this.authService
          .getJobFromProponente(this.idProponente, headers)
          .subscribe({
            next: (job: string) => {
              this.propuestaForm.cargo = job;
            },
            error: (err: any) => {
              console.error('Error:', err);
            },
          });
      } else if (tipo === 'responsable') {
        // Lógica para el responsable
        this.propuestaForm.responsable = data.name;
        this.authService
          .getJobFromProponente(data.id, headers)
          .subscribe({
            next: (job: string) => {
              this.dniProp = data.username;
              this.propuestaForm.cargoResponsable = job;
              this.hrefSolicitado = data.links[0].href; //self
            },
            error: (err: any) => {
              console.error('Error:', err);
            },
          });
      }
    });
  }

  recepcionPDF(data: any): void {
    // Handle the data received from the child component
    this.comprobarExpdt();
    this.pdfRecibido = data;
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    //Tras generar el PDF, generamos el URI / Enlace de envio:
    this.authService.generarURI(headers).subscribe({
      next: (datoModl) => {
        //Teniendo el enlace, subimos el PDF al enlace:
        this.authService.subidaPDF(this.pdfRecibido, datoModl, headers).subscribe({
          next: dataUPL => {
            console.log("EL RESULTADO DE LA SUBIDA ES: ", dataUPL);
            //Aqui se añadiria el PDF
            this.authService.addArchivoToExpdt(dataUPL, this.propuestaForm.objeto, this.idExpdtAsoc, headers).subscribe({
              next: (dataDoc: any) => {
                let parsedDataDoc;
                try {
                  parsedDataDoc = JSON.parse(dataDoc);
                } catch (error) {
                  console.error("Error al analizar la cadena JSON:", error);
                  this.openModal("Error al procesar los datos del documento.");
                  return;
                }
                var id_doc_Gest = parsedDataDoc.idDocGestiona;
                //Con el id_documento_Gestiona se hace uin Put a Propuesta
                this.authService.getDatosPropuesta(this.idPropuesta, headers).subscribe({
                  next: (datosPropuesta: any) => {
                    //Con los datos de propuesta actualizamos el id_doc_gestiona y lo insertamos
                    var idPropuesta = datosPropuesta.idPropuesta;
                    var proponent = datosPropuesta.proponente;
                    var responsabl = datosPropuesta.responsable
                    var carg = datosPropuesta.cargo;
                    var cargoResponsabl = datosPropuesta.cargoResponsable;
                    var objet = datosPropuesta.objeto;
                    var necesity = datosPropuesta.necesidad;
                    var plaz = datosPropuesta.plazo;
                    var usuControl = datosPropuesta.usuarioControl;
                    var fecContr = datosPropuesta.fechaControl;
                    var importeNuevo = datosPropuesta.importe;
                    var fechaProp = datosPropuesta.fechaControl;
                    var exptAsoc = datosPropuesta.expdtAsoc;
                    var estProp = datosPropuesta.estadoPropuesta;
                    var eje = datosPropuesta.ejercicio;
                    var codprog = datosPropuesta.codProg;
                    var codeco = datosPropuesta.codEco;
                    var idDocGes = id_doc_Gest;
                    var dniProp = datosPropuesta.dniProponente;
                    var idConta = datosPropuesta.idContabilidad;
                    var expdtGes = datosPropuesta.expdtGestiona;
                    this.authService.updatePropuesta(idPropuesta, proponent, responsabl, carg, cargoResponsabl, objet, necesity, plaz,
                      usuControl, fecContr, importeNuevo, fechaProp, exptAsoc, estProp, eje,
                      codprog, codeco, idDocGes, dniProp, idConta, expdtGes, headers).subscribe({

                        next: (putDevuelto) => {
                          //Con el put devuelto, devolvemos
                          console.log("Succesfull");

                          this.authService.realizar_Auditoria(putDevuelto, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                            next: (datoString) => {
                              this.authService
                                .getHrefFromProponente(this.dniProp, headers)
                                .subscribe({
                                  next: (href: string) => {
                                    //Necesitamos el hrefSolicitado
                                    this.hrefSolicitado = href; //self
                                    this.CircuitoTramitacion(parsedDataDoc.hrefCircuito, cargoResponsabl, this.hrefSolicitado, responsabl, headers);
                                  },
                                  error: (err: any) => {
                                    console.error('Error: ', err);
                                    this.openModal("Imposible encontrar Enlace al proponente");

                                    this.router.navigate(['/gestionGasto/propuestas']);
                                  },
                                });


                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);

                              this.router.navigate(['/login']);
                            },
                          });
                        },
                        error: (err) => {

                          // Redirigir al usuario a la página de inicio de sesión
                          console.log(err);
                          this.showErrorModal(err);

                          this.router.navigate(['/login']);
                        }

                      });
                  },
                  error: (err) => {

                    // Redirigir al usuario a la página de inicio de sesión
                    console.log(err);
                    console.log("Error en el update");
                    this.showErrorModal(err);

                    this.router.navigate(['/login']);
                  }

                });

              },
              error: (err) => {

                // Redirigir al usuario a la página de inicio de sesión
                console.log(err);
                console.error('Error: ', err);
                this.openModal("No se ha podido añadir el PDF al Expediente en Gestiona");

                this.router.navigate(['/gestionGasto/propuestas']);
              }
            });
          },
          error: err => {

            // Redirigir al usuario a la página de inicio de sesión
            console.log(err);
            console.error('Error: ', err);
            this.openModal("Error al subir el PDF a la URL indicada");

            this.router.navigate(['/gestionGasto/propuestas']);
          }
        });
      },
      error: (err) => {

        // Redirigir al usuario a la página de inicio de sesión
        console.error('Error: ', err);
        this.openModal("Error al encontrar una URI para el PDF");

        this.router.navigate(['/gestionGasto/propuestas']);
      },
    });
  }

  recepcionPDF2(data: any): void {
    // Handle the data received from the child component
    this.comprobarExpdt();
    this.pdfRecibidoDos = data;
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    //Tras generar el PDF, generamos el URI / Enlace de envio:
    this.authService.generarURI(headers).subscribe({
      next: (datoMod) => {
        //Teniendo el enlace, subimos el PDF al enlace:
        this.authService.subidaPDF(this.pdfRecibidoDos, datoMod, headers).subscribe({
          next: dataUPL => {
            console.log("EL RESULTADO DE LA SUBIDA ES: ", dataUPL);
            //Aqui se añadiria el PDF
            this.authService.addArchivoToExpdt(dataUPL, this.propuestaForm.objeto, this.idExpdtAsoc, headers).subscribe({
              next: (dataDoc: any) => {
                let parsedDataDoc;
                try {
                  parsedDataDoc = JSON.parse(dataDoc);
                } catch (error) {
                  console.error("Error al analizar la cadena JSON:", error);
                  this.openModal("Error al procesar los datos del documento.");
                  return;
                }
                var id_doc_Gest = parsedDataDoc.idDocGestiona;
                //Con el id_documento_Gestiona se hace uin Put a Propuesta
                this.authService.getDatosPropuesta(this.idPropuesta, headers).subscribe({
                  next: (datosPropuesta: any) => {
                    //Con los datos de propuesta actualizamos el id_doc_gestiona y lo insertamos
                    var idPropuesta = datosPropuesta.idPropuesta;
                    var proponent = datosPropuesta.proponente;
                    var responsabl = datosPropuesta.responsable
                    var carg = datosPropuesta.cargo;
                    var cargoResponsabl = datosPropuesta.cargoResponsable;
                    var objet = datosPropuesta.objeto;
                    var necesity = datosPropuesta.necesidad;
                    var plaz = datosPropuesta.plazo;
                    var usuControl = datosPropuesta.usuarioControl;
                    var fecContr = datosPropuesta.fechaControl;
                    var importeNuevo = datosPropuesta.importe;
                    var fechaProp = datosPropuesta.fechaControl;
                    var exptAsoc = datosPropuesta.expdtAsoc;
                    var estProp = datosPropuesta.estadoPropuesta;
                    var eje = datosPropuesta.ejercicio;
                    var codprog = datosPropuesta.codProg;
                    var codeco = datosPropuesta.codEco;
                    var idDocGes = id_doc_Gest;
                    var dniProp = datosPropuesta.dniProponente;
                    var idConta = datosPropuesta.idContabilidad;
                    var expdtGes = datosPropuesta.expdtGestiona;
                    this.authService.updatePropuesta(idPropuesta, proponent, responsabl, carg, cargoResponsabl, objet, necesity, plaz,
                      usuControl, fecContr, importeNuevo, fechaProp, exptAsoc, estProp, eje,
                      codprog, codeco, idDocGes, dniProp, idConta, expdtGes, headers).subscribe({

                        next: (putDevuelto) => {
                          //Con el put devuelto, devolvemos
                          console.log("Succesfull");

                          this.authService.realizar_Auditoria(putDevuelto, false, "Propuesta", "UPDATE", this.idUsuarioActual, headers).subscribe({
                            next: (datoString) => {
                              this.authService
                                .getHrefFromProponente(this.dniProp, headers)
                                .subscribe({
                                  next: (href: string) => {
                                    //Necesitamos el hrefSolicitado
                                    //Necesitamos el hrefSolicitado
                                    this.hrefSolicitado = href; //self
                                    this.CircuitoTramitacion(parsedDataDoc.hrefCircuito, cargoResponsabl, this.hrefSolicitado, responsabl, headers);
                                  },
                                  error: (err: any) => {
                                    console.error('Error: ', err);
                                    this.openModal("Imposible encontrar Enlace al proponente");

                                    this.router.navigate(['/gestionGasto/propuestas']);
                                  },
                                });


                            },
                            error: (err) => {
                              console.error('Error: ', err);
                              this.showErrorModal(err);

                              this.router.navigate(['/login']);
                            },
                          });
                        },
                        error: (err) => {

                          // Redirigir al usuario a la página de inicio de sesión
                          console.log(err);
                          this.showErrorModal(err);

                          this.router.navigate(['/login']);
                        }

                      });
                  },
                  error: (err) => {

                    // Redirigir al usuario a la página de inicio de sesión
                    console.log(err);
                    console.log("Error en el update");
                    this.showErrorModal(err);

                    this.router.navigate(['/login']);
                  }

                });

              },
              error: (err) => {

                // Redirigir al usuario a la página de inicio de sesión
                console.log(err);
                console.error('Error: ', err);
                this.openModal("No se ha podido añadir el PDF al Expediente en Gestiona");

                this.router.navigate(['/gestionGasto/propuestas']);
              }
            });
          },
          error: err => {

            // Redirigir al usuario a la página de inicio de sesión
            console.log(err);
            console.error('Error: ', err);
            this.openModal("Error al subir el PDF a la URL indicada");

            this.router.navigate(['/gestionGasto/propuestas']);
          }
        });
      },
      error: (err) => {

        // Redirigir al usuario a la página de inicio de sesión
        console.error('Error: ', err);
        this.openModal("Error al encontrar una URI para el PDF");

        this.router.navigate(['/gestionGasto/propuestas']);
      },
    });
  }

  CircuitoTramitacion(href: string, cargo: string, hrefProponente: string, name: string, headers: HttpHeaders) {
    var esAlcade: boolean = false;

    if (cargo.toLowerCase() == "alcalde") {
      esAlcade = true;
    } else {
      esAlcade = false;
    }

    if (cargo != "" && cargo != null && cargo != undefined) {
      this.authService.CircuitoTramitacion(esAlcade, hrefProponente, href, name, headers).subscribe({
        next: (tramitado) => {
          this.router.navigate(['gestionGasto/propuestas']);
        },
        error: (err) => {

          console.error('Error: ', err);
          this.openModal("No se ha podido tramitar");

          this.router.navigate(['/gestionGasto/propuestas']);
        }
      });
    } else {
      console.error('El cargo se encuentra vacio');
    }
  }

  comprobarExpdt() {
    var strHead = { Authorization: `Bearer ${this.tokenJWT}` };
    const headers = new HttpHeaders(strHead);
    var expedienteBuscar: string = "";
    if (this.propuestaForm.expediente == "") {
      expedienteBuscar = this.expedienteGestiona;
    } else {
      expedienteBuscar = this.propuestaForm.expediente;
    }

    this.authService.CompruebaExpdt(expedienteBuscar, headers).subscribe({
      next: (dataComprobado: any) => {
        if (dataComprobado.error) {
          this.expdtComprobado = false
          //this.nombreExpdt= "No se ha encontrado Expediente Asociado";
        } else {
          this.idExpdtAsoc = dataComprobado.content[0].id;
          this.nombreExpdt = dataComprobado.content[0].name;
          this.expdtComprobado = true
        }
      },
      error: (err) => {
        this.expdtComprobado = false
      }
    });
  }

}
